import React from "react";
import { Card, Grid } from "semantic-ui-react";

import EsvdPagination from "./EsvdPagination";
// import { CurrentReferenceInfo } from "../../models/ReferenceInfo";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import { LIMIT } from "../../shared/Literals";
import EsvdTable from "./EsvdTable";

// interface IProps {
// 	fetchEsvdsCount?: any;
// 	esvdCount?: number;
// 	currentVersion?: CurrentReferenceInfo;
// }

const EsvdTablePanel = () => {
	const { esvdCount, activePage, setActivePage } = useEsvdValuation();

	return (
		<>
			<EsvdTable />
			<Card.Content>
				<Grid>
					<Grid.Row style={{ marginTop: "1em", marginBottom: "1em" }}>
						<Grid.Column width={4}>
							<EsvdPagination
								currentPage={activePage}
								setActivePage={setActivePage}
								totalPages={Math.ceil(esvdCount / LIMIT)}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Card.Content>
		</>
	);
	// }
};

export default EsvdTablePanel;
