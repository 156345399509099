import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";

const Logout = () => {
	const { logout } = useAuth();

	useEffect(() => {
		return () => {
			logout();
		};
	}, []);
	return <Redirect to="/" />;
};

const ResetState = () => {
	return <Logout />;
};

export default ResetState;
