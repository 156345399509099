import { RESET_ESVDS } from "../../actions/esvdActions";
import { ESVD_SUMMARY_STATS } from "../../actions/esvdStatsActions";
import {
  setMultiColumnMaxLensSummaryStats,
  summaryStatsRowsObjects,
} from "../../EsvdFunctions";
import { SummaryStatsColumnStructure } from "../../models/columnStructure";
import { SummaryStatsColumnTemplate } from "../../models/columnTemplate";

const esvdSummaryStatsReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case ESVD_SUMMARY_STATS:
      const cols: SummaryStatsColumnStructure = setMultiColumnMaxLensSummaryStats(
        action.payload,
        SummaryStatsColumnTemplate
      );
      // const heads = headerTransform(action.payload, cols);
      const body = summaryStatsRowsObjects(action.payload, cols);
      return body;
    case RESET_ESVDS:
      return [];
    default:
      return state;
  }
};

export default esvdSummaryStatsReducer;
