import React from "react";
import { Card } from "semantic-ui-react";

const StudiesReadMe = () => {
	return (
		<Card fluid>
			<Card.Content>
				<h4>Read Me</h4>
			</Card.Content>
			<Card.Content>
				<ul className="dash-list">
					<li>
						Enter and save information about a study and its valuations in the
						cards to the left.
					</li>
					<li>
						Clicking on a saved study in the list in the card below loads the
						study and its valuation for continued editing.
					</li>
					<li>
						To make a study and valuations become publicly available, mark a
						study ‘complete’ to publish.
					</li>
				</ul>
			</Card.Content>
		</Card>
	);
};

export default StudiesReadMe;
