import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import * as signalR from "@microsoft/signalr";
import { useAuth } from "./AuthProvider";

interface IValue {
	vslGridHub?: signalR.HubConnection | null;
}

const VslGridHubContext = React.createContext<IValue | null>(null);

const VslGridHubProvider = ({ children }: { children: any }) => {
	const { getToken } = useAuth();
	const [vslGridHub, setVslGridHub] = useState<signalR.HubConnection | null>(
		null
	);

	const createHubConnection = () => {
		const url = `${process.env.REACT_APP_API_URL}/UploadNotificationHub`;
		const hubCon = new signalR.HubConnectionBuilder()
			.withUrl(url, { accessTokenFactory: () => getToken() }) //this helps to populate the user details in the signalr hub: username etc
			.withAutomaticReconnect()
			.build();
		return hubCon;
	};

	function notifyVslHubClients() {
		const vh = createHubConnection();
		if (vh) {
			vh.start()
				.then(() => vh.invoke<any>("VslClientExit"))
				.then(() => {
					vh.stop();
				});
		}
	}

	useEffect(() => {
		const vh = createHubConnection();
		setVslGridHub(vh);
		return () => {
			notifyVslHubClients();
		};
	}, []);

	return (
		<VslGridHubContext.Provider
			value={{
				vslGridHub,
			}}>
			{children}
		</VslGridHubContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useVslGridHub = () => {
	const vslH = useContext(VslGridHubContext);
	if (vslH == null) {
		throw new Error("useVsl() called outside of its hook.");
	}
	return vslH;
};

export { VslGridHubProvider, useVslGridHub };
