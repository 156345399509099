import React, { Component, Fragment } from "react";
import { Button, Checkbox, Grid, Progress } from "semantic-ui-react";
import { connect } from "react-redux";
import { openModal, closeModal } from "../../layouts/EsvdModal";

import { resetEsvdAppendedFilteredData } from "../../actions/esvdActions";

import {
	EsvdQueryDataWithOffset,
	EsvdQueryParams,
} from "../../models/EsvdQueryFilters";
import { ColumnStructure } from "../../models/columnStructure";
import {
	bodyRowsFlattened,
	setMultiColumnMaxLenFlattened,
} from "../../EsvdFunctions";
import { ColumnTemplate } from "../../models/columnTemplate";
import { CSVLink } from "react-csv";
import { LIMIT } from "../../shared/Literals";
import agent from "../../api/agent";
import MakePDF from "./MakePDF";
import { CurrentReferenceInfo } from "../../models/ReferenceInfo";
import filterCheck from "../../shared/FilterCheck";

import {
	defaultEsvdParams,
	// EsvdQueryParams,
	// Params,
} from "../../models/EsvdQueryFilters";

interface IProps {
	// fetchFilteredEsvds?: any;
	resetEsvdAppendedFilteredData?: any;
	esvdFilteredCount: number;
	esvdAppendedFilteredData: EsvdQueryDataWithOffset;
	closeModal: Function;
	openModal: Function;
	esvdQueryParams: EsvdQueryParams;
	esvds: { esvdsAllCols: any[]; headerArray: any[]; bodyArray: any[] };
	filename: string;
	ButtonText: string;
	isFull?: boolean;
	currentVersion?: CurrentReferenceInfo | null;
	saveQueryParams?: Function;
}

interface IState {
	esvds: {
		headerArray: any[];
		bodyArray: any[];
	};
	hasMore: boolean;
	page: number;
	isLoading: boolean;
	csvData: any[];
	isComplete: boolean;
	isStart: boolean;
	isDone: boolean;
	isDownloadCitation: boolean;
	isOpeneDownloadPrompt: boolean;
	count: number;
	pages: number;
	progress: number;
}

let downloadPdf = false;

class EsvdExportEsvd extends Component<IProps> {
	state: IState;
	csvLinkRef: any | null = null;

	constructor(props: IProps) {
		super(props);

		this.csvLinkRef = React.createRef<{ link: HTMLAnchorElement }>();

		this.state = {
			esvds: {
				headerArray: [],
				bodyArray: [],
			},
			hasMore: false,
			page: 1,
			isLoading: false,
			csvData: [],
			isComplete: false,
			isStart: false,
			isDone: false,
			count: 0,
			pages: 0,
			progress: 0,
			isDownloadCitation: false,
			isOpeneDownloadPrompt: true,
		};
	}

	noFilterPrompt = () => {
		const { openModal } = this.props;
		openModal({
			icon: "exclamation",
			header: "Attention!",
			content: (
				<Grid.Row centered>
					<Grid.Column width={12}>
						<p className="pos_fixed">
							There are no valuations yet to download.
						</p>
						<p className="pos_fixed">
							You need to apply a filter and get the valuations first before you
							can download.
						</p>
					</Grid.Column>
				</Grid.Row>
			),
			isOpen: true,
			actions: null,
		});
	};

	toggle = () =>
		this.setState(() => ({
			isDownloadCitation: !this.state.isDownloadCitation,
		}));
	handleChange = (e: any, checkedState: any) => {
		downloadPdf = checkedState.checked;
		this.setState(() => ({ isDownloadCitation: checkedState.checked }));
	};
	showDownloadPrompt = () => {
		const { openModal, closeModal, currentVersion } = this.props;
		// const { isDownloadCitation, isOpeneDownloadPrompt } = this.state;
		openModal({
			icon: "exclamation",
			header: "Attention!",
			content: (
				<Grid.Row>
					<Grid.Column width={12}>
						<p className="">
							By downloading data from this ESVD website you specifically agree
							to the Terms of Use.
						</p>
						<p className="">
							You are downloading data from Database Version:{" "}
							{currentVersion?.version}
						</p>
						<p className="">
							Correct Citation: {currentVersion?.referenceText}
						</p>

						<p className="">
							<Checkbox
								label="Also download a pdf with the correct citation."
								onChange={this.handleChange}
							/>
						</p>
					</Grid.Column>
				</Grid.Row>
			),
			isOpen: true,
			actions: () => (
				<>
					<Button
						color="green"
						style={{ marginRight: "20px" }}
						onClick={async () => {
							const {
								resetEsvdAppendedFilteredData,
								esvdQueryParams,
								saveQueryParams,
								isFull,
							} = this.props;
							if (!esvdQueryParams.saved && filterCheck(esvdQueryParams)) {
								//save query if not saved already
								saveQueryParams && saveQueryParams({ ...esvdQueryParams });
							}

							closeModal();
							if (currentVersion && downloadPdf) {
								try {
									currentVersion && MakePDF(currentVersion);
								} catch (error) {
									// console.log(error);
								}
							}
							downloadPdf = false;
							//the esvdAppendedFilteredData must be reset before retrieving the filter data.
							resetEsvdAppendedFilteredData();
							this.setState(() => ({ isLoading: true }));
							await this.fetchFilteredEsvds(
								isFull ? { ...defaultEsvdParams } : esvdQueryParams
							).then((r) => {
								const cols: ColumnStructure = setMultiColumnMaxLenFlattened(
									r.data,
									ColumnTemplate
								);
								// specify the key to delete from the columns in the downloaded csv
								const keyToDelete: string = "inclExclText";

								const body = bodyRowsFlattened(r.data, {
									...cols,
									[keyToDelete]: undefined,
								});

								this.setState(() => ({
									csvData: body,
									count: r.count,
									isLoading: false,
								}));
							});
							if (this.csvLinkRef?.current) {
								this.csvLinkRef.current.link.click();
							}
						}}>
						Download
					</Button>
					<Button
						style={{ marginLeft: "10px" }}
						color="red"
						onClick={() => closeModal()}>
						Cancel
					</Button>
				</>
			),
		});
	};

	fetchFilteredEsvds = async (filters: EsvdQueryParams) => {
		const count = await agent.Esvds.esvdCount(filters);
		const data: any[] = [];
		if (count > 0) {
			const pages = Math.ceil(count / LIMIT);
			filters.params.limit = LIMIT;
			let nextOffset = 0;
			while (nextOffset < pages) {
				filters.params.offset = nextOffset;
				const response = await agent.Esvds.list(filters);
				data.push(...response);
				const progress = Math.ceil(((nextOffset + 1) / pages) * 100);
				this.setState(() => ({
					progress,
				}));
				nextOffset++;
			}
		}
		return { count, data };
	};

	handleClick = async () => {
		const {
			// resetEsvdAppendedFilteredData,
			esvdQueryParams,
			// esvds: { bodyArray },
			isFull,
		} = this.props;

		if (!isFull) {
			if (!filterCheck(esvdQueryParams)) {
				this.noFilterPrompt();
				return;
			}
		}

		this.setState(() => ({
			isStart: true,
			isDone: false,
			isDownloaded: false,
		}));

		this.showDownloadPrompt();
	};

	render() {
		const { isLoading, progress } = this.state;
		const { ButtonText, filename } = this.props;
		return (
			<Fragment>
				<Button
					positive
					loading={isLoading}
					content={ButtonText}
					onClick={() => this.handleClick()}
					disabled={isLoading}
					className={`${isLoading ? "invisible" : ""}`}
				/>
				<Progress
					percent={progress}
					// progress
					className={`${isLoading ? "" : "invisible"}`}
				/>
				<CSVLink
					ref={this.csvLinkRef}
					data={this.state.csvData}
					filename={filename}
					target="_blank"
					className={`${"invisible"}`}
					enclosingCharacter={'"'}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		esvdAppendedFilteredData: state.esvdAppendedFilteredData,
		esvdFilteredCount: state.esvdFilteredCount,
		esvds: state.esvds,
	};
};

export default connect(mapStateToProps, {
	openModal,
	closeModal,
	resetEsvdAppendedFilteredData,
})(EsvdExportEsvd);
