import React, { useEffect } from "react";
import {
	Button,
	Card,
	Dimmer,
	Form,
	Grid,
	Label,
	Loader,
	Progress,
} from "semantic-ui-react";
import { ErrorMessage, Formik } from "formik";
import moment from "moment";
import agent from "../../api/agent";
import EsvdDropZoneField from "../../shared/DropZone";
import ValidateDataset from "./ValidateDataset";
import { useUploadInfo } from "../../providers/UploadInfoProvider";
import { EsvdDataStatistics } from "../../models/EsvdDataStatistics";
import { useVslGridHub } from "../../providers/VslGridHubProvider";

const VslUploadDataset = () => {
	const { uploading, setUploading, loading, setLoading } = useUploadInfo();
	const { vslGridHub } = useVslGridHub();
	const [data, setData] = React.useState<any>({});
	//this indicates the error is fatal and must be fixed before proceeding with upload
	const [isValidData, setIsValidData] = React.useState<boolean>(true);

	const [esvdCurrentUploadInfo, setEsvdCurrentUploadInfo] =
		React.useState<EsvdDataStatistics | null>(null);

	const [esvdLastUploadInfo, setEsvdLastUploadInfo] =
		React.useState<EsvdDataStatistics | null>(null);

	const [progress, setProgress] = React.useState<number>(0);
	const [file, setFile] = React.useState<any>(null);

	useEffect(() => {
		//get info about the last upload
		(async () => {
			var uploadStats: EsvdDataStatistics =
				await agent.VslUploadLists.GetLastUploadStatistics();
			setEsvdLastUploadInfo(uploadStats);
		})();
	}, []);

	useEffect(() => {
		if (vslGridHub) {
			if (vslGridHub.state === "Disconnected") {
				vslGridHub.start();
			}
			vslGridHub.on("VslUploadProgress", (uploadInfo: EsvdDataStatistics) => {
				setEsvdCurrentUploadInfo(uploadInfo);
				setUploading(uploadInfo.uploading);
				if (uploadInfo.esvdTotalCount) {
					const pro = Math.floor(
						((((uploadInfo.esvdInserted || 0) +
							(uploadInfo?.esvdUpdated || 0)) /
							(parseInt(uploadInfo?.esvdTotalCount) || 1)) *
							100) |
							1
					);
					setProgress(pro);
				}
			});

			// vslGridHub.on("VslUploadStatus", (status: any) => {
			// 	setUploading(false);
			// });
		}
	}, [vslGridHub]);

	const resetDups = () => {
		setData({});
		setProgress(0);
		setEsvdCurrentUploadInfo(null);
	};

	const handleFile = (fil: any /*:File*/) => {
		setLoading(true);
		setFile(fil);
	};

	const onDrop = (evt: any) => {
		setLoading(true);
		evt.stopPropagation();
		evt.preventDefault();
		const files = evt.dataTransfer.files;
		if (files && files[0]) {
			handleFile(files[0]);
		}
	};

	const handleFormSubmit = async (values: any, setErrors: Function) => {
		setUploading(true);
		agent.VslUploadLists.uploadJson({
			jsonDataset: JSON.stringify(data.uploaddata),
			filename: data.filename,
			esvdCount: data.esvdCount,
		})
			.then((res) => {
				setFile(null);
				// refreshStats();
			})
			.catch((err) => {
				setFile(file);
				// setLoading(false);
			})
			.finally(() => {
				setUploading(false);
				setLoading(false);
			});
		setErrors(null);
	};

	const renderFormik = () => {
		// const { uploaddata } = data;
		// const { esvds } = uploaddata || {};
		return (
			<Formik
				enableReinitialize={true}
				initialValues={{ error: null }}
				onSubmit={(values, { setErrors }) => {
					handleFormSubmit(values, setErrors);
				}}>
				{({ handleSubmit, isValid, isSubmitting, dirty, errors, touched }) => (
					<Form
						className="custom-ui-element"
						onSubmit={handleSubmit}
						autoComplete={"off"}>
						<Card fluid>
							<Card.Content>
								<div onDrop={onDrop}>
									<Dimmer active={loading}>
										<Loader />
									</Dimmer>
								</div>
								<Grid>
									<Grid.Row columns={2}>
										<Grid.Column>
											<label>Current upload stats</label>

											<hr />
											<div
												style={{
													visibility: esvdCurrentUploadInfo
														? "visible"
														: "hidden",
												}}>
												<label>{`File name: ${
													esvdCurrentUploadInfo?.filename || ""
												}`}</label>
												<br />
												{esvdCurrentUploadInfo?.uploadedEsvdCount ? (
													<>
														<label>
															{`Uploading ${
																esvdCurrentUploadInfo?.uploadedEsvdCount || ""
															} valuations`}
														</label>
														<br />
													</>
												) : null}

												<label>{`Upload status: ${
													esvdCurrentUploadInfo?.statusText || ""
												}`}</label>
												<br />
												{esvdCurrentUploadInfo?.uploadedSuccessfully ===
													false && (
													<>
														<label
															id="msg"
															style={{
																color: esvdCurrentUploadInfo?.uploadFailed
																	? "red"
																	: "",
															}}>{`Error message: ${
															(esvdCurrentUploadInfo?.uploadFailed &&
																"There are errors. Click on 'Copy error' and paste for details") ||
															""
														}`}</label>
														<br />
														{esvdCurrentUploadInfo?.uploadFailed && (
															<button
																className="ui button btn btn-danger btn-sm"
																onClick={() => {
																	navigator.clipboard.writeText(
																		esvdCurrentUploadInfo?.uploadFailed || ""
																	);
																}}
																type="reset"
																style={{
																	height: "2rem",
																	width: "12rem",
																}}>
																Copy error
															</button>
														)}
														<br />
													</>
												)}
												<label id="msg">{`Inserted: ${
													(esvdCurrentUploadInfo?.uploadedEsvdCount &&
														esvdCurrentUploadInfo?.esvdInserted) ||
													""
												}`}</label>
												<br />
												<label id="msg">{`Updated: ${
													(esvdCurrentUploadInfo?.uploadedEsvdCount &&
														esvdCurrentUploadInfo?.esvdUpdated) ||
													""
												}`}</label>
												<br />
												{<Progress percent={progress} progress success />}
											</div>
										</Grid.Column>
										<Grid.Column>
											<label>Last upload stats.</label>
											<label
												className="ui right floated"
												style={{ display: "inline-block" }}>
												{`Total valuations: ${
													esvdLastUploadInfo?.esvdTotalCount || 0
												}`}
											</label>

											<hr />
											<label>{`File name: ${
												esvdLastUploadInfo?.filename || ""
											}`}</label>
											<br />
											<label>{`Date: ${
												esvdLastUploadInfo?.dateUploaded
													? moment(esvdLastUploadInfo?.dateUploaded).format(
															"DD-MM-YYYY HH:mm:ss"
													  )
													: ""
											}`}</label>
											<br />
											<label>{`Uploaded: ${esvdLastUploadInfo?.uploadedEsvdCount} valuations`}</label>
											<br />
											<label>{`Upload status: ${
												(esvdLastUploadInfo?.uploadedSuccessfully === false
													? "Failed"
													: esvdLastUploadInfo?.uploadedSuccessfully
													? "Finished successfully."
													: "") || ""
											}`}</label>
											<br />
											{esvdLastUploadInfo?.uploadedSuccessfully === false && (
												<>
													<label
														id="msg"
														style={{
															color: esvdLastUploadInfo?.uploadFailed
																? "red"
																: "",
														}}>{`Error message: ${
														(esvdLastUploadInfo?.uploadFailed &&
															"There are errors. Click on 'Copy error' and paste for details") ||
														""
													}`}</label>
													<br />
													{esvdLastUploadInfo?.uploadFailed && (
														<button
															className="ui button btn btn-danger btn-sm"
															onClick={() => {
																navigator.clipboard.writeText(
																	esvdLastUploadInfo?.uploadFailed || ""
																);
															}}
															type="reset"
															style={{
																height: "2rem",
																width: "12rem",
															}}>
															Copy error
														</button>
													)}
													<br />
												</>
												// <>
												// 	<label id="msg">{`Error message: ${
												// 		(esvdLastUploadInfo?.uploadFailed &&
												// 			esvdLastUploadInfo?.uploadFailed) ||
												// 		""
												// 	}`}</label>
												// 	<br />
												// </>
											)}
											<label id="msg">{`Inserted: ${
												esvdLastUploadInfo?.esvdInserted || ""
											}`}</label>
											<br />
											<label id="msg">{`Updated: ${
												esvdLastUploadInfo?.esvdUpdated || ""
											}`}</label>
											<br />
											<label>
												{esvdLastUploadInfo?.uploadFailed && `Status: Failed`}
											</label>
											<br />
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Card.Content>
							<Card.Content>
								{!uploading && (
									<EsvdDropZoneField
										name="dataupload"
										customHeight={false}
										onSelectionChange={(d: any) => {
											resetDups();
											handleFile(d[0]);
										}}
										value={[]}
										accept=".xlsx"
									/>
								)}
							</Card.Content>
							{esvdLastUploadInfo?.uploadFailed && (
								<Card.Content extra>
									<ErrorMessage
										name="error"
										render={() => (
											<Label
												style={{ marginBottom: 5, borderColor: "none" }}
												basic
												color="red"
												size="tiny"
												content={"Upload failed"}
											/>
										)}
									/>
								</Card.Content>
							)}
						</Card>
						{!uploading && file && !loading && (
							<div
								className="ui two buttons"
								style={{
									visibility: isValidData ? "visible" : "hidden",
								}}>
								<Button
									disabled={!isValidData}
									// loading={loading}
									primary
									type={"submit"}>
									Upload Dataset
								</Button>
							</div>
						)}
					</Form>
				)}
			</Formik>
		);
	};

	return (
		<>
			{renderFormik()}
			{(!uploading || !loading) && (
				<ValidateDataset
					file={file}
					setData={setData}
					data={data}
					setIsValidData={setIsValidData}
					isValidData={isValidData}
				/>
			)}
		</>
	);
};

export default VslUploadDataset;
