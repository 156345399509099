import React, { useContext } from "react";
import _ from "lodash";
import moment from "moment";
import agent from "../api/agent";
import { FilterQueryParams } from "../models/EsvdQueryFilters";
import { FilterQuery } from "../models/FilterQuery";

interface IValue {
	// filterQueries: FilterQuery[];
	getFilterQueries: Function;
	getFilterQueriesJSON: Function;
	getTotalCount: Function;
	// totalFilterQueries: number;
}

const FilterQueryContext = React.createContext<IValue | null>(null);

// The FilterQueryProvider is responsible for FilterQuery management
const FilterQueryProvider = ({ children }: { children: any }) => {
	const getTotalCount = async () => {
		try {
			return await agent.FilterQueries.totalCount();
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getFilterQueries = async (filterQuery?: FilterQueryParams) => {
		try {
			const fq = await agent.FilterQueries.list(filterQuery);
			if (!fq) return [];
			const items = processFilters(fq);
			return items.sort((a, b) => {
				return !!a.createdOn > !!b.createdOn ? 1 : -1; //sort by study in descending order
			});
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	function processFilters(fq: FilterQuery[]) {
		let arr: FilterQuery[] = [];
		for (const item of fq) {
			item.filterObject = JSON.parse(item.filterString);
			arr.push(item);
		}
		return Object.values<FilterQuery>({
			..._.mapKeys(arr, "id"),
		});
	}

	const getFilterQueriesJSON = async () => {
		try {
			const fq = await agent.FilterQueries.list();
			if (!fq) return [];
			const items = processFilters(fq).sort((a, b) => {
				return !!a.createdOn < !!b.createdOn ? -1 : 1; //sort by study in descending order
			});
			// : any[] = [];
			const data = items.map((s, i) => {
				const { filterObject } = s;
				const dat: any = {};
				if (!filterObject) return;
				// if (filterObject) {
				const {
					biomeV2EcosystemV2Ecozones,
					biomeV2EcosystemV2EcozonesCodes,
					// biomeEcosystems,
					ecosystemServices,
					ecosystemServicesCodes,
					freeText,
					countries,
					// countriesCodes,
					continents,
					// continentsCodes,
					// ciceses,
					cicesesCodes,
					seeas,
					seeasCodes,
					protectionStatuses,
					// protectionStatusesCodes,
					valuationMethods,
					// valuationMethodsCodes,
					resultsCount,
				} = filterObject;

				// dat["id"] = s.id;
				dat["User"] = s.user;
				dat["Date/time"] = moment(s.createdOn).format("Do-MMM-yyyy HH:mm:ss");
				dat["Valuation Rows(s)"] = resultsCount;
				dat["Biome/Ecozone code"] = Array.isArray(
					biomeV2EcosystemV2EcozonesCodes
				)
					? biomeV2EcosystemV2EcozonesCodes.join(";")
					: biomeV2EcosystemV2EcozonesCodes;
				dat["Biome/Ecozone"] = Array.isArray(biomeV2EcosystemV2Ecozones)
					? biomeV2EcosystemV2Ecozones.join(";")
					: biomeV2EcosystemV2Ecozones;
				dat["Countries"] = Array.isArray(countries)
					? countries.join(";")
					: countries;
				dat["Continent"] = Array.isArray(continents)
					? continents.join(";")
					: continents;
				dat["Protection Status"] = Array.isArray(protectionStatuses)
					? protectionStatuses.join(";")
					: protectionStatuses;
				dat["TEEB code"] = Array.isArray(ecosystemServicesCodes)
					? ecosystemServicesCodes.join(";")
					: ecosystemServicesCodes;
				dat["TEEB"] = Array.isArray(ecosystemServices)
					? ecosystemServices.join(";")
					: ecosystemServices;
				dat["CICES"] = Array.isArray(cicesesCodes)
					? cicesesCodes.join(";")
					: cicesesCodes;
				dat["SEEA code"] = Array.isArray(seeasCodes)
					? seeasCodes.join(";")
					: seeasCodes;
				dat["SEEA"] = Array.isArray(seeas) ? seeas.join(";") : seeas;
				dat["Valuation method"] = Array.isArray(valuationMethods)
					? valuationMethods.join(";")
					: valuationMethods;
				dat["Free Text Search"] = freeText;
				// data.push(dat);
				// } else {
				// 	return null;
				// }
				return dat;
			});
			return data;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<FilterQueryContext.Provider
			value={{
				getFilterQueries,
				getFilterQueriesJSON,
				getTotalCount,
			}}>
			{children}
		</FilterQueryContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useFilterQuery = () => {
	const filterQuery = useContext(FilterQueryContext);
	if (filterQuery == null) {
		throw new Error("useFilterQuery() called outside of a filterQuery hook?");
	}
	return filterQuery;
};

export { FilterQueryProvider, useFilterQuery };
