import _ from "lodash";

import {
  IS_EDITOR,
  IS_LOADING_STUDIES,
  STUDIES_FETCH,
  // STUDIES_RESET,
  STUDY_CREATE,
  STUDY_DELETE,
  STUDY_EDIT,
  STUDY_FETCH,
  // USER_STUDIES_FETCH,
} from "../actions/studyActions";

const studiesReducer = (state = {}, action: any) => {
  switch (action.type) {
    case STUDY_FETCH:
      return { ...state, [action.payload.id]: action.payload };
    case STUDY_CREATE:
      return { ...state, [action.payload.id]: action.payload };
    case STUDY_EDIT:
      return { ...state, [action.payload.id]: action.payload };
    case STUDY_DELETE:
      return _.omit(state, action.payload);
    // case USER_STUDIES_FETCH:
    case STUDIES_FETCH:
      return { ..._.mapKeys(action.payload, "id") }; // case USER_STUDIES_FETCH:
    // case STUDIES_RESET:
    //   return { ..._.mapKeys(action.payload, "id") };
    default:
      return state;
  }
};
export default studiesReducer;

export const isEditorReducer = (state = false, action: any) => {
  switch (action.type) {
    case IS_EDITOR:
      return action.payload;
    default:
      return state;
  }
};
export const isLoadingStudiesReducer = (state = false, action: any) => {
  switch (action.type) {
    case IS_LOADING_STUDIES:
      return action.payload;
    default:
      return state;
  }
};
