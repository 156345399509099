import React, { Fragment } from "react";
import { Card, Grid, Item, Popup, Table } from "semantic-ui-react";
import { Statistics } from "../../models/esvd";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
// import { ItemLabels } from "../../shared/Literals";
import { calculateStats, processStatsData } from "./processStatsData";
import StatsPopup from "./StatsPopup";

export const measureStats = {
	mean: "mean",
	count: "count",
	max: "max",
	min: "min",
	median: "median",
};

const headerCellWith = "20%";

const StatisticsPanel = () => {
	const { esvdsSummaryStats } = useEsvdValuation();

	const [statsBody, setStatsBody] = React.useState<any[]>([]);
	const [statsHeader, setStatsHeader] = React.useState<any[]>([]);
	const [totalValidValuationsUsed, setTotalValidValuationsUsed] =
		React.useState<number>(0);

	React.useEffect(() => {
		const result =
			esvdsSummaryStats.length > 0
				? processStatsData(esvdsSummaryStats)
				: { header: [], body: [] };
		let validValuationsUsed = 0;
		const data = result.body.map((a: any, index: number) => (
			<Table.Row key={index}>
				{buildTableCells(a, (cnt: number) => {
					validValuationsUsed += cnt;
				})}
			</Table.Row>
		));
		setStatsHeader(result.header);
		setStatsBody(data);
		setTotalValidValuationsUsed(validValuationsUsed);
	}, [esvdsSummaryStats]);

	const buildTableCells = (row: any, countValid: Function) => {
		return Object.entries(row).map(([key, value]) => {
			let arr = [];
			arr.push(
				<Table.Cell style={{ width: `${headerCellWith}` }} key={key}>
					{key}
				</Table.Cell>
			);
			const valuations: any = value;
			const valValues = Object.values(valuations);
			const stats: Statistics[] = valValues.map((vals: any, i) =>
				calculateStats(vals, measureStats.mean)
			);
			const countNum = stats.reduce((a, b) => a + (b?.countNum || 0), 0);
			countValid && countValid(countNum);
			stats.map((stat, i) =>
				arr.push(
					<Table.Cell key={i} style={{ cursor: "pointer" }}>
						<Popup
							wide
							size="small"
							trigger={<span>{stat.display}</span>}
							content={<StatsPopup {...stat} />}
							// basic
						/>
					</Table.Cell>
				)
			);
			return arr;
		});
	};

	return (
		<Fragment>
			<Card fluid style={{ overflow: "scroll", minHeight: "30vh" }}>
				<Card.Content>
					<Item>
						<Item.Content>
							<Item.Header>
								<h4 className="ui left floated">
									Summary statistics:{" "}
									<span>{`${
										totalValidValuationsUsed || 0
									}   valuation(s)`}</span>
								</h4>
							</Item.Header>
						</Item.Content>
					</Item>
				</Card.Content>
				<Card.Content>
					<Grid>
						<Grid.Row>
							<Grid.Column>
								<div
									// className=""
									style={{
										overflow: "inherit",
										minHeight: "30vh",
										maxHeight: "60vh",
									}}>
									<Table definition celled striped singleLine>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell
													style={{
														width: `${headerCellWith}`,
													}}></Table.HeaderCell>
												{statsHeader.map((a: any, index: number) => (
													<Table.HeaderCell key={index}>{a}</Table.HeaderCell>
												))}
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{statsBody}
											{/* {statsData.body.map((a: any, index: number) => (
												<Table.Row key={index}>{renderContent(a)}</Table.Row>
											))} */}
										</Table.Body>
									</Table>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Card.Content>
			</Card>
		</Fragment>
	);
};

export default StatisticsPanel;

/**
 * stefanos writes the following:
 * I apply the function in Excel =TRIMMEAN(7:100,2.5). Now, 2.5% of 3 (number of values) is 0.07.
 * Obviously, Excel cannot exclude 0.07 records, so no values will be excluded.
 * Therefore, the number of values that will be considered for the calculation of statistics will be 3 (N=3).
 * Then I calculate the mean using Excel's =AVERAGE(7:100) which equals to 42,33.
 * For your second email, the explanation is like above. No values can be excluded from a dataset with only 1 value, so N will equal 1.
 * In order for values to actually be excluded with this filter, a dataset of at least 40 values is needed (2.5% of 40 = 1).
 * 2.5% of the number of values must be greater than 1 for this filter to have effect (so that Excel can exclude at least 1 value point).
 */
