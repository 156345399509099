import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect } from "react";
import { Form, Label, Card, Checkbox, Grid } from "semantic-ui-react";
import * as Yup from "yup";

// import { useVttBiome } from "../../../providers/VttBiomeProvider";
import { useState } from "react";
import {
	VttBiome,
	VttVariable,
	// VttBiome,
	VttVariableDetailMap,
} from "../../../models/vtt-models/VttBiome";
// import NumberInputInline from "../../../shared/InputInlineNumber";
import EsvdInputInline from "../../../shared/InputInline";
import { ErrorHandler } from "../../../shared/ExceptionHandling";
import { useVttVariableDetailMap } from "../../../providers/VttVariableDetailMapProvider";
import { useLocation } from "react-router-dom";
import SelectDropdown from "../../../shared/SelectDropdown";
import { TreeType } from "../../../models/ecosystemService";
// import EsvdInput from "../../../shared/Input";
import Select from "react-select"; //, { OnChangeValue }
import { useVttVariable } from "../../../providers/VttVariableProvider";
import { DropdownType } from "../../../models/dropdownType";
import { useVttSubBiome } from "../../../providers/VttSubBiomeProvider";
import CheckboxInline from "../../../shared/CheckboxInline";
import NumberInputInline from "../../../shared/InputInlineNumber";
import { ViewHandles, useVtt } from "../../../providers/VttProvider";
import { useVttBiome } from "../../../providers/VttBiomeProvider";

// interface IProps {
// 	emailSuggestedStudy: Function;
// 	resetSuggestStudy: Function;
// 	onStudyUpdated: Function;
// 	suggestedStudy: SuggestStudy | null;
// 	openModal: Function;
// }
const defaultBiome: VttVariableDetailMap = {
	vttBiomeId: "",
	vttBiome: null,
	vttSubBiomeId: "",
	vttSubBiome: null,
	vttVariableId: "",
	vttVariable: null,
	coefficient: "",
	sampleMean: "",
	min: "",
	max: "",
	isTransformation: false,
};

const VttVariableDetailEntry = () => {
	const { currentView, setCurrentView } = useVtt();
	const { getVttBiome } = useVttBiome();
	const {
		createVttVariableDetailMap,
		getVttVariableDetailMap,
		updateVttVariableDetailMap,
	} = useVttVariableDetailMap();
	const { getUnattahcedVttVariables } = useVttVariable();
	const { getVttSubBiomes } = useVttSubBiome();

	const [vttVariableDetail, setVttVariableDetail] = useState<
		VttVariableDetailMap | any
	>(null);
	const [vttVariables, setVttVariables] = useState<any[]>([]);
	const [vttSubBiomes, setVttSubBiomes] = useState<any[]>([]);
	const [vttBiome, setVttBiome] = useState<VttBiome>();
	const [selectedVariable, setSelectedVariable] = React.useState();
	const [selectedSubbiome, setSelectedSubbiome] = React.useState();
	const [isTransformation, setIsTransformation] = React.useState(false);

	useEffect(() => {
		(async () => {
			const { detailid, biomeid } = currentView;
			if (biomeid) {
				var biome = await getVttBiome(biomeid);
				setVttBiome(biome);
			}

			if (detailid) {
				var vtt = await getVttVariableDetailMap(detailid);
				setVttVariableDetail(vtt);
				setIsTransformation(vtt?.isTransformation || false);
				const vttvar = await getUnattahcedVttVariables(
					biomeid,
					vtt.vttVariableId
				);
				setVttVariables(vttvar);
			} else {
				//set to default
				setVttVariableDetail(defaultBiome);
				const vttvar = await getUnattahcedVttVariables(biomeid);
				setVttVariables(vttvar);
			}
			const vttsub = await getVttSubBiomes();
			setVttSubBiomes(vttsub);
		})();
	}, []);

	useEffect(() => {
		if (vttVariableDetail) {
			const { vttVariableId, vttSubBiomeId } = vttVariableDetail;
			setSelectedVariable(vttVariableId);
			setSelectedSubbiome(vttSubBiomeId);
		}
	}, [vttVariableDetail]);

	const handleFormSubmit: Function = async (
		values: any,
		setErrors: Function
	) => {
		try {
			const { biomeid } = currentView;
			const { id } = values;
			if (id) {
				await updateVttVariableDetailMap({
					...values,
					isTransformation,
					vttBiomeId: biomeid,
				});
			} else {
				await createVttVariableDetailMap({
					...values,
					isTransformation,
					vttBiomeId: biomeid,
				});
			}
			// }
			setCurrentView({ view: ViewHandles.manage, biomeid });
		} catch (error) {
			setErrors({ error: ErrorHandler(error) });
		}
	};

	return (
		<>
			<Card.Content>
				<h4>{vttBiome && vttBiome.name}</h4>
				<Card.Description>
					{`Constant Value:${vttBiome && vttBiome.constantValue}`}
				</Card.Description>
			</Card.Content>

			<Card.Content style={{ height: "50vh" }}>
				<Formik
					enableReinitialize={true}
					validationSchema={validationSchema}
					initialValues={vttVariableDetail || defaultBiome}
					onSubmit={(values, { setErrors }) => {
						handleFormSubmit(values, setErrors);
					}}>
					{({ handleSubmit, errors }) => (
						<Form
							className="custom-ui-element"
							onSubmit={handleSubmit}
							autoComplete={"off"}
							encType="multipart/form-data">
							<div style={{ display: "none" }}>
								<EsvdInputInline name="id" type="hidden" />
							</div>
							<Field
								name="vttVariableId"
								label="Variable (*)"
								options={vttVariables}
								isClearable={true}
								component={SelectDropdown}
								value={selectedVariable}
								placeholder="Variable"
								onChange={(value: any) => {
									setSelectedVariable(value);
								}}
							/>

							<Field
								name="vttSubBiomeId"
								label="Sub biome"
								options={vttSubBiomes}
								isClearable={true}
								component={SelectDropdown}
								value={selectedSubbiome}
								placeholder="Sub biome"
								onChange={(value: any) => {
									setSelectedSubbiome(value);
								}}
							/>

							<EsvdInputInline
								className="number"
								name="coefficient"
								label="Coefficient (*)"
								fluid
								placeholder="Coefficient"
							/>
							<EsvdInputInline
								className="number"
								name="sampleMean"
								label="Sample Mean (*)"
								fluid
								placeholder="Sample Mean"
							/>
							<EsvdInputInline
								className="number"
								name="min"
								label="Min (*)"
								fluid
								placeholder="Min"
							/>
							<EsvdInputInline
								className="number"
								name="max"
								label="Max (*)"
								fluid
								placeholder="Max"
							/>
							<CheckboxInline
								name="isTransformation"
								label="Is Transformed"
								onChange={(e: any, data: any) => {
									setIsTransformation(data.checked);
								}}
								// onChange={() => {
								// 	setIsTransformation(!isTransformation);
								// }}
								checked={isTransformation}
							/>
							<EsvdInputInline
								// className="number"
								name="remarksText"
								label="Remarks"
								fluid
								placeholder="Remarks"
							/>
							<ErrorMessage
								name="error"
								render={() => (
									<Label
										style={{ marginBottom: 5, borderColor: "none" }}
										basic
										color="red"
										size="tiny"
										content={errors.error}
									/>
								)}
							/>

							<label
								style={{
									color: "red",
								}}>{`(*) = required`}</label>
							<br />
							<button
								className="ui button btn btn-primary"
								type="reset"
								style={{
									marginTop: "1rem",
									backgroundColor: "#70bbfd",
									display: "block !important",
									height: "2rem",
									width: "5rem",
								}}
								onClick={() => {
									const { biomeid } = currentView;
									setCurrentView({
										view: ViewHandles.manage,
										biomeid: biomeid,
									});
								}}>
								Back
							</button>
							<div className="ui right floated">
								<button
									className="ui button btn btn-primary"
									type="submit"
									style={{
										marginTop: "1rem",
										backgroundColor: "#70bbfd",
										display: "block !important",
										height: "2rem",
										width: "5rem",
									}}>
									Save
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</Card.Content>
		</>
	);
};

const validationSchema: any = Yup.object({
	vttVariableId: Yup.number().required("Select a variable"),
	// .typeError("Variable must be a number")
	coefficient: Yup.number()
		.typeError("Coefficient must be a number")
		.required("Coefficient is required"),
	sampleMean: Yup.number()
		.typeError("SampleMean must be a number")
		.required("SampleMean is required"),
	min: Yup.number()
		.typeError("Min must be a number")
		.required("Min is required"),
	max: Yup.number()
		.typeError("Max must be a number")
		.required("Max is required"),
	// name: Yup.string().trim().required("Name is required"),
	// constantValue: Yup.number().required("Constant value is required"),
});

export default VttVariableDetailEntry;
