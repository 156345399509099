import { Component, Fragment } from "react";
import { Grid } from "semantic-ui-react";

interface Props {
	conetent?: any;
}

export const NoVslFilterGrid = (props: Props) => {
	return (
		<Grid.Row centered>
			<Grid.Column width={12}>
				{props.conetent || (
					<Fragment>
						<p className="pos_fixed">
							You need to select at least one filter before getting the
							valuation studies.
						</p>
					</Fragment>
				)}
			</Grid.Column>
		</Grid.Row>
	);
};

class NoFilterGrid extends Component<Props> {
	render() {
		return (
			<Grid.Row centered>
				<Grid.Column width={12}>
					{this.props.conetent || (
						<Fragment>
							<p className="pos_fixed">
								You need to select at least one filter before getting the
								valuations.
							</p>
							<br />
							<p className="pos_fixed">
								If you want all valuations you can download the full ESVD as CSV
								at the bottom right.
							</p>
						</Fragment>
					)}
				</Grid.Column>
			</Grid.Row>
		);
	}
}

export default NoFilterGrid;
