import React, { useEffect, useState } from "react";
import { Grid, Container } from "semantic-ui-react";
import SuggestEntry from "./SuggestEntry";
import { SuggestStudy } from "../../models/SuggestStudy";
import { actions, resources } from "../../shared/Literals";
import SuggestReadMePanel from "./SuggestReadMePanel";
import Can from "../../accessControls/Can";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
import { useActiveMenu } from "../../providers/ActiveMenuProvider";
// import VslPublicFilterPanel from "../vslsForPublicView/VslPublicFilterPanel";
// import VslPublicActionsPanel from "../vslsForPublicView/VslPublicActionsPanel";
import { VslProvider, useVsl } from "../../providers/VslProvider";
import { ListsProvider, useLists } from "../../providers/ListsProvider";
import { VslGridHubProvider } from "../../providers/VslGridHubProvider";
import VslPublicFilterPanel from "../vslsForPublicView/VslPublicFilterPanel";
import VslPublicTable from "../vslsForPublicView/VslPublicTable";
import {
	EsvdQueryParams,
	defaultEsvdParams,
} from "../../models/EsvdQueryFilters";
import VslFilterPanel from "../vsls/VslFilterPanel";
const { Media, MediaContextProvider } = MediaLayout;

const SuggestionBoard = () => {
	const { setActiveItem } = useActiveMenu();
	const [selectedStudy, setSelectedStudy] = useState<SuggestStudy | null>(null);

	const { getVsls } = useVsl();
	const { loadLists } = useLists();

	useEffect(() => {
		// resetEsvds();
		setActiveItem(resources.suggestStudy.name);
		(async () => {
			await getVsls(vslFilters);
		})();
		ItemsLoading(true);
		(async () => {
			setDropdownOptions(await loadLists());
			// const {
			// 	biomeEcosystems,
			// 	biomeV2EcosystemV2Ecozones,
			// 	ecosystemServices,
			// 	countries,
			// 	protectionStatuses,
			// } = ls;

			// setBiomeEcosystems(biomeEcosystems);
			// setbiomeV2EcosystemV2Ecozones(biomeV2EcosystemV2Ecozones);
			// setecosystemServices(ecosystemServices);
			// setcountries(countries);
			// setprotectionStatuses(protectionStatuses);
			ItemsLoading(false);
		})();
	}, []);
	const [vslFilters, setVslFilters] = useState<EsvdQueryParams>({
		...defaultEsvdParams,
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [dropdownOptions, setDropdownOptions] = useState<any>(null);

	const ItemsLoading = (isLoad: boolean) => {
		setIsLoading(isLoad);
	};

	const renderContent = () => {
		return (
			<VslGridHubProvider>
				<VslProvider>
					<ListsProvider>
						<MediaContextProvider>
							<Media at="mobile" className="page-content">
								<SuggestReadMePanel />
								<SuggestEntry
									suggestedStudy={selectedStudy}
									resetSuggestStudy={setSelectedStudy}
									onStudyUpdated={(study: SuggestStudy) =>
										setSelectedStudy(study)
									}
								/>
								<VslFilterPanel
									vslFilters={vslFilters}
									setVslFilters={setVslFilters}
									dropdownOptions={dropdownOptions}
									setIsLoading={(isload: any) => ItemsLoading(isload)}
									isLoading={isLoading}
								/>
								<VslPublicTable
									vslFilters={vslFilters}
									setVslFilters={setVslFilters}
									setIsLoading={(isload: any) => ItemsLoading(isload)}
									dropdownOptions={dropdownOptions}
									isLoading={isLoading}
								/>
							</Media>
							<Media greaterThan="mobile">
								<Container fluid className="top-container">
									<Grid columns={2} className="page-content" stackable>
										<Grid.Row>
											<Grid.Column width={12}>
												<SuggestEntry
													suggestedStudy={selectedStudy}
													resetSuggestStudy={setSelectedStudy}
													onStudyUpdated={(study: SuggestStudy) =>
														setSelectedStudy(study)
													}
												/>
											</Grid.Column>
											<Grid.Column width={4}>
												<SuggestReadMePanel />
											</Grid.Column>
										</Grid.Row>
									</Grid>
									<Grid columns={2} className="page-content" stackable>
										<Grid.Row>
											<Grid.Column width={12} stretched>
												<VslPublicTable
													setVslFilters={setVslFilters}
													vslFilters={vslFilters}
													setIsLoading={(isload: any) => ItemsLoading(isload)}
													dropdownOptions={dropdownOptions}
													isLoading={isLoading}
												/>
											</Grid.Column>
											<Grid.Column width={4}>
												<VslFilterPanel
													vslFilters={vslFilters}
													setVslFilters={setVslFilters}
													dropdownOptions={dropdownOptions}
													setIsLoading={(isload: any) => ItemsLoading(isload)}
													isLoading={isLoading}
												/>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Container>
							</Media>
						</MediaContextProvider>
					</ListsProvider>
				</VslProvider>
			</VslGridHubProvider>
		);
	};

	return (
		<Can
			action={actions.read}
			resource={resources.suggestStudy.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
};

const SuggestionBoardWrapper = () => {
	return (
		<VslProvider>
			<ListsProvider>
				<SuggestionBoard />
			</ListsProvider>
		</VslProvider>
	);
};
export default SuggestionBoardWrapper;
