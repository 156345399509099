// import _ from "lodash";
import { BIOMEV2_ECOSYSTEMV2S_FETCH } from "../actions/biomeV2EcosystemV2EcozoneAction";

import { TreeType } from "../models/ecosystemService";

const biomev2ecosystemv2ecozonesReducer = (state: TreeType[] = [], action: any) => {
  switch (action.type) {
    case BIOMEV2_ECOSYSTEMV2S_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default biomev2ecosystemv2ecozonesReducer;
