import agent from "../api/agent";
export const VALUATION_METHOD_CREATE = "VALUATION_METHOD_CREATE";
export const VALUATION_METHOD_EDIT = "VALUATION_METHOD_EDIT";
export const VALUATION_METHOD_DELETE = "VALUATION_METHOD_DELETE";
export const VALUATION_METHOD_FETCH = "VALUATION_METHOD_FETCH";
export const VALUATION_METHODS_FETCH = "VALUATION_METHODS_FETCH";

export const fetchValuationMethods = () => async (dispatch: any) => {
  const response = await agent.ValuationMethods.list();

  dispatch({
    type: VALUATION_METHODS_FETCH,
    payload: response,
  });
};
