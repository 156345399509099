import agent from "../api/agent";
export const BIOMEV2_ECOSYSTEMV2_CREATE = "BIOMEV2_ECOSYSTEMV2_CREATE";
export const BIOMEV2_ECOSYSTEMV2_EDIT = "BIOMEV2_ECOSYSTEMV2_EDIT";
export const BIOMEV2_ECOSYSTEMV2_DELETE = "BIOMEV2_ECOSYSTEMV2_DELETE";
export const BIOMEV2_ECOSYSTEMV2_FETCH = "BIOMEV2_ECOSYSTEMV2_FETCH";
export const BIOMEV2_ECOSYSTEMV2S_FETCH = "BIOMEV2_ECOSYSTEMV2S_FETCH";

export const fetchBiomeV2EcosystemV2Ecozones = () => async (dispatch: any) => {
  const response = await agent.BiomeV2EcosystemV2Ecozones.list();

  dispatch({
    type: BIOMEV2_ECOSYSTEMV2S_FETCH,
    payload: response,
  });
};
