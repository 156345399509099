import agent from "../../api/agent";
export const UNITOFMEASURE_CREATE = "UNITOFMEASURE_CREATE";
export const UNITOFMEASURE_EDIT = "UNITOFMEASURE_EDIT";
export const UNITOFMEASURE_DELETE = "UNITOFMEASURE_DELETE";
export const UNITOFMEASURE_FETCH = "UNITOFMEASURE_FETCH";
export const UNITSOFMEASURE_FETCH = "UNITSOFMEASURE_FETCH";

export const fetchUnitsOfMeasure = () => async (dispatch: any) => {
  const response = await agent.UnitsOfMeasure.list();

  dispatch({
    type: UNITSOFMEASURE_FETCH,
    payload: response,
  });
};
