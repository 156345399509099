import { useEffect, useState } from "react";
import { Button, Card } from "semantic-ui-react";
import { actions, resources } from "../../shared/Literals";

import Can from "../../accessControls/Can";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
import AccountDetails from "./AccountDetails";
import { useActiveMenu } from "../../providers/ActiveMenuProvider";
import WelcomeHeader from "../../shared/WelcomeHeader";
import UserProfileDetails from "./UserProfileDetails";

const { Media, MediaContextProvider } = MediaLayout;

const UserProfile = () => {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const { setActiveItem } = useActiveMenu();

	useEffect(() => {
		setActiveItem(resources.userProfile.name);
		return () => {};
	}, []);

	const renderContent = () => {
		return (
			<MediaContextProvider>
				<Media at="mobile" className="page-content">
					<WelcomeHeader
						titleText="My Profile on "
						accentText="ESVD"
						subheadText={`User profile ${
							isEdit ? ": Edit (All fields required)" : ""
						}`}
						showButton={
							isEdit ? (
								<Button
									icon="arrow left"
									onClick={() => setIsEdit(false)}
									size="tiny"
									className="ui right floated"
								/>
							) : (
								<Button
									icon="edit"
									onClick={() => setIsEdit(true)}
									size="tiny"
									className="ui right floated"
								/>
							)
						}
					/>
					{isEdit ? (
						<AccountDetails setIsEdit={setIsEdit} />
					) : (
						<UserProfileDetails />
					)}
				</Media>
				<Media greaterThan="mobile">
					<div
						style={{
							display: "flex",
							width: "60%",
							margin: "auto",
							marginTop: "5%",
							height: "80vh",
						}}>
						<Card fluid style={{ height: "80vh" }}>
							<Card.Content>
								<WelcomeHeader
									titleText="My Profile on "
									accentText="ESVD"
									subheadText={`User profile ${
										isEdit ? ": Edit (All fields required)" : ""
									}`}
									showButton={
										isEdit ? (
											<Button
												icon="arrow left"
												onClick={() => setIsEdit(false)}
												size="tiny"
												className="ui right floated"
											/>
										) : (
											<Button
												icon="edit"
												onClick={() => setIsEdit(true)}
												size="tiny"
												className="ui right floated"
											/>
										)
									}
								/>
							</Card.Content>
							<Card.Content>
								{isEdit ? (
									<AccountDetails setIsEdit={setIsEdit} />
								) : (
									<UserProfileDetails />
								)}
							</Card.Content>
						</Card>
					</div>
				</Media>
			</MediaContextProvider>
		);
	};
	return (
		<Can
			action={actions.read}
			resource={resources.userProfile.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
};

export default UserProfile;
