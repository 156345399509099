import { useField } from "formik";
import { Fragment } from "react";
import {
  Form,
  // Icon,
  // Input,
  Label,
  SemanticICONS,
  TextArea,
} from "semantic-ui-react";

interface Props {
  name: string;
  placeholder?: string;
  icon?: SemanticICONS;
  label?: string;
  rightIcon?: any;
  fluid?: boolean;
  type?: string;
  value?: string;
  className?: string;
  minHeight?: number;
}

const EsvdTextArea = (props: Props) => {
  const [field, meta] = useField(props.name);
  const size = "mini";
  return (
    <Fragment>
      <label>{props.label}</label>
      <Form.Field error={meta.touched && !!meta.error}>
        <TextArea
          {...field}
          placeholder={props.placeholder}
          size={size}
          // value={props.value || ""}
          className={props.className}
          style={{ minHeight: props.minHeight || null }}
        />

        {meta.touched && meta.error ? (
          <Label
            basic
            color="red"
            size="tiny"
            style={{ padding: "0px", border: "none" }}
          >
            {meta.error}
          </Label>
        ) : null}
      </Form.Field>
    </Fragment>
  );
};

export default EsvdTextArea;
