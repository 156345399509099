import React from "react";
// import { connect } from "react-redux";
import { Label } from "semantic-ui-react";
// import { fetchEsvdsSummaryStats } from "../../actions/esvdStatsActions";
// import { EsvdQueryParams } from "../../models/EsvdQueryFilters";
// import { ErrorHandler } from "../../shared/ExceptionHandling";
import filterCheck from "../../shared/FilterCheck";
import { ErrorMessage, Form, Formik } from "formik";
import NoFilterGrid from "../../shared/NoFilterGrid";
import { LoadingButton } from "../../shared/LoadingOverlay";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import { useModal } from "../../hooks/ModalHook";

const SummaryStatsButton = () => {
	const { getEsvdsSummaryStats, esvdQueryParams, saveQueryParams } =
		useEsvdValuation();
	const { openModal } = useModal();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const handleClick = async (setErrors: Function) => {
		if (filterCheck(esvdQueryParams)) {
			setIsLoading(true);
			if (!esvdQueryParams.saved && filterCheck(esvdQueryParams)) {
				//save query if not saved already
				saveQueryParams && saveQueryParams({ ...esvdQueryParams });
			}

			await getEsvdsSummaryStats(esvdQueryParams);

			setIsLoading(false);
		} else {
			openModal({
				icon: "exclamation",
				header: "Attention!",
				main: (
					<NoFilterGrid
						conetent={
							<>
								<p className="pos_fixed">
									Summary statistics are based on your query/filter and result
									set of Valuation records.
								</p>
								<br />
								<p className="pos_fixed">
									You have 0 valuations at this moment. Therefore no summary
									statistics can be calculated yet.
								</p>
							</>
						}
					/>
				),
				isOpen: true,
				actions: null,
			});
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{ username: "", password: "", error: null }}
			onSubmit={(values, { setErrors }) => {
				handleClick(setErrors);
			}}>
			{({ handleSubmit, errors }) => (
				<Form
					// className="ui form page-content"
					onSubmit={handleSubmit}
					autoComplete={"off"}>
					<LoadingButton
						isLoading={isLoading}
						text={"Show summary statistics"}
						type="submit"
					/>

					<ErrorMessage
						name="error"
						render={() => (
							<Label
								style={{ marginBottom: 10 }}
								basic
								color="red"
								content={errors.error}
							/>
						)}
					/>
				</Form>
			)}
		</Formik>
	);
};

export default SummaryStatsButton;
