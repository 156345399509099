import { TYPEOFBENEFICIARIES_FETCH } from "../../actions/others/typeOfBeneficiaryActions";

const typeOfBeneficiaryReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case TYPEOFBENEFICIARIES_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default typeOfBeneficiaryReducer;
