import React, { useContext } from "react";
import history from "../history";

import agent from "../api/agent";
import { User } from "../models/user";
import { UserQueryParams } from "../models/EsvdQueryFilters";
import { resources } from "../shared/Literals";
import access from "../accessControls/rbac-rules";

const CryptoJS = require("crypto-js");

interface IValue {
	isRefresh: boolean;
	user?: User | null;
	login: Function;
	signup: Function;
	logout: Function;
	updateUser: Function;
	getUsers: Function;
	getUsersCount: Function;
	fetchCurrentUser: Function;
	forgotpassword: Function;
	resendconfirmationlink: Function;
	confirmemail: Function;
	resetpassword: Function;
	setToken: Function;
	getToken: Function;
	// setAppLoaded: boolean;
}

const AuthContext = React.createContext<IValue | null>(null);

// The AuthProvider is responsible for Auth management
const AuthProvider = ({ children }: { children: any }) => {
	const [user, setUser] = React.useState<User | null>();
	const [isRefresh, setIsRefresh] = React.useState<boolean>(false);
	// const [setAppLoaded] = React.useState<boolean>(true);

	const fetchCurrentUser = async () => {
		try {
			// dispatch({ type: CURRENT_USER_CHECKED, payload: true });
			const token = getToken();
			if (!token) {
				setUser(null);
				return;
			}

			const isRefresh = sessionStorage.getItem("refreshToken");
			if (!isRefresh) {
				sessionStorage.removeItem("jwt");
				logout();
				setUser(null);
				return;
			} else {
				setIsRefresh(true);
			}

			const us = await agent.Account.current();
			if (!us) {
				setUser(null);
				// dispatch({ type: FETCH_USER, payload: null });
				return;
			}
			sessionStorage.setItem("refreshToken", "true");

			await buildResourcePermissionsList(us);
			setToken(us.token);
			setUser(us);
			// dispatch({ type: FETCH_USER, payload: user });
		} catch (error: any) {
			// console.log(error);
			throw error.response ? error.response.statusText : error.message;
		}
	};

	/**
	 * build an array of resource permissions for the user. This will be used in the "Can" component to determine which resources
	 * the user can access and those not accessible
	 * @param user user for whom this list is being built
	 */
	const buildResourcePermissionsList = async (user: User) => {
		user.resourceAccess = [];
		for (const value of Object.values(resources)) {
			const permission = await access
				.can(user.role)
				.sync()
				.execute("read")
				.sync()
				.on(value.name);

			user.resourceAccess.push({
				resource: value.name,
				granted: permission.granted,
			});
		}
	};
	// useEffect(() => {
	// 	// fetchCurrentUser();
	// 	return () => {};
	// }, []);

	const login = async (values: any) => {
		try {
			const key = process.env.REACT_APP_CRYPTO_KEY || "";

			//encrypt token
			values.password = CryptoJS.AES.encrypt(values.password, key).toString();
			values.varpass = key;
			const us = await agent.Account.login(values);
			setToken(us.token);
			await buildResourcePermissionsList(us);
			sessionStorage.setItem("refreshToken", "true");
			setUser(us);
			// dispatch({ type: FETCH_USER, payload: user });
		} catch (error: any) {
			setUser(null);
			throw error.response ? error.response.data : error.message;
		}
	};

	const signup = async (values: any) => {
		try {
			const key = process.env.REACT_APP_CRYPTO_KEY || "";
			//encrypt token
			values.password = CryptoJS.AES.encrypt(values.password, key).toString();
			values.varpass = key;
			const us = await agent.Account.signup(values);
			// setToken(us.token);
			// await buildResourcePermissionsList(us);
			// sessionStorage.setItem("refreshToken", "true");
			// setUser(us);
			// dispatch({ type: FETCH_USER, payload: user });
		} catch (error: any) {
			setUser(null);
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const logout = async (
		forced: boolean = false,
		isTimeout: boolean = false
	) => {
		//
		await agent.Account.logout();
		setToken();
		sessionStorage.setItem("refreshToken", "false");
		// dispatch({ type: SET_USER_TOKEN, payload: null }); //This sets the token to null
		// dispatch({ type: FETCH_USER, payload: null });
		setUser(null);
		// dispatch({ type: RESET_STATE, payload: null }); //TODO: find an appropriate alternative to this
		if (!forced) {
			isTimeout ? history.push("/login") : history.push("/");
		}
	};

	const updateUser = async (values: any) => {
		try {
			const user1 = await agent.Account.useredit(values);
			// setToken(user.token);
			user1 && (await buildResourcePermissionsList(user1));
			setUser(user1);
		} catch (error: any) {
			// setUser(null);
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getUsers = async (userQuery?: UserQueryParams) => {
		try {
			const items = await agent.Account.userslist(userQuery);
			return items.sort((a, b) => {
				return !!a.registrationDate > !!b.registrationDate ? 1 : -1; //sort by study in descending order
			});
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getUsersCount = async () => {
		try {
			return await agent.Account.usersCount();
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const forgotpassword = async (values: any) => {
		try {
			await agent.Account.forgotpassword(values);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const resendconfirmationlink = async (values: any) => {
		try {
			await agent.Account.resendconfirmationlink(values);
		} catch (error: any) {
			throw error.response ? error.response.data : error.message;
		}
	};

	// const logoutReset = (forced: boolean = false, isTimeout: boolean = false) => {
	// 	//clear returnUrl from localstorge
	// 	// saveCurrentUrl();
	// 	// setToken();
	// 	// await agent.Account.logout();
	// 	// dispatch({ type: SET_USER_TOKEN, payload: null });
	// 	// dispatch({ type: FETCH_USER, payload: null });
	// 	// dispatch({ type: RESET_STATE, payload: null });
	// 	// if (!forced) {
	// 	//   isTimeout ? history.push("/login") : history.push("/");
	// 	// }
	// };

	const confirmemail = async (values: any) => {
		try {
			if (!values.username || !values.code) {
				throw new Error("Invalid operation.");
			}
			await agent.Account.confirmemail(values);
		} catch (error: any) {
			throw error.response ? error.response.data : error.message;
		}
	};

	const resetpassword = async (values: any) => {
		try {
			const key = process.env.REACT_APP_CRYPTO_KEY || "";
			//encrypt token
			values.password = CryptoJS.AES.encrypt(values.password, key).toString();
			values.varpass = key;
			await agent.Account.resetpassword(values);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const setToken = (token?: string) => {
		if (token) {
			try {
				const key = process.env.REACT_APP_CRYPTO_KEY || "";
				//encrypt token
				const token_enc = CryptoJS.AES.encrypt(token, key).toString();
				// const token_enc = CryptoJS.AES.encrypt(JSON.stringify({ token }), key).toString();
				// window.localStorage.setItem("jwt", token_enc);
				sessionStorage.setItem("jwt", token_enc);
			} catch (error) {}
		} else {
			sessionStorage.removeItem("jwt");
			// window.localStorage.removeItem("jwt");
		}
	};

	const getToken = () => {
		let token = sessionStorage.getItem("jwt");
		if (token) {
			try {
				const key = process.env.REACT_APP_CRYPTO_KEY || "";
				//decrypt token
				const tokenBytes = CryptoJS.AES.decrypt(token, key);
				token = tokenBytes.toString(CryptoJS.enc.Utf8);
				// dispatch({ type: SET_USER_TOKEN, payload: token });
			} catch (error) {}
		}

		return token;
	};

	return (
		<AuthContext.Provider
			value={{
				isRefresh,
				user,
				login,
				signup,
				logout,
				updateUser,
				getUsers,
				getUsersCount,
				fetchCurrentUser,
				forgotpassword,
				resendconfirmationlink,
				confirmemail,
				resetpassword,
				setToken,
				getToken,
				// setAppLoaded,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useAuth = () => {
	const auth = useContext(AuthContext);
	if (auth == null) {
		throw new Error("useAuth() called outside of a auth hook?");
	}
	return auth;
};

export { AuthProvider, useAuth };
