import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

const LoadingOverlay = ({
	active,
	text,
	content,
}: {
	active: boolean | undefined;
	text?: string | undefined;
	content?: any;
}) => {
	return (
		<Dimmer active={active} inverted>
			<Loader size="small" content={content}>
				{text || "Loading"}
			</Loader>
		</Dimmer>
	);
};

export default LoadingOverlay;

export const LoadingButton = ({
	// active,
	text,
	isLoading = false,
	type = "button",
	semanticColor = "success",
	onClick,
	className,
}: {
	// active: boolean | undefined;
	isLoading: boolean;
	text?: string;
	semanticColor?: "success" | "primary";
	type?: "button" | "submit" | "reset" | undefined;
	onClick?: Function;
	className?: string;
}) => {
	return (
		<button
			disabled={isLoading}
			className={`${className} btn btn-${semanticColor} btn-sm`}
			type={type}
			onClick={(e) => {
				onClick && onClick();
				return true;
			}}>
			<span className={`${isLoading ? "hide" : ""}`}>{text}</span>
			<Dimmer active={isLoading} inverted>
				<Loader size="tiny"></Loader>
			</Dimmer>
		</button>
	);
};

export const LoadingStyledButton = ({
	// active,
	text,
	isLoading = false,
	type = "button",
	semanticColor = "success",
}: {
	// active: boolean | undefined;
	isLoading: boolean;
	text: string;
	semanticColor?: "success" | "primary";
	type?: "button" | "submit" | "reset" | undefined;
}) => {
	return (
		<button
			className={`ui button fluid btn btn-${semanticColor} btn-sm`}
			type={type}
			style={{
				backgroundColor: "#70bbfd",
				display: "block !important",
				height: "3rem",
			}}>
			<span className={`${isLoading ? "hide" : ""}`}>{text}</span>
			<Dimmer active={isLoading} inverted>
				<Loader size="tiny"></Loader>
			</Dimmer>
		</button>
	);
};

export const LoadingFormButton = ({
	text,
	isLoading = false,
	disabled = false,
	type = "button",
	semanticColor = "success",
	display = "block !important",
	backgroundColor = "#70bbfd",
	height = "3rem",
	style = undefined,
}: {
	disabled?: boolean;

	isLoading: boolean;
	text: string;
	semanticColor?: "success" | "primary";
	type?: "button" | "submit" | "reset" | undefined;
	display?: string;
	backgroundColor?: string;
	height?: string;
	style?: React.CSSProperties | undefined;
}) => {
	return (
		<button
			disabled={disabled}
			className={`ui button fluid btn btn-${semanticColor} btn-sm`}
			type={type}
			style={
				style
					? style
					: {
							backgroundColor: backgroundColor,
							display: display,
							height: height,
					  }
			}>
			<span className={`${isLoading ? "hide" : ""}`}>{text}</span>
			<Dimmer active={isLoading} inverted>
				<Loader size="tiny"></Loader>
			</Dimmer>
		</button>
	);
};
