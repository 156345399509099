import { useField } from "formik";
import React from "react";
import {
	Form,
	Grid,
	Icon,
	Input,
	Label,
	SemanticICONS,
} from "semantic-ui-react";

interface Props {
	name: string;
	placeholder?: string;
	icon?: SemanticICONS;
	label?: string;
	rightIcon?: any;
	fluid?: boolean;
	value?: string;
	className?: string;
	disabled?: boolean;
	min?: number;
}

// const valueCheck = (value: any) => {
// 	if (Number(value) >= 0) return value;

// 	return "";
// };
const NumberInputInline = (props: Props) => {
	const [field, meta] = useField(props);
	const size = "mini";
	return (
		<Form.Field error={meta.touched && !!meta.error}>
			<Grid>
				<Grid.Row>
					<Grid.Column width={4}>
						<label>{props.label}</label>
					</Grid.Column>
					<Grid.Column width={12}>
						<Input
							{...field}
							placeholder={props.placeholder}
							fluid={props.fluid || false}
							type="number"
							size={size}
							// value={valueCheck(field.value)}
							value={field.value || ""}
							min={props.min}
							disabled={props.disabled || false}
							className={props.className}>
							{props.icon && (
								<Label style={{ paddingRight: "1px", lineHeight: "0.9" }}>
									<Icon name={props.icon} />
								</Label>
							)}
							<input />
						</Input>
						{meta.touched && meta.error ? (
							<Label
								basic
								color="red"
								size="tiny"
								style={{ padding: "0px", border: "none", display: "block" }}>
								{meta.error}
							</Label>
						) : null}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form.Field>
	);
};

export default NumberInputInline;
