import React, { useContext } from "react";

import { resources } from "../shared/Literals";

interface IValue {
	activeItem: string;
	setActiveItem: Function;
}

const ActiveMenuContext = React.createContext<IValue | null>(null);

// The ActiveMenuProvider is responsible for ActiveMenu management
const ActiveMenuProvider = ({ children }: { children: any }) => {
	const [activeItem, setActiveItem] = React.useState<string>(
		resources.home.name
	);

	return (
		<ActiveMenuContext.Provider
			value={{
				activeItem,
				setActiveItem,
			}}>
			{children}
		</ActiveMenuContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the activeMenu context value.
const useActiveMenu = () => {
	const activeMenu = useContext(ActiveMenuContext);
	if (activeMenu == null) {
		throw new Error("useActiveMenu() called outside of a activeMenu hook?");
	}
	return activeMenu;
};

export { ActiveMenuProvider, useActiveMenu };
