import { useEffect, useRef, useState } from "react";
import "react-base-table/styles.css";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
import { HotTable, HotColumn } from "@handsontable/react";

import MultiSelectEditor from "./MultiSelectEditor";
import { useVsl } from "../../providers/VslProvider";
import {
	formatEnableOnlyEcosystemForDropdown,
	formatShowOnlyBiomeForDropdown,
} from "../../EsvdFunctions";
import SingleSelectEditor from "./SingleSelectEditor";
import { SingleSelectRenderer } from "./SingleSelectRenderer";
import { CellChange, ChangeSource } from "handsontable/common";
import { useVslGridHub } from "../../providers/VslGridHubProvider";
import { VslCellEditInfo } from "../../models/vsl-models/VslCellEditInfo";
import { Card } from "semantic-ui-react";
import LoadingOverlay from "../../shared/LoadingOverlay";
import { MultiSelectCodesRenderer } from "./MultiSelectCodesRenderer";
import { SingleSelectCodesRenderer } from "./SingleSelectCodesRenderer";
import {
	IncludedInEsvdOptions,
	RepoIncludedOptions,
} from "../../shared/Literals";
import { IVslFilter } from "../../models/vsl-models/IVslFilter";
import { ColumnConditions } from "handsontable/plugins/filters";
import MyModal from "../../layouts/Modals";

registerAllModules();

const VslTable = (props: IVslFilter) => {
	const { vslGridHub } = useVslGridHub();
	const {
		getVsls,
		setHotTableInstance,
		hotTableInstance,
		editVsl,
		vslData,
		gridLockState,
		selectedRowDispatch,
		addVsl,
		setvslsCount,
		vslsCount,
		gridEditSyncLock,
	} = useVsl();

	const { isLoading, dropdownOptions } = props;
	const {
		vslFilters,
		biomeEcosystems,
		biomeV2EcosystemV2Ecozones,
		ecosystemServices,
		countries,
		protectionStatuses,
	} = dropdownOptions || {};

	const [filteredRowsCnt, setFilteredRowsCnt] = useState<number | null>(null);
	const [opened, setOpened] = useState<boolean>(false);
	const [modalMessage, setModalMessage] = useState<string | null>(null);
	const [isModalConfirmed, setIsModalConfirmed] = useState<boolean>(false);

	const hotRef = useRef<any>(null);

	const { lock } = gridLockState;

	useEffect(() => {
		(async () => {
			await getVsls(vslFilters);
		})();
		setFilteredRowsCnt(null);
		if (hotRef.current) {
			setHotTableInstance && setHotTableInstance(hotRef.current.hotInstance);
		}
	}, []);

	// useEffect(() => {
	// 	if (isModalConfirmed) {
	// 		setIsModalConfirmed(false);
	// 		(async () => {
	// 			if (delItem) {
	// 				await deleteVttSubBiome(delItem);
	// 				var vtt = await getVttSubBiomes();
	// 				setVttSubBiomes(vtt);
	// 				setIsLoading(false);
	// 				setDelItem(null);
	// 			}
	// 		})();
	// 	}
	// 	return () => {};
	// }, [isModalConfirmed]);

	useEffect(() => {
		if (vslGridHub) {
			if (vslGridHub.state === "Disconnected") {
				vslGridHub.start();
			}
			vslGridHub.on(
				"SyncGridUpdateInfo",
				async (cellEditInfo: VslCellEditInfo) => {
					if (hotTableInstance) {
						const { id, column, dataValue, actionType } = cellEditInfo;
						const search = hotTableInstance.getPlugin("search");
						if (actionType === "edit") {
							const queryResult = search.query(id?.toString());
							if (queryResult && queryResult.length > 0) {
								const { row } = queryResult[0];

								hotTableInstance.setSourceDataAtCell(row, column, dataValue);
							}
						} else if (actionType === "add") {
							hotTableInstance.alter("insert_row_above", 0);
							hotTableInstance.setSourceDataAtCell(0, "id", id);
							hotTableInstance.setSourceDataAtCell(0, column, dataValue);
							hotTableInstance.render();
							setvslsCount(vslsCount ? vslsCount + 1 : 1);
						} else if (actionType === "delete") {
							const queryResult = search.query(id?.toString());
							if (queryResult && queryResult.length > 0) {
								const { row } = queryResult[0];
								hotTableInstance.alter("remove_row", row);
								hotTableInstance.render();
								setvslsCount(vslsCount ? vslsCount - 1 : 0);
							}
						}
					}
				}
			);

			vslGridHub.on("CellIsReadonly", (cellEditInfo: VslCellEditInfo) => {
				const { row, column, locked } = cellEditInfo;
				if (hotTableInstance) {
					hotTableInstance.setCellMeta(row, column, "readOnly", locked);
					hotTableInstance.render();
				}
			});
		}
	}, [vslGridHub]);

	return (
		<>
			<LoadingOverlay active={isLoading} text="Loading vsl" />
			<Card fluid style={{ height: "92vh" }}>
				<Card.Content>
					<h4 style={{ display: "inline-block", float: "left" }}>
						Valuation studies total:{" "}
						<span>{`${vslsCount ? vslsCount : ""}`}</span>
					</h4>
					{filteredRowsCnt ? (
						<h4
							style={{
								display: "inline-block",
								// textAlign: "right",
								float: "right",
								paddingRight: "2em",
							}}>
							In column filtering:
							<span>{`${filteredRowsCnt ? filteredRowsCnt : null}`}</span>
						</h4>
					) : null}
				</Card.Content>
				<HotTable
					colHeaders={true}
					ref={hotRef}
					data={vslData}
					rowHeaders={true}
					height="95%"
					rowHeights={40}
					manualRowResize={true}
					manualColumnResize={true}
					multiColumnSorting={true}
					autoColumnSize={false}
					allowInsertRow={lock}
					wordWrap={false}
					readOnly={lock || gridEditSyncLock}
					filters={true}
					renderAllRows={false}
					// enable the `Search` plugin
					// search={true}
					// but display only the 'Filter by value' list and the 'OK' and 'Cancel' buttons
					// enable filtering

					// enable the column menu, but display only the filter menu items
					dropdownMenu={true}
					// dropdownMenu={{
					// 	items: {
					// 		filter_by_value: {
					// 			// hide the 'Filter by value' list from all columns but the first one
					// 			hidden() {
					// 				return hotTableInstance.getSelectedRangeLast().to.col > 3;
					// 			},
					// 		},
					// 		filter_action_bar: {
					// 			// hide the 'OK' and 'Cancel' buttons from all columns but the first one
					// 			hidden() {
					// 				return hotTableInstance.getSelectedRangeLast().to.col > 3;
					// 			},
					// 		},
					// 		clear_column: {
					// 			// hide the 'Clear column' menu item from the first column
					// 			hidden() {
					// 				return hotTableInstance.getSelectedRangeLast().to.col < 1;
					// 			},
					// 		},
					// 	},
					// }}
					// dropdownMenu={[
					// 	// "filter_by_condition",
					// 	"filter_by_value",
					// 	"filter_action_bar",
					// ]}
					// dropdownMenu={{
					// 	items: {
					// 		make_read_only: {},
					// 		alignment: {},
					// 		"---------": {},
					// 		undo: {},
					// 		redo: {},
					// 		filter_by_value: {
					// 			// hide the 'Filter by value' list from all columns but the first one
					// 			hidden() {
					// 				// return hotTableInstance.getSelectedRangeLast().to.col > 0;
					// 			},
					// 		},
					// 		filter_action_bar: {
					// 			// hide the 'OK' and 'Cancel' buttons from all columns but the first one
					// 			hidden() {
					// 				// return hotTableInstance.getSelectedRangeLast().to.col > 0;
					// 			},
					// 		},
					// 		clear_column: {
					// 			// hide the 'Clear column' menu item from the first column
					// 			hidden() {
					// 				// return hotTableInstance.getSelectedRangeLast().to.col < 1;
					// 			},
					// 		},
					// 	},
					// }}
					licenseKey="non-commercial-and-evaluation"
					hiddenColumns={{
						// specify columns hidden by default
						columns: [0],
					}}
					afterChange={async (
						changes: CellChange[] | null,
						source: ChangeSource
					) => {
						if (source !== "loadData") {
							// return; //don't save this change

							if (changes) {
								const [row, column, prevValue, nextValue] = changes[0];
								//Note: the vitual row index that's returned for a filtered or sorted column is different from the physical row index.
								//Therefore, convert the vitual row index to its physical index for proper mapping
								let physicalRow = hotTableInstance.toPhysicalRow(row);
								if (hotTableInstance && prevValue !== nextValue) {
									//retrieve the entire row as JSON or as source data format
									const rowData =
										hotTableInstance.getSourceDataAtRow(physicalRow);
									try {
										let id = rowData.id;
										if (id) {
											await editVsl(rowData);
										} else {
											id = await addVsl(rowData);
											setvslsCount(vslsCount ? vslsCount + 1 : 1);
											if (id) {
												hotTableInstance.setSourceDataAtCell(
													physicalRow,
													"id",
													id
												);
											}
										}
										const col = hotTableInstance.colToProp(column);
										let cellEditInfo: VslCellEditInfo = {
											id: id,
											row: physicalRow,
											column: col,
											dataValue: nextValue,
											actionType: rowData.id ? "edit" : "add",
										};
										try {
											vslGridHub &&
												vslGridHub.invoke<any>("VslGridEditInfo", cellEditInfo);
										} catch (error) {}
									} catch (error: any) {
										hotTableInstance.setSourceDataAtCell(
											physicalRow,
											column,
											prevValue
										);
										// alert(error);
										setModalMessage(error || "");
										setOpened(true);
									}
								}
							}
						}
					}}
					afterSelection={(row, column) => {
						//get details of selected row
						//Note: the vitual row index that's returned for a filtered or sorted column is different from the physical row index.
						//Therefore, convert the vitual row index to its physical index for proper mapping
						let physicalRow = hotTableInstance.toPhysicalRow(row);
						const rowData = hotTableInstance.getSourceDataAtRow(physicalRow);
						if (rowData)
							selectedRowDispatch &&
								selectedRowDispatch({
									type: "selected",
									rowInfo: { index: physicalRow, id: rowData.id },
								});
					}}
					afterFilter={(conditionsStack: ColumnConditions[]) => {
						//if length of conditionsStack == 0, then no filter applied
						if (conditionsStack.length > 0)
							setFilteredRowsCnt(hotTableInstance.countRows());
						else setFilteredRowsCnt(null);
						// console.log(hotTableInstance.countRows());
					}}>
					<HotColumn width={10} data="id" title="id" />
					<HotColumn
						width={120}
						data="authors"
						title="Authors"
						columnSorting={true}
					/>
					<HotColumn
						width={50}
						data="yearOfPublication"
						title="Year"
						columnSorting={true}
						forceNumeric={true}
					/>
					<HotColumn
						width={300}
						data="title"
						title="Title"
						columnSorting={true}
					/>
					<HotColumn width={300} data="reference" title="Reference" />
					<HotColumn
						width={150}
						data="includedInESVDText"
						title="ESVD Included">
						<SingleSelectRenderer hot-renderer data={IncludedInEsvdOptions} />
						<SingleSelectEditor
							hot-editor
							data={IncludedInEsvdOptions}
							codeCell="includedInESVD"
						/>
					</HotColumn>

					<HotColumn
						width={75}
						data="studyId"
						title="StudyID"
						columnSorting={true}
					/>
					<HotColumn width={150} data="flatVsBiomeCodes" title="Biomes">
						<MultiSelectCodesRenderer
							hot-renderer
							data={biomeEcosystems}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={biomeEcosystems}
							isMulti={true}
							formatter={formatShowOnlyBiomeForDropdown}
							codeCell="flatVsBiomeCodes"
						/>
					</HotColumn>
					<HotColumn width={150} data="flatVsEcosystemCodes" title="Ecosystems">
						<MultiSelectCodesRenderer
							hot-renderer
							data={biomeEcosystems}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={biomeEcosystems}
							isMulti={true}
							formatter={formatEnableOnlyEcosystemForDropdown}
							codeCell="flatVsEcosystemCodes"
						/>
					</HotColumn>
					<HotColumn
						width={150}
						data="flatVsBiomeV2Codes"
						title="ESVD2.0 Biome">
						<MultiSelectCodesRenderer
							hot-renderer
							data={biomeV2EcosystemV2Ecozones}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={biomeV2EcosystemV2Ecozones}
							isMulti={true}
							formatter={formatShowOnlyBiomeForDropdown}
							codeCell="flatVsBiomeV2Codes"
						/>
					</HotColumn>
					<HotColumn
						width={150}
						data="flatVsEcozoneCodes"
						title="ESVD2.0 Ecozone"
						style={{
							overflow: "hidden",
							whiteSpace: "nowrap",
						}}>
						<MultiSelectCodesRenderer
							hot-renderer
							data={biomeV2EcosystemV2Ecozones}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={biomeV2EcosystemV2Ecozones}
							isMulti={true}
							formatter={formatEnableOnlyEcosystemForDropdown}
							codeCell="flatVsEcozoneCodes"
						/>
					</HotColumn>
					<HotColumn
						width={150}
						data="flatVsEcosystemServiceCodes"
						title="ES (TEEB)">
						<MultiSelectCodesRenderer
							hot-renderer
							data={ecosystemServices}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={ecosystemServices}
							isMulti={true}
							formatter={formatShowOnlyBiomeForDropdown}
							codeCell="flatVsEcosystemServiceCodes"
						/>
					</HotColumn>
					<HotColumn width={100} data="flatVsCountryCodes" title="Country">
						<MultiSelectCodesRenderer
							hot-renderer
							data={countries}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={countries}
							isMulti={true}
							codeCell="flatVsCountryCodes"
						/>
					</HotColumn>
					<HotColumn
						width={150}
						data="protectionStatus"
						title="Protection Status">
						<SingleSelectCodesRenderer hot-renderer data={protectionStatuses} />
						<SingleSelectEditor
							hot-editor
							data={protectionStatuses}
							codeCell="protectionStatusId"
						/>
					</HotColumn>
					<HotColumn
						width={150}
						data="includedRepositoryText"
						title="Repo Included">
						<SingleSelectRenderer hot-renderer data={RepoIncludedOptions} />
						<SingleSelectEditor
							hot-editor
							data={RepoIncludedOptions}
							codeCell="includedRepository"
						/>
					</HotColumn>
					<HotColumn width={100} data="repositoryFolder" title="Repo Folder" />
					<HotColumn width={100} data="checkedBy" title="Checked By" />
					<HotColumn width={100} data="language" title="Language" />
					<HotColumn width={100} data="notes" title="Notes" />
				</HotTable>
			</Card>
			<MyModal
				title=""
				body={`${modalMessage || null}`}
				opened={opened}
				setOpened={setOpened}
				setConfirmation={setIsModalConfirmed}
				needsAction={false}
			/>
		</>
	);
};

export default VslTable;
