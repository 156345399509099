import React from "react";
import { Grid, Container, Card } from "semantic-ui-react";
// import Can from "../../accessControls/Can";
// import { actions, resources } from "../../shared/Literals";
import { Redirect } from "react-router";
import Can from "../../../accessControls/Can";
import MediaLayout from "../../../layouts/MediaLayout";
import { actions, resources } from "../../../shared/Literals";
import VttCalculateBaseWrapper from "./VttCalculateBase";
import VttCalculateReadMePanel from "./VttCalculateReadMePanel";
// import MediaLayout from "../../layouts/MediaLayout";
// import VttBaseWrapper from "./VttBase";

const { Media, MediaContextProvider } = MediaLayout;

const VttCalculateHome = () => {
	// const renderContent = () => {
	return (
		<MediaContextProvider>
			<Media at="mobile" className="page-content">
				<VttCalculateReadMePanel />
				<VttCalculateBaseWrapper />
			</Media>
			<Media greaterThan="mobile">
				<Container fluid className="top-container">
					<Grid columns={2} className="page-content" stackable>
						<Grid.Row>
							<Grid.Column width={12}>
								<VttCalculateBaseWrapper />
							</Grid.Column>
							<Grid.Column width={4}>
								<VttCalculateReadMePanel />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</Media>
		</MediaContextProvider>
	);
};

// return {renderContent()}
// return (
// 	<Can
// 		action={actions.read}
// 		resource={resources.esvd.name}
// 		yes={() => renderContent()}
// 		no={() => <Redirect to={"/"} />}
// 	/>
// );

const VttCalculateHomeWrapper = (props: any) => {
	return (
		<Can
			action={actions.read}
			resource={resources.esvd.name}
			yes={() => <VttCalculateHome />}
			no={() => <Redirect to={"/"} />}
		/>
	);
	// return <VttCalculateHome />;
};

export default VttCalculateHomeWrapper;
