import React, { useState, useEffect } from "react";
import IdleTimer from "react-idle-timer";

import EsvdModal from "./EsvdModal";
import { LOGIN_IDLE_TIMEOUT } from "../shared/Literals";
import BaseContainersLayout from "./BaseContainersLayout";
import { useAuth } from "../providers/AuthProvider";

const MainPage = () => {
	const { user, logout } = useAuth();
	const [isTimedOut, setIsTimedOut] = useState<boolean>(false);
	const idleTimer = React.createRef<any | null>();

	useEffect(() => {
		window.addEventListener("unload", () => {
			logout();
		});
	}, []);

	const onAction = (e: any) => {
		setIsTimedOut(false);
	};

	const onActive = (e: any) => {
		setIsTimedOut(false);
	};

	const onIdle = (e: any) => {
		try {
			if (isTimedOut) {
				logout(true);
			} else {
				idleTimer.current.reset();
				setIsTimedOut(true);
			}
		} catch (error) {}
	};

	return (
		<>
			{user ? (
				<IdleTimer
					element={document}
					onActive={onActive}
					onIdle={onIdle}
					onAction={onAction}
					debounce={250}
					timeout={LOGIN_IDLE_TIMEOUT}
				/>
			) : (
				""
			)}
			<BaseContainersLayout />
			<EsvdModal />
		</>
	);
};
export default MainPage;
