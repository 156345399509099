import agent from "../api/agent";
export const COUNTRY_CREATE = "COUNTRY_CREATE";
export const COUNTRY_EDIT = "COUNTRY_EDIT";
export const COUNTRY_DELETE = "COUNTRY_DELETE";
export const COUNTRY_FETCH = "COUNTRY_FETCH";
export const COUNTRIES_FETCH = "COUNTRIES_FETCH";

export const fetchCountries = () => async (dispatch: any) => {
  const response = await agent.Countries.list();

  dispatch({
    type: COUNTRIES_FETCH,
    payload: response,
  });
};
