import React from "react";
import { Card, Item } from "semantic-ui-react";

const EsvdStatisticsPanel = () => {
	return (
		<Card fluid>
			<Card.Content>
				<Item>
					<Item.Content>
						<Item.Header>
							<h4>About summary statistics</h4>
						</Item.Header>
					</Item.Content>
				</Item>
			</Card.Content>
			<Card.Content>
				<ul className="dash-list">
					<li>
						The summary statistics show the <b>mean standardised values</b> for
						each selected biome and ecosystem service. To enable comparison,
						values in the ESVD are standardised to a common set of units:{" "}
						<b>International dollars/hectare/year in 2020 price levels.</b> The
						number of value records used to compute mean values is indicated in
						parentheses. Additional summary statistics (number of value records
						used to compute the mean, median, standard deviation, minimum,
						maximum) can be viewed by hovering the mouse pointer over the
						results. A common <b>outlier exclusion rule</b> (OER) based a
						standard boxplot graph is used. The OER defines observations as
						outliers that are either located before the first quantile minus 1.5
						times the interquartile range or beyond the third quantile plus 1.5
						times the interquartile range on a logarithmic scale.
					</li>
					<li>
						In most cases only a portion of the search results will be included
						in the calculation of summary statistics. These are value records
						that: i) <b>Could be standardised</b> to a common set of units
						(International dollars/hectare/year in 2020 price levels); ii){" "}
						<b>Refer to only 1 biome and 1 ecosystem service</b> (i.e. records
						with multiple services or biomes are not included) and iii){" "}
						<b>Do not use value transfer</b> as a valuation method.
					</li>
					<li>
						The number of value records used to compute mean values is indicated
						in parentheses.
					</li>
					<li>
						Additional summary statistics (number of value records used to
						compute the mean, median, standard deviation, minimum, maximum) can
						be viewed by hovering the mouse pointer over the results
					</li>
					<li>
						A common outlier exclusion rule (OER) based a standard boxplot graph
						is used. The OER defines observations as outliers that are either
						located before the first quantile minus 1.5 times the interquartile
						range or beyond the third quantile plus 1.5 times the interquartile
						range on a logarithmic scale.
					</li>
				</ul>
				<p style={{ paddingTop: "1rem" }}>
					Note that <b>this summary of values is for illustrative purposes</b>,
					only to provide an impression of the order of magnitude of the values
					obtained from the literature and to identify data gaps. It is not
					advised to use these summary statistics for value transfers since the
					summary statistics reflect the underlying ecological and
					socio-economic contexts of diverse (but not necessarily
					representative) study sites.
				</p>
			</Card.Content>
		</Card>
	);
};

export default EsvdStatisticsPanel;
