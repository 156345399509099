export const data: any[] = [
  {
    label: "2 -- Coral reefs",
    options: [
      {
        id: 2,
        value: 2,
        label: "Coral reefs",
        sortOrder: 1,
        path: "2",
        isSub: false,
        code: "2",
        checked: true,
      },
      {
        id: 71,
        value: 71,
        label: "Barrier reefs",
        sortOrder: 2,
        path: "2/2.1",
        isSub: true,
        code: "2.1",
      },
      {
        id: 70,
        value: 70,
        label: "Atolls",
        sortOrder: 2,
        path: "2/2.2",
        isSub: true,
        code: "2.2",
      },
      {
        id: 69,
        value: 69,
        label: "Fringing reefs",
        sortOrder: 2,
        path: "2/2.3",
        isSub: true,
        code: "2.3",
      },
      {
        id: 68,
        value: 68,
        label: "Patch reefs",
        sortOrder: 2,
        path: "2/2.4",
        isSub: true,
        code: "2.4",
      },
      {
        id: 75,
        value: 75,
        label: "Other (coral reefs)",
        sortOrder: 2,
        path: "2/2.5",
        isSub: true,
        code: "2.5",
      },
    ],
  },
  {
    label: "3 -- Coastal systems (incl. wetlands)",
    options: [
      {
        id: 3,
        value: 3,
        label: "Coastal systems (incl. wetlands)",
        sortOrder: 1,
        path: "3",
        isSub: false,
        code: "3",
      },
      {
        id: 67,
        value: 67,
        label: "Sand dunes, beaches, rocky shores",
        sortOrder: 2,
        path: "3/3.1",
        isSub: true,
        code: "3.1",
      },
      {
        id: 76,
        value: 76,
        label: "Kelp forests",
        sortOrder: 2,
        path: "3/3.11",
        isSub: true,
        code: "3.11",
      },
      {
        id: 77,
        value: 77,
        label: "Other (coastal systems)",
        sortOrder: 2,
        path: "3/3.12",
        isSub: true,
        code: "3.12",
      },
      {
        id: 65,
        value: 65,
        label: "Tidal marshes",
        sortOrder: 2,
        path: "3/3.2",
        isSub: true,
        code: "3.2",
      },
      {
        id: 64,
        value: 64,
        label: "Salt marshes",
        sortOrder: 2,
        path: "3/3.3",
        isSub: true,
        code: "3.3",
      },
      {
        id: 63,
        value: 63,
        label: "Mangroves",
        sortOrder: 2,
        path: "3/3.4",
        isSub: true,
        code: "3.4",
      },
      {
        id: 62,
        value: 62,
        label: "Lagoons",
        sortOrder: 2,
        path: "3/3.5",
        isSub: true,
        code: "3.5",
      },
      {
        id: 61,
        value: 61,
        label: "Estuaries",
        sortOrder: 2,
        path: "3/3.6",
        isSub: true,
        code: "3.6",
      },
      {
        id: 60,
        value: 60,
        label: "Unvegetated sediment",
        sortOrder: 2,
        path: "3/3.7",
        isSub: true,
        code: "3.7",
      },
      {
        id: 59,
        value: 59,
        label: "Shellfish reefs",
        sortOrder: 2,
        path: "3/3.8",
        isSub: true,
        code: "3.8",
      },
      {
        id: 66,
        value: 66,
        label: "Seagrass beds",
        sortOrder: 2,
        path: "3/3.9",
        isSub: true,
        code: "3.9",
      },
    ],
  },
];

export const userProfileGridHeaderColumns: any[] = [
  {
    key: "displayName",
    title: "Display Name",
    dataKey: "displayName",
    width: 250,
    resizable: true,
    sortable: false,
  },
  { key: "userName", title: "Username", dataKey: "userName", width: 250,
    resizable: true,
    sortable: false },
  {
    key: "email", title: "Email", dataKey: "email", width: 300,
    resizable: true,
    sortable: true, },
  {
    key: "registrationDate",
    title: "Registration Date",
    dataKey: "registrationDate",
    width: 200,
    resizable: true,
    sortable: false,
    dataType: "date"
  },
  {
    key: "numberOfSignIn",
    title: "No. Of SignIn",
    dataKey: "numberOfSignIn",
    width: 100,
    resizable: true,
    sortable: false,
  },
  {
    key: "lastSignInDate",
    title: "Last SignIn Date",
    dataKey: "lastSignInDate",
    width: 200,
    resizable: true,
    sortable: false,
    dataType: "date"
  },
  { key: "sector", title: "Sector", dataKey: "sector", width: 180,
    resizable: true,
    sortable: false },
  // { key: "id", title: "id", dataKey: "id", width: 200 },
  {
    key: "organization",
    title: "Organization",
    dataKey: "organization",
    width: 200,
    resizable: true,
    sortable: false,
  },
  {
    key: "purposeOfAccess",
    title: "Purpose Of Access",
    dataKey: "purposeOfAccess",
    width: 150,
    resizable: true,
    sortable: false,
  },
];