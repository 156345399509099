import { BENEFICIARYUNITS_FETCH } from "../../actions/others/beneficiaryUnitActions";

const beneficiaryUnitReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case BENEFICIARYUNITS_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default beneficiaryUnitReducer;
