import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import agent from "../api/agent";
import { Valuation } from "../models/Valuation";
import { Study } from "../models/Study";
import { initStudy } from "../shared/Literals";

interface IValue {
	getStudyValuations: Function;
	studyValuations: Valuation[];
	studies: Study[];
	setStudies: Function;
	deleteValuation: Function;
	setCurrentStudy: Function;
	currentStudy: Study | null;
	setIsEditor: Function;
	isEditor: boolean;
	isLoadingValuations: boolean;
	setCurrentValuation: Function;
	currentValuation: Valuation | null;
	saveValuation: Function;
	getUserStudies: Function;
	resetStudies: Function;
}

const ValuationsContext = React.createContext<IValue | null>(null);

// The ValuationsProvider is responsible for Esvd Valuation management
const ValuationsProvider = ({ children }: { children: any }) => {
	const [studies, setStudies] = useState<Study[]>([]);
	const [studyValuations, setStudyValuations] = useState<Valuation[]>([]);
	const [currentStudy, setCurrentStudy] = useState<Study | null>(initStudy);
	const [currentValuation, setCurrentValuation] = useState<Valuation | null>(
		null
	);
	const [isEditor, setIsEditor] = useState<boolean>(false);
	const [isLoadingValuations, setIsLoadingValuations] =
		useState<boolean>(false);

	// useEffect(() => {
	// 	return () => {};
	// }, []);

	const getUserStudies = async () => {
		try {
			const response = await agent.Studies.userList();
			const studs = { ..._.mapKeys(response, "id") };
			const sorted = Object.values<Study>(response).sort((a, b) => {
				return !!a.studyId > !!b.studyId ? 1 : -1; //sort by study in descending order
			});
			setStudies(sorted);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getStudyValuations = async (studyid: number | null) => {
		try {
			// //reset the valuation list
			// dispatch({
			// 	type: USER_VALUATIONS_FETCH,
			// 	payload: [],
			// });
			setIsLoadingValuations(true);
			const response = !studyid
				? []
				: await agent.StudyValutations.studyValuations(studyid);

			setStudyValuations(
				Object.values<Valuation>(response).sort(
					(a, b) => (a.valueId > b.valueId ? 1 : -1) //ascending order
				)
			);
			setIsLoadingValuations(false);
			// dispatch({
			// 	type: USER_VALUATIONS_FETCH,
			// 	payload: response,
			// });
		} catch (error: any) {
			setIsLoadingValuations(false);
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const saveValuation = async (valuation: Valuation) => {
		try {
			if (valuation.id === null) {
				valuation.id = 0;
			}
			const response =
				!valuation.id || valuation.id === 0
					? await agent.StudyValutations.create(valuation)
					: await agent.StudyValutations.update(valuation);
			return response;
			// dispatch({
			// 	type:
			// 		!valuation.id || valuation.id === 0
			// 			? VALUATION_CREATE
			// 			: VALUATION_EDIT,
			// 	payload: response,
			// });
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const deleteValuation = async (id: number) => {
		try {
			await agent.StudyValutations.delete(id);
			// dispatch({
			// 	type: VALUATION_DELETE,
			// 	payload: id,
			// });
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};
	const resetStudies = () => {
		setStudyValuations([]);
	};

	return (
		<ValuationsContext.Provider
			value={{
				getStudyValuations,
				studyValuations,
				studies,
				setStudies,
				deleteValuation,
				setCurrentStudy,
				currentStudy,
				setIsEditor,
				isEditor,
				isLoadingValuations,
				currentValuation,
				setCurrentValuation,
				saveValuation,
				getUserStudies,
				resetStudies,
			}}>
			{children}
		</ValuationsContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useValuations = () => {
	const valuations = useContext(ValuationsContext);
	if (valuations == null) {
		throw new Error("useValuations() called outside of a valuations hook?");
	}
	return valuations;
};

export { ValuationsProvider, useValuations };
