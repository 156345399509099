import agent from "../api/agent";
import { SuggestStudy } from "../models/SuggestStudy";
export const SUGGEST_STUDY_EMAIL = "SUGGEST_STUDY_EMAIL";

export const emailSuggestedStudy =
  (study: SuggestStudy) => async (dispatch: any) => {
    try {
      return await agent.SuggestStudies.create(study);
    } catch (error: any) {
      throw error.response ? error.response.statusText : error.message;
    }
  };
