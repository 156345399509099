import { CONTINENTS_FETCH } from "../actions/continentActions";

const continentsReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case CONTINENTS_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default continentsReducer;
