import React, { useEffect, useState } from "react";

export function SingleSelectRenderer(props: any) {
	const { value, cellProperties, data } = props;
	// const [txt, setTxt] = useState<any[]>([]);
	const txt: any[] = [];
	// useEffect(() => {

	// });

	// const formatValue = () => {
	// 	if (value && data) {
	// 		//build data
	// 		cellProperties.data = value;
	// 		const it = data.find((x: any) => x.id === value);
	// 		if (it) return it.label;
	// 		return value;
	// 	} else {
	// 		return value;
	// 	}
	// };

	const formatValue = () => {
		if (value) {
			//build data
			if (data) {
				// const idd = parseInt(sp[0]);
				// if (!idd || typeof idd !== "number") {
				// 	return value;
				// }
				const it = data.find((x: any) => x.label === value);
				if (!it) return value;
				txt.push(it.label);
				cellProperties.data = JSON.stringify({
					value: it.id,
					label: it.label,
					code: it.id,
				});
				// cellProperties.data = JSON.stringify(
				// 	// sp.map((id) => {
				// 		// const it = data.find((x) => x.code === id.trim());
				// 		// if (!it) return value;
				// 		// txt.push(it.label);
				// 		// setTxt([...txt, it.label]);
				// 		return { value: parseInt(id), label: it.label };
				// 	// })
				// );
			}
			return txt.length > 0 ? txt.join(", ") : value;
		} else {
			return value;
		}
	};

	return formatValue();
}
