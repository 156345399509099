import React, { useEffect, useState } from "react";

export function SingleSelectCodesRenderer(props: any) {
	const { value, cellProperties, data } = props;
	const txt: any[] = [];

	const formatValue = () => {
		if (value) {
			//build data
			if (data) {
				const it = data.find((x: any) => x.label === value);
				if (!it) return value;
				txt.push(it.value);
				cellProperties.data = JSON.stringify({
					value: it.id,
					label: it.label,
					code: it.id,
				});
			}
			return txt.length > 0 ? txt.join("; ") : value;
		} else {
			return value;
		}
	};

	return formatValue();
}
