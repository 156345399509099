import { NumberFormat } from "../../EsvdFunctions";
import { SummaryStatsColumnStructure } from "../../models/columnStructure";
import { Esvd, Statistics } from "../../models/esvd";
import { ItemLabels } from "../../shared/Literals";

export function processStatsData1(
  data: SummaryStatsColumnStructure[],
  rowHeadersProp: string,
  columnHeadersProp: string,
  valueProp: string,
  rowSortingProp: string = "",
  columnSortingProp: string = ""
): any {
  if (data.length === 0) {
    return { header: [], body: [] };
  }

  const columns = getRowColumnHeaders(
    data,
    columnHeadersProp,
    columnSortingProp
  );

  const rows = getRowColumnHeaders(data, rowHeadersProp, rowSortingProp);

  //get the sum and count of the values coresponding to each combination of row and col
  let arr = [];
  for (const row of rows) {
    let cols: any = {};
    for (const col of columns) {
      const valuations = data
        .filter((k) => {
          return (
            k[rowHeadersProp] === row.name && k[columnHeadersProp] === col.name
          );
        })
        .map((a) =>  a[valueProp]);
      cols[`${col.code}. ${col.name}`] = valuations;
    }
    arr.push({
      [`${row.code}. ${row.name}`]: cols,
    });
  }

  return { header: columns.map((a) => `${a.code}. ${a.name}`), body: arr };
}

export function processStatsData(
  data: SummaryStatsColumnStructure[],
  // teebs: string,
  // biomes: string,
  // valueProp: string,
  // rowSortingProp: string = "",
  // columnSortingProp: string = ""
): any {
  if (data.length === 0) {
    return { header: [], body: [] };
  }

  const columns = getRowColumnHeaders(
    data,
    ItemLabels.biome,
    ItemLabels.biomeCode
  );

  const rows = getRowColumnHeaders(data, ItemLabels.teeb, ItemLabels.teebCode);

  //get the sum and count of the values coresponding to each combination of row and col
  let arr = [];
  for (const row of rows) {
    let cols: any = {};
    for (const col of columns) {
      const valuations = data
        .filter((k) => {
          return (
            k[ItemLabels.teeb] === row.name && k[ItemLabels.biome] === col.name
          );
        })
        .map((a) => ({
          "intPerHectarePerYear": a[ItemLabels.Int_Per_Hectare_Per_Year],
          "inclExcl": a[ItemLabels.InclExcl]
        }));
      cols[`${col.code}. ${col.name}`] = valuations;
    }
    arr.push({
      [`${row.code}. ${row.name}`]: cols,
    });
  }

  return { header: columns.map((a) => `${a.code}. ${a.name}`), body: arr };
}

function getRowColumnHeaders(
  data: any[],
  headersProp: string,
  sortingProp: string = ""
) {
  //Get a list of names and codes of the values to use as columns/rows.
  //This may return duplicate values for each column/row. So we extract them into a Set,
  //to remove duplicates and then convert to array. The codes is included here specifically for sorting.
  //So that the list of columns/rows will be sorted based on the codes. It is also included in the display.
  //The "|" is used to here as a dilimeter so that they can be split later.
  let itemSet = Array.from<string>(
    new Set<string>(
      data.map(
        (x) => `${sortingProp && x[sortingProp] + "| "}${x[headersProp]}`
      )
    )
  );

  //Now split the columnset/rowset into an array of {code, name} objects using the "|" as the dilimeter
  //This is to allow sorting by the codes.
  let itemSet2 = itemSet.map((x: string) => {
    const xx = x.split("|");
    return { code: parseInt(xx[0]), name: xx[1].trim() };
  });

  //Now sort the columns/rows using the codes
  const items = itemSet2.sort((a, b) => {
    return a.code - b.code;
  });
  return items;
}


//www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-225.php
export const findMedian = (arr: number[]) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

//#Source https://bit.ly/2neWfJ2
export const findStandardDeviation = (arr: number[], usePopulation = false) => {
  const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
  return Math.sqrt(
    arr
      .reduce((acc: number[], val) => acc.concat((val - mean) ** 2), [])
      .reduce((acc, val) => acc + val, 0) /
    (arr.length - (usePopulation ? 0 : 1))
  );
};



const quartile = (valuations: number[], q: any) => {
  const sorted = valuations.sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

/**
 * Outliers: 1.5 above the third quartile or below the first quartile of log transformed values after sorting
 */
// export const trimOutliers = (valuations: number[]) => {
//   const factor = 1.5;
//   //1st quartile
//   const q25 = quartile(valuations, 0.25);

//   //2nd quartile also the median
//   const q50 = quartile(valuations, 0.5);

//   //3rd quartile
//   const q75 = quartile(valuations, 0.75);

//   //sort the array
//   const sorted = valuations.sort((a, b) => a - b);

//   const iqrv = q75 - q25;

//   //cut off outliers
//   return sorted.filter(
//     (x) =>
//       Math.log(x) > q25 - factor * iqrv && Math.log(x) < q75 + factor * iqrv
//   );
// };

/**
 * Outliers: 1.5 above the third quartile or below the first quartile of log transformed values after sorting
 */
export const trimOutliers = (esvds: Esvd[]) => {
  const factor = 1.5;

  let valuations = esvds.map((esvd) => Math.log10(esvd.intPerHectarePerYear || 0))
  
  //1st quartile
  const q25 = quartile(valuations, 0.25);

  ////2nd quartile also the median
  // const q50 = quartile(valuations, 0.5);

  //3rd quartile
  const q75 = quartile(valuations, 0.75);

  // //sort the array
  // const sorted = valuations.sort((a, b) => a - b);

//https://online.stat.psu.edu/stat200/lesson/3/3.2#:~:text=We%20can%20use%20the%20IQR,add%20this%20value%20to%20Q3.
  
  const iqrv = q75 - q25;

  //cut off outliers
  return esvds.filter(
    (x) =>
      Math.log10(x.intPerHectarePerYear || 0) > q25 - factor * iqrv && Math.log10(x.intPerHectarePerYear || 0) < q75 + factor * iqrv
  );
};


/**
 * 
 * Outliers: 1.5 above the third quartile or below the first quartile of log transformed values, 
 * dus de 1.5 iqr outlier exclusion rules. 
 * First standardized values are transformed to their log values and then (with help of a boxplot) 
 * is determined if the those values are outside 1.5 iqr.
 * 
 * 
 * 
 *  
 *  
 */
// /**
//  * remove 2.5% (floor) of valuations after sorting
//  */
// const trimOutliers = (valuations: number[]) => {
//   //calculate the 2.5% cut-off
//   const cutoff = Math.floor(valuations.length * 0.025);

//   //sort the array
//   const sorted = valuations.sort((a, b) => a - b);

//   //cut off outliers
//   return sorted.slice(cutoff, valuations.length - cutoff);
// };


export const calculateStats = (valuations: any[], measure: string) => {
  const stats: Statistics = {
    mean: "",
    count: "",
    max: "",
    min: "",
    median: "",
    stdDev: "",
    display: "",
    countNum: 0,
  };
  if (valuations.length === 0) return stats;

  //apply the Outlier criteria
  let dataValidForStats = valuations.filter(x=>x.inclExcl===true && x.intPerHectarePerYear);
  if (dataValidForStats.length >= 40) {
    dataValidForStats = trimOutliers(dataValidForStats);
  }

  stats.countNum = dataValidForStats.length;
  stats.count = `${NumberFormat(dataValidForStats.length, 0)} `;//of ${valuations.length}


  const onlyFigures = dataValidForStats.map(x => x.intPerHectarePerYear)
  const sum = onlyFigures.reduce((a, b) => a + b, 0);
  const mean = sum / onlyFigures.length;
  //values 10 or more are shown without the decimal whiles those below 10 are shown with the decimal.
  stats.mean = NumberFormat(mean, mean >= 10 ? 0 : 2);
  stats.display = `(${dataValidForStats.length}) ${stats.mean}`;

  const max = Math.max(...onlyFigures);
  stats.max = NumberFormat(max, max >= 10 ? 0 : 2);
  const min = Math.min(...onlyFigures.filter((x) => x !== 0));
  stats.min = NumberFormat(min, min >= 10 ? 0 : 2);
  const median = findMedian(onlyFigures);
  stats.median = NumberFormat(median, median >= 10 ? 0 : 2);
  const stdDev = findStandardDeviation(onlyFigures);
  stats.stdDev = NumberFormat(stdDev, stdDev >= 10 ? 0 : 2);

  return stats;
};

// export const getStats = (valuationValues)

// export 	const getStatistics = (row: any) => {
// 		return Object.entries(row).map(([key, value]) => {
// 			let arr = [];
// 			arr.push(<Table.Cell key={key}>{key}</Table.Cell>);
// 			const valuations: any = value;
// 			const valValues = Object.values(valuations);
// 			const stats: Statistics[] = valValues.map((vals: any, i) =>
// 				calculateStats(vals, measureStats.mean)
// 			);
// 			stats.map((stat, i) =>
// 				arr.push(
// 					<Table.Cell key={i} style={{ cursor: "pointer" }}>
// 						<Popup
// 							wide
// 							size="small"
// 							trigger={<span>{stat.display}</span>}
// 							content={<StatsPopup {...stat} />}
// 							// basic
// 						/>
// 					</Table.Cell>
// 				)
// 			);
// 			return arr;
// 		});
// 	};