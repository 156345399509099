// import _ from "lodash";
import { ECOSYSTEMSERVICES_FETCH } from "../actions/ecosystemServiceActions";

import { TreeType } from "../models/ecosystemService";

const ecosystemServiceReducer = (state: TreeType[] = [], action: any) => {
  switch (action.type) {
    case ECOSYSTEMSERVICES_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default ecosystemServiceReducer;
