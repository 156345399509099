import jsPDF from "jspdf";
import moment from "moment";
// import autoTable from "jspdf-autotable";
// import { APP_NAME } from "../../shared/Literals";
import adviceLogo from "../../images/esvd_logo_large.jpg";
import { CurrentReferenceInfo } from "../../models/ReferenceInfo";
// import riceAdviceImage from "./RiceAdviceImage";

//https://github.com/simonbengtsson/jsPDF-AutoTable#styles

const MakePDF = (currentVersion: CurrentReferenceInfo) => {

  
  const pdf = new jsPDF("p", "pt", "letter"); // Default export is a4 paper, portrait, using milimeters for units
 
  
  let currentYPos = 50;
  //use this to track didDrawCell rows
  // let rowIndex = -1;

  const startX = 40;
  const endX = pdf.internal.pageSize.getWidth() - 40;

  // const spaceBetweenSections = 25;
  // const spaceBeforeHeaderLine = 30;
  const spaceBetweenHeaderContent = 5;
  //Good resource for two tables on same row:https://stackoverflow.com/a/41698113/5367889
  //https://stackoverflow.com/a/52675932/5367889
  // var startingPage = pdf.getCurrentPageInfo().pageNumber;
  // const styles = {
  //   fontSize: 10,
  //   lineColor: 255,
  //   fontStyle: "normal", // normal, bold, italic, bolditalic
  //   fillColor: false, // false for transparent or a color as described below
  //   textColor: 20,
  //   halign: "left", // left, center, right
  //   valign: "top", // top, middle, bottom
  //   // cellPadding: 2,
  // };

  //logo
  const logo = new Image();
  logo.addEventListener("load", function (img: any) {

    
    const logoHeight = img.target.height;
    pdf.addImage(logo, "jpg", startX - 5, 20,img.target.width, logoHeight);
    //main header text
    // currentYPos += 40;
    pdf.text("Ecosystem Services Valuation Database (ESVD)", startX + img.target.width + 20, currentYPos - 5);
   currentYPos += logoHeight - 25;

 
    pdf.setDrawColor(0, 0, 0);
    pdf.setLineWidth(0.5);
    pdf.line(startX, currentYPos, endX, currentYPos);
    currentYPos += spaceBetweenHeaderContent + 20;
    //header text
    pdf.setFontSize(12);
    pdf.text("Database Version", startX, currentYPos);
    currentYPos += spaceBetweenHeaderContent + 10;
    pdf.setFontSize(10);
    pdf.text(currentVersion.version, startX, currentYPos);

    currentYPos += spaceBetweenHeaderContent + 20;
    pdf.setFontSize(12);
    pdf.text("Correct Citation", startX, currentYPos);
    currentYPos += spaceBetweenHeaderContent + 10;
    const splitText = pdf.splitTextToSize(currentVersion.referenceText || "", 600)
    pdf.setFontSize(10);
    //pdf.text(splitText, startX, currentYPos);
    // console.log(splitText);
    for (let i = 0; i < splitText.length; i++) {
      pdf.text(splitText[i], startX, currentYPos);
      currentYPos += spaceBetweenHeaderContent + 5;
    }
    // currentYPos += 600;
    currentYPos += spaceBetweenHeaderContent + 20;
    pdf.setFontSize(10);
    pdf.text("ESVD Database: https://esvd.net", startX, currentYPos);
    currentYPos += spaceBetweenHeaderContent + 10;
    pdf.text("ESVD Website: https://esvd.info", startX, currentYPos);
  
    pdf.save(`Esvd_Citation_${moment().format(
      "Do-MMM-yyyy_HH-mm-ss"
    )}_Database_Version_${currentVersion?.version}.pdf`);
    pdf.close();
  })
  
  logo.src = adviceLogo;
  
  
};

export default MakePDF;
