// import _ from "lodash";
import {
  ESVDS_FETCH_FILTERED_APPEND,
  RESET_ESVDS_APPENDED_FILTERED_DATA,
} from "../../actions/esvdActions";
import { EsvdQueryDataWithOffset } from "../../models/EsvdQueryFilters";

/**
 *
 * To keep track of the next Offset (the next page we are going to automatically pull, we add it to the state.)
 * Then inside the component where we call the action to pull the data, we use it to specify specify the next offset
 * @param action
 * @returns
 */
const esvdAppendedFilteredDataReducer = (
  state: EsvdQueryDataWithOffset = { nextOffset: 0, data: [] },
  action: any
) => {
  switch (action.type) {
    case ESVDS_FETCH_FILTERED_APPEND:
      let nextOffset = state.nextOffset + 1;
      return { nextOffset, data: [...state.data, ...action.payload] };
    case RESET_ESVDS_APPENDED_FILTERED_DATA:
      return { nextOffset: 0, data: [] };
    default:
      return state;
  }
};

export default esvdAppendedFilteredDataReducer;
