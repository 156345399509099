import React from "react";
import { ValuationsProvider } from "../../providers/ValuationsProvider";

import StudyBoard from "./StudyBoard";

const StudyBoardWrapper = () => {
	return (
		<ValuationsProvider>
			<StudyBoard />
		</ValuationsProvider>
	);
};

export default StudyBoardWrapper;
