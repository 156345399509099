import { Component } from "react";
import { Card } from "semantic-ui-react";
import { useReferenceInfo } from "../../providers/ReferenceInfoProvider";

const TermsOfUse = () => {
	const { currentInfo } = useReferenceInfo();
	return (
		<Card fluid>
			<Card.Content>
				<h4>Terms of Use </h4>
			</Card.Content>
			<Card.Content>
				<p>
					We allow use for non-commercial, research and educational purposes
					aimed at supporting more sustainable ecosystem management and policy
					making.
				</p>
				<p>
					Reports using the ESVD should be made publicly available and use
					correct citation: {currentInfo?.referenceText}
					{/* (Foundation for Sustainable Development (2021).
					Ecosystem Services Valuation Database 1.0, [download date],
					https://esvd.net). */}
				</p>
				<p>
					In no event will the Foundation for Sustainable Development, their
					affiliates, agents or collaborators be liable for any damages;
					including without limitation, direct or indirect, special or
					incidental, moral or consequential, arising in connection with your
					use of the ESVD, its information or derivatives of the ESVD.
				</p>
			</Card.Content>
		</Card>
	);
};

export default TermsOfUse;
