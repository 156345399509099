import agent from "../../api/agent";
export const TEMPORALUNIT_CREATE = "TEMPORALUNIT_CREATE";
export const TEMPORALUNIT_EDIT = "TEMPORALUNIT_EDIT";
export const TEMPORALUNIT_DELETE = "TEMPORALUNIT_DELETE";
export const TEMPORALUNIT_FETCH = "TEMPORALUNIT_FETCH";
export const TEMPORALUNITS_FETCH = "TEMPORALUNITS_FETCH";

export const fetchTemporalUnits = () => async (dispatch: any) => {
  const response = await agent.TemporalUnits.list();

  dispatch({
    type: TEMPORALUNITS_FETCH,
    payload: response,
  });
};
