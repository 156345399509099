import React from "react";
import { Grid, Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { actions, initStudy, resources } from "../../shared/Literals";
import StudyEntry from "./StudyEntry";
import StudyList from "./StudyList";
import { Study } from "../../models/Study";
import Valuations from "./Valuations";
import StudiesReadMe from "./StudiesReadMe";
// import {
// 	fetchUserStudies,
// 	setIsEditor,
// 	resetStudies,
// } from "../../actions/studyActions";
import Can from "../../accessControls/Can";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
import { useValuations } from "../../providers/ValuationsProvider";

const { Media, MediaContextProvider } = MediaLayout;

// interface IProps {
// 	fetchUserStudies: Function;
// 	resetStudies: Function;
// 	setIsEditor: Function;
// }

// interface IState {
// 	selectedStudy: Study;
// 	clearValuationUi: boolean;
// }
const StudyBoard = () => {
	const [studies, setStudies] = React.useState<Study[]>([]);
	const [selectedStudy, setSelectedStudy] = React.useState<Study>(initStudy);
	const { setIsEditor, getUserStudies } = useValuations();

	React.useEffect(() => {
		getUserStudies();
		setIsEditor(false);
	}, []);
	// state: IState = {
	// 	selectedStudy: initStudy,
	// 	clearValuationUi: false, //this notifies the Valuations component to reset the fields and show list if not already showing
	// };

	// componentDidMount() {
	// 	// this.props.resetStudies();
	// 	// this.props.setIsEditor();
	// 	// this.props.fetchUserStudies();
	// 	this.setSelectedStudy(initStudy);
	// }
	// setSelectedStudy = (study: Study) => {
	// 	this.setState(() => ({ selectedStudy: study }));
	// };

	const clearStudy = () => {
		setSelectedStudy(initStudy);
		// this.setState(() => ({
		// 	selectedStudy: initStudy,
		// 	clearValuationUi: true,
		// }));
	};

	const renderContent = () => {
		// const { selectedStudy, clearValuationUi } = this.state;
		return (
			<MediaContextProvider>
				<Media at="mobile" className="page-content">
					<StudiesReadMe />
					<StudyEntry
						headerText={
							selectedStudy.id
								? `Edit study ${selectedStudy.studyId} `
								: "Add study "
						}
						study={selectedStudy}
						clearStudy={clearStudy}
						onStudyUpdated={(study: Study) => setSelectedStudy(study)}
					/>
					<Valuations
						selectedStudy={selectedStudy}
						// clearValuationUi={clearValuationUi}
						// onClearValuationUi={() =>
						// 	this.setState(() => ({ clearValuationUi: false }))
						// }
					/>
					<StudyList
						headerText="Your studies"
						onSelectionChanged={setSelectedStudy}
						clearStudy={clearStudy}
						// studies={studies}
					/>
				</Media>
				<Media greaterThan="mobile">
					<Container fluid className="top-container">
						<Grid columns={2} className="page-content" stackable>
							<Grid.Row>
								<Grid.Column width={12}>
									<StudyEntry
										headerText={
											selectedStudy.id
												? `Edit study ${selectedStudy.studyId} `
												: "Add study "
										}
										study={selectedStudy}
										clearStudy={clearStudy}
										onStudyUpdated={(study: Study) => setSelectedStudy(study)}
									/>
									<Valuations
										selectedStudy={selectedStudy}
										// clearValuationUi={clearValuationUi}
										// onClearValuationUi={() =>
										// 	this.setState(() => ({ clearValuationUi: false }))
										// }
									/>
								</Grid.Column>
								<Grid.Column width={4}>
									<StudiesReadMe />
									<StudyList
										headerText="Your studies"
										onSelectionChanged={setSelectedStudy}
										clearStudy={clearStudy}
										// studies={studies}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</Media>
			</MediaContextProvider>
		);
	};
	return (
		<Can
			action={actions.read}
			resource={resources.study.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	); // <Fragment>{this.renderContent()}</Fragment>;
};

// const mapStateToProps = (state: any) => {
//   return { user: state.user };
// };

export default StudyBoard;

//   connect(null, { fetchUserStudies, setIsEditor, resetStudies })(
// 	StudyBoard
// );
