import agent from "../api/agent";
export const SEEA_CREATE = "SEEA_CREATE";
export const SEEA_EDIT = "SEEA_EDIT";
export const SEEA_DELETE = "SEEA_DELETE";
export const SEEA_FETCH = "SEEA_FETCH";
export const SEEAS_FETCH = "SEEAS_FETCH";

export const fetchSEEAs = () => async (dispatch: any) => {
  const response = await agent.SEEAs.list();

  dispatch({
    type: SEEAS_FETCH,
    payload: response,
  });
};
