import React from "react";
// import { connect } from "react-redux";
import { Button, Grid, List } from "semantic-ui-react";
import moment from "moment";
// import {
// 	fetchStudyValuations,
// 	deleteValuation,
// } from "../../actions/valuationsActions";
import { Study } from "../../models/Study";
import { Valuation } from "../../models/Valuation";
import LoadingOverlay from "../../shared/LoadingOverlay";
import { useValuations } from "../../providers/ValuationsProvider";

interface IProps {
	// fetchStudyValuations: Function;
	// deleteValuation: Function;
	onSelectionChanged: Function;
	onClone: Function;
	// valuations: Valuation[];
	selectedStudy: Study;
	// isEditor: boolean;
}

const ValuationList = (props: IProps) => {
	const {
		getStudyValuations,
		studyValuations,
		isEditor,
		// setIsEditor,
		isLoadingValuations,
		setCurrentValuation,
		saveValuation,
	} = useValuations();
	// const [valuations, setValuations]=React.useState<Valuation[]>([]);
	// state = {
	// 	isLoadingValuations: false,
	// 	valuations: [],
	// };

	// componentDidMount() {
	// 	this.props.fetchStudyValuations();
	// }

	// componentDidUpdate(prevProp: IProps) {
	// 	const { selectedStudy } = this.props;
	// 	if (prevProp.selectedStudy.id !== selectedStudy.id) {
	// 		this.setState(() => ({ isLoadingValuations: true }));
	// 		this.props
	// 			.fetchStudyValuations(selectedStudy.id)
	// 			.then((res: any) => {
	// 				this.setState(() => ({ isLoadingValuations: false }));
	// 			})
	// 			.catch((err: any) => {
	// 				this.setState(() => ({ isLoadingValuations: false }));
	// 				this.showModal(err);
	// 			});
	// 	}
	// }

	React.useEffect(() => {
		const { selectedStudy } = props;
		selectedStudy && getStudyValuations(selectedStudy.id);
	}, [props.selectedStudy]);

	// const showModal = (msg: string) => {
	// 	// const { openModal } = props;
	// 	openModal({
	// 		icon: "error",
	// 		header: "Error!",
	// 		content: (
	// 			<Grid.Row centered>
	// 				<Grid.Column width={12}>
	// 					<p className="pos_fixed">{msg}</p>
	// 				</Grid.Column>
	// 			</Grid.Row>
	// 		),
	// 		isOpen: true,
	// 		// actions: null,
	// 	});
	// };

	const selectedValuation = (valuation: Valuation) => {
		// this.props.onSelectionChanged(valuation);
	};

	const removeValuation = (valuation: Valuation) => {
		// this.props
		// 	.deleteValuation(valuation.id)
		// 	.then((res: any) => {})
		// 	.catch((error: any) => {});
	};

	const cloneValuation = async (valuation: Valuation) => {
		let cloned = Object.assign({}, valuation);
		cloned.valueId = 0;
		cloned.id = null;

		if (!cloned.id || cloned.id === 0) {
			const { selectedStudy } = props;
			//new valuation, so set the studyid to the current study and create the valueid
			cloned.studyId = selectedStudy?.id || 0;
			//set the valueid also
			const cnt = studyValuations.length;
			if (cnt === 0) {
				cloned.valueId = parseInt(`${selectedStudy.id}00001`) || 0;
			} else {
				//get max valueid
				const maxVal = Math.max(...studyValuations.map((x) => x.valueId));
				cloned.valueId = maxVal + 1;
			}
		}
		cloned = await saveValuation(cloned);
		// const { selectedStudy } = props;
		// selectedStudy && getStudyValuations(selectedStudy.id);
		setCurrentValuation(cloned);
		// this.props.setAsCurrentValuation(cloned);
		// this.setState(() => ({
		//   selectedValuation: cloned,
		//   showList: false,
		// }));
	};

	const renderSimpleList = () => {
		const { selectedStudy } = props;
		if (!selectedStudy) return null;

		return (
			<List celled selection animated>
				{studyValuations.map((valuation, i) => (
					<List.Item key={i}>
						<List.Content floated="right" style={{ paddingLeft: "0px" }}>
							{!selectedStudy.isPublished && (
								<Button
									size="mini"
									color={"green"}
									onClick={() => cloneValuation(valuation)}>
									copy
								</Button>
							)}
						</List.Content>
						<List.Content onClick={() => setCurrentValuation(valuation)}>
							<List.Header>{valuation.valueId}</List.Header>
							{`${valuation.studyId} - ${moment(valuation.codedDate).format(
								"DD-MM-YYYY HH:mm:ss"
							)}`}
						</List.Content>
					</List.Item>
				))}
			</List>
		);
	};

	// const	onSelectValuation = (v: Valuation) => {
	// 		const { selectedStudy, isEditor } = this.props;
	// 		if (isEditor) {
	// 			this.selectedValuation(v);
	// 		} else {
	// 			!selectedStudy.isPublished && this.selectedValuation(v);
	// 		}
	// 	};

	const renderDetailedList = () => {
		// const { valuations } = this.props;
		return (
			<List celled selection animated>
				{studyValuations.map((valuation, i) => (
					<List.Item key={i}>
						<List.Content onClick={() => setCurrentValuation(valuation)}>
							<List.Header>{valuation.valueId}</List.Header>
							<List.Description>{`Location Name: ${
								valuation.studyLocation || ""
							}`}</List.Description>
							{`Ecosystem Text: ${valuation.ecosystemDescription || ""}`} <br />
							{`Ecosystem Service Text: ${
								valuation.ecosystemServiceDescription || ""
							}`}
							<br />
							{`Valuation Change: ${valuation.valuedChange || ""}`}
						</List.Content>
					</List.Item>
				))}
			</List>
		);
	};

	// render() {
	// const { isLoadingValuations } = this.state;
	return (
		<div style={{ overflowY: "auto", height: "55vh" }}>
			<LoadingOverlay active={isLoadingValuations} text="Loading valuations" />
			{isEditor ? renderDetailedList() : renderSimpleList()}
		</div>
	);
	// }
};

// const mapStateToProps = (state: any) => {
// 	return {
// 		valuations: Object.values<Valuation>(state.valuations).sort(
// 			(a, b) => (a.valueId > b.valueId ? 1 : -1) //ascending order
// 		),
// 		isEditor: state.isEditor,
// 	};
// };

export default ValuationList;

// 	connect(mapStateToProps, {
// 	fetchStudyValuations,
// 	deleteValuation,
// 	openModal,
// })(ValuationList);
