import React, { useContext, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { EsvdDataStatistics } from "../models/EsvdDataStatistics";
// import agent from "../api/agent";

interface IValue {
	setUploading: Function;
	setFileName: Function;
	uploading: boolean | undefined;
	esvdInserted?: number;
	esvdUpdated?: number;
	uploadedEsvdCount?: number;
	// esvdsInFile?: number;
	// setEsvdsInFile: Function;
	loading: boolean;
	setLoading: Function;
	filename: string | undefined;
}

const UploadInfoContext = React.createContext<IValue | null>(null);

// The UploadInfoProvider is responsible for UploadInfo management
const UploadInfoProvider = ({ children }: { children: any }) => {
	const [uploading, setUploading] = React.useState<boolean | undefined>(false);
	const [filename, setFileName] = React.useState<string | undefined>();
	const [loading, setLoading] = React.useState<boolean>(false);
	const [esvdInserted, setEsvdInserted] = React.useState<any>(0);
	const [uploadedEsvdCount, setUploadedEsvdCount] = React.useState<any>(0);

	const [esvdUpdated, setEsvdUpdated] = React.useState<any>(0);

	return (
		<UploadInfoContext.Provider
			value={{
				uploading,
				setUploading,
				filename,
				setFileName,
				loading,
				setLoading,
				esvdInserted,
				esvdUpdated,
				uploadedEsvdCount,
			}}>
			{children}
		</UploadInfoContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useUploadInfo = () => {
	const uploadInfo = useContext(UploadInfoContext);
	if (uploadInfo == null) {
		throw new Error("useUploadInfo() called outside of a uploadInfo hook?");
	}
	return uploadInfo;
};

export { UploadInfoProvider, useUploadInfo };
