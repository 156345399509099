import { ErrorMessage, Formik } from "formik";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Checkbox, Form, Label, Icon, Card, Grid } from "semantic-ui-react";
import * as Yup from "yup";
import { saveStudy } from "../../actions/studyActions";
import { studyPublishStatus } from "../../shared/Literals";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import EsvdInputInline from "../../shared/InputInline";
import { Study } from "../../models/Study";
import NumberInputInline from "../../shared/InputInlineNumber";
import { StudyProps } from "../../shared/Literals";
import { Valuation } from "../../models/Valuation";

interface IProps {
	saveStudy: Function;
	clearStudy: Function;
	onStudyUpdated: Function;
	valuations: Valuation[];
	study: Study;
	headerText?: string;
	isEditor: boolean;
}

class SuggestEntry extends React.Component<IProps> {
	isCompleteRef: any | null = null;
	state = {
		isComplete: false,
		hasError: false,
		showPassword: false,
		isLoading: false,
		setLoading: (loading: boolean) =>
			this.setState(() => ({ isLoading: loading })),
		isPublished: false,
	};

	clearStudy = () => {
		this.props.clearStudy();
		this.setState(() => ({ isComplete: false }));
	};

	renderButtons = (values: Study) => {
		const { study, valuations, isEditor } = this.props;
		const { isLoading, isComplete } = this.state;
		if (!isEditor) {
			return (
				<Fragment>
					<Grid>
						<Grid.Column width={4}>
							<button
								className="ui button btn btn-primary"
								type="reset"
								style={{
									marginTop: "1rem",
									backgroundColor: "#70bbfd",
									// backgroundColor: "#f6da6e",
									// display: "block !important",
									height: "2rem",
									width: "4rem",
								}}
								onClick={this.clearStudy}>
								Clear
							</button>
						</Grid.Column>
						<Grid.Column width={12}>
							<div className="ui right floated">
								{study.isPublished ? null : (
									<>
										<button
											disabled={isEditor && !study.id}
											className="ui button btn btn-primary"
											type="submit"
											style={{
												marginTop: "1rem",
												backgroundColor: "#70bbfd",
												display: "block !important",
												height: "2rem",
												width: "4rem",
												marginRight: "2rem",
											}}>
											<span className={`${isLoading ? "hide" : ""}`}>Save</span>
											<span
												className={`box loader-01 ${
													isLoading ? "" : "invisible"
												}`}></span>
										</button>
										<Checkbox
											ref={this.isCompleteRef}
											disabled={!values.id || valuations.length === 0}
											label={
												<label style={{ fontSize: "0.8rem" }}>
													Is Complete{" "}
												</label>
											} //""
											onChange={() => {
												this.setState(() => ({ isComplete: !isComplete }));
											}}
											checked={isComplete}
										/>
									</>
								)}{" "}
								<button
									disabled={!isComplete && !study.isPublished}
									className="ui button btn btn-primary right floated"
									type="submit"
									style={{
										marginTop: "1rem",
										backgroundColor: "#70bbfd",
										display: "block !important",
										height: "2rem",
										width: "5rem",
									}}
									onClick={() => {
										values.isPublished = !study.isPublished;
									}}>
									{study.isPublished
										? studyPublishStatus.unpublish
										: isEditor
										? studyPublishStatus.republish
										: studyPublishStatus.published}
								</button>
							</div>
						</Grid.Column>
					</Grid>
				</Fragment>
			);
		} else {
			//isEditor: hide all except save. The editor updates is published live.
			return (
				<div className="ui right floated">
					<>
						<button
							disabled={isEditor && !study.id}
							className="ui button btn btn-primary"
							type="submit"
							style={{
								marginTop: "1rem",
								backgroundColor: "#70bbfd",
								display: "block !important",
								height: "2rem",
								width: "5rem",
							}}>
							<span className={`${isLoading ? "hide" : ""}`}>Save</span>
							<span
								className={`box loader-01 ${
									isLoading ? "" : "invisible"
								}`}></span>
						</button>
					</>
				</div>
			);
		}
	};
	renderContent = () => {
		const { study, isEditor } = this.props;

		return (
			<Fragment>
				<br />
				<Formik
					enableReinitialize={true}
					validationSchema={validationSchema}
					initialValues={study}
					onSubmit={(values, { setErrors }) => {
						this.handleFormSubmit(values, setErrors);
					}}>
					{({
						values,
						// handleChange,
						handleSubmit,
						isValid,
						isSubmitting,
						dirty,
						errors,
					}) => (
						<Form
							className="custom-ui-element"
							onSubmit={handleSubmit}
							autoComplete={"off"}>
							<div style={{ display: "none" }}>
								<EsvdInputInline name="id" type="hidden" placeholder="Year" />
							</div>
							<NumberInputInline
								className="number"
								name={StudyProps.yearOfPublication.name}
								label={StudyProps.yearOfPublication.label}
								placeholder={StudyProps.yearOfPublication.label}
								min={1950}
								disabled={isEditor && !study.id} //for editor, disable form elements until study is selected
							/>
							<EsvdInputInline
								name={StudyProps.authors.name}
								label={StudyProps.authors.label}
								fluid
								placeholder={StudyProps.authors.label}
								disabled={isEditor && !study.id}
							/>
							<EsvdInputInline
								name={StudyProps.title.name}
								label={StudyProps.title.label}
								fluid
								placeholder={StudyProps.title.label}
								disabled={isEditor && !study.id}
							/>
							<EsvdInputInline
								name={StudyProps.reference.name}
								label={StudyProps.reference.label}
								fluid
								placeholder={StudyProps.reference.label}
								disabled={isEditor && !study.id}
							/>
							<ErrorMessage
								name="error"
								render={() => (
									<Label
										style={{ marginBottom: 5, borderColor: "none" }}
										basic
										color="red"
										size="tiny"
										content={errors.error}
									/>
								)}
							/>

							{this.renderButtons(values)}
						</Form>
					)}
				</Formik>
			</Fragment>
		);
	};

	handleFormSubmit: Function = (values: Study, setErrors: Function) => {
		const { saveStudy, onStudyUpdated } = this.props;
		const { setLoading } = this.state;
		setLoading(true);
		saveStudy(values)
			.then((res: any) => {
				onStudyUpdated(res);
				setLoading(false);
			})
			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				setLoading(false);
			});
	};

	render() {
		const { study, headerText } = this.props;
		const allFields = " (All fields required)";
		return (
			<Card fluid>
				<Card.Content>
					<h4>
						{headerText}
						{study.isPublished ? <Icon name="eye" /> : null}
						<span
							style={{
								fontSize: "0.8rem",
								// color: "#70bbfd",
								fontWeight: "bolder",
								// lineHeight: "1.5rem",
								transform: "translate(50%,50%)",
							}}
							className="ui">
							{allFields}
						</span>
					</h4>
				</Card.Content>

				<Card.Content>{this.renderContent()}</Card.Content>
			</Card>
		);
	}
}
const validationSchema: any = Yup.object({
	[`${StudyProps.yearOfPublication.name}`]: Yup.number()
		// .nullable(false)
		.required(`${StudyProps.yearOfPublication.label} is required`)
		.positive(
			`${StudyProps.yearOfPublication.label} must be a positive number.`
		)
		.min(
			1950,
			`${StudyProps.yearOfPublication.label} must be grater than or equal to 1950.`
		)
		.test(
			"len",
			`${StudyProps.yearOfPublication.label} is not a valid year.`,
			(val: any) => !val || (val && val.toString().length === 4)
		),
	[`${StudyProps.title.name}`]: Yup.string()
		.trim()
		.required(`${StudyProps.title.label} is required`),
	[`${StudyProps.authors.name}`]: Yup.string()
		.trim()
		.required(`${StudyProps.authors.label} is required`),
	[`${StudyProps.reference.name}`]: Yup.string()
		.trim()
		.required(`${StudyProps.reference.label} is required`),
});

const mapStateToProps = (state: any) => {
	return {
		valuations: Object.values<Valuation>(state.valuations),
		isEditor: state.isEditor,
	};
};

export default connect(mapStateToProps, {
	saveStudy,
})(SuggestEntry);
