import React, { Fragment, useEffect } from "react";
import { Card, Label } from "semantic-ui-react";
import * as Yup from "yup";
import WelcomeHeader from "../../shared/WelcomeHeader";
import { Link, Redirect } from "react-router-dom";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { useAuth } from "../../providers/AuthProvider";

interface IProps {
	confirmemail: Function;
	// user: User;
	isSesetLinkSent: boolean;
	location: any | null;
}

const ConfirmEmail = (props: IProps) => {
	const { confirmemail, setToken } = useAuth();
	// state = {
	// 	subheadText: "Confirm Email",
	// 	showPassword: false,
	// 	isLoading: false,
	// 	setLoading: (loading: boolean) =>
	// 		this.setState(() => ({ isLoading: loading })),
	// 	isConfirmed: false,
	// 	isError: false,
	// 	error: null,
	// 	confirmationSent: () =>
	// 		this.setState(() => ({
	// 			isConfirmed: !this.state.isConfirmed,
	// 			subheadText: "Email confirmation successful.",
	// 		})),
	// 	//add query string userid and code to form values
	// 	//get details from url query string
	// 	//https://stackoverflow.com/a/56780570/5367889
	// 	//TODO: encode the userid fon the server before sending and then decode it here
	// 	queryString: Object.fromEntries(
	// 		new URLSearchParams(this.props.location.search)
	// 	),
	// };
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isConfirmed, setIsConfirmed] = React.useState<boolean>(false);
	const [error, setError] = React.useState<any>(null);
	const [isError, setIsError] = React.useState<boolean>(false);
	const [subheadText, setsubheadText] = React.useState<string>("Confirm Email");
	const [queryString] = React.useState<any>(
		Object.fromEntries(new URLSearchParams(props.location.search))
	);

	const confirmationSent = () => {
		setIsConfirmed(!isConfirmed);
		setsubheadText("Reset successful.");
	};

	const handleFormSubmit = () => {
		// const { confirmemail } = this.props;
		// const { confirmationSent, setLoading, queryString } = this.state;
		// //add query string userid and code to form values
		// //get details from url query string
		// //https://stackoverflow.com/a/56780570/5367889
		// //TODO: encode the userid fon the server before sending and then decode it here
		// const queryString: QueryString = Object.fromEntries(
		//   new URLSearchParams(this.props.location.search)
		// );
		// const params = { ...queryString };
		setIsLoading(true);

		// confirmemail({ username: queryString.username, code: 65333 })
		confirmemail(queryString)
			.then((res: any) => {
				confirmationSent();
				// setLoading(false);
			})
			.catch((error: any) => {
				// setErrors({ error: ErrorHandler(error) });
				setIsError(true);
				setError(ErrorHandler(error));
				// this.setState(() => ({ isError: true, error: ErrorHandler(error) }));
				// setLoading(false);
			});
		setIsLoading(false);
	};

	useEffect(() => {
		handleFormSubmit();
	}, []);
	// componentDidMount() {
	// 	// setErrors({ error: ErrorHandler(error) });
	// 	// this.handleFormSubmit();
	// }

	const renderContent = () => {
		// const { isConfirmed, queryString, subheadText, isError, error } =
		// 	this.state;
		// //if there's a user token, clear it to avoid autologin.
		if (queryString.username && queryString.code) {
			// const { isLoading } = this.state;
			setToken();
			return (
				<div className="login">
					<Card>
						<WelcomeHeader
							titleText="Welcome to"
							accentText="ESVD"
							subheadText={subheadText}
						/>
						{isConfirmed && !isError ? (
							<Fragment>
								<Card.Content className="text-center">
									Your confirmation was successful.
									<br />
									Please click <Link to="/login">here</Link> to login.
								</Card.Content>
							</Fragment>
						) : isError && error ? (
							<Label
								style={{ marginBottom: 10, display: "block", border: "none" }}
								className="text-center"
								basic
								color="red"
								content={error}
							/>
						) : (
							<span
								className={`box loader-01 ${
									isLoading ? "" : "invisible"
								}`}></span>
						)}
					</Card>
				</div>
			);
		}
		//a login user shouldn't see the login page.
		//Therefore is a logged user access the login route, go to home
		// history.push("/");
		else return <Redirect to="/" />;
	};

	// render() {
	return <Fragment>{renderContent()}</Fragment>;
	// }
};

// const validationSchema = Yup.object({
// 	// email: Yup.string().required("Email is required"),
// });

// const mapStateToProps = (state: any) => {
// 	return {
// 		user: state.user,
// 	};
// };

export default ConfirmEmail;
// 	connect(null, {
// 	confirmemail,
// })(ConfirmEmail);
