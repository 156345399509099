import { SCALEOFSTUDYSITES_FETCH } from "../../actions/others/scaleOfStudySiteActions";

const scaleOfStudySiteReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case SCALEOFSTUDYSITES_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default scaleOfStudySiteReducer;
