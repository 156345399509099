import React from "react";

interface IProps {
	titleText?: string;
	logoText?: string | null;
	accentText?: string | null;
	subheadText?: string | null;
	showButton?: any;
	color?: any;
}
const UserInvite = (props: IProps) => {
	const { titleText, logoText, accentText, subheadText, showButton, color } =
		props;
	return (
		// <div className="account">
		<div className="account__head">
			<h3 className="account__title">
				{titleText}
				<span className="account__logo">
					{logoText}
					<span className="account__logo-accent">{accentText}</span>
				</span>
			</h3>
			{subheadText && (
				<div
					className="account__logo"
					style={{ color: color || "" }}
					dangerouslySetInnerHTML={{ __html: subheadText }}
				/>
			)}

			{showButton && showButton}
		</div>
		// </div>
	);
};

export default UserInvite;
