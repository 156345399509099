import React from "react";
import { Card, Item } from "semantic-ui-react";
import history from "../../history";
import { resources } from "../../shared/Literals";
import EsvdExportEsvd from "./EsvdExportEsvd";

import moment from "moment";
import { useReferenceInfo } from "../../providers/ReferenceInfoProvider";
import SummaryStatsButton from "../esvdStats/SummaryStatsButton";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import { defaultEsvdParams } from "../../models/EsvdQueryFilters";

// interface IProps {
// 	esvdQueryFilters: EsvdQueryParams;
// 	currentVersion?: CurrentReferenceInfo;
// }
const EsvdActionsPanel = () => {
	const { currentInfo } = useReferenceInfo();
	const { esvdQueryParams, saveQueryParams } = useEsvdValuation();
	return (
		<Card fluid>
			<Card.Content>
				<Item>
					<Item.Content>
						<Item.Header>
							<h4>Actions</h4>
						</Item.Header>
					</Item.Content>
				</Item>
			</Card.Content>
			<Card.Content>
				<SummaryStatsButton />
			</Card.Content>
			<Card.Content>
				<EsvdExportEsvd
					currentVersion={currentInfo || null}
					ButtonText="Download valuations as CSV"
					filename={`Esvd_Data_${moment().format(
						"Do-MMM-yyyy_HH-mm-ss"
					)}_Database_Version_${currentInfo?.version}.csv`}
					esvdQueryParams={esvdQueryParams}
					saveQueryParams={saveQueryParams}
				/>
				{/* <EsvdExportFilterEsvd /> */}
			</Card.Content>
			<Card.Content>
				<button
					className="btn btn-success btn-sm"
					onClick={() => history.push(resources.suggestStudy.path)}>
					Suggest a Study
				</button>
			</Card.Content>
			<Card.Content style={{ minHeight: "6rem" }}>
				<EsvdExportEsvd
					currentVersion={currentInfo || null}
					ButtonText="Get full ESVD as CSV"
					filename={`Esvd_Full_Data_${moment().format(
						"Do-MMM-yyyy_HH-mm-ss"
					)}_Database_Version_${currentInfo?.version}.csv`}
					esvdQueryParams={{ ...defaultEsvdParams }}
					isFull={true}
				/>
			</Card.Content>
		</Card>
	);
};

// const mapStateToProps = (state: any) => {
// 	return {
// 		esvdQueryFilters: state.esvdQueryFilters,
// 	};
// };

export default EsvdActionsPanel; //connect(mapStateToProps, {})(EsvdActionsPanel);
