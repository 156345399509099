import React, { useContext } from "react";
import _ from "lodash";
import agent from "../api/agent";
import { Country } from "../models/country";
import { Continent } from "../models/continent";
import { ProtectionStatus } from "../models/protectionStatus";
import { TreeType } from "../models/ecosystemService";
import { ValuationMethod } from "../models/valuationMethods";

interface IValue {
	loadLists: Function;
	list: {
		countries: Country[];
		continents: Continent[];
		protectionStatuses: ProtectionStatus[];
		ecosystemServices: TreeType[];
		ciceses: TreeType[];
		sEEAs: TreeType[];
		biomeV2EcosystemV2Ecozones: TreeType[];
		valuationMethods: ValuationMethod[];
		biomeEcosystems: TreeType[];
	};
}

const ListsContext = React.createContext<IValue | null>(null);

// The ListsProvider is responsible for Lists management
const ListsProvider = ({ children }: { children: any }) => {
	const [list, setList] = React.useState<any>({});

	const loadLists = async () => {
		const [
			countries,
			continents,
			protectionStatuses,
			ecosystemServices,
			ciceses,
			sEEAs,
			biomeV2EcosystemV2Ecozones,
			valuationMethods,
			biomeEcosystems,
			ecosystemConditions,
		] = await Promise.all([
			agent.Countries.list(),
			agent.Continents.list(),
			agent.ProtectionStatuses.list(),
			agent.EcosystemServices.list(),
			agent.Ciceses.list(),
			agent.SEEAs.list(),
			agent.BiomeV2EcosystemV2Ecozones.list(),
			agent.ValuationMethods.list(),
			agent.BiomeEcosystems.list(),
			agent.EcosystemConditions.list(),
		]);
		const lis: any = {};

		lis.countries = countries;

		lis.continents = continents;

		lis.protectionStatuses = protectionStatuses;

		lis.ecosystemServices = ecosystemServices;

		lis.ciceses = ciceses;

		lis.sEEAs = sEEAs;

		lis.biomeV2EcosystemV2Ecozones = biomeV2EcosystemV2Ecozones;

		lis.valuationMethods = valuationMethods;

		lis.biomeEcosystems = biomeEcosystems;
		lis.ecosystemConditions = ecosystemConditions;
		setList({ ...lis });
		return lis;
	};

	return (
		<ListsContext.Provider
			value={{
				loadLists,
				list,
			}}>
			{children}
		</ListsContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useLists = () => {
	const lists = useContext(ListsContext);
	if (lists == null) {
		throw new Error("useLists() called outside of a lists hook?");
	}
	return lists;
};

export { ListsProvider, useLists };
