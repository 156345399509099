import _ from "lodash";
import {
  FILTER_QUERIES_FETCH,
  FILTER_QUERY_CREATE,
  FILTER_QUERY_DELETE,
  FILTER_QUERY_FETCH,
} from "../actions/filterQueryActions";
import { FilterQuery } from "../models/FilterQuery";

const filterQueryReducer = (state = {}, action: any) => {
  switch (action.type) {
    case FILTER_QUERY_FETCH:
      return { ...state, [action.payload.id]: action.payload };
    case FILTER_QUERY_CREATE:
      return { ...state, [action.payload.id]: action.payload };
    case FILTER_QUERY_DELETE:
      return _.omit(state, action.payload);
    // case USER_FILTER_QUERIES_FETCH:
    case FILTER_QUERIES_FETCH:
      let arr: FilterQuery[] = [];
      for (const item of action.payload) {
        item.filterObject = JSON.parse(item.filterString);
        arr.push(item);
      }
      return { ..._.mapKeys(arr, "id") };
    default:
      return state;
  }
};
export default filterQueryReducer;
