import { useState, useEffect } from "react";
import Table, { AutoResizer } from "react-base-table";
import "react-base-table/styles.css";
import { defaultSort, IS_DESCEND_DEFAULT } from "../../shared/Literals";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
// import filterCheck from "../../shared/FilterCheck";

const EsvdTable = () => {
	const { esvds, getEsvds, updateQueryParams, esvdQueryParams } =
		useEsvdValuation();
	const [isDescend, setIsDescend] = useState<boolean>(IS_DESCEND_DEFAULT);
	const [sort, setSort] = useState<any>(defaultSort);

	// useEffect(() => {
	// 	if (filterCheck(esvdQueryParams)) {
	// 		getEsvds(esvdQueryParams);
	// 	}
	// }, []);

	const onColumnSort = async (sortBy: any) => {
		esvdQueryParams.params.sortColumn = sortBy;
		esvdQueryParams.params.isDescend = !isDescend;
		updateQueryParams(esvdQueryParams);
		await getEsvds(esvdQueryParams);
		setSort(sortBy);
		setIsDescend(!isDescend);
	};

	return (
		<>
			<div className="BaseContainer">
				<AutoResizer>
					{({ width, height }) => (
						<Table
							fixed
							width={width}
							height={height - 10}
							columns={esvds.headerArray}
							data={esvds.bodyArray}
							sortBy={sort}
							rowHeight={30}
							onColumnSort={onColumnSort}
							// rowEventHandlers={rowEventHandlers}
						/>
					)}
				</AutoResizer>
			</div>
		</>
	);
	// }
};

export default EsvdTable;
