import React from "react";
import { Button, Card } from "semantic-ui-react";

// import { CurrentReferenceInfo } from "../../models/ReferenceInfo";
import { useReferenceInfo } from "../../providers/ReferenceInfoProvider";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import EsvdTablePanel from "./EsvdTablePanel";
import EsvdMapPanel from "./EsvdMapPanel";
import filterCheck from "../../shared/FilterCheck";

const EsvdDataWrapper = () => {
	const { currentInfo } = useReferenceInfo();
	// const { esvdCount, showMap, setShowMap } = useEsvdValuation();
	const {
		esvdCount,
		esvdTotalCount,
		esvdMapCount,
		showMap,
		setShowMap,
		saveQueryParams,
		esvdQueryParams,
	} = useEsvdValuation();

	const switchBetweenMapTable = () => {
		//save query if valid
		if (!esvdQueryParams.saved && filterCheck(esvdQueryParams)) {
			//save query if not saved already
			saveQueryParams && saveQueryParams({ ...esvdQueryParams });
		}
		setShowMap(!showMap);
	};

	return (
		<Card fluid style={{ height: "80vh" }}>
			<Card.Content>
				{showMap ? (
					<h4 style={{ display: "inline-block" }}>
						Valuations: <span>{`${esvdMapCount} of ${esvdTotalCount}`}</span>
					</h4>
				) : (
					<h4 style={{ display: "inline-block" }}>
						Valuations: <span>{`${esvdCount} of ${esvdTotalCount}`}</span>
					</h4>
				)}

				{currentInfo && (
					<>
						<h5
							className="ui right floated"
							style={{ display: "inline-block" }}>
							Database Version: {`${currentInfo.version}`}
						</h5>
						<br />
						<Button
							floated={"right"}
							onClick={() => switchBetweenMapTable()}
							size="tiny">
							{showMap ? "Show Table" : "Show Map"}
						</Button>
					</>
				)}
			</Card.Content>
			{showMap ? <EsvdMapPanel /> : <EsvdTablePanel />}
		</Card>
	);
	// }
};

export default EsvdDataWrapper;
