// import _ from "lodash";
import { ESVDS_FETCH, RESET_ESVDS } from "../../actions/esvdActions";
import {
  bodyRowsFlattened,
  displayBodyRowsObjects,
  displayHeaderTransform,
  // setMultiColumnMaxLens,
  setDisplayMultiColumnMaxLens,
  setMultiColumnMaxLenFlattened,
} from "../../EsvdFunctions";
import {
  ColumnStructure,
  DisplayColumnStructure,
} from "../../models/columnStructure";
import {
  ColumnTemplate,
  DisplayColumnTemplate,
} from "../../models/columnTemplate";

const esvdReducer = (
  state: { esvdsAllCols: any[]; headerArray: any[]; bodyArray: any[] } = {
    esvdsAllCols: [],
    headerArray: [],
    bodyArray: [],
  },
  action: any
) => {
  switch (action.type) {
    case ESVDS_FETCH:
      const cols: DisplayColumnStructure = setDisplayMultiColumnMaxLens(
        action.payload,
        DisplayColumnTemplate
      );
      const heads = displayHeaderTransform(action.payload, cols);
      const body = displayBodyRowsObjects(action.payload, cols);

      const allCols: ColumnStructure = setMultiColumnMaxLenFlattened(
        action.payload,
        ColumnTemplate
      );
      const esvds = bodyRowsFlattened(action.payload, allCols);

      return { esvdsAllCols: esvds, headerArray: heads, bodyArray: body };
    case RESET_ESVDS:
      return {
        esvdsAllCols: [],
        headerArray: [],
        bodyArray: [],
      };
    default:
      return state;
  }
};

export default esvdReducer;
