import React, { Component } from "react";
import { connect } from "react-redux";
// import { Fragment, useContext } from "react";
import { Header, Modal } from "semantic-ui-react";

export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const initModalData = (open: boolean = false) => ({
	icon: "",
	header: null,
	content: null,
	isOpen: open,
	actions: () => null,
});

export const openModal = (modalProp: modalDataDef) => async (dispatch: any) => {
	dispatch({ type: TOGGLE_MODAL, payload: modalProp });
};

export const OpenModal2 = (props: any) => {
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	return (
		<Modal
			className="scroll-from-top modal-content "
			size="small"
			centered={false}
			open={isOpen}
			onClose={() => {
				// props.openModal(initModalData(false));
				setIsOpen(false);
			}}
			// onOpen={() => props.openModal(initModalData(true))}
		>
			<Modal.Content style={{ height: "16em" }}>
				<Header className="pos_fixed">{props.header}</Header>
				{props.content}

				{/* <Modal.Actions> */}
				<div className="modal__footer">
					{(props.actions && props.actions()) || (
						<button
							className="btn btn-warning btn-sm"
							onClick={() => {
								setIsOpen(false);
							}}>
							Close
						</button>
					)}
				</div>
				{/* </Modal.Actions> */}
			</Modal.Content>
		</Modal>
	);
};

export const closeModal =
	(modalProp: modalDataDef = initModalData(false)) =>
	async (dispatch: any) => {
		dispatch({ type: TOGGLE_MODAL, payload: modalProp });
	};

export const modalReducer = (
	state: modalDataDef = initModalData(),
	action: any
) => {
	switch (action.type) {
		case TOGGLE_MODAL:
			return action.payload;
		default:
			return state;
	}
};

export interface modalDataDef {
	icon?: string;
	header?: any;
	content?: any;
	isOpen?: boolean;
	actions?: Function;
	// close?: Function;
}

export type ModalProps = {
	modalData: modalDataDef;
	openModal: Function;
};

export const EsvdModalFunc = (props: ModalProps) => {
	const { header, content, isOpen, actions } = props.modalData;

	return (
		<Modal
			className="scroll-from-top modal-content "
			size="small"
			centered={false}
			open={isOpen}
			onClose={() => {
				props.openModal(initModalData(false));
			}}
			onOpen={() => props.openModal(initModalData(true))}>
			<Modal.Content style={{ height: "16em" }}>
				<Header className="pos_fixed">{header}</Header>
				{content}

				{/* <Modal.Actions> */}
				<div className="modal__footer">
					{(actions && actions()) || (
						<button
							className="btn btn-warning btn-sm"
							onClick={() => {
								props.openModal(initModalData(false));
							}}>
							Close
						</button>
					)}
				</div>
				{/* </Modal.Actions> */}
			</Modal.Content>
		</Modal>
	);
};

class EsvdModal extends Component<ModalProps> {
	render() {
		const { header, content, isOpen, actions } = this.props.modalData;

		return (
			<Modal
				className="scroll-from-top modal-content "
				size="small"
				centered={false}
				open={isOpen}
				//   trigger={<Button>Show Modal</Button>}
				onClose={() => {
					this.props.openModal(initModalData(false));
				}}
				onOpen={() => this.props.openModal(initModalData(true))}>
				{/* <Header tex icon="info" content={header} /> */}

				<Modal.Content style={{ height: "16em" }}>
					<Header className="pos_fixed">{header}</Header>
					{content}

					{/* <Modal.Actions> */}
					<div className="modal__footer">
						{(actions && actions()) || (
							<button
								className="btn btn-warning btn-sm"
								onClick={() => {
									this.props.openModal(initModalData(false));
								}}>
								Close
							</button>
						)}
					</div>
					{/* </Modal.Actions> */}
				</Modal.Content>
			</Modal>
		);
	}
}

const mapStateToProps = (state: any) => {
	return { modalData: state.modalData };
};
export default connect(mapStateToProps, { openModal, closeModal })(EsvdModal);
