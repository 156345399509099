import React from "react";
import { Card, Grid, Menu, Segment, Sidebar } from "semantic-ui-react";
import { SidebarProvider, useSidebar } from "../../hooks/SidebarHook";

import EsvdMaps from "./EsvdMaps";

export const SidebarPanel = (props: any) => {
	const { visible, closeSidebar, sidebarContent } = useSidebar();
	// const [visible, setVisible] = React.useState(true);

	return (
		<Grid columns={1}>
			<Grid.Column>
				<Sidebar.Pushable as={Segment}>
					<Sidebar
						as={Menu}
						animation="overlay"
						// inverted
						onHide={() => closeSidebar()}
						vertical
						visible={visible}
						width="very wide">
						{/* <Menu.Item as="a"> */}
						<Menu.Item>
							{/* {typeof sidebarContent === "function"
								? sidebarContent()
								: sidebarContent} */}
							{sidebarContent}
						</Menu.Item>
						{/* </Menu.Item> */}
						{/* <Menu.Item as="a">
							<Icon name="gamepad" />
							Games
						</Menu.Item>
						<Menu.Item as="a">
							<Icon name="camera" />
							Channels
						</Menu.Item> */}
					</Sidebar>

					<Sidebar.Pusher>
						<EsvdMaps />
					</Sidebar.Pusher>
				</Sidebar.Pushable>
			</Grid.Column>
		</Grid>
	);
};

const EsvdMapPanel = () => {
	return (
		<Card.Content>
			<SidebarProvider>
				<SidebarPanel />
			</SidebarProvider>
		</Card.Content>
	);
};

export default EsvdMapPanel;
