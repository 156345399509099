import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Card, Label } from "semantic-ui-react";
import * as Yup from "yup";
import EsvdInput from "../../shared/Input";
import { User } from "../../models/user";
import WelcomeHeader from "../../shared/WelcomeHeader";
import { QueryString } from "../../models/EsvdQueryFilters";
import { PASSWORD } from "../../shared/Literals";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { Link, Redirect } from "react-router-dom";
import ResetPassword, { IProps } from "./ResetPassword";

const ResetPasswordWrapper = (props: IProps) => {
	return <ResetPassword {...props} />;
};

export default ResetPasswordWrapper;
