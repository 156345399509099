import { useField } from "formik";
import React from "react";
import {
	Checkbox,
	Form,
	Grid,
	// Icon,
	// Input,
	Label,
	SemanticICONS,
} from "semantic-ui-react";

interface Props {
	name: string;
	placeholder?: string;
	icon?: SemanticICONS;
	label?: string;
	rightIcon?: any;
	type?: string;
	fluid?: boolean;
	value?: string;
	className?: string;
	disabled?: boolean;
	min?: number;
	readonly?: boolean;
	transparent?: boolean;
	onChange?: any;
	checked?: boolean;
}

const CheckboxInline = (props: Props) => {
	const [field, meta] = useField(props);
	const size = "mini";
	return (
		<Form.Field error={meta.touched && !!meta.error}>
			<Grid>
				<Grid.Row>
					<Grid.Column width={4}>
						<label>{props.label}</label>
					</Grid.Column>
					<Grid.Column width={12}>
						<Checkbox
							{...field}
							size={size}
							onChange={props.onChange}
							checked={props.checked}
						/>
						{meta.touched && meta.error ? (
							<Label
								basic
								color="red"
								size="tiny"
								style={{ padding: "0px", border: "none", display: "block" }}>
								{meta.error}
							</Label>
						) : null}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form.Field>
	);
};

export default CheckboxInline;
