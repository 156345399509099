import { useState, useEffect, useRef } from "react";
import "react-base-table/styles.css";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
import { HotTable, HotColumn } from "@handsontable/react";

import { useVsl } from "../../providers/VslProvider";
import {
	formatEnableOnlyEcosystemForDropdown,
	formatShowOnlyBiomeForDropdown,
} from "../../EsvdFunctions";
import { CellChange, ChangeSource } from "handsontable/common";
import { useVslGridHub } from "../../providers/VslGridHubProvider";
import { VslCellEditInfo } from "../../models/vsl-models/VslCellEditInfo";
import { Card } from "semantic-ui-react";
import LoadingOverlay from "../../shared/LoadingOverlay";
import { MultiSelectRenderer } from "../vsls/MultiSelectRenderer";
import MultiSelectEditor from "../vsls/MultiSelectEditor";
import { SingleSelectRenderer } from "../vsls/SingleSelectRenderer";
import SingleSelectEditor from "../vsls/SingleSelectEditor";
import { IVslFilter } from "../../models/vsl-models/IVslFilter";
import { IncludedInEsvdOptions } from "../../shared/Literals";

registerAllModules();

const VslPublicTable = (props: IVslFilter) => {
	const { vslGridHub } = useVslGridHub();
	const {
		getVsls,
		setHotTableInstance,
		hotTableInstance,
		editVsl,
		vslData,
		// gridLockState,
		// selectedRowDispatch,
		addVsl,
		setvslsCount,
		vslsCount,
		// gridEditSyncLock,
		// getVslsCount,
	} = useVsl();
	// const { loadLists } = useLists();

	// const { vslFilters, setIsLoading } = props;
	const { isLoading, setIsLoading, dropdownOptions } = props;
	const {
		vslFilters,
		biomeEcosystems,
		biomeV2EcosystemV2Ecozones,
		ecosystemServices,
		countries,
		protectionStatuses,
	} = dropdownOptions || {};
	// // const [isLoading, setIsLoading] = useState<boolean>(false);
	// const [biomeEcosystems, setBiomeEcosystems] = useState<any[]>();
	// const [biomeV2EcosystemV2Ecozones, setbiomeV2EcosystemV2Ecozones] =
	// 	useState<any[]>();
	// const [ecosystemServices, setecosystemServices] = useState<any[]>();
	// const [countries, setcountries] = useState<any[]>();
	// const [protectionStatuses, setprotectionStatuses] = useState<any[]>();

	const hotRef = useRef<any>(null);

	// const { lock } = gridLockState;

	useEffect(() => {
		(async () => {
			await getVsls(vslFilters);
		})();

		// (async () => {
		// 	// setIsLoading(true);
		// 	const ls = await loadLists();
		// 	const {
		// 		biomeEcosystems,
		// 		biomeV2EcosystemV2Ecozones,
		// 		ecosystemServices,
		// 		countries,
		// 		protectionStatuses,
		// 	} = ls;
		// 	// setBiomeEcosystems(biomeEcosystems);
		// 	// setbiomeV2EcosystemV2Ecozones(biomeV2EcosystemV2Ecozones);
		// 	// setecosystemServices(ecosystemServices);
		// 	// setcountries(countries);
		// 	// setprotectionStatuses(protectionStatuses);
		// 	// setIsLoading(false);
		// })();

		if (hotRef.current) {
			setHotTableInstance && setHotTableInstance(hotRef.current.hotInstance);
		}
	}, []);

	useEffect(() => {
		if (vslGridHub) {
			if (vslGridHub.state === "Disconnected") {
				vslGridHub.start();
			}
			vslGridHub.on(
				"SyncGridUpdateInfo",
				async (cellEditInfo: VslCellEditInfo) => {
					if (hotTableInstance) {
						const { id, column, dataValue, actionType } = cellEditInfo;
						// console.log(cellEditInfo);
						// get the `Search` plugin's instance
						const search = hotTableInstance.getPlugin("search");
						// use the `Search` plugin's `query()` method
						if (actionType === "edit") {
							const queryResult = search.query(id?.toString());
							if (queryResult && queryResult.length > 0) {
								const { row } = queryResult[0];

								hotTableInstance.setSourceDataAtCell(row, column, dataValue);
							}
						} else if (actionType === "add") {
							hotTableInstance.alter("insert_row_above", 0);
							hotTableInstance.setSourceDataAtCell(0, "id", id);
							hotTableInstance.setSourceDataAtCell(0, column, dataValue);
							hotTableInstance.render();
							setvslsCount(vslsCount ? vslsCount + 1 : 1);
							// setIsLoading(true);
							// getVsls && (await getVsls(vslFilters));
							// setIsLoading(false);
						} else if (actionType === "delete") {
							const queryResult = search.query(id?.toString());
							if (queryResult && queryResult.length > 0) {
								const { row } = queryResult[0];
								hotTableInstance.alter("remove_row", row);
								hotTableInstance.render();
								setvslsCount(vslsCount ? vslsCount - 1 : 0);
								// setIsLoading(true);
								// getVsls && (await getVsls(vslFilters));
								// setIsLoading(false);
							}
						}
					}
					// setRowIsReadonly({ locked, username });
				}
			);

			vslGridHub.on("CellIsReadonly", (cellEditInfo: VslCellEditInfo) => {
				const { id, row, column, dataValue, actionType, locked } = cellEditInfo;
				// console.log(cellEditInfo);
				if (hotTableInstance) {
					hotTableInstance.setCellMeta(row, column, "readOnly", locked);
					hotTableInstance.render();
				}
				// setRowIsReadonly({ locked, username });
			});
			// })
			// .catch((error) => {
			// 	// console.log(error);
			// });
		}
	}, [vslGridHub]);

	return (
		<>
			<LoadingOverlay active={isLoading} text="Loading vsl" />
			<Card fluid style={{ height: "92vh" }}>
				<Card.Content>
					<h4 style={{ display: "inline-block" }}>
						Valuation studies total:{" "}
						<span>{`${vslsCount ? vslsCount : ""}`}</span>
					</h4>
				</Card.Content>
				<HotTable
					colHeaders={true}
					ref={hotRef}
					data={vslData}
					rowHeaders={true}
					height="95%"
					rowHeights={40}
					manualRowResize={true}
					manualColumnResize={true}
					multiColumnSorting={true}
					autoColumnSize={false}
					wordWrap={false}
					readOnly={true}
					filters={true}
					renderAllRows={false}
					// enable the `Search` plugin
					search={true}
					dropdownMenu={{
						items: {
							make_read_only: {},
							alignment: {},
							"---------": {},
							undo: {},
							redo: {},
						},
					}}
					licenseKey="non-commercial-and-evaluation"
					hiddenColumns={{
						// specify columns hidden by default
						columns: [
							0, //id
							1, //Authors
							2, //Year
							3, //Title
							// 7, //Title
							// 8, //ESVD2.0 Biomes
							9, //ESVD2.0 Ecozone
							10, //ESVD2.0 Ecozone
						],
					}}
					afterChange={async (
						changes: CellChange[] | null,
						source: ChangeSource
					) => {
						if (source !== "loadData") {
							// return; //don't save this change

							if (changes) {
								const [row, column, prevValue, nextValue] = changes[0];
								if (hotTableInstance && prevValue !== nextValue) {
									//retrieve the entire row as JSON or as source data format
									const rowData = hotTableInstance.getSourceDataAtRow(row);
									// const dataa: VslValuationStudy = rowData;
									try {
										let id = rowData.id;
										if (id) {
											await editVsl(rowData);
										} else {
											id = await addVsl(rowData);
											// setvslsCount(vslsCount ? vslsCount + 1 : 1);
											// await getVslsCount();
											if (id) {
												hotTableInstance.setSourceDataAtCell(row, "id", id);
											}
											setIsLoading(true);
											getVsls && (await getVsls(vslFilters));
											setIsLoading(false);
										}
										const col = hotTableInstance.colToProp(column);
										let cellEditInfo: VslCellEditInfo = {
											id: id,
											row,
											column: col,
											dataValue: nextValue,
											actionType: rowData.id ? "edit" : "add",
										};
										try {
											vslGridHub &&
												vslGridHub.invoke<any>("VslGridEditInfo", cellEditInfo);
										} catch (error) {
											// console.log(error);
										}
									} catch (error: any) {
										hotTableInstance.setSourceDataAtCell(
											row,
											column,
											prevValue
										);
										// alert(error);
									}
								}
							}
						}
					}}>
					<HotColumn width={10} data="id" title="id" />
					<HotColumn
						width={120}
						data="authors"
						title="Authors"
						columnSorting={true}
					/>
					<HotColumn
						width={50}
						data="yearOfPublication"
						title="Year"
						columnSorting={true}
						forceNumeric={true}
					/>
					<HotColumn
						width={300}
						data="title"
						title="Title"
						columnSorting={true}
					/>
					<HotColumn width={300} data="reference" title="Reference" />
					<HotColumn
						width={150}
						data="includedInESVDText"
						title="ESVD Included">
						<SingleSelectRenderer hot-renderer data={IncludedInEsvdOptions} />
						<SingleSelectEditor
							hot-editor
							data={IncludedInEsvdOptions}
							codeCell="includedInESVD"
						/>
					</HotColumn>
					<HotColumn
						width={75}
						data="studyId"
						title="StudyID"
						columnSorting={true}
					/>
					<HotColumn width={150} data="flatVsBiomes" title="Biomes">
						<MultiSelectRenderer
							hot-renderer
							data={biomeEcosystems}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={biomeEcosystems}
							isMulti={true}
							formatter={formatShowOnlyBiomeForDropdown}
							codeCell="flatVsBiomeCodes"
						/>
					</HotColumn>
					<HotColumn width={150} data="flatVsEcosystems" title="Ecosystems">
						<MultiSelectRenderer
							hot-renderer
							data={biomeEcosystems}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={biomeEcosystems}
							isMulti={true}
							formatter={formatEnableOnlyEcosystemForDropdown}
							codeCell="flatVsEcosystemCodes"
						/>
					</HotColumn>
					<HotColumn width={150} data="flatVsBiomeV2s" title="ESVD2.0 Biome">
						<MultiSelectRenderer
							hot-renderer
							data={biomeV2EcosystemV2Ecozones}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={biomeV2EcosystemV2Ecozones}
							isMulti={true}
							formatter={formatShowOnlyBiomeForDropdown}
							codeCell="flatVsBiomeV2Codes"
						/>
					</HotColumn>
					<HotColumn
						width={150}
						data="flatVsEcozones"
						title="ESVD2.0 Ecozone"
						style={{
							overflow: "hidden",
							whiteSpace: "nowrap",
						}}>
						<MultiSelectRenderer
							hot-renderer
							data={biomeV2EcosystemV2Ecozones}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={biomeV2EcosystemV2Ecozones}
							isMulti={true}
							formatter={formatEnableOnlyEcosystemForDropdown}
							codeCell="flatVsEcozoneCodes"
						/>
					</HotColumn>
					<HotColumn
						width={150}
						data="flatVsEcosystemServices"
						title="ES (TEEB)">
						<MultiSelectRenderer
							hot-renderer
							data={ecosystemServices}
							isMulti={true}
						/>
						<MultiSelectEditor
							hot-editor
							data={ecosystemServices}
							isMulti={true}
							formatter={formatShowOnlyBiomeForDropdown}
							codeCell="flatVsEcosystemServiceCodes"
						/>
					</HotColumn>
					<HotColumn width={100} data="flatVsCountries" title="Country">
						<MultiSelectRenderer hot-renderer data={countries} isMulti={true} />
						<MultiSelectEditor
							hot-editor
							data={countries}
							isMulti={true}
							codeCell="flatVsCountryCodes"
						/>
					</HotColumn>
					<HotColumn
						width={150}
						data="protectionStatus"
						title="Protection Status">
						<SingleSelectRenderer hot-renderer data={protectionStatuses} />
						<SingleSelectEditor
							hot-editor
							data={protectionStatuses}
							codeCell="protectionStatusId"
						/>
					</HotColumn>
					<HotColumn width={100} data="checkedBy" title="Checked By" />
					<HotColumn width={100} data="language" title="Language" />
					<HotColumn width={100} data="notes" title="Notes" />
				</HotTable>
				{/* </Card.Content> */}
			</Card>
		</>
	);
};

export default VslPublicTable;
