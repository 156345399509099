import agent from "../../api/agent";
export const TYPEOFBENEFICIARY_CREATE = "TYPEOFBENEFICIARY_CREATE";
export const TYPEOFBENEFICIARY_EDIT = "TYPEOFBENEFICIARY_EDIT";
export const TYPEOFBENEFICIARY_DELETE = "TYPEOFBENEFICIARY_DELETE";
export const TYPEOFBENEFICIARY_FETCH = "TYPEOFBENEFICIARY_FETCH";
export const TYPEOFBENEFICIARIES_FETCH = "TYPEOFBENEFICIARIES_FETCH";

export const fetchTypeOfBeneficiaries = () => async (dispatch: any) => {
  const response = await agent.TypeOfBeneficiaries.list();

  dispatch({
    type: TYPEOFBENEFICIARIES_FETCH,
    payload: response,
  });
};
