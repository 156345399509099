import { Component } from "react";
import { Card } from "semantic-ui-react";
import esvd_logo from "../../images/esvd_logo.jpg";
import esvd_logo2 from "../../images/esvd_logo_3.png";

class EcosystemLogo extends Component {
	render() {
		return (
			<>
				{" "}
				{/* <Card fluid>
					<img
						src={esvd_logo}
						alt="logo"
						style={{
							// height: "1em",
							// width: "3em",
							backgroundSize: "cover",
							outline: "none",
							border: "none",
						}}
					/>
				</Card> */}
				{/* <Card fluid> */}
				<img
					src={esvd_logo2}
					alt="logo"
					style={{
						// height: "2em",
						width: "6em",
						backgroundSize: "contain",
						outline: "none",
						border: "none",
					}}
				/>
				{/* </Card> */}
			</>
		);
	}
}

export default EcosystemLogo;
