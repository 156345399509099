// import _ from "lodash";
import { ESVDS_IS_BACKGROUND_LOADING } from "../../actions/esvdActions";

const esvdBackgroundIsLoadingReducer = (
  state: boolean = false,
  action: any
) => {
  switch (action.type) {
    case ESVDS_IS_BACKGROUND_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default esvdBackgroundIsLoadingReducer;
