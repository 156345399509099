// import _ from "lodash";
import { ESVDS_ACTIVE_PAGE } from "../../actions/esvdActions";

const esvdActivePageReducer = (state: string | number = 0, action: any) => {
  switch (action.type) {
    case ESVDS_ACTIVE_PAGE:
      return action.payload;
    default:
      return state;
  }
};

export default esvdActivePageReducer;
