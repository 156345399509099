import agent from "../api/agent";
import { FreeTextQueryParams } from "../models/EsvdQueryFilters";
import { Study } from "../models/Study";
export const STUDY_CREATE = "STUDY_CREATE";
export const STUDY_EDIT = "STUDY_EDIT";
export const STUDY_DELETE = "STUDY_DELETE";
export const STUDY_FETCH = "STUDY_FETCH";
export const STUDIES_FETCH = "STUDIES_FETCH";
export const USER_STUDIES_FETCH = "USER_STUDIES_FETCH";
export const IS_EDITOR = "IS_EDITOR";
export const IS_LOADING_STUDIES = "IS_LOADING_STUDIES";
// export const STUDY_CLEAR_SELECTED = "STUDY_CLEAR_SELECTED";

export const fetchUserStudies = () => async (dispatch: any) => {
  try {
    dispatch({
      type: IS_LOADING_STUDIES,
      payload: true,
    });
    const response = await agent.Studies.userList();

    dispatch({
      type: STUDIES_FETCH,
      payload: response,
    });
    dispatch({
      type: IS_LOADING_STUDIES,
      payload: false,
    });
  } catch (error: any) {
    throw error.response ? error.response.statusText : error.message;
  }
};

export const fetchAllStudies =
  (filters: FreeTextQueryParams) => async (dispatch: any) => {
    try {
      dispatch({
        type: STUDIES_FETCH,
        payload: [],
      });
      dispatch({
        type: IS_LOADING_STUDIES,
        payload: true,
      });
      const response = await agent.Studies.list(filters);
      dispatch({
        type: STUDIES_FETCH,
        payload: response,
      });
      dispatch({
        type: IS_LOADING_STUDIES,
        payload: false,
      });
    } catch (error: any) {
      dispatch({
        type: IS_LOADING_STUDIES,
        payload: false,
      });
      throw error.response ? error.response.statusText : error.message;
    }
  };

export const resetStudies = () => async (dispatch: any) => {
  dispatch({
    type: STUDIES_FETCH,
    payload: [],
  });
};

// export const clearSelectedStudy = () => async (dispatch: any) => {
//   dispatch({
//     type: STUDY_CLEAR_SELECTED,
//   });
// };

export const saveStudy = (study: Study) => async (dispatch: any) => {
  try {
    if (study.id === null) {
      study.id = 0;
    }
    const response =
      !study.id || study.id === 0
        ? await agent.Studies.create(study)
        : await agent.Studies.update(study);

    dispatch({
      type: !study.id || study.id === 0 ? STUDY_CREATE : STUDY_EDIT,
      payload: response,
    });
    return response;
  } catch (error: any) {
    throw error.response ? error.response.statusText : error.message;
  }
};

export const deleteStudy = (studyid: number) => async (dispatch: any) => {
  try {
    await agent.Studies.delete(studyid);
    dispatch({
      type: STUDY_DELETE,
      payload: studyid,
    });
  } catch (error: any) {
    throw error.response ? error.response.statusText : error.message;
  }
};

export const setIsEditor =
  (isEditor: boolean = false) =>
  (dispatch: any) => {
    dispatch({
      type: IS_EDITOR,
      payload: isEditor,
    });
  };
