import React from "react";
import { Grid, Container, Card } from "semantic-ui-react";
import Can from "../../accessControls/Can";
import { actions, resources } from "../../shared/Literals";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
import VttBaseWrapper from "./VttBase";

const { Media, MediaContextProvider } = MediaLayout;

const VttHome = () => {
	const renderContent = () => {
		return (
			<MediaContextProvider>
				<Media at="mobile" className="page-content">
					<VttBaseWrapper />
				</Media>
				<Media greaterThan="mobile">
					<Container fluid className="top-container">
						<Grid columns={2} className="page-content" stackable centered>
							<Grid.Row>
								<Grid.Column width={12}>
									<VttBaseWrapper />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</Media>
			</MediaContextProvider>
		);
	};

	return (
		<Can
			action={actions.read}
			resource={resources.vtt.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
};

const VttHomeWrapper = (props: any) => {
	return <VttHome />;
};

export default VttHomeWrapper;
