import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Card } from "semantic-ui-react";
import {
	fetchStudyValuations,
	fetchStudyValuation,
	deleteValuation,
	setAsCurrentValuation,
} from "../../actions/valuationsActions";
import { Study } from "../../models/Study";
import { Valuation } from "../../models/Valuation";
import { initValuation } from "../../shared/Literals";
import ValuationEntry from "./ValuationEntry";
import ValuationList from "./ValuationList";

// import { fetchBiomeEcosystems } from "../../actions/biomeEcosystemAction";
import { fetchEcosystemServices } from "../../actions/ecosystemServiceActions";
import { fetchCountries } from "../../actions/countryActions";
import { fetchProtectionStatuses } from "../../actions/protectionStatusActions";
import { fetchValuationMethods } from "../../actions/valuationMethodActions";
import { fetchContinents } from "../../actions/continentActions";
import { fetchCiceses } from "../../actions/cicesActions";
import { fetchEcosystemConditions } from "../../actions/ecosystemConditionActions";
import { fetchCurrencies } from "../../actions/others/currencyActions";
import { fetchScaleOfStudySites } from "../../actions/others/scaleOfStudySiteActions";
import { fetchBiomeV2EcosystemV2Ecozones } from "../../actions/biomeV2EcosystemV2EcozoneAction";
import { fetchSEEAs } from "../../actions/seeaActions";

interface IProps {
	fetchStudyValuation: Function;
	setAsCurrentValuation: Function;
	fetchStudyValuations: Function;
	onClearValuationUi?: Function;
	deleteValuation: Function;
	valuations: Valuation[];
	selectedStudy: Study;
	selectedValuation: Valuation;
	isEditor: boolean;
	clearValuationUi?: boolean;

	// fetchBiomeEcosystems: Function;
	fetchEcosystemServices: Function;
	fetchCountries: Function;
	fetchProtectionStatuses: Function;
	fetchValuationMethods: Function;
	fetchContinents: Function;
	fetchCiceses: Function;
	fetchEcosystemConditions: Function;
	fetchCurrencies: Function;
	fetchScaleOfStudySites: Function;
	fetchSEEAs: Function;
	fetchBiomeV2EcosystemV2Ecozones: Function;
}

const ButtonStates = { ShowList: "Show list", AddNew: "Add new" };
class Valuations extends React.Component<IProps> {
	state = {
		// selectedValuation: this.props.selectedValuation,
		isLoading: false,
		isNew: false,
		setLoading: (loading: boolean) =>
			this.setState(() => ({ isLoading: loading })),
		showList: true,
		isEntry: false,
		buttonState: ButtonStates.AddNew,
		selectedValuation: this.props.selectedValuation,
	};

	componentDidMount() {
		// //get biomes
		// this.props.fetchBiomeEcosystems();
		this.props.fetchBiomeV2EcosystemV2Ecozones();
		//get ecosystemservices
		this.props.fetchEcosystemServices();
		//get countries
		this.props.fetchCountries();
		//get protectionStatuses
		this.props.fetchProtectionStatuses();
		//get valuationMethods
		this.props.fetchValuationMethods();
		this.props.fetchContinents();

		this.props.fetchScaleOfStudySites();
		this.props.fetchCurrencies();
		this.props.fetchEcosystemConditions();
		this.props.fetchCiceses();
		this.props.fetchSEEAs();
	}

	componentDidUpdate(prevProp: IProps, prevState: any) {
		const {
			selectedValuation,
			clearValuationUi,
			onClearValuationUi,
			fetchStudyValuations,
		} = this.props;
		if (prevProp.selectedValuation.id !== selectedValuation.id) {
			this.setState(() => ({
				selectedValuation: selectedValuation,
			}));
			this.toggleList();
		}
		if (clearValuationUi) {
			this.resetForm();
			fetchStudyValuations(); //reset the list of valuations for the selected study
			onClearValuationUi && onClearValuationUi(); //signal to parent that valuation ui have been reset.
		}
	}

	getSelectedValuation = (valuation: Valuation) => {
		this.props.fetchStudyValuation(valuation);
	};

	resetForm = () => {
		this.setState(() => ({
			showList: true,
		}));

		//set button state
		this.setState(() => ({
			buttonState: ButtonStates.AddNew,
		}));
	};

	toggleList = () => {
		this.setState(() => ({
			showList: this.state.buttonState === ButtonStates.ShowList,
		}));

		//set button state
		if (this.state.buttonState === ButtonStates.ShowList) {
			this.setState(() => ({
				buttonState: ButtonStates.AddNew,
			}));
		} else {
			this.setState(() => ({
				buttonState: ButtonStates.ShowList,
			}));
		}
	};

	addNew = () => {
		const { selectedStudy, valuations } = this.props;
		const initVal = initValuation;
		//new valuation, so set the studyid to the current study and create the valueid
		initVal.studyId = selectedStudy?.id || 0;
		//set the valueid also
		const cnt = valuations.length;
		if (cnt === 0) {
			initVal.valueId = parseInt(`${selectedStudy.id}00001`) || 0;
		} else {
			//get max valueid
			const maxVal = Math.max(...valuations.map((x) => x.valueId));
			initVal.valueId = maxVal + 1;
		}
		this.props.setAsCurrentValuation(initVal);
	};

	cloneValuation = (valuation: Valuation) => {
		let cloned = Object.assign({}, valuation);
		cloned.valueId = 0;
		cloned.id = null;

		if (!cloned.id || cloned.id === 0) {
			const { selectedStudy, valuations } = this.props;
			//new valuation, so set the studyid to the current study and create the valueid
			cloned.studyId = selectedStudy?.id || 0;
			//set the valueid also
			const cnt = valuations.length;
			if (cnt === 0) {
				cloned.valueId = parseInt(`${selectedStudy.id}00001`) || 0;
			} else {
				//get max valueid
				const maxVal = Math.max(...valuations.map((x) => x.valueId));
				cloned.valueId = maxVal + 1;
			}
		}
		this.props.setAsCurrentValuation(cloned);
		// this.setState(() => ({
		//   selectedValuation: cloned,
		//   showList: false,
		// }));
	};

	renderShowListButton = () => {
		const { selectedStudy, isEditor } = this.props;
		const { showList } = this.state;
		if (isEditor) {
			return showList ? (
				""
			) : (
				<button
					disabled={!selectedStudy.id}
					className="ui button btn btn-primary right floated"
					type="submit"
					style={{
						backgroundColor: "#70bbfd",
						// display: "block !important",
						height: "2rem",
						width: "5rem",
					}}
					onClick={() => this.toggleList()}>
					Show list
				</button>
			);
		} else {
			return selectedStudy.isPublished ? null : (
				<button
					disabled={!selectedStudy.id}
					className="ui button btn btn-primary right floated"
					type="submit"
					style={{
						backgroundColor: "#70bbfd",
						// display: "block !important",
						height: "2rem",
						width: "5rem",
					}}
					onClick={() => {
						if (this.state.buttonState === ButtonStates.AddNew) {
							this.addNew();
						}
						this.toggleList();
					}}>
					{this.state.buttonState}
					{/* {showList ? ButtonStates.ShowList : ButtonStates.AddNew} */}
				</button>
			);
		}
	};

	renderContent = () => {
		const { valuations, selectedStudy, selectedValuation } = this.props;
		const { showList } = this.state;
		return (
			<Card fluid style={{ minHeight: "30vh" }}>
				<Card.Content>
					<h4 className="ui left floated">
						Valuations from this study {`(${valuations.length} valuations)`}
					</h4>
					{this.renderShowListButton()}
				</Card.Content>
				<Card.Content style={{ borderTop: "none" }}>
					{showList ? (
						<ValuationList
							onSelectionChanged={(valuation: any) =>
								this.getSelectedValuation(valuation)
							}
							onClone={(clone: Valuation) => {
								this.cloneValuation(clone);
								this.toggleList();
							}}
							selectedStudy={selectedStudy}
						/>
					) : (
						<ValuationEntry
							resetValuation={(valuation: Valuation) => {
								this.resetForm();
								// this.getSelectedValuation(valuation);
							}}
							selectedValuation={selectedValuation}
						/>
					)}
				</Card.Content>
			</Card>
		);
	};

	render() {
		return <Fragment>{this.renderContent()}</Fragment>;
	}
}

const mapStateToProps = (state: any) => {
	return {
		valuations: Object.values<Valuation>(state.valuations),
		isEditor: state.isEditor,
		selectedValuation: state.selectedValuation,
	};
};

export default connect(mapStateToProps, {
	fetchStudyValuation,
	fetchStudyValuations,
	deleteValuation,
	setAsCurrentValuation,

	// fetchBiomeEcosystems,
	fetchEcosystemServices,
	fetchCountries,
	fetchProtectionStatuses,
	fetchValuationMethods,
	fetchContinents,
	fetchCiceses,
	fetchSEEAs,
	fetchBiomeV2EcosystemV2Ecozones,
	fetchCurrencies,
	fetchEcosystemConditions,
	fetchScaleOfStudySites,
})(Valuations);
