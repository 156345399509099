import React from "react";
import Select, { components } from "react-select";
import { Form, Grid } from "semantic-ui-react";

//https://codesandbox.io/s/53jomww8yn?file=/src/index.js:650-788
const Option = (props: any) => {
	const { data, morePadding } = props;
	return (
		<components.Option {...props}>
			{/* <h2>{props.data.label}</h2>
        <div style={{ fontSize: 12 }}>{props.data.subLabel}</div> */}
			{data.code && !data.hideCode ? (
				<Grid>
					<Grid.Row className="options">
						<Grid.Column
							width={2}
							style={
								morePadding
									? { paddingLeft: "1.5rem", paddingRight: "1.5rem" }
									: {}
							}>
							{data.isDisabled ? "" : data.code}
						</Grid.Column>
						<Grid.Column
							width={14}
							style={
								morePadding
									? { paddingLeft: "1.5rem", paddingRight: "1.5rem" }
									: {}
							}>
							{`${
								data.isDisabled
									? "--".repeat(data.sortOrder - 1)
									: "  ".repeat(data.sortOrder - 1)
							}${data.label}`}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			) : (
				<span style={{ paddingLeft: "1rem" }}> {data.label}</span>
			)}
		</components.Option>
	);
};

const colourStyles = {
	// ValueContainer
	valueContainer: (base: any) => ({
		...base,
		fontSize: "0.87rem",
	}),
	control: (base: any) => ({
		...base,
		fontSize: "0.87rem",
	}),
	option: (
		base: any,
		{
			isDisabled,
		}: {
			data: any;
			isDisabled: boolean;
			isFocused: boolean;
			isSelected: boolean;
		}
	) => {
		// const color = chroma(data.color);
		return {
			...base,
			fontSize: "0.87rem",
			cursor: isDisabled ? "not-allowed" : "default",
			padding: "0.5px",
		};
	},
	multiValue: (styles: any, { data }: { data: any }) => {
		// const color = chroma(data.color);
		return {
			...styles,
			// lineHeight: "0.5rem",
		};
	},
	multiValueLabel: (styles: any, { data }: { data: any }) => ({
		...styles,
	}),
	multiValueRemove: (styles: any, { data }: { data: any }) => ({
		...styles,
	}),
};

// export default SelectDsropdown;

export const SelectDropdown = (props: any) => {
	const {
		field: {
			// provided by Formik Field
			name,
			// value,
			// onChange,
		},
		form: {
			// also provided by Formik Field
			setFieldValue,
		},
		label, // our own label prop
		options, //ooptions to use
		value,
		placeholder,
		getRef,
		isMulti,
		onChange,
		optionsTransform, //used to transform the options into a format for a particular dropdown, if available
	} = props;
	return (
		<Form.Field>
			<Grid>
				<Grid.Row>
					<Grid.Column width={4}>
						<label>{label}</label>
					</Grid.Column>
					<Grid.Column width={12}>
						{MySelect(
							getRef,
							isMulti,
							placeholder,
							optionsTransform,
							options,
							onChange,
							setFieldValue,
							name,
							value
						)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form.Field>
	);
};

function MySelect(
	getRef: any,
	isMulti: any,
	placeholder: any,
	optionsTransform: any,
	options: any,
	onChange: any,
	setFieldValue: any,
	name: any,
	value: any
) {
	return (
		<Select
			ref={(ref) => {
				getRef && getRef(ref);
			}}
			// {...props.field}
			closeMenuOnSelect={!isMulti || false}
			placeholder={placeholder}
			isMulti={isMulti || false}
			isClearable={false}
			options={optionsTransform ? optionsTransform(options) : options}
			components={{ Option }}
			styles={colourStyles}
			// onChange={onChange}
			onChange={(selected) => {
				let sel = Array.isArray(selected)
					? selected.map((a: any) => a.value)
					: selected?.value;
				onChange && onChange(sel);
				setFieldValue(name, sel);
			}}
			defaultValue={options.filter((f: any) => {
				if (Array.isArray(value)) {
					return value.includes(f.value);
				}
				return value === f.value;
			})}
			value={options.filter((f: any) => {
				if (Array.isArray(value)) {
					return value.includes(f.value);
				}
				return value === f.value;
			})}
		/>
	);
}

export const MyDropdown = (props: any) => {
	const {
		field: {
			// provided by Formik Field
			name,
			// value,
			// onChange,
		},
		form: {
			// also provided by Formik Field
			setFieldValue,
		},
		label, // our own label prop
		options, //ooptions to use
		value,
		placeholder,
		getRef,
		isMulti,
		onChange,
		optionsTransform, //used to transform the options into a format for a particular dropdown, if available
	} = props;
	return (
		<>
			<label>{label}</label>
			<Form.Field>
				{MySelect(
					getRef,
					isMulti,
					placeholder,
					optionsTransform,
					options,
					onChange,
					setFieldValue,
					name,
					value
				)}
			</Form.Field>
		</>
	);
};
