import { Component } from "react";
import { Card } from "semantic-ui-react";

const EditorReadMe = () => {
	return (
		<Card fluid>
			<Card.Content>
				<h4>Read Me</h4>
			</Card.Content>
			<Card.Content>
				<ul className="dash-list">
					<li>Use this functionality to edit a published study.</li>
					<li>
						Use the free text search to search a study (you can enter a StudyID
						too).
					</li>
					<li>Select your study from the result list.</li>
					<li>Edit Study or Valuations details in the cards to the left.</li>
					<li>Edits are immediately visible upon Save.</li>
				</ul>
			</Card.Content>
		</Card>
	);
};

export default EditorReadMe;
