import agent from "../api/agent";
export const CONTINENT_CREATE = "CONTINENT_CREATE";
export const CONTINENT_EDIT = "CONTINENT_EDIT";
export const CONTINENT_DELETE = "CONTINENT_DELETE";
export const CONTINENT_FETCH = "CONTINENT_FETCH";
export const CONTINENTS_FETCH = "CONTINENTS_FETCH";

export const fetchContinents = () => async (dispatch: any) => {
  const response = await agent.Continents.list();

  dispatch({
    type: CONTINENTS_FETCH,
    payload: response,
  });
};
