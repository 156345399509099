import React from "react";
import { Button, Card, Form, Input, Label } from "semantic-ui-react";
import { setEsvdQueryFilters } from "../../actions/esvdActions";
import {
	defaultSort,
	FILTERS,
	IS_DESCEND_DEFAULT,
	LIMIT,
} from "../../shared/Literals";
import { SelectDrop } from "../../shared/SelectDropdown";
import { TreeType } from "../../models/ecosystemService";
import {
	buildTreeTypeQueryList,
	formatBiomeForDropdown,
	formatCicesForDropdown,
	formatESForDropdown,
	formatSeeasDropdown,
} from "../../EsvdFunctions";

import { ErrorMessage, Formik } from "formik";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import filterCheck from "../../shared/FilterCheck";
import NoFilterGrid from "../../shared/NoFilterGrid";
import { LoadingButton } from "../../shared/LoadingOverlay";
import { useLists } from "../../providers/ListsProvider";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import { useModal } from "../../hooks/ModalHook";
import { defaultEsvdParams } from "../../models/EsvdQueryFilters";

const EsvdFilterPanel = () => {
	const { openModal } = useModal();
	const {
		getEsvds,
		getEsvdsCount,
		clearEsvds,
		saveQueryParams,
		activePage,
		setActivePage,
		updateQueryParams,
		getEsvdTotalMapCount,
		getEsvdTotalCount,
		showMap,
		getEsvdMapData,
		setQueryParamsForSave,
		queryParamsForSave,
		esvdQueryParams,
	} = useEsvdValuation();
	const { loadLists, list } = useLists();

	let countriesRef: any | null = null;
	let continentsRef: any | null = null;
	let ecosystemServicesRef: any | null = null;
	let cicesRef: any | null = null;
	let protectionStatusRef: any | null = null;
	let valuationMethodRef: any | null = null;
	let biomeV2Ref: any | null = null;
	let seeasRef: any | null = null;

	const [queryFilters, setQueryFilters] = React.useState<any>({
		...defaultEsvdParams,
	});

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [currentPage, setCurrentPage] = React.useState<number>(0);

	React.useEffect(() => {
		clearFilter();
		(async () => {
			setIsLoading(true);
			await loadLists();
			await getEsvdTotalCount();
			await getEsvdTotalMapCount();
			setIsLoading(false);
		})();
		setCurrentPage(activePage);
		return () => {
			// clearFilter();
		};
	}, []);

	React.useEffect(() => {
		if (activePage !== currentPage) {
			(async () => {
				setIsLoading(true);
				await getActivePageData(false);
				setIsLoading(false);
				setCurrentPage(activePage);
			})();
		}

		return () => {};
	}, [activePage]);

	const setSelectedItem = (value: TreeType[], description: string) => {
		if ([FILTERS.ecosystemServices, FILTERS.ciceses].includes(description)) {
			queryFilters.isTeeb =
				value.length === 0
					? null
					: description === FILTERS.ciceses
					? "cices"
					: "teeb";
		}
		// const params = queryFilters.params;
		// params[`${description}`] = value.map((a) => a.id);
		queryFilters.params[`${description}`] = value.map((a) => a.id);
		queryParamsForSave[`${description}`] = value.map((a) => a.label);
		queryParamsForSave[`${description}Codes`] = value.map((a) => a.code);
		setQueryParamsForSave({ ...queryParamsForSave });
		setQueryFilters({ ...queryFilters });
		updateQueryParams(queryFilters);
	};

	const setFreeText = (e: any) => {
		queryFilters.params.freeText = e.target.value;
		queryParamsForSave.freeText = e.target.value;
		setQueryFilters({ ...queryFilters });
		setQueryParamsForSave({ ...queryParamsForSave });
		updateQueryParams(queryFilters);
	};

	const resetSortColumn = () => {
		queryFilters.params.sortColumn = defaultSort.key;
		queryFilters.params.isDescend = IS_DESCEND_DEFAULT;
		setQueryFilters({ ...queryFilters });
		setEsvdQueryFilters(queryFilters);
	};

	const getActivePageData = async (isFreshFilter: boolean) => {
		const page = Number(isFreshFilter ? 1 : activePage);

		const {
			params: { ecosystemServices, biomeV2EcosystemV2Ecozones, ciceses },
		} = queryFilters;

		queryFilters.params.offset = page > 1 ? page - 1 : 0;

		if (ecosystemServices && ecosystemServices.length > 0) {
			queryFilters.params.ecosystemServices = buildTreeTypeQueryList(
				ecosystemServices,
				list.ecosystemServices
			);
		}

		if (biomeV2EcosystemV2Ecozones && biomeV2EcosystemV2Ecozones.length > 0) {
			queryFilters.params.biomeV2EcosystemV2Ecozones = buildTreeTypeQueryList(
				biomeV2EcosystemV2Ecozones,
				list.biomeV2EcosystemV2Ecozones
			);
		}

		if (ciceses && ciceses.length > 0) {
			queryFilters.params.ciceses = buildTreeTypeQueryList(
				ciceses,
				list.ciceses
			);
		}

		setIsLoading(true);
		if (isFreshFilter) {
			//get the count of records to build the pagination UI
			await getEsvdsCount(queryFilters);
		}
		await getEsvds(queryFilters);
		setIsLoading(false);
		setQueryFilters({ ...queryFilters });
	};

	const clearFilter = () => {
		const query = {
			params: {
				limit: LIMIT,
				offset: 0,
				freeText: "",
				sortColumn: defaultSort.key,
				isDescend: IS_DESCEND_DEFAULT,
			},
		};
		setQueryFilters(query);
		continentsRef.select.clearValue();
		countriesRef.select.clearValue();
		ecosystemServicesRef.select.clearValue();
		protectionStatusRef.select.clearValue();
		valuationMethodRef.select.clearValue();
		cicesRef.select.clearValue();
		biomeV2Ref.select.clearValue();
		seeasRef.select.clearValue();
		setActivePage(0);
		updateQueryParams(query);
		setQueryParamsForSave({});
		showMap && getEsvdMapData(query);
		clearEsvds();
	};

	const handleFormSubmit = (
		values: { error: null },
		setErrors: Function
	): void => {
		clearEsvds();
		resetSortColumn();
		if (filterCheck(queryFilters)) {
			setIsLoading(true);
			if (!showMap) {
				getActivePageData(true)
					.then((res: any) => {
						setIsLoading(false);
						// //get the total number of items in the results
						// queryParamsForSave.resultsCount = esvdCount;
						// //save filter query
						// queryParamsForSave && saveQueryParams(queryParamsForSave);

						if (!esvdQueryParams.saved && filterCheck(esvdQueryParams)) {
							//save query if not saved already
							saveQueryParams && saveQueryParams();
						}
					})
					.catch((error: any) => {
						setErrors({ error: ErrorHandler(error) });
						setIsLoading(false);
					});
			} else {
				getEsvdMapData(queryFilters);
				setIsLoading(false);
			}
		} else {
			openModal({
				icon: "exclamation",
				header: "Attention!",
				main: <NoFilterGrid />,
				actions: undefined,
			});
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{ error: null }}
			onSubmit={(values, { setErrors }) => {
				handleFormSubmit(values, setErrors);
			}}>
			{({ handleSubmit, isValid, dirty, isSubmitting, errors }) => (
				<Form onSubmit={handleSubmit} autoComplete={"off"}>
					<Card className="top" fluid>
						<Card.Content>
							<h4 className="ui left floated">Filters</h4>
							<Button.Group floated="right" size="small">
								<LoadingButton
									isLoading={isLoading}
									text="Get valuations"
									type="submit"
									onClick={() => {
										setActivePage(0);
									}}
									className="ui button"
								/>
								<button
									className="ui button btn btn-warning btn-sm"
									onClick={() => {
										clearFilter();
										clearEsvds();
									}}
									type="reset"
									style={{
										// marginTop: "1rem",
										backgroundColor: "#f6da6e",
										// display: "block !important",
										height: "2rem",
										// width: "5rem",
									}}>
									Clear Filters
								</button>
							</Button.Group>
						</Card.Content>
						<Card.Content>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										biomeV2Ref = ref;
									}}
									options={formatBiomeForDropdown(
										list.biomeV2EcosystemV2Ecozones
									)}
									placeholder="Biome / Ecozone"
									onChange={(value: any) =>
										setSelectedItem(value, FILTERS.biomeV2EcosystemV2Ecozones)
									}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										countriesRef = ref;
									}}
									options={list.countries}
									placeholder="Country"
									onChange={(value: any) =>
										setSelectedItem(value, FILTERS.countries)
									}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										continentsRef = ref;
									}}
									options={list.continents}
									placeholder="Continent"
									onChange={(value: any) =>
										setSelectedItem(value, FILTERS.continents)
									}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										protectionStatusRef = ref;
									}}
									options={list.protectionStatuses}
									placeholder="Protection Status"
									// onChange={this.setSelectedProtectionStatuses}
									onChange={(value: any) =>
										setSelectedItem(value, FILTERS.protectionStatuses)
									}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									disabled={queryFilters.isTeeb === "cices"}
									getRef={(ref: any) => {
										ecosystemServicesRef = ref;
									}}
									options={formatESForDropdown(list.ecosystemServices)}
									placeholder="TEEB services classification"
									// onChange={this.setSelectedEcosystemServices}
									onChange={(value: any) =>
										setSelectedItem(value, FILTERS.ecosystemServices)
									}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									disabled={queryFilters.isTeeb === "teeb"}
									getRef={(ref: any) => {
										cicesRef = ref;
									}}
									options={formatCicesForDropdown(list.ciceses)}
									placeholder="CICES services classification"
									// onChange={this.setSelectedCICES}
									onChange={(value: any) =>
										setSelectedItem(value, FILTERS.ciceses)
									}
									// morePadding={true}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										seeasRef = ref;
									}}
									options={formatSeeasDropdown(list.sEEAs)}
									placeholder="SEEA services classification"
									// onChange={this.setSelectedSEEAS}
									onChange={(value: any) =>
										setSelectedItem(value, FILTERS.seeas)
									}
								/>
							</Form.Field>
							<Form.Field>
								<SelectDrop
									getRef={(ref: any) => {
										valuationMethodRef = ref;
									}}
									options={list.valuationMethods}
									placeholder="Valuation Method"
									onChange={(value: any) =>
										setSelectedItem(value, FILTERS.valuationMethods)
									}
								/>
							</Form.Field>
							<Form.Field>
								<Input
									size={"small"}
									onChange={(val) => setFreeText(val)}
									name={"freeText"}
									placeholder={'Free Text Search - Use "" for string matching'}
									data-after='Use "" for string matching'
									value={queryFilters.params.freeText}
								/>
							</Form.Field>
							<ErrorMessage
								name="error"
								render={() => (
									<Label
										style={{ marginBottom: 10, border: "none" }}
										basic
										color="red"
										content={errors.error}
									/>
								)}
							/>
						</Card.Content>
					</Card>
				</Form>
			)}
		</Formik>
	);
	// }
};

export default EsvdFilterPanel;
