import { ErrorMessage, Formik } from "formik";
import React, { useEffect } from "react";
import { Form, Label, Card } from "semantic-ui-react";
import * as Yup from "yup";

import { useState } from "react";
import { VttVariable } from "../../../models/vtt-models/VttBiome";
import EsvdInputInline from "../../../shared/InputInline";
import { ErrorHandler } from "../../../shared/ExceptionHandling";
import { useVttVariable } from "../../../providers/VttVariableProvider";
import { ViewHandles, useVtt } from "../../../providers/VttProvider";

const defaultBiome: VttVariable = { name: "" };

const VttVariableEntry = () => {
	const { currentView, setCurrentView } = useVtt();
	const { createVttVariable, getVttVariable, updateVttVariable } =
		useVttVariable();
	const [vttVariable, setVttVariable] = useState<VttVariable>();

	useEffect(() => {
		const { variableid } = currentView;
		if (variableid) {
			//retrieve biome
			(async () => {
				const vt = await getVttVariable(variableid);
				setVttVariable(vt);
			})();
		} else {
			//set to default
			setVttVariable(defaultBiome);
		}
	}, []);

	const handleFormSubmit: Function = async (
		values: any,
		setErrors: Function
	) => {
		try {
			if (values.id) {
				await updateVttVariable(values);
			} else {
				await createVttVariable(values);
			}
			setCurrentView({ view: ViewHandles.variable });
		} catch (error) {
			// setErrors(error);
			setErrors({ error: ErrorHandler(error) });
			// console.log(error);
		}
	};

	return (
		<Card.Content>
			<Formik
				enableReinitialize={true}
				validationSchema={validationSchema}
				initialValues={vttVariable || defaultBiome}
				onSubmit={(values, { setErrors }) => {
					handleFormSubmit(values, setErrors);
				}}>
				{({
					values,
					handleChange,
					handleSubmit,
					isValid,
					isSubmitting,
					dirty,
					errors,
					resetForm,
					setFieldValue,
				}) => (
					<Form
						className="custom-ui-element"
						onSubmit={handleSubmit}
						autoComplete={"off"}
						encType="multipart/form-data">
						<div style={{ display: "none" }}>
							<EsvdInputInline name="id" type="hidden" />
						</div>
						<EsvdInputInline
							name="name"
							label="Name (*)"
							fluid
							placeholder="Name"
						/>
						<ErrorMessage
							name="error"
							render={() => (
								<Label
									style={{ marginBottom: 5, borderColor: "none" }}
									basic
									color="red"
									size="tiny"
									content={errors.error}
								/>
							)}
						/>
						<label
							style={{
								color: "red",
							}}>{`(*) = required`}</label>
						<br />
						<button
							className="ui button btn btn-primary"
							type="reset"
							style={{
								marginTop: "1rem",
								backgroundColor: "#70bbfd",
								display: "block !important",
								height: "2rem",
								width: "5rem",
							}}
							onClick={() => {
								setCurrentView({ view: ViewHandles.variable });
							}}>
							Back
						</button>
						<div className="ui right floated">
							<button
								className="ui button btn btn-primary"
								type="submit"
								style={{
									marginTop: "1rem",
									backgroundColor: "#70bbfd",
									display: "block !important",
									height: "2rem",
									width: "5rem",
								}}>
								Save
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</Card.Content>
	);
};

const validationSchema: any = Yup.object({
	name: Yup.string().trim().required("Name is required"),
});

export default VttVariableEntry;
