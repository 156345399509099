import React from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
import LoadingOverlay from "../../shared/LoadingOverlay";
import EsvdMapMarker from "./EsvdMapMarker";

const containerStyle = {
	width: "100%",
	height: "70vH",
	// height: "90vH",
};

const center = {
	lat: -3.745,
	lng: -38.523,
};
const EsvdMaps = () => {
	const { getEsvdMapData, mapData, esvdQueryParams, isMapBusy, setIsMapBusy } =
		useEsvdValuation();

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY || "",
	});

	const [mapDats, setMapDats] = React.useState<any>();

	React.useEffect(() => {
		getEsvdMapData(esvdQueryParams);
	}, []);

	// React.useEffect(() => {
	// 	getEsvdMapData(esvdQueryParams);
	// }, [esvdQueryParams]);

	const groupDups = () => {
		const marks: any = {};
		mapData.forEach((mark) => {
			const pos = `${mark.latitude}:${mark.longitude}`;
			if (marks[pos]) {
				marks[pos].valueIds.push(mark.valueId);
				marks[pos].locations.add(mark.studyLocation);
			} else {
				marks[pos] = {
					position: { lat: Number(mark.latitude), lng: Number(mark.longitude) },
					valueIds: [mark.valueId],
					locations: new Set([mark.studyLocation]),
				};
			}
		});
		return marks;
	};
	React.useEffect(() => {
		//  mapData.map((d: EsvdMap, i: number) => ({
		// 	title: d.valueId?.toString(),
		// 	valueId: d.valueId,
		// 	position: { lat: Number(d.latitude), lng: Number(d.longitude) },
		// }));
		const dat = groupDups();

		const maks = markers(dat);
		setMapDats(maks);
	}, [mapData]);

	const onUnmount = React.useCallback(function callback(map) {
		setMapDats([]);
	}, []);

	const markers = (marks: any) => {
		let mks: any[] = [];
		(async function mars() {
			<>
				{
					(mks = Object.entries(marks).map(([key, value], i) => (
						<EsvdMapMarker key={i} markerData={value} />
					)))
				}
			</>;
		})();
		// setIsMapBusy(false);
		return mks;
	};

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={2.5}
			options={{
				minZoom: 2.5,
				maxZoom: 18,
				restriction: {
					latLngBounds: {
						north: 85,
						south: -85,
						west: -180,
						east: 180,
					},
				},
			}}
			onUnmount={onUnmount}>
			{/* Child components, such as markers, info windows, etc. */}
			<>
				<LoadingOverlay active={isMapBusy} />
				{mapData.length > 0 && mapDats}
				{/* <MarkerF title="Meee" position={{lat:55.7482273, lng:37.5403716}} /> */}
			</>
		</GoogleMap>
	) : (
		<></>
	);
};

export default React.memo(EsvdMaps);
