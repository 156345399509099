import { ErrorMessage, Formik } from "formik";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Form, Label, Card, Grid } from "semantic-ui-react";
import * as Yup from "yup";
import { emailSuggestedStudy } from "../../actions/suggestStudyActions";
import { initSuggestStudy, StudySuggestionProps } from "../../shared/Literals";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { SuggestStudy } from "../../models/SuggestStudy";
import EsvdInput from "../../shared/Input";
import EsvdTextArea from "../../shared/InputTextArea";
import EsvdDropZoneField from "../../shared/DropZone";
import { readAllFiles } from "../../EsvdFunctions";
import { openModal } from "../../layouts/EsvdModal";

interface IProps {
	emailSuggestedStudy: Function;
	resetSuggestStudy: Function;
	onStudyUpdated: Function;
	suggestedStudy: SuggestStudy | null;
	openModal: Function;
}

class SuggestEntry extends React.Component<IProps> {
	isCompleteRef: any | null = null;
	state = {
		hasError: false,
		showPassword: false,
		isSending: false,
		isSent: false,
		attachment: [],
		suggestedStudy: this.props.suggestedStudy,
	};

	resetStudy = () => {
		this.props.resetSuggestStudy(initSuggestStudy);
		this.setState(() => ({
			suggestedStudy: initSuggestStudy,
			attachment: [],
			isSent: false,
		}));
	};

	renderContent = () => {
		const { isSending, isSent, suggestedStudy } = this.state;
		// const { suggestedStudy } = this.props;

		return isSent ? (
			<Card.Content className="text-center">
				{/* <ThankYou />
          <Label
            className="ui right floated"
            style={{ cursor: "pointer", backgroundColor: "#70bbfd" }}
            content={<Icon name="arrow circle left" />}
            onClick={() => this.setState(() => ({ isSent: false }))}
          /> */}
			</Card.Content>
		) : (
			<Fragment>
				<Card.Content>
					<h4>Suggest a Study</h4>
				</Card.Content>
				<Card.Content>
					<Formik
						enableReinitialize={true}
						validationSchema={validationSchema}
						initialValues={suggestedStudy || {}}
						onSubmit={(values, { setErrors }) => {
							this.handleFormSubmit(values, setErrors);
						}}>
						{({
							values,
							handleChange,
							handleSubmit,
							isValid,
							isSubmitting,
							dirty,
							errors,
							resetForm,
							setFieldValue,
						}) => (
							<Form
								className="custom-ui-element"
								onSubmit={handleSubmit}
								autoComplete={"off"}
								encType="multipart/form-data">
								<EsvdInput
									name={StudySuggestionProps.title.name}
									label={StudySuggestionProps.title.label}
									fluid
									placeholder={StudySuggestionProps.title.label}
								/>
								<EsvdTextArea
									name={StudySuggestionProps.description.name}
									label={StudySuggestionProps.description.label}
									fluid
									minHeight={150}
									placeholder={StudySuggestionProps.description.label}
								/>
								<EsvdInput
									name={StudySuggestionProps.studyType.name}
									label={StudySuggestionProps.studyType.label}
									fluid
									placeholder={StudySuggestionProps.studyType.label}
								/>
								<label>
									{this.state.attachment.length < 2
										? `${StudySuggestionProps.attachment.label}. Formats allowed: [pdf, docx, odt, txt]. Max files: 2. Max total size: 10 MB`
										: "Selected file(s)"}
								</label>
								<EsvdDropZoneField
									name={StudySuggestionProps.attachment.name}
									customHeight={false}
									onSelectionChange={(d: any) => {
										values.attachment = d;
										this.setState(() => ({ attachment: d }));
									}}
									value={this.state.attachment || []}
									accept=".pdf, .odt, .doc,.docx, .txt "
								/>
								<ErrorMessage
									name="error"
									render={() => (
										<Label
											style={{ marginBottom: 5, borderColor: "none" }}
											basic
											color="red"
											size="tiny"
											content={errors.error}
										/>
									)}
								/>
								<button
									className="ui button btn btn-primary"
									type="reset"
									style={{
										marginTop: "1rem",
										backgroundColor: "#70bbfd",
										display: "block !important",
										height: "2rem",
										width: "5rem",
									}}
									onClick={() => {
										setFieldValue("title", "");
										setFieldValue("description", "");
										setFieldValue("studyType", "");
										setFieldValue("attachment", []);
										// values = initSuggestStudy;
										this.resetStudy();
									}}>
									Clear
								</button>
								<div className="ui right floated">
									<button
										className="ui button btn btn-primary"
										type="submit"
										style={{
											marginTop: "1rem",
											backgroundColor: "#70bbfd",
											display: "block !important",
											height: "2rem",
											width: "5rem",
										}}>
										<span className={`${isSending ? "hide" : ""}`}>Submit</span>
										<span
											className={`box loader-01 ${
												isSending ? "" : "invisible"
											}`}></span>
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</Card.Content>
			</Fragment>
		);
	};

	handleFormSubmit: Function = async (
		values: SuggestStudy,
		setErrors: Function
	) => {
		const { emailSuggestedStudy } = this.props;

		values.attachment = await readAllFiles(values.attachment || []);

		this.setState(() => ({ isSending: true }));
		emailSuggestedStudy(values)
			.then((res: any) => {
				this.setState(() => ({ isSending: false, isSent: true }));
				this.showModal();
				this.resetStudy();
			})
			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				this.setState(() => ({ isSending: false }));
			});
	};

	showModal = () => {
		const { openModal } = this.props;
		openModal({
			icon: "information",
			header: "Thank You!",
			content: (
				<Grid.Row centered>
					<Grid.Column width={12}>
						<p className="pos_fixed">
							Thank you for suggesting a study for inclusion in the ESVD.
						</p>
						<br />
						<p className="pos_fixed">
							Your submission will be evaluated by one of the ESVD team members.
							<br />
							<br />
							The ESVD team.
						</p>
					</Grid.Column>
				</Grid.Row>
			),
			isOpen: true,
			actions: null,
		});
	};
	render() {
		return <Card fluid>{this.renderContent()}</Card>;
	}
}
const validationSchema: any = Yup.object({
	[`${StudySuggestionProps.title.name}`]: Yup.string()
		.trim()
		.required(`${StudySuggestionProps.title.label} is required`),
	[`${StudySuggestionProps.description.name}`]: Yup.string()
		.trim()
		.required(`Study Description is required`),
	[`${StudySuggestionProps.studyType.name}`]: Yup.string()
		.trim()
		.required(`Study Type is required`),
	[`${StudySuggestionProps.attachment.name}`]: Yup.array()
		.min(1, "Attachment field must have at least 1 item")
		.required("Attachment field must have at least 1 item"),
});

// const mapStateToProps = (state: any) => {
//   return {
//     valuations: Object.values<Valuation>(state.valuations),
//   };
// };

export default connect(null, {
	emailSuggestedStudy,
	openModal,
})(SuggestEntry);
