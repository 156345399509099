import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Menu, Sidebar } from "semantic-ui-react";
import Can from "../accessControls/Can";
import EcosystemLogo from "../components/home/EcosystemLogo";
import { useAuth } from "../providers/AuthProvider";
import { actions, resources } from "../shared/Literals";

interface IProps {
	onSidebarOpened: Function;
	sidebarOpened: boolean;
}

const NavBarMobile = (props: IProps) => {
	const { user, fetchCurrentUser } = useAuth();
	const [active, setActive] = React.useState<string>(resources.home.name);

	useEffect(() => {
		//get the current window path
		const pathname = window.location && window.location.pathname;
		//get the name of the resource with this pathname
		const resource = Object.values(resources).find(
			(value: any) => value.path === pathname
		);

		if (
			resource?.name === resources.userprofiledownloads.name ||
			resource?.name === resources.filterqueriesdownloads.name
		) {
			setActive(resources.downloads.name);
			return;
		}
		resource && setActive(resource.name);
		fetchCurrentUser();
	}, []);

	const handleSidebarHide = () => {
		const { onSidebarOpened } = props;
		onSidebarOpened && onSidebarOpened();
	};

	const handleItemClick = (name: string) => {
		const { sidebarOpened } = props;
		if (sidebarOpened) {
			handleSidebarHide();
		} else {
			setActive(name);
		}
	};

	const renderLeftMenu = () => {
		// const { activeItem } = this.state;
		// const { user } = this.props;

		// if (user) {
		return (
			<Fragment>
				<Menu.Item
					name={resources.esvd.name}
					active={active === resources.esvd.name}
					onClick={() => handleItemClick(resources.esvd.name)}
					as={Link}
					to={`${resources.esvd.path}`}
				/>
				{user && (
					<Can
						action={actions.read}
						resource={resources.esvd.name}
						yes={() => (
							<Menu.Item
								name={resources.vttCalculations.name}
								active={active === resources.vttCalculations.name}
								onClick={() => handleItemClick(resources.vttCalculations.name)}
								as={Link}
								to={`${resources.vttCalculations.path}`}>
								{resources.vttCalculations.label}
							</Menu.Item>
						)}
						no={() => null}
					/>
				)}
				{/* {user && (
					<Can
						action={actions.read}
						resource={resources.study.name}
						yes={() => (
							<Menu.Item
								name={resources.study.name}
								active={active === resources.study.name}
								onClick={() => handleItemClick(resources.study.name)}
								as={Link}
								to={`${resources.study.path}`}>
								{resources.study.name}
							</Menu.Item>
						)}
						no={() => null}
					/>
				)} */}

				{user ? (
					<Can
						action={actions.read}
						resource={resources.suggestStudy.name}
						yes={() => (
							<Menu.Item
								name={resources.suggestStudy.name}
								active={active === resources.suggestStudy.name}
								onClick={() => handleItemClick(resources.suggestStudy.name)}
								as={Link}
								to={`${resources.suggestStudy.path}`}>
								{resources.suggestStudy.name}
							</Menu.Item>
						)}
						no={() => null}
					/>
				) : (
					<Menu.Item
						name={resources.suggestStudy.name}
						active={active === resources.suggestStudy.name}
						onClick={() => handleItemClick(resources.suggestStudy.name)}
						as={Link}
						to={`${resources.suggestStudy.path}`}>
						{resources.suggestStudy.name}
					</Menu.Item>
				)}

				{/* {user && (
					<Can
						action={actions.read}
						resource={resources.studyEditor.name}
						yes={() => (
							<Menu.Item
								name={resources.studyEditor.name}
								active={active === resources.studyEditor.name}
								onClick={() => handleItemClick(resources.studyEditor.name)}
								as={Link}
								to={`${resources.studyEditor.path}`}>
								{resources.studyEditor.name}
							</Menu.Item>
						)}
						no={() => null}
					/>
				)} */}

				{user && (
					<Can
						action={actions.read}
						resource={resources.admin.name}
						yes={() => (
							// <Menu position="right">
							<Menu.Item active={active === resources.admin.name}>
								<Dropdown
									style={{ paddingLeft: "0px" }}
									item
									text={resources.admin.name}>
									<Dropdown.Menu
										style={{ left: "10%" }}
										active={active === resources.admin.name}>
										<Dropdown.Item as={Link} to={resources.vtt.path}>
											<Icon name="settings" />
											{resources.vtt.label}
										</Dropdown.Item>
										<Dropdown.Item as={Link} to={resources.vsl.path}>
											<Icon name="settings" />
											{resources.vsl.label}
										</Dropdown.Item>
										{/* <Dropdown.Item as={Link} to={resources.study.path}>
											<Icon name="settings" />
											{resources.study.name}
										</Dropdown.Item>

										<Dropdown.Item as={Link} to={resources.studyEditor.path}>
											<Icon name="settings" />
											{resources.studyEditor.name}
										</Dropdown.Item> */}

										{user && (
											<Can
												action={actions.read}
												resource={resources.settings.name}
												yes={() => (
													<Dropdown.Item as={Link} to={resources.settings.path}>
														<Icon name="settings" />
														{resources.settings.name}
													</Dropdown.Item>
												)}
												no={() => null}
											/>
										)}
										<Dropdown.Item
											as={Link}
											to={resources.userprofiledownloads.path}>
											<Icon name="users" />
											{resources.userprofiledownloads.name}
										</Dropdown.Item>

										<Dropdown.Item
											as={Link}
											to={resources.filterqueriesdownloads.path}>
											<Icon name="filter" />
											{resources.filterqueriesdownloads.name}
										</Dropdown.Item>

										<Dropdown.Item as={Link} to={resources.uploads.path}>
											<Icon name="upload" />
											{resources.uploads.label}
										</Dropdown.Item>

										{user && (
											<Can
												action={actions.read}
												resource={resources.vsluploads.name}
												yes={() => (
													<Dropdown.Item
														as={Link}
														to={resources.vsluploads.path}>
														<Icon name="upload" />
														{resources.vsluploads.label}
													</Dropdown.Item>
												)}
												no={() => null}
											/>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</Menu.Item>
							// </Menu>
						)}
						no={() => null}
					/>
				)}
			</Fragment>
		);
		// }
	};

	const renderNav = () => {
		const { sidebarOpened } = props;
		return (
			<Sidebar
				as={Menu}
				animation="overlay"
				inverted
				onHide={handleSidebarHide}
				vertical
				visible={sidebarOpened}>
				<Menu.Item>
					<EcosystemLogo />
				</Menu.Item>
				<Menu.Item
					name={resources.home.name}
					active={active === resources.home.name}
					onClick={() => handleItemClick(resources.home.name)}
					as={Link}
					to={"/"}
				/>
				{renderLeftMenu()}

				<Menu.Menu position="right">
					{!user ? (
						<Menu.Item
							name="login"
							active={active === "login"}
							as={Link}
							to={"/login"}>
							Login/Sign Up
						</Menu.Item>
					) : (
						<Menu.Item>
							<Icon name="user circle" />
							<Dropdown
								style={{ paddingLeft: "0px" }}
								item
								text={user.displayName ? user.displayName : user.userName}>
								<Dropdown.Menu>
									<Dropdown.Item>
										<Icon name="user" />
										<Link to={resources.userProfile.path}>My Profile</Link>
									</Dropdown.Item>
									<Dropdown.Divider />
									{/* <Dropdown.Item>
										<Icon name="setting" />
										Account Settings
									</Dropdown.Item> */}
									<Dropdown.Item as={Link} to={"/logout"}>
										<Icon name="log out" />
										Logout
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Menu.Item>
					)}
				</Menu.Menu>
			</Sidebar>
		);
	};
	return renderNav();
};

export default NavBarMobile;
