import React from "react";
import { Grid } from "semantic-ui-react";
import { actions, resources } from "../../shared/Literals";

import FilterQueryList from "./FilterQueryList";
import FilterQueryButton from "./FilterQueryButton";
import Can from "../../accessControls/Can";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
// import { FilterQueryProvider } from "../../providers/FilterQueryProvider";
import UserProfileTable from "./UserProfileTable";

const { Media, MediaContextProvider } = MediaLayout;

const UserProfileDownloadsBoard = () => {
	const renderContent = () => {
		return (
			<MediaContextProvider>
				<Media at="mobile">
					<FilterQueryButton />
					<FilterQueryList />
					<UserProfileTable />
				</Media>
				<Media greaterThan="mobile">
					<div
						style={{ display: "block", marginTop: "3em", marginBottom: "5em" }}>
						{/* <FilterQueryProvider> */}
						<Grid className="page-content">
							<UserProfileTable />
						</Grid>
						{/* </FilterQueryProvider> */}
					</div>
				</Media>
			</MediaContextProvider>
		);
	};
	// render() {
	return (
		<Can
			action={actions.read}
			resource={resources.downloads.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
	// }
};

export default UserProfileDownloadsBoard; // connect(null, { fetchFilterQueries })(UserProfileDownloadsBoard);
