import React, { useEffect, useState } from "react";
import { Button, Card, Item } from "semantic-ui-react";
// import moment from "moment";
// import * as signalR from "@microsoft/signalr";

import VslExport from "./VslExport";
import { useVsl } from "../../providers/VslProvider";
import MyModal from "../../layouts/Modals";
// import { useAuth } from "../../providers/AuthProvider";
import { useVslGridHub } from "../../providers/VslGridHubProvider";
import { VslCellEditInfo } from "../../models/vsl-models/VslCellEditInfo";

export function vslGridEditSyncLockReducer(state: any, action: any) {
	switch (action.type) {
		case "synclocked":
			return true;
		default:
			return false;
	}
}

export function vslGridEditStatusReducer(state: any, action: any) {
	switch (action.type) {
		case "lock":
			return { lock: true };
		case "edit":
			return { lock: false };
		default:
			throw new Error("Unsupported action...");
	}
}

export function vslSelectedRowReducer(state: any, action: any) {
	switch (action.type) {
		case "selected":
			return { rowInfo: action.rowInfo };
		default:
			return { rowInfo: null };
	}
}
// type: "selected",
// rowInfo: { index: row, id: rowData.id },

const VslActionsPanel = () => {
	const {
		selectedRowState,
		selectedRowDispatch,
		hotTableInstance,
		gridLockState,
		deleteStudy,
		setvslsCount,
		vslsCount,
		gridEditSyncLock,
		gridEditSycnLockDispatch,
	} = useVsl();

	const { vslGridHub } = useVslGridHub();

	const [opened, setOpened] = useState<boolean>(false);
	const [isModalConfirmed, setIsModalConfirmed] = useState<boolean>(false);
	const [rowIsReadonly, setRowIsReadonly] = useState<any>(null);

	useEffect(() => {
		if (vslGridHub) {
			if (vslGridHub.state === "Disconnected") {
				vslGridHub.start();
			}
			vslGridHub.on("GridIsReadonly", (locked: any, username: string) => {
				setRowIsReadonly({ locked, username });
			});
		}
	}, [vslGridHub]);

	useEffect(() => {
		//toggle grid state
		if (rowIsReadonly) {
			const { locked } = rowIsReadonly;
			if (locked) {
				gridEditSycnLockDispatch &&
					gridEditSycnLockDispatch({ type: "synclocked" });
			} else {
				gridEditSycnLockDispatch && gridEditSycnLockDispatch({ type: "" });
			}
		}
		return () => {
			// if (hubConnection) {
			// 	hubConnection.stop();
			// }
		};
	}, [rowIsReadonly]);

	useEffect(() => {
		if (isModalConfirmed) {
			setIsModalConfirmed(false);
			(async () => {
				const { index, id } = selectedRowState.rowInfo;
				if (id) {
					await deleteStudy(id);
					// hotTableInstance.alter("remove_row", index);
				}
				if (index >= 0) {
					hotTableInstance.alter("remove_row", index);
					let cellEditInfo: VslCellEditInfo = {
						id: id,
						row: index,
						// column: col,
						actionType: "delete",
					};
					try {
						vslGridHub &&
							vslGridHub.invoke<any>("VslGridEditInfo", cellEditInfo);
					} catch (error) {
						// console.log(error);
					}
				}
				// await getVslsCount();
				setvslsCount(vslsCount && vslsCount > 1 ? vslsCount - 1 : 0);
				selectedRowDispatch && selectedRowDispatch({ rowInfo: null });
			})();
		} else {
			selectedRowDispatch && selectedRowDispatch({ rowInfo: null });
		}
		return () => {};
	}, [isModalConfirmed]);

	const { lock } = gridLockState;

	return (
		<>
			<Card fluid>
				<Card.Content>
					<Item>
						<Item.Content>
							<Item.Header>
								<h4>Actions</h4>
							</Item.Header>
						</Item.Content>
					</Item>
				</Card.Content>
				{!gridEditSyncLock ? (
					<>
						{/* <Card.Content style={{ minHeight: "6rem" }}>
							<button
								className="ui button btn btn-warning btn-sm"
								onClick={() => {
									gridStateDispatch && gridStateDispatch({ type: "edit" });
									try {
										vslGridHub &&
											vslGridHub.invoke<any>("VslGridInEditMode", true);
									} catch (error) {
										// console.log(error);
									}
								}}
								type="reset"
								style={{
									// marginTop: "1rem",
									backgroundColor: "#f6da6e",
									// display: "block !important",
									height: "2rem",
									width: "12rem",
								}}
								disabled={!lock || gridEditSyncLock}>
								Lock editing to others
							</button>
							<br />
							<br />
							<button
								className="ui button btn btn-primary btn-sm"
								onClick={() => {
									gridStateDispatch && gridStateDispatch({ type: "lock" });
									try {
										vslGridHub &&
											vslGridHub.invoke<any>("VslGridInEditMode", false);
									} catch (error) {
										console.log(error);
									}
								}}
								type="reset"
								style={{
									// marginTop: "1rem",
									// backgroundColor: "#f6da6e",
									// display: "block !important",
									height: "2rem",
									width: "12rem",
								}}
								disabled={lock || gridEditSyncLock}>
								Unlock editing for others
							</button>
						</Card.Content> */}
						<Card.Content style={{ minHeight: "6rem" }}>
							<button
								className="ui button btn btn-primary btn-sm"
								onClick={() => {
									hotTableInstance.alter("insert_row_above", 0);
								}}
								type="reset"
								style={{
									// marginTop: "1rem",
									// backgroundColor: "#f6da6e",
									// display: "block !important",
									height: "2rem",
									width: "12rem",
								}}>
								Add Study Record
							</button>
							<br />
							<br />
							<button
								className="ui button btn btn-danger btn-sm"
								onClick={() => {
									const { index } = selectedRowState.rowInfo;
									if (index >= 0) {
										setOpened(true);
									}
								}}
								type="reset"
								style={{
									// marginTop: "1rem",
									// backgroundColor: "#f6da6e",
									// display: "block !important",
									height: "2rem",
									width: "12rem",
								}}
								disabled={
									selectedRowState.rowInfo === null //|| gridEditSyncLock
								}>
								Delete Study Record
							</button>
						</Card.Content>
					</>
				) : (
					<Card.Content style={{ minHeight: "6rem" }}>
						<h5>{`Grid is locked for editing by ${rowIsReadonly.username}`}</h5>
					</Card.Content>
				)}
				<Card.Content style={{ minHeight: "6rem" }}>
					<VslExport
						ButtonText="Download VSL as CSV"
						// filename={`Vsl_Data_${moment().format("Do-MMM-yyyy_HH-mm-ss")}.csv`}
					/>
				</Card.Content>
			</Card>
			<MyModal
				title="Delete"
				body="Are you sure you want to delete this Study Record ?"
				opened={opened}
				setOpened={setOpened}
				setConfirmation={setIsModalConfirmed}
			/>
		</>
	);
};

export default VslActionsPanel;
