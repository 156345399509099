import { Component } from "react";
import { Card, Item } from "semantic-ui-react";
import { COMPANY_EMAIL } from "../../shared/Literals";

class SuggestReadMePanel extends Component {
	render() {
		return (
			<Card fluid>
				<Card.Content>
					<Item>
						<Item.Content>
							<Item.Header>
								<h4>Read Me</h4>
							</Item.Header>
						</Item.Content>
					</Item>
				</Card.Content>
				<Card.Content>
					<p>
						On this page, you can suggest a study or check our list of valuation
						studies.
					</p>
					<p style={{ overflowWrap: "break-word" }}>
						The "Suggest a study" functionality allows you to propose a study,
						PhD thesis, report, journal article, conference paper, or book
						(section){" "}
						<strong>
							containing the monetary valuation of ecosystem services.
						</strong>
						You can check whether the study is already included in our
						repository via the "Valuation Studies List" functionality. After an
						internal check, your proposed study could be included in the ESVD.
						If you're interested in the status of your suggested study, feel
						free to contact us via <a href={`mailto:${COMPANY_EMAIL}`}>email</a>
						.
					</p>
					<br />
					<p style={{ overflowWrap: "break-word" }}>
						The "Valuation Studies List" (VSL) functionality shows all our
						collected studies containing monetary values. Note that not all
						studies in the valuation studies list are (yet) in the ESVD. The VSL
						includes bibliographic references and key variables such as biome,
						ecosystem service, country, and language. Under the "ESVD Included"
						variable, you can determine whether the study has been included in
						the ESVD, excluded, or is pending review. You can filter the VSL
						using the search functionality.{" "}
						<strong>
							We suggest to use the free text filter to check whether your
							proposed study is already in the valuation study list.
						</strong>
					</p>
					{/* <p style={{ overflowWrap: "break-word" }}>
						Please check your suggestion against the{" "}
						<a
							href="https://www.zotero.org/groups/2923630/esvd/library/"
							target="_blank"
							rel="noopener noreferrer">
							ESVD bibliographic reference database in Zotero
						</a>{" "}
						to see if it is already in our repository or not.
						<br />
					</p>
					<p>
						After a quality control, and review, the information could be
						included in the ESVD. If you are interested in the status of your
						suggested study, you can always get in touch with us by{" "}
						<a href={`mailto:${COMPANY_EMAIL}`}>email</a>.
					</p> */}
				</Card.Content>
			</Card>
		);
	}
}

export default SuggestReadMePanel;
