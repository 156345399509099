import agent from "../api/agent";
import { EsvdQueryParams } from "../models/EsvdQueryFilters";

export const ESVDS_FETCH_COUNT = "ESVDS_FETCH_COUNT";
export const ESVDS_FETCH_FULL_COUNT = "ESVDS_FETCH_FULL_COUNT";
export const RESET_FILTERED_ESVDS = "RESET_FILTERED_ESVDS";
export const ESVDS_FETCH_FILTERED_COUNT = "ESVDS_FETCH_FILTERED_COUNT";
export const ESVD_SUMMARY_STATS = "ESVD_SUMMARY_STATS";

export const fetchEsvdsCount =
  (filters: EsvdQueryParams, isFullCount: boolean = false) =>
    async (dispatch: any) => {
      // const response = await agent.EsvdStats.count(filters);
      // dispatch({
      //   type: isFullCount ? ESVDS_FETCH_FULL_COUNT : ESVDS_FETCH_COUNT,
      //   payload: response,
      // });
    };

/**
 * use this to get the full dataset in chunks specified by the limit arugument and append to full list
 * @param args
 * @returns
 */
export const fetchEsvdsSummaryStats =
  (filters: EsvdQueryParams) => async (dispatch: any) => {
    const response = await agent.EsvdStats.summaryStats(filters);
    dispatch({
      type: ESVD_SUMMARY_STATS,
      payload: response,
    });
  };
