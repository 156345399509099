import { ErrorMessage, Field, Formik } from "formik";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Label, Form, Checkbox } from "semantic-ui-react";
import * as Yup from "yup";
import { saveValuation } from "../../actions/valuationsActions";
// import { fetchBiomeEcosystems } from "../../actions/biomeEcosystemAction";
import { fetchBiomeV2EcosystemV2Ecozones } from "../../actions/biomeV2EcosystemV2EcozoneAction";
import { fetchEcosystemServices } from "../../actions/ecosystemServiceActions";
import { fetchCountries } from "../../actions/countryActions";
import { fetchProtectionStatuses } from "../../actions/protectionStatusActions";
import { fetchValuationMethods } from "../../actions/valuationMethodActions";
import { fetchContinents } from "../../actions/continentActions";
import { fetchCiceses } from "../../actions/cicesActions";
import { fetchSEEAs } from "../../actions/seeaActions";
import { fetchEcosystemConditions } from "../../actions/ecosystemConditionActions";
import { fetchCurrencies } from "../../actions/others/currencyActions";
import { fetchScaleOfStudySites } from "../../actions/others/scaleOfStudySiteActions";

import { initValuation, ValuationProps } from "../../shared/Literals";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import EsvdInputInline from "../../shared/InputInline";

import {
	formatBiomeForDropdown,
	formatCicesForDropdown,
	formatESForDropdown,
	formatSeeasDropdown,
} from "../../EsvdFunctions";
import { TreeType } from "../../models/ecosystemService";

import { Valuation } from "../../models/Valuation";

import SelectDropdown from "../../shared/SelectDropdown";
import NumberInputInline from "../../shared/InputInlineNumber";
import CheckboxInline from "../../shared/CheckboxInline";

interface IProps {
	// fetchBiomeEcosystems: Function;

	fetchEcosystemServices: Function;
	fetchCountries: Function;
	fetchProtectionStatuses: Function;
	fetchValuationMethods: Function;
	fetchContinents: Function;
	fetchCiceses: Function;
	fetchSEEAs: Function;
	fetchBiomeV2EcosystemV2Ecozones: Function;
	fetchEcosystemConditions: Function;
	fetchCurrencies: Function;
	fetchScaleOfStudySites: Function;

	saveValuation: Function;
	resetValuation: Function;
	// valuation: Valuation | any;

	countries: any[];
	continents: any[];
	protectionStatuses: any[];
	ecosystemServices: any[];
	ciceses: any[];
	seeas: any[];
	biomeEcosystems: any[];
	biomeV2EcosystemV2Ecozones: any[];
	valuationMethods: any[];
	scaleOfStudySites: any[];
	currencies: any[];
	ecosystemConditions: any[];
	selectedValuation: Valuation;
}
interface IState {
	isLoading: boolean;
	isInclExcl: boolean;
	setLoading: Function;

	selectedBiomeEcosystems: any[];
	selectedBiomeV2EcosystemV2Ecozones: any[];
	selectedEcosystemServices: any[];
	selectedValuation: Valuation;
	selectedCountries: any[];
	selectedCiceses: any[];
	selectedSeeas: any[];
	selectedProtectionStatusId: any;
	selectedValuationMethods: any[];
	selectedContinentId: any;
	selectedEcosystemConditionId: any;
	selectedScaleOfStudySiteId: any;
	selectedScaleOfStudySiteCode: any;
	selectedCurrencyId: any;
}
class ValuationEntry extends React.Component<IProps> {
	biomeEcosystemsRef: any | null = null;
	biomeV2EcosystemV2EcozonesRef: any | null = null;
	ecosystemServiceRef: any | null = null;
	countriesRef: any | null = null;
	valuationMethodRef: any | null = null;
	protectionStatusRef: any | null = null;
	continentRef: any | null = null;
	ecosystemConditionRef: any | null = null;
	scaleOfStudySiteRef: any | null = null;
	currencyIdRef: any | null = null;
	cicesRef: any | null = null;
	seeaRef: any | null = null;
	state: IState;

	isInclExclRef: any | null = null;

	constructor(props: IProps) {
		super(props);

		this.state = {
			isInclExcl: false,
			isLoading: false,
			setLoading: (loading: boolean) =>
				this.setState(() => ({ isLoading: loading })),
			selectedBiomeEcosystems: props.selectedValuation.biomeEcosystems,
			selectedBiomeV2EcosystemV2Ecozones:
				props.selectedValuation.biomeV2EcosystemV2Ecozones,
			selectedEcosystemServices: props.selectedValuation.ecosystemServices,
			selectedCountries: props.selectedValuation.countries,
			selectedProtectionStatusId: props.selectedValuation.protectionStatusId,
			selectedValuationMethods: props.selectedValuation.valuationMethods,
			selectedValuation: props.selectedValuation,
			selectedContinentId: props.selectedValuation.continentId,
			selectedScaleOfStudySiteId: props.selectedValuation.scaleOfStudySiteId,
			selectedCurrencyId: props.selectedValuation.currencyId,
			selectedEcosystemConditionId:
				props.selectedValuation.ecosystemConditionId,
			selectedScaleOfStudySiteCode:
				props.selectedValuation.scaleOfStudySiteCode,
			selectedCiceses: props.selectedValuation.ciceses,
			selectedSeeas: props.selectedValuation.seeas,
		};
	}

	resetValuation = () => {
		this.props.resetValuation(initValuation);
	};

	setSelectedBiomeEcosystems = (value: TreeType[]) => {
		this.setState(() => ({ selectedBiomeEcosystems: value }));
	};

	setSelectedBiomeV2EcosystemV2Ecozones = (value: TreeType[]) => {
		this.setState(() => ({ selectedBiomeV2EcosystemV2Ecozones: value }));
	};

	setSelectedEcosystemServices = (value: TreeType[]) => {
		this.setState(() => ({
			selectedEcosystemServices: value,
		}));
	};

	setSelectedCiceses = (value: TreeType[]) => {
		this.setState(() => ({
			selectedCiceses: value,
		}));
	};

	setSelectedSeeas = (value: TreeType[]) => {
		this.setState(() => ({
			selectedSeeas: value,
		}));
	};
	setSelectedCountries = (value: TreeType[]) => {
		this.setState(() => ({
			selectedCountries: value,
		}));
	};

	setSelectedProtectionStatusId = (value: TreeType) => {
		this.setState(() => ({
			selectedProtectionStatusId: value,
		}));
	};

	setSelectedScaleOfStudySiteId = (value: TreeType) => {
		this.setState(() => ({
			selectedScaleOfStudySiteId: value,
		}));
	};

	setSelectedValuationMethods = (value: TreeType[]) => {
		this.setState(() => ({
			selectedValuationMethods: value,
		}));
	};

	renderContent = () => {
		const {
			selectedValuation,
			isLoading,
			isInclExcl,
			selectedEcosystemServices,
			// selectedBiomeEcosystems,
			selectedBiomeV2EcosystemV2Ecozones,
			selectedCountries,
			selectedProtectionStatusId,
			selectedValuationMethods,
			selectedContinentId,
			selectedEcosystemConditionId,
			selectedScaleOfStudySiteId,
			selectedCurrencyId,
			selectedCiceses,
			selectedSeeas,
		} = this.state;

		const {
			biomeEcosystems,
			ecosystemServices,
			countries,
			protectionStatuses,
			valuationMethods,
			continents,
			currencies,
			ciceses,
			ecosystemConditions,
			scaleOfStudySites,
			biomeV2EcosystemV2Ecozones,
			seeas,
		} = this.props;

		return (
			<Fragment>
				<Formik
					enableReinitialize={true}
					validationSchema={validationSchema}
					initialValues={selectedValuation}
					onSubmit={(values, { setErrors }) => {
						this.handleFormSubmit(values, setErrors);
					}}>
					{({
						values,
						handleChange,
						handleSubmit,
						isValid,
						isSubmitting,
						dirty,
						errors,
						initialValues,
					}) => (
						<Form
							className="custom-ui-element"
							onSubmit={handleSubmit}
							autoComplete={"off"}>
							<div style={{ display: "none" }}>
								<EsvdInputInline name={ValuationProps.id.name} type="hidden" />
							</div>
							<div style={{ display: "none" }}>
								<EsvdInputInline
									name={ValuationProps.studyId.name}
									type="hidden"
								/>
							</div>
							<EsvdInputInline
								name={ValuationProps.valueId.name}
								label={ValuationProps.valueId.label}
								placeholder={ValuationProps.valueId.label}
								readonly={true}
							/>
							<Field
								name={ValuationProps.biomeV2EcosystemV2Ecozones.name}
								label={ValuationProps.biomeV2EcosystemV2Ecozones.label}
								options={biomeV2EcosystemV2Ecozones}
								optionsTransform={formatBiomeForDropdown}
								component={SelectDropdown}
								isMulti={true}
								value={selectedBiomeV2EcosystemV2Ecozones}
								getRef={(ref: any) => {
									this.biomeV2EcosystemV2EcozonesRef = ref;
								}}
								placeholder={ValuationProps.biomeV2EcosystemV2Ecozones.label}
								onChange={this.setSelectedBiomeV2EcosystemV2Ecozones}
							/>
							<EsvdInputInline
								name={ValuationProps.ecosystemDescription.name}
								label={ValuationProps.ecosystemDescription.label}
								fluid
								placeholder={ValuationProps.ecosystemDescription.label}
							/>
							<EsvdInputInline
								name={ValuationProps.ecosystemServiceDescription.name}
								label={ValuationProps.ecosystemServiceDescription.label}
								fluid
								placeholder={ValuationProps.ecosystemServiceDescription.label}
							/>
							<Field
								name={ValuationProps.ecosystemServices.name}
								label={ValuationProps.ecosystemServices.label}
								options={ecosystemServices}
								optionsTransform={formatESForDropdown}
								component={SelectDropdown}
								isMulti={true}
								value={selectedEcosystemServices}
								getRef={(ref: any) => {
									this.ecosystemServiceRef = ref;
								}}
								placeholder={ValuationProps.ecosystemServices.label}
								onChange={this.setSelectedEcosystemServices}
							/>
							<Field
								name={ValuationProps.ciceses.name}
								label={ValuationProps.ciceses.label}
								options={ciceses}
								optionsTransform={formatCicesForDropdown}
								component={SelectDropdown}
								isMulti={true}
								value={selectedCiceses}
								getRef={(ref: any) => {
									this.cicesRef = ref;
								}}
								placeholder={ValuationProps.ciceses.label}
								onChange={this.setSelectedCiceses}
							/>
							<Field
								name={ValuationProps.seeas.name}
								label={ValuationProps.seeas.label}
								options={seeas}
								optionsTransform={formatSeeasDropdown}
								component={SelectDropdown}
								isMulti={true}
								value={selectedSeeas}
								getRef={(ref: any) => {
									this.seeaRef = ref;
								}}
								placeholder={ValuationProps.seeas.label}
								onChange={this.setSelectedSeeas}
							/>
							<Field
								name={ValuationProps.countries.name}
								label={ValuationProps.countries.label}
								options={countries}
								component={SelectDropdown}
								value={selectedCountries}
								getRef={(ref: any) => {
									this.countriesRef = ref;
								}}
								placeholder={ValuationProps.countries.label}
								onChange={this.setSelectedCountries}
								isMulti={true}
							/>
							<Field
								name={ValuationProps.continentId.name}
								label={ValuationProps.continentId.label}
								options={continents}
								component={SelectDropdown}
								value={selectedContinentId}
								getRef={(ref: any) => {
									this.continentRef = ref;
								}}
								placeholder={ValuationProps.continentId.label}
								onChange={(value: TreeType) =>
									this.setState(() => ({ selectedContinentId: value }))
								}
							/>
							<Field
								name={ValuationProps.scaleOfStudySiteId.name}
								label={ValuationProps.scaleOfStudySite.label}
								options={scaleOfStudySites}
								component={SelectDropdown}
								value={selectedScaleOfStudySiteId}
								getRef={(ref: any) => {
									this.scaleOfStudySiteRef = ref;
								}}
								placeholder={ValuationProps.scaleOfStudySite.label}
								onChange={(value: TreeType) => {
									// value in the options is the primary key id from db.
									// use it to retrieve the code for the selected option from the options list.
									// assign the code to the code state variable
									// set the code for this scale
									// const scale = scaleOfStudySites.find((x) => x.id === value);
									this.setState(() => ({
										selectedScaleOfStudySiteId: value,
										// selectedScaleOfStudySiteCode: scale.code,
									}));
								}}
							/>

							<EsvdInputInline
								name={ValuationProps.studyLocation.name}
								label={ValuationProps.studyLocation.label}
								fluid
								placeholder={ValuationProps.studyLocation.label}
							/>
							<Field
								name="protectionStatusId"
								label="Protection Status"
								options={protectionStatuses}
								component={SelectDropdown}
								value={selectedProtectionStatusId}
								getRef={(ref: any) => {
									this.protectionStatusRef = ref;
								}}
								placeholder="Protection Status"
								onChange={this.setSelectedProtectionStatusId}
							/>
							<NumberInputInline
								className="number"
								name="siteArea"
								label="Site Area"
								placeholder="Site Area"
							/>
							<EsvdInputInline
								name={ValuationProps.siteAreaUnit.name}
								label={ValuationProps.siteAreaUnit.label}
								fluid
								placeholder={ValuationProps.siteAreaUnit.label}
							/>
							<NumberInputInline
								name="siteAreaInHectares"
								label="Site Area In Hectares"
								className="number"
								placeholder="Site Area In Hectares"
							/>
							<NumberInputInline
								className="number"
								name="siteLength"
								label="Site Length"
								placeholder="Site Length"
							/>
							<EsvdInputInline
								name={ValuationProps.siteLengthUnit.name}
								label={ValuationProps.siteLengthUnit.label}
								fluid
								placeholder={ValuationProps.siteLengthUnit.label}
							/>
							<NumberInputInline
								name="siteLengthKm"
								label="Site Length Km"
								className="number"
								placeholder="Site Length Km"
							/>

							<EsvdInputInline
								name={ValuationProps.siteCondition.name}
								label={ValuationProps.siteCondition.label}
								fluid
								placeholder={ValuationProps.siteCondition.label}
							/>
							<Field
								name={ValuationProps.ecosystemConditionId.name}
								label={ValuationProps.ecosystemConditionId.label}
								options={ecosystemConditions}
								component={SelectDropdown}
								value={selectedEcosystemConditionId}
								getRef={(ref: any) => {
									this.ecosystemConditionRef = ref;
								}}
								placeholder={ValuationProps.ecosystemConditionId.label}
								onChange={(value: TreeType) => {
									this.setState(() => ({
										selectedEcosystemConditionId: value,
									}));
								}}
							/>
							<NumberInputInline
								name="latitude"
								label="Latitude"
								className="number"
								placeholder="Latitude"
							/>
							<NumberInputInline
								name="longitude"
								label="Longitude"
								className="number"
								placeholder="Longitude"
							/>
							<Field
								name="valuationMethods"
								label="Valuation Methods"
								options={valuationMethods}
								component={SelectDropdown}
								isMulti={true}
								value={selectedValuationMethods}
								getRef={(ref: any) => {
									this.valuationMethodRef = ref;
								}}
								placeholder="Valuation Methods"
								onChange={this.setSelectedValuationMethods}
							/>
							<EsvdInputInline
								name="valuedChange"
								label="Valued Change"
								fluid
								placeholder="Valued Change"
							/>
							<NumberInputInline
								name="originalValue"
								label="Original value"
								className="number"
								placeholder="Original value"
							/>
							<Field
								name="currencyId"
								label="Currency"
								options={currencies}
								component={SelectDropdown}
								value={selectedCurrencyId}
								getRef={(ref: any) => {
									this.currencyIdRef = ref;
								}}
								placeholder="Currency"
								onChange={(value: TreeType) =>
									this.setState(() => ({
										selectedCurrencyId: value,
									}))
								}
							/>
							<NumberInputInline
								name="valueYear"
								label="Value Year"
								className="number"
								placeholder="Value Year"
							/>
							<EsvdInputInline
								name={ValuationProps.spatialUnit.name}
								label={ValuationProps.spatialUnit.label}
								fluid
								placeholder={ValuationProps.spatialUnit.label}
							/>
							<EsvdInputInline
								name={ValuationProps.temporalUnit.name}
								label={ValuationProps.temporalUnit.label}
								fluid
								placeholder={ValuationProps.temporalUnit.label}
							/>
							<NumberInputInline
								name="presentValueYear"
								label="Present Value Year"
								className="number"
								placeholder="Present Value Year"
							/>
							<NumberInputInline
								name="presentValueDiscountRate"
								label="Present Value Discount Rate"
								className="number"
								placeholder="Present Value Discount Rate"
							/>
							<EsvdInputInline
								name={ValuationProps.beneficiaryUnit.name}
								label={ValuationProps.beneficiaryUnit.label}
								fluid
								placeholder={ValuationProps.beneficiaryUnit.label}
							/>
							<NumberInputInline
								name="numberOfBeneficiaries"
								label="Number Of Beneficiaries"
								className="number"
								placeholder="Number Of Beneficiaries"
							/>
							<EsvdInputInline
								name="typeOfBeneficiary"
								label="Type Of Beneficiary"
								fluid
								placeholder="Type Of Beneficiary"
							/>
							<NumberInputInline
								name="intPerHectarePerYear"
								label="Int_$_Ha_Yr"
								fluid
								placeholder="Int_$_Ha_Yr"
							/>
							<EsvdInputInline
								name="notes"
								label="Notes"
								fluid
								placeholder="Notes"
							/>
							<EsvdInputInline
								name="secondaryDataSources"
								label="Secondary Data Sources"
								fluid
								placeholder="Secondary Data Sources"
							/>
							<CheckboxInline
								name="inclExcl"
								label="inclExcl"
								onChange={() => {
									this.setState(() => ({ isInclExcl: !isInclExcl }));
								}}
								checked={isInclExcl}
							/>
							{isInclExcl ? (
								<EsvdInputInline
									name="inclExclComments"
									label="Incl Excl Comments"
									fluid
									placeholder=""
								/>
							) : (
								<div style={{ display: "none" }}>
									<EsvdInputInline
										name="inclExclComments"
										label="Incl Excl Comments"
										fluid
										placeholder=""
									/>
								</div>
							)}
							<ErrorMessage
								name="error"
								render={() => (
									<Label
										style={{ marginBottom: 5, borderColor: "none" }}
										basic
										color="red"
										size="tiny"
										content={errors.error}
									/>
								)}
							/>
							<button
								disabled={!isValidEntry(values)}
								className="ui button btn btn-primary right floated"
								type="submit"
								style={{
									marginTop: "1rem",
									backgroundColor: "#70bbfd",
									display: "block !important",
									height: "2rem",
									width: "5rem",
								}}>
								<span className={`${isLoading ? "hide" : ""}`}>Save</span>
								<span
									className={`box loader-01 ${
										isLoading ? "" : "invisible"
									}`}></span>
							</button>
						</Form>
					)}
				</Formik>
			</Fragment>
		);
	};

	handleFormSubmit: Function = (values: Valuation, setErrors: Function) => {
		const { saveValuation } = this.props;
		const { setLoading, isInclExcl } = this.state;
		values.inclExcl = isInclExcl;
		setLoading(true);
		saveValuation(values)
			.then((res: any) => {
				setLoading(false);
				this.props.resetValuation(initValuation);
			})
			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				setLoading(false);
			});
	};

	render() {
		return <Fragment>{this.renderContent()}</Fragment>;
	}
}

//this determines whether the save button is active or not.
const isValidEntry = (values: any): boolean => {
	//check for valid entry
	const entries = Object.values(values).filter((a: any) => {
		if (Array.isArray(a) && a.length === 0) {
			return false;
		}
		if (typeof a === "string" && a.trim() === "") {
			return false;
		}
		if (typeof a === "string" && a === null) {
			return false;
		}

		if (a === null || a === "") {
			return false;
		}
		return true;
	});
	if (
		(!values.id && entries.length <= 2) ||
		(values.id && entries.length <= 3)
	) {
		//nothing has been entered yet
		return false;
	}

	return true;
};
const validationSchema: any = Yup.object({
	[`${ValuationProps.numberOfBeneficiaries.name}`]: Yup.number()
		.nullable(true)
		.positive(
			`${ValuationProps.numberOfBeneficiaries.label} must be a positive number.`
		),
	[`${ValuationProps.siteArea.name}`]: Yup.number()
		.nullable(true)
		.positive(`${ValuationProps.siteArea.label} must be a positive number.`),
	[`${ValuationProps.siteAreaInHectares.name}`]: Yup.number()
		.nullable(true)
		.positive(
			`${ValuationProps.siteAreaInHectares.label} must be a positive number.`
		),
	[`${ValuationProps.siteLength.name}`]: Yup.number()
		.nullable(true)
		.positive(`${ValuationProps.siteLength.label} must be a positive number.`),
	[`${ValuationProps.siteLengthKm.name}`]: Yup.number()
		.nullable(true)
		.positive(
			`${ValuationProps.siteLengthKm.label} must be a positive number.`
		),
	[`${ValuationProps.siteLengthKm.name}`]: Yup.number()
		.nullable(true)
		.positive(
			`${ValuationProps.siteLengthKm.label} must be a positive number.`
		),
	[`${ValuationProps.intPerHectarePerYear.name}`]: Yup.number()
		.nullable(true)
		.positive(
			`${ValuationProps.intPerHectarePerYear.label} must be a positive number.`
		),
	[`${ValuationProps.valueYear.name}`]: Yup.number()
		.nullable(true)
		.positive(`${ValuationProps.valueYear.label} must be a positive number.`)
		.test(
			"len",
			`${ValuationProps.valueYear.label} is not a valid year.`,
			(val: any) => !val || (val && val.toString().length === 4)
		),
	[`${ValuationProps.presentValueYear.name}`]: Yup.number()
		.nullable(true)
		.positive(
			`${ValuationProps.presentValueYear.label} must be a positive number.`
		),
	[`${ValuationProps.presentValueDiscountRate.name}`]: Yup.number()
		.nullable(true)
		.positive(
			`${ValuationProps.presentValueDiscountRate.label} must be a positive number.`
		),
});
const mapStateToProps = (state: any) => {
	return {
		countries: state.countries,
		continents: state.continents,
		protectionStatuses: state.protectionStatuses,
		ecosystemServices: state.ecosystemServices,
		biomeEcosystems: state.biomeEcosystems,
		biomeV2EcosystemV2Ecozones: state.biomeV2EcosystemV2Ecozones,
		valuationMethods: state.valuationMethods,
		ciceses: state.ciceses,
		seeas: state.seeas,
		currencies: state.currencies,
		scaleOfStudySites: state.scaleOfStudySites,
		ecosystemConditions: state.ecosystemConditions,
		// selectedValuation: state.selectedValuation,
	};
};
export default connect(mapStateToProps, {
	saveValuation,
	// fetchBiomeEcosystems,
	fetchBiomeV2EcosystemV2Ecozones,
	fetchEcosystemServices,
	fetchCountries,
	fetchProtectionStatuses,
	fetchValuationMethods,
	fetchContinents,
	fetchCiceses,
	fetchSEEAs,
	fetchCurrencies,
	fetchEcosystemConditions,
	fetchScaleOfStudySites,
})(ValuationEntry);
