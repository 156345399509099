import agent from "../api/agent";
export const PROTECTION_STATUS_CREATE = "PROTECTION_STATUS_CREATE";
export const PROTECTION_STATUS_EDIT = "PROTECTION_STATUS_EDIT";
export const PROTECTION_STATUS_DELETE = "PROTECTION_STATUS_DELETE";
export const PROTECTION_STATUS_FETCH = "PROTECTION_STATUS_FETCH";
export const PROTECTION_STATUSES_FETCH = "PROTECTION_STATUSES_FETCH";

export const fetchProtectionStatuses = () => async (dispatch: any) => {
  const response = await agent.ProtectionStatuses.list();

  dispatch({
    type: PROTECTION_STATUSES_FETCH,
    payload: response,
  });
};
