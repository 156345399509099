import { useState } from "react";
import { Pagination } from "semantic-ui-react";

interface IProps {
	setActivePage: Function;
	totalPages: number;
	currentPage: number;
}
const EsvdPagination = (props: IProps) => {
	const { currentPage, setActivePage, totalPages } = props;
	const [boundaryRange] = useState<number>(1);
	const [siblingRange] = useState<number>(1);
	const [showFirstAndLastNav] = useState<boolean>(true);
	const [showEllipsis] = useState<boolean>(true);
	const [showPreviousAndNextNav] = useState<boolean>(true);

	return (
		<Pagination
			activePage={currentPage}
			boundaryRange={boundaryRange}
			onPageChange={(e, { activePage }) => {
				setActivePage(activePage || 1);
			}}
			size="mini"
			siblingRange={siblingRange}
			totalPages={totalPages}
			ellipsisItem={showEllipsis ? undefined : null}
			firstItem={showFirstAndLastNav ? undefined : null}
			lastItem={showFirstAndLastNav ? undefined : null}
			prevItem={showPreviousAndNextNav ? undefined : null}
			nextItem={showPreviousAndNextNav ? undefined : null}
		/>
	);
	// }
};

export default EsvdPagination;
