import { Component } from "react";
import { Card, Form, Input, Label } from "semantic-ui-react";
import { connect } from "react-redux";

import { fetchAllStudies, resetStudies } from "../../actions/studyActions";

import { ErrorMessage, Formik } from "formik";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { initStudy } from "../../shared/Literals";
import { LoadingButton } from "../../shared/LoadingOverlay";

interface IProps {
	fetchAllStudies: Function;
	resetStudies: Function;
	resetSearch: Function;
}

interface IState {
	freeText: string | "";
	isLoading: boolean;
}

class StudyFilterPanel extends Component<IProps> {
	state: IState;

	constructor(props: IProps) {
		super(props);
		this.state = {
			freeText: "",
			isLoading: false,
		};
	}

	setFreeText = (e: any) => {
		this.setState(() => ({ freeText: e.target.value }));
	};

	clearFilter = () => {
		this.setState(() => ({ freeText: null }));
	};

	render() {
		const { isLoading, freeText } = this.state;

		return (
			<Card className="top" fluid>
				<Card.Content>
					<h4 className="ui left floated">Search Studies</h4>
				</Card.Content>
				<Card.Content>
					<Formik
						enableReinitialize
						initialValues={{ error: null }}
						onSubmit={(values, { setErrors }) => {
							this.handleFormSubmit(values, setErrors);
						}}>
						{({ handleSubmit, isValid, dirty, isSubmitting, errors }) => (
							<Form onSubmit={handleSubmit} autoComplete={"off"}>
								<Form.Field>
									<Input
										size={"small"}
										onChange={(val) => this.setFreeText(val)}
										name={"freeText"}
										placeholder={
											'Free Text Search - Use "" for string matching'
										}
										value={freeText}
									/>
								</Form.Field>
								<ErrorMessage
									name="error"
									render={() => (
										<Label
											style={{ marginBottom: 10, border: "none" }}
											basic
											color="red"
											content={errors.error}
										/>
									)}
								/>

								<LoadingButton
									isLoading={isLoading}
									text={"Get studies"}
									type="submit"
								/>
							</Form>
						)}
					</Formik>
				</Card.Content>
			</Card>
		);
	}

	handleFormSubmit(values: { error: null }, setErrors: Function): void {
		const { freeText } = this.state;
		const { fetchAllStudies, resetSearch } = this.props;

		//reset selected study when button is clicked for results of the filter
		resetSearch && resetSearch(initStudy);
		resetStudies();

		this.setState(() => ({ isLoading: true }));
		fetchAllStudies({ freeText })
			.then((res: any) => {
				this.setState(() => ({ isLoading: false }));
			})
			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				this.setState(() => ({ isLoading: false }));
			});
	}
}

export default connect(null, {
	fetchAllStudies,
	resetStudies,
})(StudyFilterPanel);
