import { PROTECTION_STATUSES_FETCH } from "../actions/protectionStatusActions";

const protectionStatusReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case PROTECTION_STATUSES_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default protectionStatusReducer;
