// import _ from "lodash";
import { ESVDS_IS_LOADING } from "../../actions/esvdActions";

const esvdIsLoadingReducer = (state: boolean = false, action: any) => {
  switch (action.type) {
    case ESVDS_IS_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default esvdIsLoadingReducer;
