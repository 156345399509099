import React, { useContext } from "react";
// import _ from "lodash";
import agent from "../api/agent";
// import { PagingParams } from "../models/PaginationParams";
import { VttVariableDetailMap } from "../models/vtt-models/VttBiome";

interface IValue {
	getVttVariableDetailMaps: Function;
	// getVttVariableDetailMapCount: Function;
	createVttVariableDetailMap: Function;
	getVttVariableDetailMap: Function;
	updateVttVariableDetailMap: Function;
	deleteVttVariableDetailMap: Function;
}

const VttVariableDetailMapContext = React.createContext<IValue | null>(null);

// The FilterQueryProvider is responsible for FilterQuery management
const VttVariableDetailMapProvider = ({ children }: { children: any }) => {
	// const getVttVariableDetailMapCount = async () => {
	// 	try {
	// 		return await agent.VttVariableDetailMaps.count();
	// 	} catch (error: any) {
	// 		throw error.response ? error.response.statusText : error.message;
	// 	}
	// };

	const getVttVariableDetailMaps = async (vttBiomeid: number) => {
		try {
			const vtts = await agent.VttVariableDetailMaps.list(vttBiomeid);
			if (!vtts) return [];
			return vtts;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getVttVariableDetailMap = async (
		detailid: number
		// vttvariableid: number
	) => {
		try {
			return await agent.VttVariableDetailMaps.details(detailid);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const createVttVariableDetailMap = async (vttBiome: VttVariableDetailMap) => {
		try {
			await agent.VttVariableDetailMaps.create(vttBiome);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const updateVttVariableDetailMap = async (
		vttVariableDetailMap: VttVariableDetailMap
	) => {
		try {
			await agent.VttVariableDetailMaps.update(vttVariableDetailMap);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const deleteVttVariableDetailMap = async (id: number) => {
		try {
			await agent.VttVariableDetailMaps.delete(id);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<VttVariableDetailMapContext.Provider
			value={{
				getVttVariableDetailMaps,
				// getVttVariableDetailMapCount,
				createVttVariableDetailMap,
				getVttVariableDetailMap,
				updateVttVariableDetailMap,
				deleteVttVariableDetailMap,
			}}>
			{children}
		</VttVariableDetailMapContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useVttVariableDetailMap = () => {
	const filterQuery = useContext(VttVariableDetailMapContext);
	if (filterQuery == null) {
		throw new Error("useVttVariableDetailMap() called outside of its hook.");
	}
	return filterQuery;
};

export { VttVariableDetailMapProvider, useVttVariableDetailMap };
