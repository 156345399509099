import React, { useState, Fragment } from "react";

import { CurrentReferenceInfo } from "../../models/ReferenceInfo";

import { useVsl } from "../../providers/VslProvider";
import moment from "moment";

interface IProps {
	// filename: string;
	ButtonText: string;
	currentVersion?: CurrentReferenceInfo | null;
}

const VslExport = (props: IProps) => {
	const { hotTableInstance } = useVsl();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { ButtonText } = props;

	const handleClick = async () => {
		if (hotTableInstance) {
			setIsLoading(true);
			const exportPlugin = hotTableInstance.getPlugin("exportFile");
			const filename = `Vsl_Data_${moment().format("Do-MMM-yyyy_HH-mm-ss")}`;
			exportPlugin.downloadFile("csv", {
				filename: filename,
				columnHeaders: true, // default false
			});
			setIsLoading(false);
		}
	};

	return (
		<Fragment>
			<button
				className="ui button btn btn-success btn-sm"
				onClick={() => handleClick()}
				type="reset"
				style={{
					height: "2rem",
					width: "12rem",
				}}
				disabled={isLoading}>
				{ButtonText}
			</button>
			{/* <Button
				positive
				loading={isLoading}
				content={ButtonText}
				onClick={() => handleClick()}
				disabled={isLoading}
			/> */}
		</Fragment>
	);
};

export default VslExport;
