import { useEffect, useState } from "react";
import { Grid, Container } from "semantic-ui-react";
import VslActionsPanel from "./VslActionsPanel";
import Can from "../../accessControls/Can";
import { actions, resources } from "../../shared/Literals";
import { resetEsvds } from "../../actions/esvdActions";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
import VslFilterPanel from "./VslFilterPanel";
import { VslProvider } from "../../providers/VslProvider";
import { ListsProvider, useLists } from "../../providers/ListsProvider";
import { VslGridHubProvider } from "../../providers/VslGridHubProvider";
import {
	EsvdQueryParams,
	defaultEsvdParams,
} from "../../models/EsvdQueryFilters";
import VslTable from "./VslTable";
import VslOnlineAdmins from "./VslOnlineAdmins";

const { Media, MediaContextProvider } = MediaLayout;

const VslDatabaseHome = () => {
	const { loadLists } = useLists();
	const [vslFilters, setVslFilters] = useState<EsvdQueryParams>({
		...defaultEsvdParams,
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [dropdownOptions, setDropdownOptions] = useState<any>(null);

	const ItemsLoading = (isLoad: boolean) => {
		setIsLoading(isLoad);
	};
	useEffect(() => {
		// console.log(vslFilters);
	});
	useEffect(() => {
		resetEsvds();
		ItemsLoading(true);
		setVslFilters({
			...defaultEsvdParams,
		});
		(async () => {
			const dropdownLists = await loadLists();
			//add default entry to protectiostatus list
			dropdownLists.protectionStatuses = [
				{
					text: "",
					id: undefined,
					value: null,
					label: "",
					name: null,
					code: null,
					hideCode: null,
					parentId: null,
				},
				...dropdownLists.protectionStatuses,
			];
			setDropdownOptions(dropdownLists);

			ItemsLoading(false);
		})();
	}, []);

	const renderContent = () => {
		return (
			<VslGridHubProvider>
				<MediaContextProvider>
					<Media at="mobile" className="page-content">
						<VslFilterPanel
							vslFilters={vslFilters}
							setVslFilters={setVslFilters}
							dropdownOptions={dropdownOptions}
							setIsLoading={(isload: any) => ItemsLoading(isload)}
							isLoading={isLoading}
						/>
						<VslTable
							setVslFilters={setVslFilters}
							vslFilters={vslFilters}
							setIsLoading={(isload: any) => ItemsLoading(isload)}
							dropdownOptions={dropdownOptions}
							isLoading={isLoading}
						/>
						<VslOnlineAdmins />
						<VslActionsPanel />
					</Media>
					<Media greaterThan="mobile">
						<Container fluid className="top-container">
							<Grid columns={2} className="page-content" stackable>
								<Grid.Row>
									<Grid.Column width={12} stretched>
										<VslTable
											setVslFilters={setVslFilters}
											vslFilters={vslFilters}
											setIsLoading={(isload: any) => ItemsLoading(isload)}
											dropdownOptions={dropdownOptions}
											isLoading={isLoading}
										/>
									</Grid.Column>
									<Grid.Column width={4}>
										<VslFilterPanel
											vslFilters={vslFilters}
											setVslFilters={setVslFilters}
											dropdownOptions={dropdownOptions}
											setIsLoading={(isload: any) => ItemsLoading(isload)}
											isLoading={isLoading}
										/>
										<VslOnlineAdmins />
										<VslActionsPanel />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Container>
					</Media>
				</MediaContextProvider>
			</VslGridHubProvider>
		);
	};

	return (
		<Can
			action={actions.read}
			resource={resources.esvd.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
};

const VslDatabaseHomeWrapper = (props: any) => {
	return (
		<VslProvider>
			<ListsProvider>
				<VslDatabaseHome />
			</ListsProvider>
		</VslProvider>
	);
};

export default VslDatabaseHomeWrapper;
