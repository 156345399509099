import { Component } from "react";
import { Card, Item } from "semantic-ui-react";

// interface IProps {
//   mobile?: boolean;
// }

const EsvdGetStartedPanel = () => {
	// const { mobile } = this.props;
	return (
		<Card fluid>
			<Card.Content>
				<Item>
					<Item.Content>
						<Item.Header>
							<h4>GET STARTED</h4>
						</Item.Header>
					</Item.Content>
				</Item>
			</Card.Content>
			<Card.Content>
				{/* <div style={{ overflowY: "auto", height: "100%" }}> */}
				<ul className="dash-list">
					<li>
						Set your search criteria in the “Filters” card to find value
						records.
					</li>
					<li>
						Browse your results in the “Valuations” table below. The results can
						be toggled between table format and a map display.
					</li>
					<li>
						Generate summary statistics, download data, or suggest a study using
						the “Actions” card.
					</li>
					<li>
						{/* <Link to={resources.userProfile.path}>My Profile</Link> */}
						For more information consult the "About the ESVD" section on the
						Home page and/or the{" "}
						<a
							href="https://esvd.info"
							target="_blank"
							rel="noopener noreferrer">
							ESVD.info
						</a>{" "}
						website
					</li>
				</ul>
				{/* </div> */}
			</Card.Content>
		</Card>
	);
};

export default EsvdGetStartedPanel;
