import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment } from "react";
// import { connect } from "react-redux";
import { Card, Label } from "semantic-ui-react";
import * as Yup from "yup";
import EsvdInput from "../../shared/Input";
import { User } from "../../models/user";
import WelcomeHeader from "../../shared/WelcomeHeader";
import { QueryString } from "../../models/EsvdQueryFilters";
import { PASSWORD } from "../../shared/Literals";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import { Link, Redirect } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";

export interface IProps {
	resetpassword: Function;
	user: User;
	location: any | null;
}

const ResetPassword = (props: IProps) => {
	const { resetpassword, setToken } = useAuth();
	// state = {
	// 	subheadText: "Enter New Password To Reset",
	// 	showPassword: false,
	// 	isLoading: false,
	// 	setLoading: (loading: boolean) =>
	// 		this.setState(() => ({ isLoading: loading })),
	// 	isReset: false,
	// 	resetCompleted: () =>
	// 		this.setState(() => ({
	// 			isReset: !this.state.isReset,
	// 			subheadText: "Reset successful.",
	// 		})), //add query string userid and code to form values
	// 	//get details from url query string
	// 	//https://stackoverflow.com/a/56780570/5367889
	// 	//TODO: encode the userid fon the server before sending and then decode it here
	// 	queryString: Object.fromEntries(
	// 		new URLSearchParams(this.props.location.search)
	// 	),
	// };

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isReset, setIsReset] = React.useState<boolean>(false);
	const [subheadText, setsubheadText] = React.useState<string>(
		"Enter New Password To Reset"
	);
	const [queryString] = React.useState<any>(
		Object.fromEntries(new URLSearchParams(props.location.search))
	);

	const resetCompleted = () => {
		setIsReset(!isReset);
		setsubheadText("Reset successful.");
	};
	// this.setState(() => ({
	// 	isReset: !this.state.isReset,
	// 	subheadText: "Reset successful.",
	// }))

	const renderContent = () => {
		// const { user } = this.props;
		// const { isLoading, isReset, queryString, subheadText } = this.state;
		if (queryString.username && queryString.code) {
			setToken();
			return (
				<div className="login">
					<Card>
						<WelcomeHeader
							titleText="Welcome to"
							accentText="ESVD"
							subheadText={subheadText}
						/>
						{isReset ? (
							<Fragment>
								<Card.Content className="text-center">
									Password reset successful. Click{" "}
									<Link to={"/login"}>here</Link> to login.
								</Card.Content>
							</Fragment>
						) : (
							<Fragment>
								<Formik
									validationSchema={validationSchema}
									enableReinitialize
									initialValues={{
										code: "",
										userid: "",
										password: "",
										error: null,
									}}
									onSubmit={(values, { setErrors }) => {
										handleFormSubmit(values, setErrors);
									}}>
									{({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
										<Form
											className="ui form page-content"
											onSubmit={handleSubmit}
											autoComplete={"off"}>
											<EsvdInput
												name="password"
												label="Password"
												type="password"
												fluid
												icon={"key"}
												placeholder="Password"
											/>
											<EsvdInput
												name="confirmpassword"
												label="Confirm Password"
												type="password"
												fluid
												icon={"key"}
												placeholder="Confirm Password"
											/>
											<ErrorMessage
												name="error"
												render={() => (
													<Label
														style={{ marginBottom: 10 }}
														basic
														color="red"
														content={errors.error}
													/>
												)}
											/>
											<button
												disabled={!dirty || (dirty && !isValid)}
												className="ui button fluid btn btn-primary"
												type="submit"
												style={{
													backgroundColor: "#70bbfd",
													display: "block !important",
													height: "3rem",
												}}>
												<span className={`${isLoading ? "hide" : ""}`}>
													Save
												</span>
												<span
													className={`box loader-01 ${
														isLoading ? "" : "invisible"
													}`}></span>
											</button>
										</Form>
									)}
								</Formik>
								<Card.Content>
									<Link to="/login" className="ui right floated">
										Login?
									</Link>
								</Card.Content>
							</Fragment>
						)}
					</Card>
				</div>
			);
		}

		//a login user shouldn't see the resetpassword page.
		//Therefore is a logged user access the resetpassword route, go to home
		// history.push("/");
		// return null;
		else return <Redirect to="/" />;
	};

	const handleFormSubmit = (
		values: { code: string; userid: string; password: string; error: null },
		setErrors: Function
	) => {
		// const { setLoading, resetCompleted } = this.state;
		//add query string userid and code to form values
		//get details from url query string
		//https://stackoverflow.com/a/56780570/5367889
		//TODO: encode the userid fon the server before sending and then decode it here
		const queryString: QueryString = Object.fromEntries(
			new URLSearchParams(props.location.search)
		);
		const params = { ...values, ...queryString };
		setIsLoading(true);
		resetpassword(params)
			.then((res: any) => {
				// setLoading(false);
				resetCompleted();
			})
			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				// setLoading(false);
			});
		setIsLoading(false);
	};

	// render() {
	return <Fragment>{renderContent()}</Fragment>;
	// }
};

const validationSchema = Yup.object({
	password: Yup.string()
		.trim()
		.min(PASSWORD.min_len, PASSWORD.msg)
		.required("Password is required"),
	confirmpassword: Yup.string()
		.oneOf([Yup.ref("password"), null], "Passwords must match")
		.required("Confirm Password is required"),
});

export default ResetPassword;
// 	connect(null, {
// 	resetpassword,
// })(ResetPassword);
