

export default function filterCheck(esvdQueryFilters: any): boolean {
	const {
		isTeeb,
		params: {
			countries,
			protectionStatuses,
			continents,
			valuationMethods,
			ecosystemServices,
			// biomeEcosystems,
			biomeV2EcosystemV2Ecozones,
			seeas,
			ciceses,
			freeText,
		},
	} = esvdQueryFilters;

	if (
		(!countries || countries?.length === 0) &&
		(!protectionStatuses || protectionStatuses?.length === 0) &&
		(!continents || continents?.length === 0) &&
		(!valuationMethods || valuationMethods?.length === 0) &&
		(isTeeb === "teeb"
			? !ecosystemServices || ecosystemServices?.length === 0
			: isTeeb === "cices"
				? !ciceses || ciceses?.length === 0
				: true) &&
		// (!biomeEcosystems || biomeEcosystems?.length === 0) &&
		(!biomeV2EcosystemV2Ecozones || biomeV2EcosystemV2Ecozones?.length === 0) &&
		(!seeas || seeas?.length === 0) &&
		!freeText?.trim()
	) {
		return false;
	} else {
		return true;
	}
}
