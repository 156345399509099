import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment } from "react";
import { Card, Grid, Label } from "semantic-ui-react";
import * as Yup from "yup";
import EsvdInput from "../../shared/Input";

import { ResendConfirmation, User } from "../../models/user";
import WelcomeHeader from "../../shared/WelcomeHeader";
import { Link, Redirect } from "react-router-dom";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import agent from "../../api/agent";
import { useAuth } from "../../providers/AuthProvider";
import UserInvite from "../../shared/UserInvite";

interface IProps {
	login: Function;
	resendconfirmationlink: Function;
	user: User;
	match: any;
}

const Login = (props: IProps) => {
	const { user, login } = useAuth();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isSent, setIsSent] = React.useState<boolean>(false);

	const handleResendLink = async (value: ResendConfirmation) => {
		// const { setLoading } = this.state;
		// const { resendconfirmationlink } = this.props;
		setIsLoading(true);
		agent.Account.resendconfirmationlink(value)
			// resendconfirmationlink(value)
			.then((res: any) => {
				setIsSent(true);
				setIsLoading(false);
			})
			.catch((error: any) => {
				setIsLoading(false);
			});
	};

	const renderContent = () => {
		// const { user, login, match } = this.props;
		if (!user) {
			// const { isLoading, setLoading, isSent } = this.state;
			return (
				<div className="login">
					{isSent ? (
						<Card>
							<WelcomeHeader
								titleText="Welcome to"
								accentText="ESVD"
								subheadText="Send confirmation link"
							/>
							<Card.Content className="text-center">
								An email has been sent to your inbox. <br />
								Please check your inbox to reset your password.
								<br />
								If you don't see an email, please check your spam folder.
							</Card.Content>
						</Card>
					) : (
						<Card>
							<WelcomeHeader
								titleText="Welcome to the"
								accentText="ESVD"
								subheadText="Login below if you already have an account. If you do not have an account yet, please Sign Up first by clicking the Sign Up button below and create your account."
							/>
							<Formik
								validationSchema={validationSchema}
								enableReinitialize
								initialValues={{ username: "", password: "", error: null }}
								onSubmit={(values, { setErrors }) => {
									setIsLoading(true);
									login(values)
										.then((res: any) => {
											setIsLoading(false);
										})
										.catch((error: any) => {
											setErrors({ error: ErrorHandler(error) });
											setIsLoading(false);
										});
								}}>
								{({
									handleSubmit,
									values,
									isValid,
									dirty,
									isSubmitting,
									errors,
								}) => (
									<Form
										className="ui form page-content"
										onSubmit={handleSubmit}
										autoComplete={"off"}>
										<EsvdInput
											name="username"
											label="Username"
											fluid
											icon={"user outline"}
											placeholder="Username"
										/>
										<EsvdInput
											name="password"
											label="Password"
											type="password"
											fluid
											icon={"key"}
											placeholder="Password"
										/>

										<Grid>
											<Grid.Row
												style={{
													marginTop: "0",
													paddingTop: "0px",
													fontSize: "0.8rem",
												}}>
												<Grid.Column style={{ marginTop: "0" }}>
													<Link
														style={{ marginTop: "0" }}
														to="/forgotpassword"
														className="ui right floated">
														Forgot a password?
													</Link>
													<ErrorMessage
														name="error"
														render={() => (
															<>
																<Label
																	style={{ border: "none", color: "red" }}
																	basic
																	// color="red"
																	content={errors.error}></Label>
																{errors.error &&
																	errors.error.includes(
																		"Email not confirmed"
																	) && (
																		<button
																			style={{
																				padding: "5px",
																				marginBottom: "5px",
																				border: "none",
																				backgroundColor: "#fff",
																				color: "#70bbfd",
																				cursor: "pointer",
																			}}
																			onClick={() =>
																				handleResendLink({
																					username: values.username,
																				})
																			}
																			// to={{
																			//   pathname: "/resendconfirmelink",
																			//   search: "?sort=name",
																			//   hash: "#the-hash",
																			//   state: { username: values.username },
																			// }}
																			className="ui right floated">
																			Resend confirmation link?
																		</button>
																	)}
															</>
														)}
													/>
												</Grid.Column>
											</Grid.Row>
											<Grid.Row>
												<Grid.Column width={8}>
													<button
														disabled={!dirty || (dirty && !isValid)}
														className="ui button fluid btn btn-primary"
														type="submit"
														style={{
															backgroundColor: "#70bbfd",
															display: "block !important",
															height: "3rem",
														}}>
														<span className={`${isLoading ? "hide" : ""}`}>
															Login
														</span>
														<span
															className={`box loader-01 ${
																isLoading ? "" : "invisible"
															}`}></span>
													</button>
												</Grid.Column>
												<Grid.Column width={8}>
													<div
														style={{
															border: "0.05rem solid #70bbfd",
															borderRadius: "5px",
														}}>
														<Link
															to={"/signup"}
															className="ui button fluid btn btn-primary"
															type="submit"
															style={{
																borderColor: "#70bbfd",
																backgroundColor: "#fff",
																height: "2.8rem",
																color: "#70bbfd",
																lineHeight: "2.2rem",
															}}>
															<span>Sign Up</span>
														</Link>
													</div>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Form>
								)}
							</Formik>
							<Card.Content style={{ borderTop: "none" }}>
								<br />
								<UserInvite
									// titleText="Welcome to the"
									// accentText=""
									color="crimson"
									subheadText="Note: It is now possible to add additional information (organization, sector, purpose of access) to your profile.
												Adding this information to our profile greatly helps us to improve the services offered on this website.<br/>
												If you have not done so yet, you can add information to your profile by clicking on your username in the upper right corner after loggin in.
												Subsequenly select 'My Profile' in the drop down menu. On the next page, click the edit icon (pencil) to the right to add the information.<br/>
												Please don't forget to save....."
								/>
							</Card.Content>
						</Card>
					)}
				</div>
			);
		}
		if (props.match.params) {
			const { params } = props.match;
			if (Object.keys(params).length === 0) {
				return <Redirect to="/" />;
			} else {
				return <Redirect to={`/${params.returnUrl}`} />;
			}
		} else {
			//a login user shouldn't see the login page.
			//Therefore is a logged user access the login route, go to home
			// history.push("/");
			return <Redirect to="/" />;
		}
	};
	// render() {
	return <Fragment>{renderContent()}</Fragment>;
	// }
};

const validationSchema = Yup.object({
	username: Yup.string().required("Username is required"),
	password: Yup.string().required("Enter password"),
});

// const mapStateToProps = (state: any) => {
// 	return { user: state.user };
// };

export default Login;
// export default connect(mapStateToProps, {
// 	login,
// 	resendconfirmationlink,
// })(Login);
