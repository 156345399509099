import React from "react";

interface IProps {
	invalidEntries: any | any[];
	withJoin?: boolean;
	delimiter?: string;
	isFatal?: boolean;
}
const InvalidItems = (props: IProps) => {
	return (
		<ul style={props.isFatal ? { color: "red" } : {}}>
			<li>
				{props.withJoin
					? Object.keys(props.invalidEntries)
							.map(
								(key: any, i: number) => `${key}: ${props.invalidEntries[key]}`
							)
							.join(props.delimiter || "; ")
					: Object.keys(props.invalidEntries).map((key: any, i: number) => (
							<li key={i}>{`ValueID: ${key}: ${props.invalidEntries[key]}`}</li>
					  ))}
			</li>
		</ul>
	);
};

export default InvalidItems;
