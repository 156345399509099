import agent from "../api/agent";
export const BIOME_ECOSYSTEM_CREATE = "BIOME_ECOSYSTEM_CREATE";
export const BIOME_ECOSYSTEM_EDIT = "BIOME_ECOSYSTEM_EDIT";
export const BIOME_ECOSYSTEM_DELETE = "BIOME_ECOSYSTEM_DELETE";
export const BIOME_ECOSYSTEM_FETCH = "BIOME_ECOSYSTEM_FETCH";
export const BIOME_ECOSYSTEMS_FETCH = "BIOME_ECOSYSTEMS_FETCH";

export const fetchBiomeEcosystems = () => async (dispatch: any) => {
  const response = await agent.BiomeEcosystems.list();

  dispatch({
    type: BIOME_ECOSYSTEMS_FETCH,
    payload: response,
  });
};
