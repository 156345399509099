import React from "react";
import { Grid, Container } from "semantic-ui-react";
import { actions, resources } from "../../shared/Literals";

import FilterQueryList from "./FilterQueryList";
import FilterQueryButton from "./FilterQueryButton";
import Can from "../../accessControls/Can";
// import { FilterQuery } from "../../models/FilterQuery";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";

const { Media, MediaContextProvider } = MediaLayout;

const FilterQueriesDownloadsBoard = (props: any) => {
	const renderContent = () => {
		return (
			<MediaContextProvider>
				<Media at="mobile">
					<FilterQueryButton />
					<FilterQueryList />
				</Media>
				<Media greaterThan="mobile">
					<Container className="top-container">
						{/* <FilterQueryProvider> */}
						<Grid columns={2} className="page-content" stackable>
							<Grid.Row>
								<Grid.Column width={16} stretched>
									<FilterQueryList />
								</Grid.Column>
								{/* <Grid.Column width={4}>
										<FilterQueryButtonsPanel />
									</Grid.Column> */}
							</Grid.Row>
						</Grid>
						{/* </FilterQueryProvider> */}
					</Container>
				</Media>
			</MediaContextProvider>
		);
	};
	return (
		<Can
			action={actions.read}
			resource={resources.downloads.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
};

export default FilterQueriesDownloadsBoard;
