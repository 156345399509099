import { ErrorMessage, Form, Formik } from "formik";
import React, { Fragment } from "react";
import { Label } from "semantic-ui-react";
import * as Yup from "yup";
import EsvdInput from "../../shared/Input";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import EsvdInputInline from "../../shared/InputInline";
import UserAdditionalInfo from "./UserAdditionalInfo";

const AccountDetails = (props: any) => {
	const { user, updateUser } = useAuth();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	// const [sectorOtherShow, setSectorOtherShow] = React.useState<boolean>(false);
	// const [purposeOtherShow, setPurposeOtherShow] =
	React.useState<boolean>(false);
	// const [selectedSector, setSelectedSector] = React.useState<string>(
	// 	user !== null && user !== undefined ? user?.sector : ""
	// );
	// const [selectedPurpose, setSelectedPurpose] = React.useState<string>(
	// 	user !== null && user !== undefined ? user?.purposeOfAccess : ""
	// );

	const renderContent = () => {
		// const { isSigned, isLoading, subheadText } = this.state;
		if (user !== undefined && user !== null) {
			const {
				id,
				displayName,
				organization,
				sector,
				sectorOther,
				purposeOfAccess,
				purposeOfAccessOther,
				email,
				userName,
			} = user;

			return (
				<Fragment>
					<Formik
						validationSchema={validationSchema}
						enableReinitialize
						initialValues={{
							id: id ? id : null,
							displayName: displayName,
							organization: organization,
							sector: sector,
							sectorOther: sectorOther || "",
							purposeOfAccess: purposeOfAccess,
							purposeOfAccessOther: purposeOfAccessOther || "",
							email: email,
							username: userName,
							password: "",
							error: null,
						}}
						onSubmit={(values, { setErrors }) => {
							handleFormSubmit(values, setErrors);
						}}>
						{({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
							<Form
								// className="custom-ui-element"
								className="ui form page-content custom-ui-element"
								onSubmit={handleSubmit}
								autoComplete={"off"}>
								<EsvdInputInline
									name="displayName"
									label="Display Name"
									fluid
									placeholder="Display name"
								/>
								<EsvdInputInline
									name="username"
									label="Username"
									className="ui primary"
									fluid
									placeholder="Username"
									readonly={true}
									transparent={true}
									// disabled={true}
								/>
								<EsvdInputInline
									name="email"
									label="Email"
									className="ui primary"
									fluid
									placeholder="Email"
									readonly={true}
									transparent={true}
									// disabled={true}
								/>
								<UserAdditionalInfo user={user} />
								{/* <EsvdInputInline
									name="organization"
									label="Organization"
									fluid
									placeholder="Organization"
								/>
								<Field
									name="sector"
									label="Sector"
									options={sectorsList}
									component={SelectDropdown}
									isMulti={false}
									value={selectedSector}
									placeholder="Sector"
									onChange={setSector}
								/>
								{sectorOtherShow ? (
									<EsvdInputInline
										name="sectorOther"
										label=""
										fluid
										placeholder=""
									/>
								) : (
									<div
										style={{
											display: "none",
										}}>
										<EsvdInputInline
											name="sectorOther"
											label=""
											fluid
											placeholder=""
										/>
									</div>
								)}
								<Field
									name="purposeOfAccess"
									label="Purpose of access"
									options={purposeOfAccessList}
									component={SelectDropdown}
									isMulti={false}
									value={selectedPurpose}
									placeholder="Purpose of access"
									onChange={setPurposeOfAccess}
								/>
								{purposeOtherShow ? (
									<EsvdInputInline
										name="purposeOfAccessOther"
										label=""
										fluid
										placeholder=""
									/>
								) : (
									<div
										style={{
											display: "none",
										}}>
										<EsvdInputInline
											name="purposeOfAccessOther"
											label=""
											fluid
											placeholder=""
										/>
									</div>
								)} */}
								<div style={{ display: "none" }}>
									<EsvdInput name="id" label="id" fluid placeholder="id" />
									<EsvdInput
										name="username"
										label="Username"
										fluid
										icon={"user outline"}
										placeholder="Username"
									/>
								</div>
								<ErrorMessage
									name="error"
									render={() => (
										<Label
											style={{ marginBottom: 5, borderColor: "none" }}
											basic
											color="red"
											size="tiny"
											content={errors.error}
										/>
									)}
								/>
								<button
									disabled={!dirty || (dirty && !isValid)}
									className="ui button fluid btn btn-primary"
									type="submit"
									style={{
										backgroundColor: "#70bbfd",
										display: "block !important",
										height: "3rem",
									}}>
									<span className={`${isLoading ? "hide" : ""}`}>Save</span>
									<span
										className={`box loader-01 ${
											isLoading ? "" : "invisible"
										}`}></span>
								</button>
							</Form>
						)}
					</Formik>
				</Fragment>
			);
		}

		//a login user shouldn't see the signup page.
		//Therefore is a logged user access the signup route, go to home
		// history.push("/");
		// return null;
		else return <Redirect to="/" />;
	};

	const handleFormSubmit = (
		values: {
			id: string | null;
			displayName: string;
			organization: string;
			sector: string;
			sectorOther?: string;
			purposeOfAccess: string;
			purposeOfAccessOther?: string;
			email: string;
			username: string;
			password: string;
			error: null;
		},
		setErrors: Function
	) => {
		values.id = user?.id === null || user?.id === undefined ? null : user.id;
		const { sector, purposeOfAccess } = values;
		if (sector && sector.toLowerCase() !== "other") {
			values.sectorOther = "";
		}
		if (purposeOfAccess && purposeOfAccess.toLowerCase() !== "other") {
			values.purposeOfAccessOther = "";
		}
		setIsLoading(true);
		updateUser(values)
			.then((res: any) => {
				setIsLoading(false);
				props.setIsEdit && props.setIsEdit(false);
			})
			.catch((error: any) => {
				setErrors({ error: ErrorHandler(error) });
				setIsLoading(false);
			});
	};

	return <>{renderContent()}</>;
};
const validationSchema = Yup.object({
	displayName: Yup.string().required("Display name is required"),
	organization: Yup.string().required("Organization is required"),
	sector: Yup.string().required("Sector is required"),
	sectorOther: Yup.string().when(["sector"], {
		is: (sector: string) => sector && sector.toLowerCase() === "other",
		then: Yup.string().required("Sector description is required"),
	}),
	purposeOfAccess: Yup.string().required("Purpose of access is required"),
	purposeOfAccessOther: Yup.string().when(["purposeOfAccess"], {
		is: (purposeOfAccess: string) =>
			purposeOfAccess && purposeOfAccess.toLowerCase() === "other",
		then: Yup.string().required("Purpose description is required"),
	}),
});

export default AccountDetails;

/**
 * Validation for a field based on the value of another field
 * 
 * export const VALIDATION_SCHEMA = Yup.object().shape({
    'referenceMonth' : Yup.number().required(YUP_DEFAULT_ERROR_VALUE),
    'openingDate' : Yup.string().when(['referenceMonth', 'openingDate'], {
        is: (referenceMonth, openingDate) => 
             referenceMonth !== `${new Date(openingDate).getMonth()}`,
        then: Yup.string().required('Select right month')
    }),
}, [['referenceMonth', 'openingDate']])
 * 
 */
