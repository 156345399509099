import agent from "../../api/agent";
export const BENEFICIARYUNIT_CREATE = "BENEFICIARYUNIT_CREATE";
export const BENEFICIARYUNIT_EDIT = "BENEFICIARYUNIT_EDIT";
export const BENEFICIARYUNIT_DELETE = "BENEFICIARYUNIT_DELETE";
export const BENEFICIARYUNIT_FETCH = "BENEFICIARYUNIT_FETCH";
export const BENEFICIARYUNITS_FETCH = "BENEFICIARYUNITS_FETCH";

export const fetchBeneficiaryUnits = () => async (dispatch: any) => {
  const response = await agent.BeneficiaryUnits.list();

  dispatch({
    type: BENEFICIARYUNITS_FETCH,
    payload: response,
  });
};
