import { combineReducers } from "redux";
import {
  esvdCountReducer,
  esvdFilteredCountReducer,
  esvdFullCountReducer,
} from "./esvds/esvdCountReducer";
import esvdIsLoadingReducer from "./esvds/esvdIsLoadingReducer";
import esvdActivePageReducer from "./esvds/esvdActivePageReducer";
import esvdAppendedDataReducer from "./esvds/esvdAppendedDataReducer";
import esvdReducer from "./esvds/esvdReducer";
import esvdBackgroundIsLoadingReducer from "./esvds/esvdBackgroundIsLoadingReducer";
import { modalReducer } from "../layouts/EsvdModal";
import ecosystemServiceReducer from "./ecosystemServiceReducer";
// import esvdQueryFiltersReducer from "./esvds/esvdQueryFiltersReducer";
import esvdAppendedFilteredDataReducer from "./esvds/esvdAppendedFilteredDataReducer";
import accountReducer, {
  isRefreshReducer,
  tokenReducer,
} from "./accountReducer";
import biomeEcosystemsReducer from "./biomeEcosystemsReducer";
import esvdSummaryStatsReducer from "./esvdStats/esvdStatsReducer";
import countryReducer from "./countryReducer";
import protectionStatusReducer from "./protectionStatusReducer";
import continentsReducer from "./continentsReducer";
import studiesReducer, {
  isEditorReducer,
  isLoadingStudiesReducer,
} from "./studyReducer";
import studyValuationReducer, {
  selectedValuationReducer,
} from "./studyValuationReducer";
import valuationMethodReducer from "./valuationMethodReducer";

import beneficiaryUnitReducer from "./others/beneficiaryUnitReducer";
import currencyReducer from "./others/currencyReducer";
import scaleOfStudySiteReducer from "./others/scaleOfStudySiteReducer";
import siteConditionReducer from "./others/siteConditionReducer";
import studyLocationReducer from "./others/studyLocationReducer";
import temporalUnitReducer from "./others/temporalUnitReducer";
import typeOfBeneficiaryReducer from "./others/typeOfBeneficiaryReducer";
import unitOfMeasureReducer from "./others/unitOfMeasureReducer";
import valuedChangeReducer from "./others/valuedChangeReducer";
import cicesReducer from "./cicesReducer";
import ecosystemConditionsReducer from "./ecosystemConditionsReducer";
import filterQueryReducer from "./filterQueryReducer";
import biomev2ecosystemv2ecozonesReducer from "./biomev2ecosystemv2ecozonesReducer";
import seeaReducer from "./seeaReducer";
import { RESET_STATE } from "../shared/Literals";


const appReducer = combineReducers({
  // form: formReducer,
  esvds: esvdReducer,
  esvdCount: esvdCountReducer,
  esvdFullCount: esvdFullCountReducer,
  esvdIsLoading: esvdIsLoadingReducer,
  esvdActivePage: esvdActivePageReducer,
  esvdAppendedData: esvdAppendedDataReducer,
  esvdBackgroundIsLoading: esvdBackgroundIsLoadingReducer,
  modalData: modalReducer,
  ecosystemServices: ecosystemServiceReducer,
  seeas: seeaReducer,
  biomeEcosystems: biomeEcosystemsReducer,
  biomeV2EcosystemV2Ecozones: biomev2ecosystemv2ecozonesReducer,
  // esvdQueryFilters: esvdQueryFiltersReducer,
  esvdAppendedFilteredData: esvdAppendedFilteredDataReducer,
  user: accountReducer,
  token: tokenReducer,
  esvdSummaryStats: esvdSummaryStatsReducer,
  countries: countryReducer,
  protectionStatuses: protectionStatusReducer,
  continents: continentsReducer,
  studies: studiesReducer,
  valuations: studyValuationReducer,
  valuationMethods: valuationMethodReducer,

  beneficiaryUnits: beneficiaryUnitReducer,
  currencies: currencyReducer,
  scaleOfStudySites: scaleOfStudySiteReducer,
  siteConditions: siteConditionReducer,
  studyLocations: studyLocationReducer,
  temporalUnits: temporalUnitReducer,
  typeOfBeneficiaries: typeOfBeneficiaryReducer,
  unitsOfMeasure: unitOfMeasureReducer,
  valuedChanges: valuedChangeReducer,
  ciceses: cicesReducer,
  ecosystemConditions: ecosystemConditionsReducer,

  esvdFilteredCount: esvdFilteredCountReducer,
  isEditor: isEditorReducer,
  isLoadingStudies: isLoadingStudiesReducer,

  selectedValuation: selectedValuationReducer,
  filterQueries: filterQueryReducer,
  // currentUserChecked: currentUserCheckedReducer,
  isRefresh: isRefreshReducer,
});

const rootReducer = (state: any = {}, action: any) => {
  if (action.type === RESET_STATE) {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
