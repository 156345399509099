import agent from "../../api/agent";
export const CURRENCY_CREATE = "CURRENCY_CREATE";
export const CURRENCY_EDIT = "CURRENCY_EDIT";
export const CURRENCY_DELETE = "CURRENCY_DELETE";
export const CURRENCY_FETCH = "CURRENCY_FETCH";
export const CURRENCIES_FETCH = "CURRENCIES_FETCH";

export const fetchCurrencies = () => async (dispatch: any) => {
  const response = await agent.Currencies.list();

  dispatch({
    type: CURRENCIES_FETCH,
    payload: response,
  });
};
