import React, { useEffect } from "react";
// import ReactDOM from "react-dom";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { useEsvdValuation } from "../../providers/EsvdValuationProvider";
// import { Esvd } from "../../models/esvd";
import { Card, Divider } from "semantic-ui-react";
import { useSidebar } from "../../hooks/SidebarHook";
import { standardValueFormat } from "../../EsvdFunctions";

//getEsvdMapMarkerData
const EsvdMapMarker = ({ markerData }: { markerData: any }) => {
	const { getEsvdMapMarkerData } = useEsvdValuation();
	const {
		visible,
		triggerElementId,
		openSidebar,
		closeSidebar,
		// toggleSidebar,
		// changeContent,
	} = useSidebar();

	const [showInfoWindow, setShowInfoWindow] = React.useState<boolean>(false);
	// const [infoWindowHover, setInfoWindowHover] = React.useState<boolean>(false);

	// const [esvds, setEsvds] = React.useState<Esvd[]>([]);

	const handleMouseOver = async (e: any) => {
		setShowInfoWindow(true);
	};

	const handleMouseExit = (e: any) => {
		setShowInfoWindow(false);
	};

	const handleMouseClick = async (e: any) => {
		// toggleSidebar();
		if (visible && triggerElementId === markerData.valueIds[0]) {
			closeSidebar(markerData.valueIds[0]);
		} else {
			const esv = await getEsvdMapMarkerData(markerData.valueIds);
			openSidebar(tippers(esv), markerData.valueIds[0]);
		}
	};
	//https://medium.com/free-code-camp/how-to-change-javascript-google-map-marker-color-8a72131d1207
	//https://sites.google.com/site/gmapsdevelopment/

	// const getLocations = (locations:any) => {
	// 	markerData.locations.map((loc:string)=>)
	// }
	return (
		<MarkerF
			position={markerData.position}
			onMouseOver={(e) => handleMouseOver(e)}
			onClick={(e) => handleMouseClick(e)}
			onMouseOut={(e) => handleMouseExit(e)}
			icon="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
			// label={
			// 	markerData.valueIds.length > 1 ? `${markerData.valueIds.length}` : ""
			// }
		>
			{/* {markerData.valueIds.length > 1 && (
				<span>{markerData.valueIds.length}</span>
			)} */}
			{showInfoWindow && (
				<InfoWindowF position={markerData.position}>
					<>
						<Card.Description
							content={`Valuations: ${markerData.valueIds.length}`}
						/>
						<Card.Description
							content={`Location: ${Array.from(markerData.locations).join(
								"; "
							)}`}
						/>
						<Card.Description
							content={`Latitude: ${markerData.position.lat}`}
						/>
						<Card.Description
							content={`Longitude: ${markerData.position.lng}`}
						/>
					</>
				</InfoWindowF>
			)}
		</MarkerF>
	);

	function tippers(esvds: any[]) {
		return (
			<div>
				<Card.Content>
					<Card.Description
						content={`${
							esvds.length > 1
								? esvds.length + " valuations"
								: esvds.length + " valuation"
						} `}
					/>
				</Card.Content>
				<Divider />
				{esvds.map((esvd, i) => (
					<Card.Content>
						<Card.Description
							content={`${
								esvds.length > 1 ? i + 1 + ". Location" : "Location"
							}: ${esvd?.studyLocation || ""}`}
						/>
						{esvd.siteAreaInHectares && (
							<Card.Description
								content={`Site Area (ha): ${esvd?.siteAreaInHectares || ""}`}
							/>
						)}
						<Card.Description
							content={`Biomes: ${esvd?.esvdFlatBiomeV2s || ""}`}
						/>
						<Card.Description
							content={`Ecozones: ${esvd?.esvdFlatEcozones || ""}`}
						/>
						<Card.Description
							content={`Ecosystem: ${esvd?.esvdFlatEcosystemV2s || ""}`}
						/>
						<Card.Description
							content={`TEEB Ecosystem Services: ${
								esvd?.esvdFlatEcosystemServices || ""
							}`}
						/>
						<Card.Description
							content={`Value 2020 $/ha/yr: ${
								!esvd?.intPerHectarePerYear
									? ""
									: standardValueFormat(esvd?.intPerHectarePerYear)
							}`}
						/>
						<Card.Description content={`StudyID: ${esvd.studyId || ""} `} />
						{esvds.length > 1 && i !== esvds.length - 1 && <Divider />}
					</Card.Content>
				))}
			</div>
		); //Site Area (ha):
	}
};

export default React.memo(EsvdMapMarker);
