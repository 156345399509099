import React, { useState } from "react";
import { Header, Modal } from "semantic-ui-react";

const ModalContext = React.createContext<any>(null);

// The ActiveMenuProvider is responsible for ActiveMenu management
const ModalProvider = ({ children }: { children: any }) => {
	const [isShowing, setIsShowing] = useState(false);
	const [content, setContent] = useState<any>();
	// const [header, setHeader] = useState<string | null>();
	// const [actions, setActions] = useState<any>();

	function openModal(payload: any) {
		setContent(payload);
		setIsShowing(true);
	}

	function close() {
		setIsShowing(false);
		setContent(null);
	}

	// function updateModalContent(text: string) {
	// 	setContent(text);
	// }

	// {
	// 	isShowing,
	// 	hide,
	// 	content,
	// }: {
	// 	isShowing?: boolean;
	// 	hide?: any;
	// 	content?: any;
	// }
	const ModalDialog = () =>
		isShowing ? (
			// ReactDOM.createPortal(
			// 		<React.Fragment>
			<Modal
				className="scroll-from-top modal-content "
				size="small"
				centered={false}
				open={isShowing}
				onClose={() => {
					close();
				}}
				onOpen={openModal}>
				<Modal.Content style={{ height: "16em" }}>
					<Header className="pos_fixed">{content.header}</Header>
					{content.main}

					{/* <Modal.Actions> */}
					<div className="modal__footer">
						{(content.actions && content.actions()) || (
							<button
								className="btn btn-warning btn-sm"
								onClick={() => {
									close();
								}}>
								Close
							</button>
						)}
					</div>
				</Modal.Content>
			</Modal>
		) : // 		</React.Fragment>,
		// 		document.body
		//   )
		null;

	return (
		<ModalContext.Provider
			value={{
				ModalDialog,
				isShowing,
				openModal,
				close,
			}}>
			{children}
		</ModalContext.Provider>
	);
};
const useModal = () => {
	const modal = React.useContext(ModalContext);
	if (modal == null) {
		throw new Error("useModal() called outside of a modal hook?");
	}
	return modal;
};

export { ModalProvider, useModal };
