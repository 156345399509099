import _ from "lodash";

import {
  VALUATION_CREATE,
  VALUATION_DELETE,
  VALUATION_EDIT,
  VALUATION_FETCH,
  USER_VALUATIONS_FETCH,
} from "../actions/valuationsActions";
import { initValuation } from "../shared/Literals";

const studyValuationReducer = (state = {}, action: any) => {
  switch (action.type) {
    case VALUATION_CREATE:
      return { ...state, [action.payload.id]: action.payload };
    case VALUATION_EDIT:
      return { ...state, [action.payload.id]: action.payload };
    case VALUATION_DELETE:
      return _.omit(state, action.payload);
    case USER_VALUATIONS_FETCH:
      return { ..._.mapKeys(action.payload, "id") };
    default:
      return state;
  }
};
export default studyValuationReducer;

export const selectedValuationReducer = (
  state = initValuation,
  action: any
) => {
  switch (action.type) {
    case VALUATION_FETCH:
      return action.payload;
    default:
      return state;
  }
};
