import React from "react";
import { Button, Card, Item } from "semantic-ui-react";
import { COMPANY_EMAIL } from "../../../shared/Literals";
import { Link } from "react-router-dom";
// import MyPDF from "../../../files/explanatory_variable_definitions_and_data_sources.pdf";
// import MyPDF from "../images/explanatory_variable_definitions_and_data_sources.pdf";

const Helpers = {
	httpRequest: (url: string, method: string, payload: any, headers: any) => {
		const conf: any = {
			method,
			headers: {
				"Content-Type": "application/pdf",
			},
		};
		if (method.toLowerCase() === "post" && payload && payload.length > 0) {
			conf.body = JSON.stringify(payload);
		}
		return fetch(url, conf).then((response: any) => {
			if (response.ok) {
				let contentdata = response;
				if (
					response.headers.get("Content-Type").indexOf("application/pdf") > -1
				) {
					contentdata = response.json();
				}
				return contentdata;
			}
			return Promise.reject(response);
		});
	},
};
// export Helpers;

const VttCalculateReadMePanel = () => {
	return (
		<Card fluid>
			<Card.Content>
				<Item>
					<Item.Content>
						<Item.Header>
							<h4>Read Me</h4>
						</Item.Header>
					</Item.Content>
				</Item>
			</Card.Content>
			<Card.Content>
				<p>
					The value transfer tool can be used to estimate ecosystem service
					values that reflect site characteristics entered by the user.
					Currently this tool is available for a limited set of biomes and will
					be expanded to include other biomes in the near future.
				</p>
				<p>To use the tool:</p>
				<ol>
					<li>Select the biome of interest from the drop-down list.</li>
					<li>
						Select the ecosystem service category for which you want to estimate
						a value.{" "}
					</li>
					<li>
						Enter data for each of the variables describing your study site. The
						units in which each variable is measured are provided under “Short
						explanation”. The range of feasible values for each explanatory
						variable is limited to the range observed for the study sites of the
						selected biome contained in the ESVD. Further information on the
						definitions and data sources for the explanatory variables included
						in the value functions can be found{" "}
						<a
							// href="https://drive.google.com/file/d/1I6FCJ_ktUqxjowxm8TzcAkPA5FJlFiak/view"
							href="https://www.esvd.info/value-transfer"
							target="_blank"
							rel="noopener noreferrer">
							here
						</a>
						.{" "}
						{/* <a
								href={undefined}
								onClick={onButtonClick}
								// download="explanatory_variable_definitions_and_data_sources.pdf"
							>
								{" "}
								here
							</a> */}
						{/* <button onClick={onButtonClick}>Download PDF</button>{" "}
							<Button as="a" onClick={onButtonClick}>
								Download PDF
							</Button> */}
					</li>
					<li>
						In the case that data for an explanatory variable is unavailable for
						your study site, it is possible to use a default value (select the
						checkbox on the right). The default value is the mean value for the
						variable across the study sites of the selected biome contained in
						the ESVD.
					</li>
					<li>
						All fields are required to estimate an ecosystem service value.
					</li>
					<li>
						Click on the “Calculate” button to estimate a value, which displays
						in the “Result” box. Values are reported in International
						dollars/ha/year at 2020 price levels.
					</li>
				</ol>
				<p>
					Note that the estimated value provides an indication of the order of
					magnitude of the ecosystem service value at the study site controlling
					for a set of relevant explanatory factors. It does not necessarily
					reflect all relevant determinants of ecosystem service value at the
					study site and the estimated value may differ from the results of
					site-specific primary valuation studies. Estimated values should only
					be used in relevant contexts and validated using a broader set of
					decision-making tools. For advice and collaboration regarding the use
					of value transfers for decision making, please get in touch via{" "}
					<a href={`mailto:${COMPANY_EMAIL}`}>esvd@fsd.nl</a>
				</p>
				<p>
					The value transfer tool is based on a set of ecosystem service value
					functions estimated through meta-analyses of the data contained in the
					ESVD. See{" "}
					<a
						href="https://link.springer.com/article/10.1007/s10640-011-9535-1"
						target="_blank"
						rel="noopener noreferrer">
						Brander et al. (2012)
					</a>{" "}
					for an explanation of the approach. Publications documenting each
					value function are forthcoming.
				</p>
			</Card.Content>
		</Card>
	);
};

export default VttCalculateReadMePanel;
