import { Field } from "formik";
import React, { useEffect } from "react";
// import * as Yup from "yup";
import { purposeOfAccessList, sectorsList } from "../../shared/Literals";
import { SelectDropdown } from "../../shared/SingleSelect";
import EsvdInputInline from "../../shared/InputInline";

const UserAdditionalInfo = (props: any) => {
	const { user } = props;
	const [sectorOtherShow, setSectorOtherShow] = React.useState<boolean>(false);
	const [purposeOtherShow, setPurposeOtherShow] =
		React.useState<boolean>(false);
	const [selectedSector, setSelectedSector] = React.useState<string>(
		user !== null && user !== undefined ? user?.sector : ""
	);
	const [selectedPurpose, setSelectedPurpose] = React.useState<string>(
		user !== null && user !== undefined ? user?.purposeOfAccess : ""
	);

	useEffect(() => {
		if (user) {
			const { sector, purposeOfAccess } = user;
			sector && setSectorOtherShow(sector.toLowerCase() === "other");
			purposeOfAccess &&
				setPurposeOtherShow(purposeOfAccess.toLowerCase() === "other");
		}
	}, [user]);
	const setSector = (value: string) => {
		setSelectedSector(value);
		value && setSectorOtherShow(value.toLowerCase() === "other");
	};
	const setPurposeOfAccess = (value: string) => {
		setSelectedPurpose(value);
		value && setPurposeOtherShow(value.toLowerCase() === "other");
	};
	const renderContent = () => {
		return (
			<>
				<EsvdInputInline
					name="organization"
					label="Organization"
					fluid
					placeholder="Organization"
				/>
				<Field
					name="sector"
					label="Sector"
					options={sectorsList}
					component={SelectDropdown}
					isMulti={false}
					value={selectedSector}
					placeholder="Sector"
					onChange={setSector}
				/>
				{sectorOtherShow ? (
					<EsvdInputInline name="sectorOther" label="" fluid placeholder="" />
				) : (
					<div
						style={{
							display: "none",
						}}>
						<EsvdInputInline name="sectorOther" label="" fluid placeholder="" />
					</div>
				)}
				<Field
					name="purposeOfAccess"
					label="Purpose of access"
					options={purposeOfAccessList}
					component={SelectDropdown}
					isMulti={false}
					value={selectedPurpose}
					placeholder="Purpose of access"
					onChange={setPurposeOfAccess}
				/>
				{purposeOtherShow ? (
					<EsvdInputInline
						name="purposeOfAccessOther"
						label=""
						fluid
						placeholder=""
					/>
				) : (
					<div
						style={{
							display: "none",
						}}>
						<EsvdInputInline
							name="purposeOfAccessOther"
							label=""
							fluid
							placeholder=""
						/>
					</div>
				)}
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default UserAdditionalInfo;
