import agent from "../../api/agent";
export const SCALEOFSTUDYSITE_CREATE = "SCALEOFSTUDYSITE_CREATE";
export const SCALEOFSTUDYSITE_EDIT = "SCALEOFSTUDYSITE_EDIT";
export const SCALEOFSTUDYSITE_DELETE = "SCALEOFSTUDYSITE_DELETE";
export const SCALEOFSTUDYSITE_FETCH = "SCALEOFSTUDYSITE_FETCH";
export const SCALEOFSTUDYSITES_FETCH = "SCALEOFSTUDYSITES_FETCH";

export const fetchScaleOfStudySites = () => async (dispatch: any) => {
  const response = await agent.ScaleOfStudySites.list();

  dispatch({
    type: SCALEOFSTUDYSITES_FETCH,
    payload: response,
  });
};
