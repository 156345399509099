import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { User } from "../models/user";
// import { connect } from "react-redux";
import { useAuth } from "../providers/AuthProvider";

interface IProps {
	// user: User;
	yes: Function;
	no?: Function;
	resource: any;
	action: any;
	location?: string;
	// currentUserChecked: boolean;
	// isRefresh: boolean;
}

const Can = (props: IProps) => {
	const { user, isRefresh, fetchCurrentUser } = useAuth();
	const checkResourcePermission = (user: User) => {
		const { yes, no, resource } = props;

		const permission = user.resourceAccess?.find(
			(x) => x.resource === resource
		);
		return !permission || !permission.granted
			? no
				? no()
				: () => <Redirect to="/" />
			: yes();
	};

	useEffect(() => {
		fetchCurrentUser();
	}, []);

	if (
		// !currentUserChecked &&
		!user &&
		window.location.pathname !== "/" &&
		!isRefresh //for refresh, we skip showing the login screen
	) {
		if (user === undefined) {
			return null;
		}
		return (
			<Redirect
				to={
					!window.location.pathname.includes("/login") //this check is necessary else "/login" is prepended to the url on every refresh
						? `/login${window.location.pathname}`
						: window.location.pathname
				}
			/>
		);
	}

	if (!user) {
		if (isRefresh) {
			return null;
		}
		return <Redirect to={`/login${window.location.pathname}`} />;
	}

	return checkResourcePermission(user);
	// return (
	// 	<>
	// 		{!user ? (
	// 			<Redirect to={`/login${window.location.pathname}`} />
	// 		) : (
	// 			checkResourcePermission(user)
	// 		)}
	// 	</>
	// );
};
// }

export default Can; // connect(mapStateToProps, {})(Can);
