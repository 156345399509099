import agent from "../api/agent";
import { Valuation } from "../models/Valuation";
import { initValuation } from "../shared/Literals";
export const VALUATION_CREATE = "VALUATION_CREATE";
export const VALUATION_EDIT = "VALUATION_EDIT";
export const VALUATION_DELETE = "VALUATION_DELETE";
export const VALUATION_FETCH = "VALUATION_FETCH";
export const VALUATIONS_FETCH = "VALUATIONS_FETCH";
export const USER_VALUATIONS_FETCH = "USER_VALUATIONS_FETCH";

export const fetchStudyValuations =
  (studyid: number | null) => async (dispatch: any) => {
    try {
      //reset the valuation list
      dispatch({
        type: USER_VALUATIONS_FETCH,
        payload: [],
      });
      const response = !studyid
        ? []
        : await agent.StudyValutations.studyValuations(studyid);

      dispatch({
        type: USER_VALUATIONS_FETCH,
        payload: response,
      });
    } catch (error: any) {
      throw error.response ? error.response.statusText : error.message;
    }
  };

export const fetchStudyValuation =
  (valuation: Valuation) => async (dispatch: any) => {
    try {
      const response =
        !valuation.id || valuation.id === 0
          ? initValuation
          : await agent.StudyValutations.details(valuation.id);

      dispatch({
        type: VALUATION_FETCH,
        payload: response,
      });
    } catch (error: any) {
      throw error.response ? error.response.statusText : error.message;
    }
  };

export const setAsCurrentValuation =
  (valuation: Valuation) => async (dispatch: any) => {
    dispatch({
      type: VALUATION_FETCH,
      payload: valuation,
    });
  };

export const saveValuation =
  (valuation: Valuation) => async (dispatch: any) => {
    try {
      if (valuation.id === null) {
        valuation.id = 0;
      }
      const response =
        !valuation.id || valuation.id === 0
          ? await agent.StudyValutations.create(valuation)
          : await agent.StudyValutations.update(valuation);

      dispatch({
        type:
          !valuation.id || valuation.id === 0
            ? VALUATION_CREATE
            : VALUATION_EDIT,
        payload: response,
      });
    } catch (error: any) {
      throw error.response ? error.response.statusText : error.message;
    }
  };

export const deleteValuation = (id: number) => async (dispatch: any) => {
  try {
    await agent.StudyValutations.delete(id);
    dispatch({
      type: VALUATION_DELETE,
      payload: id,
    });
  } catch (error: any) {
    throw error.response ? error.response.statusText : error.message;
  }
};
