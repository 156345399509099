import React from "react";
import { Card } from "semantic-ui-react";
import { Redirect } from "react-router";
import { useAuth } from "../../providers/AuthProvider";

const UserProfileDetails = () => {
	const { user } = useAuth();

	const renderContent = () => {
		if (user) {
			return (
				<Card.Content>
					<span>
						<strong>Display name: </strong>
						{user && user.displayName}
					</span>
					<br />
					<span>
						<strong>Username: </strong>
						{user && user.userName}
					</span>
					<br />
					<span>
						<strong>Email: </strong>
						{user && user.email}
					</span>
					<br />
					<span>
						<strong>Organization: </strong>
						{user && user.organization}
					</span>
					<br />
					<span>
						<strong>Sector: </strong>
						{user && user.sector}
						{user && user.sector === "Other" && `: ${user.sectorOther}`}
					</span>
					<br />
					<span>
						<strong>Purpose of access: </strong>
						{user && user.purposeOfAccess}
						{user &&
							user.purposeOfAccess === "Other" &&
							`: ${user.purposeOfAccessOther}`}
					</span>
					<br />
				</Card.Content>
			);
		} else return <Redirect to="/" />;
	};

	return <>{renderContent()}</>;
};

export default UserProfileDetails;
