import { Component } from "react";
import { Grid, Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { actions, initStudy, resources } from "../../shared/Literals";
import { Study } from "../../models/Study";
import Valuations from "../studies/Valuations";
import StudyList from "../studies/StudyList";
import StudyEntry from "../studies/StudyEntry";
import StudyFilterPanel from "./StudyFilterPanel";
import { resetStudies, setIsEditor } from "../../actions/studyActions";
import Can from "../../accessControls/Can";
import EditorReadMe from "./EditorReadMe";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";

const { Media, MediaContextProvider } = MediaLayout;

interface IProps {
  resetStudies: Function;
  setIsEditor: Function;
}

interface IState {
  selectedStudy: Study;
}
class EditorStudyBoard extends Component<IProps> {
  state: IState = { selectedStudy: initStudy };

  componentDidMount() {
    this.props.setIsEditor(true);
    this.props.resetStudies();
    this.setSelectedStudy(initStudy);
  }

  setSelectedStudy = (study: Study) => {
    this.setState(() => ({ selectedStudy: study }));
  };

  renderContent = () => {
    const { selectedStudy } = this.state;
    return (
      <MediaContextProvider>
        <Media at="mobile" className="page-content">
          <EditorReadMe />
          <StudyFilterPanel resetSearch={this.setSelectedStudy} />
          <StudyList
            headerText="Search Results"
            onSelectionChanged={this.setSelectedStudy}
            clearStudy={this.setSelectedStudy}
          />
          <StudyEntry
            headerText={`Edit study ${selectedStudy.studyId} `}
            study={selectedStudy}
            clearStudy={this.setSelectedStudy}
            onStudyUpdated={(study: Study) => this.setSelectedStudy(study)}
          />
          <Valuations selectedStudy={selectedStudy} />
        </Media>
        <Media greaterThan="mobile">
          <Container fluid className="top-container">
            <Grid columns={2} className="page-content" stackable>
              <Grid.Row>
                <Grid.Column width={12}>
                  <StudyEntry
                    headerText={`Edit study ${selectedStudy.studyId} `}
                    study={selectedStudy}
                    clearStudy={this.setSelectedStudy}
                    onStudyUpdated={(study: Study) =>
                      this.setSelectedStudy(study)
                    }
                  />
                  <Valuations selectedStudy={selectedStudy} />
                </Grid.Column>
                <Grid.Column width={4}>
                  <EditorReadMe />
                  <StudyFilterPanel resetSearch={this.setSelectedStudy} />
                  <StudyList
                    headerText="Search Results"
                    onSelectionChanged={this.setSelectedStudy}
                    clearStudy={this.setSelectedStudy}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Media>
      </MediaContextProvider>
    );
  };
  render() {
    return (
      <Can
        action={actions.read}
        resource={resources.studyEditor.name}
        yes={() => this.renderContent()}
        no={() => <Redirect to={"/"} />}
      />
    ); //<Fragment>{this.renderContent()}</Fragment>;
  }
}

export default connect(null, { resetStudies, setIsEditor })(EditorStudyBoard);
