import agent from "../api/agent";
import { defaultEsvdParams, EsvdQueryParams } from "../models/EsvdQueryFilters";
export const ESVD_CREATE = "ESVD_CREATE";
export const ESVD_EDIT = "ESVD_EDIT";
export const ESVD_DELETE = "ESVD_DELETE";
export const ESVD_FETCH = "ESVD_FETCH";
export const ESVDS_FETCH = "ESVDS_FETCH";
export const ESVDS_FETCH_APPEND = "ESVDS_FETCH_APPEND";

export const RESET_ESVDS = "RESET_ESVDS";
export const RESET_ESVDS_APPENDED_FILTERED_DATA =
  "RESET_ESVDS_APPENDED_FILTERED_DATA";

export const ESVDS_IS_LOADING = "ESVDS_IS_LOADING";
export const ESVDS_IS_BACKGROUND_LOADING = "ESVDS_IS_BACKGROUND_LOADING";
export const ESVDS_ACTIVE_PAGE = "ESVDS_ACTIVE_PAGE";
export const ESVDS_QUERY_FILTERS = "ESVDS_QUERY_FILTERS";
export const ESVDS_FETCH_FILTERED_APPEND = "ESVDS_FETCH_FILTERED_APPEND";
export const ESVDS_FILTERED_IS_BACKGROUND_LOADING =
  "ESVDS_FILTERED_IS_BACKGROUND_LOADING";

//action to reset state
export const resetEsvds = () => (dispatch: any) => {
  dispatch({ type: RESET_ESVDS });
};

//action to reset state
export const resetEsvdAppendedFilteredData = () => (dispatch: any) => {
  dispatch({ type: RESET_ESVDS_APPENDED_FILTERED_DATA });
};

/**
 * use this to get the full dataset in chunks specified by the limit arugument and append to full list
 * @param args
 * @returns
 */

export const fetchEsvds =
  (filters: EsvdQueryParams) => async (dispatch: any) => {
    try {
      const response = await agent.Esvds.list(filters);
      dispatch({
        type: ESVDS_FETCH,
        payload: response,
      });
    } catch (error: any) {
      throw error.response ? error.response.statusText : error.message;
    }
  };

export const showEsvdIsLoading =
  (isLoading: boolean) => async (dispatch: any) => {
    dispatch({
      type: ESVDS_IS_LOADING,
      payload: isLoading,
    });
  };

export const setActivePage =
  (page: string | number) => async (dispatch: any) => {
    dispatch({
      type: ESVDS_ACTIVE_PAGE,
      payload: page,
    });
  };

export const setEsvdQueryFilters =
  (filters: EsvdQueryParams = { ...defaultEsvdParams}) =>
  async (dispatch: any) => {
    dispatch({
      type: ESVDS_QUERY_FILTERS,
      payload: filters,
    });
  };
