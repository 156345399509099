import React, { useContext, useEffect, useState } from "react";
import agent from "../api/agent";
import { trimOutliers } from "../components/esvdStats/processStatsData";
import {
	bodyRowsFlattened,
	buildTreeTypeQueryList,
	// buildTreeTypeQueryListCices,
	displayBodyRowsObjects,
	displayHeaderTransform,
	// getMonthNameShort,
	setDisplayMultiColumnMaxLens,
	setMultiColumnMaxLenFlattened,
	setMultiColumnMaxLensSummaryStats,
	summaryStatsRowsObjects,
} from "../EsvdFunctions";
import {
	ColumnStructure,
	DisplayColumnStructure,
	SummaryStatsColumnStructure,
} from "../models/columnStructure";
import {
	ColumnTemplate,
	DisplayColumnTemplate,
	SummaryStatsColumnTemplate,
} from "../models/columnTemplate";
import { EsvdArray } from "../models/esvd";
import { EsvdMap } from "../models/esvdMap";
import { defaultEsvdParams, EsvdQueryParams } from "../models/EsvdQueryFilters";
// import { EsvdSearchParams } from "../models/EsvdQueryFilters";
// import { EsvdFilters } from "../models/FilterQuery";
// import filterCheck from "../shared/FilterCheck";

interface IValue {
	getEsvds: Function;
	esvds: EsvdArray;
	getEsvdsCount: Function;
	esvdCount: number;
	getEsvdTotalCount: Function;
	esvdTotalCount: number;

	getEsvdMapData: Function;
	mapData: EsvdMap[];
	getEsvdMapCount: Function;
	esvdMapCount: number;
	getEsvdTotalMapCount: Function;
	esvdTotalMapCount: number;

	clearEsvds: Function;
	saveQueryParams: Function;

	getPageData: Function;
	setActivePage: Function;
	activePage: number;

	setShowMap: Function;
	showMap: boolean;
	updateQueryParams: Function;
	esvdQueryParams: EsvdQueryParams;
	getEsvdsSummaryStats: Function;
	esvdsSummaryStats: SummaryStatsColumnStructure[];
	isMapBusy?: boolean | undefined;
	getEsvdMapMarkerData: Function;
	setIsMapBusy: Function;

	queryParamsForSave: any;
	setQueryParamsForSave: Function;
}

const EsvdValuationContext = React.createContext<IValue | null>(null);

// The EsvdValuationProvider is responsible for Esvd Valuation management
const EsvdValuationProvider = ({ children }: { children: any }) => {
	const [esvds, setEsvds] = useState<EsvdArray>({
		esvdsAllCols: [],
		headerArray: [],
		bodyArray: [],
	});

	const [esvdCount, setEsvdCount] = useState<number>(0);
	const [esvdTotalCount, setEsvdTotalCount] = useState<number>(0);

	const [esvdMapCount, setEsvdMapCount] = useState<number>(0);
	const [esvdTotalMapCount, setEsvdTotalMapCount] = useState<number>(0);

	const [esvdsSummaryStats, setEsvdsSummaryStats] = useState<
		SummaryStatsColumnStructure[]
	>([]);
	const [esvdQueryParams, setEsvdQueryParams] = useState<EsvdQueryParams>({
		...defaultEsvdParams,
	});
	const [queryParamsForSave, setQueryParamsForSave] = useState<any>({});

	const [showMap, setShowMap] = useState<boolean>(false);
	const [activePage, setActivePage] = useState<number>(0);
	const [isMapBusy, setIsMapBusy] = React.useState<boolean | undefined>(false);

	useState<boolean>(false);

	const updateQueryParams = (query: EsvdQueryParams) => {
		// if (!filterCheck(query)) {
		clearEsvds();
		// }
		setEsvdQueryParams({ ...query, saved: false });
	};

	const getEsvds = async (query: EsvdQueryParams) => {
		try {
			const count = await agent.Esvds.esvdCount(query);
			setEsvdCount(count);

			const response = await agent.Esvds.list(query);
			const cols: DisplayColumnStructure = setDisplayMultiColumnMaxLens(
				response,
				DisplayColumnTemplate
			);
			const heads = displayHeaderTransform(response, cols);
			const body = displayBodyRowsObjects(response, cols);

			const allCols: ColumnStructure = setMultiColumnMaxLenFlattened(
				response,
				ColumnTemplate
			);
			const items = bodyRowsFlattened(response, allCols);

			// return { esvdsAllCols: items, headerArray: heads, bodyArray: body };
			setEsvds({ esvdsAllCols: items, headerArray: heads, bodyArray: body });

			// return response;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getEsvdsCount = async (query: EsvdQueryParams) => {
		try {
			const response = await agent.Esvds.esvdCount(query);
			setEsvdCount(response);
			// return response;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getEsvdTotalCount = async () => {
		try {
			const response = await agent.Esvds.esvdTotalCount();
			setEsvdTotalCount(response);
			// return response;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const clearEsvds = () => {
		setEsvds({ esvdsAllCols: [], headerArray: [], bodyArray: [] });
		setEsvdCount(0);
		setEsvdsSummaryStats([]);
		setEsvdQueryParams({ ...defaultEsvdParams });
		setEsvdMapCount(0);
		setEsvdTotalMapCount(0);
		setMapData([]);
		setIsMapBusy(false);
		// setEsvdTotalCount(0);
	};

	const saveQueryParams = async () => {
		try {
			let params: any = {};
			//remove empty or null properties
			for (const [key, value] of Object.entries(queryParamsForSave)) {
				if (
					(Array.isArray(value) && value.length > 0) ||
					(typeof value === "string" &&
						value.trim() !== "" &&
						value !== null) ||
					(typeof value === "number" && value !== null)
				) {
					params = { ...params, [key]: value };
				}
			}

			//do not save empty filters
			if (Object.keys(params).length === 0) {
				return;
			}

			params.resultsCount = await agent.Esvds.esvdCount(esvdQueryParams);

			await agent.FilterQueries.create({
				filterString: JSON.stringify(params),
			});
			setEsvdQueryParams({ ...esvdQueryParams, saved: true });
			// return response;
		} catch (error: any) {
			//throw error.response ? error.response.statusText : error.message;
		}
	};

	const getEsvdsSummaryStats = async (query: EsvdQueryParams) => {
		try {
			const dat = await agent.EsvdStats.summaryStats(query);

			// const trimmedOutliers = dat.length > 40 ? trimOutliers(dat) : dat;

			const cols: SummaryStatsColumnStructure =
				setMultiColumnMaxLensSummaryStats(dat, SummaryStatsColumnTemplate);
			// const heads = headerTransform(action.payload, cols);
			const stats = summaryStatsRowsObjects(dat, cols);

			setEsvdsSummaryStats(stats);

			// return response;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getPageData = async (
		query: EsvdQueryParams,
		isFreshFilter: boolean,
		activePage: number,
		lists: any
	) => {
		const page = Number(isFreshFilter ? 1 : activePage);

		// const { queryFilters } = this.state;
		const {
			params: { ecosystemServices, biomeV2EcosystemV2Ecozones, ciceses },
		} = query;

		query.params.offset = page > 1 ? page - 1 : 0;

		if (ecosystemServices && ecosystemServices.length > 0) {
			query.params.ecosystemServices = buildTreeTypeQueryList(
				ecosystemServices,
				lists.ecosystemServices
			);
		}

		if (biomeV2EcosystemV2Ecozones && biomeV2EcosystemV2Ecozones.length > 0) {
			query.params.biomeV2EcosystemV2Ecozones = buildTreeTypeQueryList(
				biomeV2EcosystemV2Ecozones,
				lists.props.biomeV2EcosystemV2Ecozones
			);
		}

		if (ciceses && ciceses.length > 0) {
			query.params.ciceses = buildTreeTypeQueryList(
				ciceses,
				lists.props.ciceses
			);
		}
		await getEsvds(query);
	};

	const [mapData, setMapData] = useState<EsvdMap[]>([]);

	const getEsvdMapData = async (query: EsvdQueryParams) => {
		try {
			setIsMapBusy(true);
			await getEsvdMapCount(query);
			const data = await agent.EsvdMaps.esvdMapList(query);
			setMapData(data);

			setIsMapBusy(false);
			// }
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getEsvdTotalMapCount = async () => {
		try {
			const response = await agent.EsvdMaps.totalMapListCount();
			setEsvdTotalMapCount(response);
			// return response;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getEsvdMapCount = async (query: EsvdQueryParams) => {
		try {
			const response = await agent.EsvdMaps.mapListCount(query);
			setEsvdMapCount(response);
			// return response;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getEsvdMapMarkerData = async (valueIds: number[]) => {
		try {
			const esvd = await agent.EsvdMaps.markerDetails(valueIds);

			return esvd;
			// }
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<EsvdValuationContext.Provider
			value={{
				getEsvds,
				esvds,
				getEsvdsCount,
				esvdCount,
				getEsvdTotalCount,
				esvdTotalCount,
				//
				getEsvdMapData,
				mapData,
				getEsvdMapCount,
				esvdMapCount,
				getEsvdTotalMapCount,
				esvdTotalMapCount,
				getEsvdMapMarkerData,
				//
				clearEsvds,
				saveQueryParams,
				getPageData,
				setActivePage,
				activePage,
				setShowMap,
				showMap,
				updateQueryParams,
				esvdQueryParams,
				getEsvdsSummaryStats,
				esvdsSummaryStats,
				isMapBusy,
				setIsMapBusy,
				queryParamsForSave,
				setQueryParamsForSave,
			}}>
			{children}
		</EsvdValuationContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useEsvdValuation = () => {
	const esvdValuation = useContext(EsvdValuationContext);
	if (esvdValuation == null) {
		throw new Error(
			"useEsvdValuation() called outside of a esvdValuation hook?"
		);
	}
	return esvdValuation;
};

export { EsvdValuationProvider, useEsvdValuation };
