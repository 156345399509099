import agent from "../../api/agent";
export const SITECONDITION_CREATE = "SITECONDITION_CREATE";
export const SITECONDITION_EDIT = "SITECONDITION_EDIT";
export const SITECONDITION_DELETE = "SITECONDITION_DELETE";
export const SITECONDITION_FETCH = "SITECONDITION_FETCH";
export const SITECONDITIONS_FETCH = "SITECONDITIONS_FETCH";

export const fetchSiteConditions = () => async (dispatch: any) => {
  const response = await agent.SiteConditions.list();

  dispatch({
    type: SITECONDITIONS_FETCH,
    payload: response,
  });
};
