import React from "react";
import Select from "react-select";
import { BaseEditorComponent } from "@handsontable/react";
import { colourStyles, Option } from "../../shared/SelectDropdown";

interface IProps {
	data: any[] | undefined;
	placeholder?: string;
	formatter?: Function;
	codeCell?: string;
}

// an editor component
class SingleSelectEditor extends BaseEditorComponent<IProps> {
	private mainElementRef = React.createRef<any>();
	constructor(props: any) {
		super(props);
		this.state = {
			value: "",
			data: props.data || [],
		};
	}

	setValue(value: any, callback: any) {
		//custom cell properties key, data, holds actual selected options from the dropdown.
		//So use it to get the objects of the selected options since value in the parameter will contain only the labels without the ids
		//use the custom cellproperties data key for the actual values
		if (value)
			this.setState((state, props) => {
				return { value: this.cellProperties.data || "" };
			}, callback);
	}

	getValue(args: any) {
		const { codeCell } = this.props;
		//In order to preserve the original underlying selection from the multi-select dropdown including their ids(value) and labels,
		//add it as a data key to the cell properties object.
		//This is necessary because the value in the cells are displayed as plain text without the ids of the selected options.
		//So we want to be able to access the original selection when the editor shows up again.
		this.cellProperties.data = this.state.value;
		//extra all label for display
		//value is an array of objects ie [{value:xxx, label:xxx}], so use map to extract the labels and join them for display in the cell
		// const temp = this.state.value ? JSON.parse(this.state.value) : {};
		// const res = temp ? temp.label : "";
		// return res;
		let ree;
		const { row } = this.cellProperties;
		if (this.state.value) {
			const jso = JSON.parse(this.state.value);
			if (!jso) {
				this.hotInstance.setSourceDataAtCell(row, codeCell, "");
				return null;
			}
			ree = jso.label;
			const ids = jso.id ? jso.id : jso.value !== 0 ? jso.value : null;
			this.hotInstance.setSourceDataAtCell(row, codeCell, ids);
		} else {
			ree = "";
			this.hotInstance.setSourceDataAtCell(row, codeCell, "");
		}
		return ree;
	}

	open() {
		this.mainElementRef.current.style.display = "inline-block";
	}

	close() {
		this.setState((state, props) => {
			return {
				value: null,
			};
		});
		this.mainElementRef.current.style.display = "none";
	}

	prepare(
		row: any,
		col: any,
		prop: any,
		td: any,
		originalValue: any,
		cellProperties: any
	) {
		// We'll need to call the `prepare` method from
		// the `BaseEditorComponent` class, as it provides
		// the component with the information needed to use the editor
		// (hotInstance, row, col, prop, TD, originalValue, cellProperties)
		super.prepare(row, col, prop, td, originalValue, cellProperties);

		const tdPosition = td.getBoundingClientRect();

		// As the `prepare` method is triggered after selecting
		// any cell, we're updating the styles for the editor element,
		// so it shows up in the correct position.
		this.mainElementRef.current.style.left =
			tdPosition.left + window.pageXOffset + "px";
		this.mainElementRef.current.style.top =
			tdPosition.top + window.pageYOffset + "px";
		this.mainElementRef.current.style.minWidth =
			tdPosition.width + window.pageYOffset + "px";
	}

	stopMousedownPropagation(e: any) {
		e.stopPropagation();
	}

	render() {
		const { data, formatter, placeholder } = this.props;
		return (
			<div
				style={{
					display: "none",
					position: "absolute",
					left: 0,
					top: 0,
					// background: "#fff",
					// border: "1px solid #000",
					// flex: "3 0 auto",
					zIndex: 999,
				}}
				ref={this.mainElementRef}
				onMouseDown={this.stopMousedownPropagation}
				id="editorElement">
				<Select
					closeMenuOnSelect={true}
					placeholder={placeholder}
					isClearable={true}
					isSearchable={false}
					options={formatter ? formatter(data || []) : data}
					components={{ Option }}
					styles={colourStyles}
					onChange={(selected: any) => {
						this.setState((state, props) => {
							return {
								value: JSON.stringify(selected),
							};
						});
					}}
					value={this.state.value && JSON.parse(this.state.value)}
				/>
			</div>
		);
	}
}

export default SingleSelectEditor;
