import React, { useState } from "react";
// import ReactDOM from "react-dom";
// import { Header, Sidebar } from "semantic-ui-react";

const SidebarContext = React.createContext<any>(null);

// The ActiveMenuProvider is responsible for ActiveMenu management
const SidebarProvider = ({ children }: { children: any }) => {
	const [visible, setVisible] = useState(false);
	const [triggerElementId, setTriggerElementId] = useState(null);
	const [sidebarContent, setSidebarContent] = useState<any>();

	function openSidebar(content: any, elementId: any) {
		setSidebarContent(content);
		setVisible(true);
		setTriggerElementId(elementId);
	}

	function toggleSidebar(content: any) {
		setSidebarContent(visible ? null : content);
		setVisible(!visible);
	}

	function closeSidebar(elementId: any) {
		if (elementId && triggerElementId) {
			if (triggerElementId !== elementId) return;
		}
		setVisible(false);
		setSidebarContent(null);
		setTriggerElementId(null);
	}

	function changeContent(content: any) {
		setSidebarContent(content);
	}

	return (
		<SidebarContext.Provider
			value={{
				sidebarContent,
				openSidebar,
				closeSidebar,
				toggleSidebar,
				visible,
				changeContent,
				triggerElementId,
			}}>
			{children}
		</SidebarContext.Provider>
	);
};
const useSidebar = () => {
	const modal = React.useContext(SidebarContext);
	if (modal == null) {
		throw new Error("useSidebar() called outside of a modal hook?");
	}
	return modal;
};

export { SidebarProvider, useSidebar };
