import React, { useEffect } from "react";
import { Grid, Container } from "semantic-ui-react";
import EcosystemValuationDB from "./EcosystemValuationDB";
import TermsOfUse from "./TermsOfUse";
import AboutESVD from "./AboutESVD";
import Contact from "./Contact";
import TechnicalImplementation from "./TechnicalImplementation";
import EcosystemImage from "./EcosystemImage";
import MediaLayout from "../../layouts/MediaLayout";
import { useActiveMenu } from "../../providers/ActiveMenuProvider";
import { resources } from "../../shared/Literals";
import Contribute from "./Contribute";

const { Media, MediaContextProvider } = MediaLayout;

const HomePage = () => {
	const { setActiveItem } = useActiveMenu();

	useEffect(() => {
		setActiveItem(resources.home.name);
		return () => {};
	}, []);

	return (
		<MediaContextProvider>
			<Media at="mobile">
				<Container fluid>
					<EcosystemValuationDB />
					<EcosystemImage />
					<AboutESVD />
					<TermsOfUse />
					<Contribute />
					<Contact />
					<TechnicalImplementation />
				</Container>
			</Media>
			<Media greaterThan="mobile">
				<Container fluid className="top-container">
					<Grid columns={2} className="page-content" stackable>
						<Grid.Row>
							<Grid.Column width={12}>
								<Grid.Row>
									<Grid columns={2} stackable>
										<Grid.Column width={12} stretched>
											<EcosystemValuationDB />
										</Grid.Column>
										<Grid.Column width={4} stretched>
											<EcosystemImage />
										</Grid.Column>
									</Grid>
								</Grid.Row>
								<AboutESVD />
								<TechnicalImplementation />
							</Grid.Column>
							<Grid.Column width={4}>
								<TermsOfUse />
								<Contribute />
								<Contact />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</Media>
		</MediaContextProvider>
	);
};

export default HomePage;
