import agent from "../api/agent";
export const ECOSYSTEMSERVICE_CREATE = "ECOSYSTEMSERVICE_CREATE";
export const ECOSYSTEMSERVICE_EDIT = "ECOSYSTEMSERVICE_EDIT";
export const ECOSYSTEMSERVICE_DELETE = "ECOSYSTEMSERVICE_DELETE";
export const ECOSYSTEMSERVICE_FETCH = "ECOSYSTEMSERVICE_FETCH";
export const ECOSYSTEMSERVICES_FETCH = "ECOSYSTEMSERVICES_FETCH";

export const fetchEcosystemServices = () => async (dispatch: any) => {
  const response = await agent.EcosystemServices.list();

  dispatch({
    type: ECOSYSTEMSERVICES_FETCH,
    payload: response,
  });
};
