import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import { Router } from "react-router-dom";
import "./styles/styles.css";
import App from "./layouts/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import history from "./history";
import { ModalProvider } from "./hooks/ModalHook";

//npx node-sass ./src/scss/styles.scss ./src/styles/styles.css

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router history={history}>
				<ModalProvider>
					<App />
				</ModalProvider>
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
