import React, { useContext } from "react";
// import _ from "lodash";
import agent from "../api/agent";
import { PagingParams } from "../models/PaginationParams";
import { VttSubBiome } from "../models/vtt-models/VttBiome";

interface IValue {
	getVttSubBiomes: Function;
	getVttSubBiomeCount: Function;
	createVttSubBiome: Function;
	getVttSubBiome: Function;
	updateVttSubBiome: Function;
	deleteVttSubBiome: Function;
}

const VttSubBiomeContext = React.createContext<IValue | null>(null);

// The FilterQueryProvider is responsible for FilterQuery management
const VttSubBiomeProvider = ({ children }: { children: any }) => {
	const getVttSubBiomeCount = async () => {
		try {
			return await agent.VttSubBiomes.count();
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getVttSubBiomes = async (pageParams: PagingParams) => {
		try {
			const vtts = await agent.VttSubBiomes.list(pageParams);
			if (!vtts) return [];
			return vtts;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getVttSubBiome = async (id: number) => {
		try {
			return await agent.VttSubBiomes.details(id);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const createVttSubBiome = async (vttSubBiome: VttSubBiome) => {
		try {
			await agent.VttSubBiomes.create(vttSubBiome);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const updateVttSubBiome = async (vttSubBiome: VttSubBiome) => {
		try {
			await agent.VttSubBiomes.update(vttSubBiome);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const deleteVttSubBiome = async (id: number) => {
		try {
			await agent.VttSubBiomes.delete(id);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<VttSubBiomeContext.Provider
			value={{
				getVttSubBiomes,
				getVttSubBiomeCount,
				createVttSubBiome,
				getVttSubBiome,
				updateVttSubBiome,
				deleteVttSubBiome,
			}}>
			{children}
		</VttSubBiomeContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useVttSubBiome = () => {
	const filterQuery = useContext(VttSubBiomeContext);
	if (filterQuery == null) {
		throw new Error("useVttSubBiome() called outside of its hook.");
	}
	return filterQuery;
};

export { VttSubBiomeProvider, useVttSubBiome };
