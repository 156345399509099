import React from "react";
import { Link } from "react-router-dom";
import { Card } from "semantic-ui-react";
import { COMPANY_EMAIL, resources } from "../../shared/Literals";

// interface IProps {
//   esvds: { esvds: Esvd[]; headerArray: any[]; bodyArray: any[] };
// }<IProps>
const AboutESVD = () => {
	// render() {
	// const { esvds } = this.props;

	return (
		<Card fluid>
			<Card.Content>
				<h4>About the ESVD &amp; Registration</h4>
			</Card.Content>
			<Card.Content>
				<p>
					To use the ESVD, you can{" "}
					<Link to={"/signup"}>register for a free account</Link>. By signing
					up, you are indicating agreement with the Terms of Use specified in
					the card to the right.
				</p>
				<p>
					You can search the ESVD in the {`“${resources.esvd.name}”`} page using
					a set of drop-down lists and/or free text search. Here you can view
					your search results, generate summary statistics, and download data.
				</p>
				<p>
					The valuations from your search query can be toggled between table
					format and a map. The table provides different relevant variables on
					ecosystem type, ecosystem services, standardized values,
					beneficiaries, valuation methods, location information and
					bibliographic information. Additionally, it highlights whether or not
					a valuation has been checked by an external reviewer.
				</p>
				<p>
					The map shows the valuations based on their point location
					coordinates. Hoovering over a valuation pointer on the map shows basic
					information. Clicking on a pointer opens a window to show more
					information on the ecosystems and ecosystem services of your
					valuation(s) of interest. Please note that a map pointer can contain
					multiple valuations from multiple studies and that it can reflect a
					small or large study area. Pointers can also refer to valuations local
					in scale, but also to valuations on a national, multi-country or
					global scale.
				</p>
				<p>
					The monetary valuations underlying the ESVD are based on reports and
					academic publications. Therefore, valuation information in these
					academic publications and reports is also what is included in the
					ESVD. You may note that some valuations include one ecosystem type
					(biome, ecozone or ecosystem) or ecosystem service, but others include
					multiple types and/or services. Multiple types and/or services
					underlying a monetary value{" "}
					<b>
						should always be reported together and should not be disaggregated
					</b>{" "}
					since it is not possible to attribute a specific part of the value to
					either one ecosystem type or ecosystem service. For example, a
					monetary value of $1,500 per hectare per year for the ecosystem
					services of existence value and recreation, should{" "}
					<b>not be disaggregated</b> into a value of $1,000 for existence and
					$500 for recreation. We advise to use these monetary valuations with
					care in your specific research or decision-making context. For more
					information consult our{" "}
					<a href="https://esvd.info" target="_blank" rel="noopener noreferrer">
						info website
					</a>{" "}
					or send us an <a href={`mailto:${COMPANY_EMAIL}`}>email.</a>.
				</p>
				Notes on using the ESVD:
				<ul>
					<li>
						Where possible, value records are standardised to a common set of
						units (International dollars/hectare/year in 2020 price levels).
					</li>
					<li>
						Some value records cannot be standardised to a common set of units
						due to missing information but nevertheless contain potentially
						useful information for users. For this reason, some search results
						may contain data but do not contain standardised values.
						Non-standardised values are not included in the summary statistics.
					</li>
					<li>
						The data is under an ongoing review process to check that the
						interpretation and coding of study results are correct but not all
						data has been reviewed yet. The review status of each value record
						is indicated in the data.
					</li>
				</ul>
			</Card.Content>
		</Card>
	);
	// }
};

export default AboutESVD;
