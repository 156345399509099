// import moment from "moment";
import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	Grid,
	Header,
	Icon,
	Label,
	List,
	Table,
} from "semantic-ui-react";
// import { useHistory } from "react-router-dom";
// import { LIMIT } from "../../shared/Literals";
// import EsvdPagination from "../esvdPanel/EsvdPagination";
// import FilterQueryButton from "./FilterQueryButton";
import {
	// VttSubBiomeProvider,
	useVttSubBiome,
} from "../../../providers/VttSubBiomeProvider";

import LoadingOverlay from "../../../shared/LoadingOverlay";
import { LIMIT, resources } from "../../../shared/Literals";
import EsvdPagination from "../../esvdPanel/EsvdPagination";

import { VttSubBiome } from "../../../models/vtt-models/VttBiome";
import { Link } from "react-router-dom";
import MyModal from "../../../layouts/Modals";
import { ViewHandles, useVtt } from "../../../providers/VttProvider";

const VttSubBiomeList = () => {
	const { setCurrentView } = useVtt();
	const { getVttSubBiomeCount, getVttSubBiomes, deleteVttSubBiome } =
		useVttSubBiome();
	const [isLoading, setIsLoading] = React.useState(false);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [vttSubBiomesCnt, setVttSubBiomesCnt] = useState<number>(0);
	const [vttSubBiomes, setVttSubBiomes] = useState<VttSubBiome[]>([]);
	const [opened, setOpened] = useState<boolean>(false);
	const [delItem, setDelItem] = useState<number | null>(null);
	const [isModalConfirmed, setIsModalConfirmed] = useState<boolean>(false);
	const [modalMessage, setModalMessage] = useState<string | null>(null);
	const [isActionNeeded, setisActionNeeded] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(true);
		(async () => {
			var filtersCnt = await getVttSubBiomeCount();
			setVttSubBiomesCnt(filtersCnt);
		})();

		(async () => {
			var vtt = await getVttSubBiomes();
			setVttSubBiomes(vtt);
			setIsLoading(false);
		})();

		return () => {};
	}, []);

	useEffect(() => {
		if (isModalConfirmed) {
			setIsModalConfirmed(false);
			(async () => {
				if (delItem) {
					await deleteVttSubBiome(delItem);
					var vtt = await getVttSubBiomes();
					setVttSubBiomes(vtt);
					setIsLoading(false);
					setDelItem(null);
				}
			})().catch((err) => {
				setOpened(true);
				setisActionNeeded(false);
				setModalMessage(err || null);
			});
		}
		return () => {};
	}, [isModalConfirmed]);

	const deleting = (id: number) => {
		setisActionNeeded(true);
		setModalMessage("Are you sure you want to delete?");
		setOpened(true);
		setDelItem(id);
	};

	const renderContent = () => {
		return (
			<>
				<LoadingOverlay active={isLoading} text="Loading filter queries" />

				<Table celled selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Name</Table.HeaderCell>
							<Table.HeaderCell></Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{vttSubBiomes &&
							vttSubBiomes.map((subbiome, i) => {
								return (
									<Table.Row onClick={() => null} key={i}>
										<Table.Cell>
											{/* <Link
												to={{
													pathname: resources.vttCalculations.path,
													state: {
														id: subbiome.id,
													},
												}}></Link> */}
											{subbiome.name}
										</Table.Cell>
										<Table.Cell textAlign="right">
											<Button.Group>
												<button
													className="btn btn-success btn-sm"
													style={{ minWidth: "4em" }}
													onClick={() => {
														setCurrentView({
															view: ViewHandles.subbiomeEntry,
															subbiomeid: subbiome.id,
														});
													}}>
													<Icon name="edit" />
												</button>
												<button
													className="btn btn-warning btn-sm"
													style={{ minWidth: "4em" }}
													onClick={() => subbiome.id && deleting(subbiome.id)}>
													<Icon name="trash" />
												</button>
											</Button.Group>
										</Table.Cell>
									</Table.Row>
								);
							})}
					</Table.Body>
				</Table>
			</>
		);
	};

	// function setPage(activePage: string | number | undefined) {
	// 	setIsLoading(true);
	// 	setCurrentPage(Number(activePage));
	// 	(async () => {
	// 		var vtts = await getVttSubBiomes({
	// 			params: {
	// 				offset: activePage ? Number(activePage) - 1 : 0,
	// 			},
	// 		});
	// 		setVttSubBiomes(vtts);
	// 		setIsLoading(false);
	// 	})();
	// }

	return (
		<>
			<Card.Content>
				<button
					className="ui button btn btn-primary floated"
					type="submit"
					style={{
						backgroundColor: "#70bbfd",
						// display: "block !important",
						height: "2rem",
						// width: "5rem",
					}}
					onClick={() => {
						setCurrentView({
							view: ViewHandles.subbiomeEntry,
						});
					}}>
					Add sub biome
				</button>
			</Card.Content>
			<Card.Content style={{ overflowY: "auto", height: "80vh" }}>
				{renderContent()}
			</Card.Content>
			{/* <Grid>
				<Grid.Row style={{ marginTop: "1em", marginBottom: "1em" }}>
					<Grid.Column width={12}>
						<EsvdPagination
							currentPage={currentPage}
							setActivePage={setPage}
							totalPages={vttSubBiomesCnt / LIMIT}
						/>
					</Grid.Column>
					<Grid.Column width={4} floated="right">
						<Label className="ui right floated">
							{`${
								LIMIT * currentPage < vttSubBiomesCnt
									? LIMIT * currentPage
									: vttSubBiomesCnt
							} of ${vttSubBiomesCnt}`}
						</Label>
					</Grid.Column>
				</Grid.Row>
			</Grid> */}

			<MyModal
				title="Delete"
				body={`${modalMessage || null}`}
				opened={opened}
				setOpened={setOpened}
				setConfirmation={setIsModalConfirmed}
				needsAction={isActionNeeded}
			/>
		</>
	);
};

export default VttSubBiomeList;
