import { UNITSOFMEASURE_FETCH } from "../../actions/others/unitOfMeasureActions";

const unitOfMeasureReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case UNITSOFMEASURE_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default unitOfMeasureReducer;
