import { TEMPORALUNITS_FETCH } from "../../actions/others/temporalUnitActions";

const temporalUnitReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case TEMPORALUNITS_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default temporalUnitReducer;
