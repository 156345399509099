import { VALUATION_METHODS_FETCH } from "../actions/valuationMethodActions";

const valuationMethodReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case VALUATION_METHODS_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default valuationMethodReducer;
