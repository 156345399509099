import React, { Fragment } from "react";
import Dropzone from "react-dropzone";
import { useField } from "formik";
import { Button, Form, Label, List } from "semantic-ui-react";

export interface IFile {
	lastModified?: number;
	lastModifiedDate?: string;
	name: string;
	path?: string;
	preview?: string;
	type?: string;
}

const DropZoneField = ({
	name,
	onSelectionChange,
	value,
	accept,
	customHeight = false,
}: {
	name: any;
	onSelectionChange: Function;
	accept: string | null;
	customHeight: boolean;
	value: File[];
}) => {
	let files: File[] = value;

	const removeFile = (index: number, e: any) => {
		e.preventDefault();
		Array.isArray(files) &&
			onSelectionChange(files.filter((val: any, i: number) => i !== index));
	};
	return (
		<div>
			{files && Array.isArray(files) && files.length < 2 && (
				<div
					className={`dropzone dropzone--single${
						customHeight ? " dropzone--custom-height" : ""
					}`}>
					<div className="form-group">
						<Dropzone
							accept={accept || ".pdf, .odt, .doc,.docx, .txt "}
							multiple
							maxFiles={2}
							minSize={0}
							maxSize={50 * 1048576} //50MB. For your reference, 1 Megabyte = 1048576 Bytes.
							onDrop={(acceptedFiles) => {
								// do nothing if no files
								if (acceptedFiles.length === 0) {
									return;
								}
								files = [...files, ...acceptedFiles];
								onSelectionChange &&
									onSelectionChange(
										files.map(
											(
												fl: File //fl
											) => fl
										)
									);
							}}>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps()} className="dropzone__input">
									{(!files || files.length === 0) && (
										<div className="dropzone__drop-here">
											{/* <span className="lnr lnr-upload" /> */}
											Click here to select a file from your computer containing
											your dataset
										</div>
									)}
									<input {...getInputProps()} />
								</div>
							)}
						</Dropzone>
					</div>
				</div>
			)}
			{files && Array.isArray(files) && files.length > 0 && (
				// <div className="dropzone__imgs-wrapper">
				<List celled verticalAlign="middle">
					{files.map((file, i) => (
						<List.Item>
							<List.Content floated="right">
								<Button size="tiny" onClick={(e) => removeFile(i, e)}>
									x
								</Button>
							</List.Content>
							{/* <Image avatar src='https://react.semantic-ui.com/images/avatar/small/lena.png' /> */}
							<List.Content>
								<List.Header>{file.name}</List.Header>
							</List.Content>
						</List.Item>
					))}
				</List>
			)}
		</div>
	);
};

interface Props {
	name: string;
	onSelectionChange: Function;
	customHeight: boolean;
	value: File[];
	accept: string | null;
}

const EsvdDropZoneField = (props: Props) => {
	const [field, meta] = useField(props.name);
	return (
		<Fragment>
			<Form.Field error={meta.touched && !!meta.error}>
				<DropZoneField
					{...field}
					customHeight={false}
					onSelectionChange={props.onSelectionChange}
					value={props.value || []}
					accept={props.accept}
				/>

				{meta.touched && meta.error ? (
					<Label
						basic
						color="red"
						size="tiny"
						style={{ padding: "0px", border: "none" }}>
						{meta.error}
					</Label>
				) : null}
			</Form.Field>
		</Fragment>
	);
};

export default EsvdDropZoneField;
