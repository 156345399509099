import * as React from "react";
import { Grid, Container } from "semantic-ui-react";
import { actions, resources } from "../../shared/Literals";

import Can from "../../accessControls/Can";
import { Redirect } from "react-router";
import MediaLayout from "../../layouts/MediaLayout";
import VersionEntry from "./VersionEntry";
import CitationEntry from "./CitationEntry";
import DeleteValuation from "./DeleteValuation";

const { Media, MediaContextProvider } = MediaLayout;

const SettingsMain = () => {
	const renderContent = () => {
		return (
			<MediaContextProvider>
				<Media at="mobile" className="page-content">
					<VersionEntry />
					<CitationEntry />
					<DeleteValuation />
				</Media>
				<Media greaterThan="mobile">
					<Container fluid className="top-container">
						<Grid className="page-content" stackable>
							<Grid.Row>
								<Grid.Column>
									<VersionEntry />
									<CitationEntry />
									<DeleteValuation />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
				</Media>
			</MediaContextProvider>
		);
	};

	return (
		<Can
			action={actions.read}
			resource={resources.settings.name}
			yes={() => renderContent()}
			no={() => <Redirect to={"/"} />}
		/>
	);
};

export default SettingsMain;
