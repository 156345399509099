import React from "react";
import { CSVLink } from "react-csv";
import { Button, Card, Progress } from "semantic-ui-react";
import moment from "moment";
import { useFilterQuery } from "../../providers/FilterQueryProvider";
import { useReferenceInfo } from "../../providers/ReferenceInfoProvider";

const FilterQueryButton = () => {
	const { getFilterQueriesJSON } = useFilterQuery();
	const { currentInfo } = useReferenceInfo();
	const csvLinkRef = React.useRef<any>();
	const [csvData, setCsvData] = React.useState<any[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [filename, setFilename] = React.useState<string>("");

	const downloadFilterQueries = async () => {
		setFilename(
			`Esvd_filter_queries_${moment().format("Do-MMM-yyyy_HH-mm-ss")}_${
				currentInfo?.version
			}.csv`
		);

		setIsLoading(true);
		const data = await getFilterQueriesJSON();
		setCsvData(data);
		setIsLoading(false);
		if (csvLinkRef?.current) {
			csvLinkRef.current.link.click();
		}
		// });
	};

	return (
		<>
			<Button
				positive
				loading={isLoading}
				content={"Download Saved Queries"}
				onClick={() => downloadFilterQueries()}
				disabled={isLoading}
				className={`${isLoading ? "invisible" : ""}`}
			/>
			<Progress
				// percent={progress}
				// progress
				className={`${isLoading ? "" : "invisible"}`}
			/>
			<CSVLink
				ref={csvLinkRef}
				data={csvData}
				filename={filename}
				target="_blank"
				className={`${"invisible"}`}
			/>
		</>
	);
};

export default FilterQueryButton;
