
import { User } from "../models/user";
import { FETCH_USER,ISREFRESH,SET_USER_TOKEN } from "../shared/Literals";

//this reducer is responsible for deciding whether a user is logged in or not
const accountReducer = (state: User | null = null, action: any) => {
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false;

    default:
      return state;
  }
};

export default accountReducer;

export const tokenReducer = (state: string | null = null, action: any) => {
  switch (action.type) {
    case SET_USER_TOKEN:
      return action.payload;

    default:
      return state;
  }
};

// export const currentUserCheckedReducer = (
//   state: boolean = false,
//   action: any
// ) => {
//   switch (action.type) {
//     case CURRENT_USER_CHECKED:
//       return action.payload;

//     default:
//       return state;
//   }
// };
export const isRefreshReducer = (state: boolean = false, action: any) => {
  switch (action.type) {
    case ISREFRESH:
      return action.payload;

    default:
      return state;
  }
};
