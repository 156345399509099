import agent from "../api/agent";
export const CICES_CREATE = "CICES_CREATE";
export const CICES_EDIT = "CICES_EDIT";
export const CICES_DELETE = "CICES_DELETE";
export const CICES_FETCH = "CICES_FETCH";
export const CICESES_FETCH = "CICESES_FETCH";

export const fetchCiceses = () => async (dispatch: any) => {
  const response = await agent.Ciceses.list();

  dispatch({
    type: CICESES_FETCH,
    payload: response,
  });
};
