import React, { Fragment, useEffect } from "react";
// import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga"; //https://stackoverflow.com/a/57923505/5367889
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../shared/AppInsights";
import history from "../history";

import MainPage from "./MainPage";
import { ReferenceInfoProvider } from "../providers/ReferenceInfoProvider";
import { AuthProvider } from "../providers/AuthProvider";
import { ActiveMenuProvider } from "../providers/ActiveMenuProvider";
import { FilterQueryProvider } from "../providers/FilterQueryProvider";
import { useModal } from "../hooks/ModalHook";
import { UploadInfoProvider } from "../providers/UploadInfoProvider";

//to resolve issues with "uncaught referenceerror: process is not defined create-react app"
//https://stackoverflow.com/a/70495171

//https://stackoverflow.com/a/57923505/5367889
// ReactGA.initialize(GA_NO);
ReactGA.initialize("UA-191165667-1");
history.listen((location, action) => {
	ReactGA.pageview(location.pathname + location.search);
});

const App = () => {
	const { ModalDialog } = useModal();
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<Fragment>
			<AuthProvider>
				<ActiveMenuProvider>
					<ReferenceInfoProvider>
						<FilterQueryProvider>
							<UploadInfoProvider>
								<MainPage />
							</UploadInfoProvider>
						</FilterQueryProvider>
					</ReferenceInfoProvider>
				</ActiveMenuProvider>
			</AuthProvider>
			<ModalDialog />
		</Fragment>
	);
};

export default withAITracking(reactPlugin, App);
