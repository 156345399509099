import React, { useEffect } from "react";
import { Card, Header, Statistic } from "semantic-ui-react";
import * as XLSX from "xlsx";
import { VslColumnMapping, VslExcelHeaders } from "../../shared/Literals";
import InvalidItems from "./InvalidItems";
import { useUploadInfo } from "../../providers/UploadInfoProvider";

const ValidateDataset = (props: any) => {
	const { uploading, loading, setLoading, filename, setFileName } =
		useUploadInfo();
	const { file, setData, data, setIsValidData, isValidData } = props;

	const [duplicateStudyIds, setDuplicateStudyIds] = React.useState<any>(null);
	const [noEsvdMasterSheet, setNoEsvdMasterSheet] =
		React.useState<boolean>(false);

	const [dupBiomesCodes, setDupBiomesCodes] = React.useState<any>(null);
	const [dupBiomesV2Codes, setDupBiomesV2Codes] = React.useState<any>(null);

	const [dupTEEBsCodes, setDupTEEBsCodes] = React.useState<any>(null);

	const [dupEcosystemsCodes, setDupEcosystemsCodes] = React.useState<any>(null);
	const [dupEcosystemsV2Codes, setDupEcosystemsV2Codes] =
		React.useState<any>(null);
	const [dupEcozonesCodes, setDupEcozonesCodes] = React.useState<any>(null);
	const [labelColumnErrors, setLabelColumnErrors] = React.useState<
		any[] | null
	>(null);

	useEffect(() => {
		resetDups();
		if (file) {
			setLoading(true);
			setFileName(file.name);
			process(file);
		} else {
			setFileName("");
		}
	}, [file]);

	const process = (file: any) => {
		try {
			setLoading(true);
			/* Boilerplate to set up FileReader */
			const reader = new FileReader();

			const rABS = !!reader.readAsBinaryString;
			reader.onload = (e: any) => {
				try {
					/* Parse data */
					const bstr = e.target.result;

					const wb = XLSX.read(bstr, {
						type: rABS ? "binary" : "array",
						cellText: false,
						cellDates: true,
					});
					// /* Get first worksheet */
					// const wsname = wb.SheetNames["ESVD"];
					// if (wsname !== "maindata") {
					// 	throw new Error("First sheet in the excel must be 'maindata.'");
					// }
					const esvdSheetName =
						wb.SheetNames.find((x) => x.startsWith("VSL MASTER")) || undefined;
					if (esvdSheetName === undefined) {
						setIsValidData(false);
						setNoEsvdMasterSheet(true);
						setLoading(false);
						return;
					}

					let labelColumnError: any[] | null = null;
					const excelSheetHeaders: string[] = wb.SheetNames;
					const codeSheetHeaders: string[] = Object.keys(VslExcelHeaders).map(
						(k) => k
					);

					for (const ch of codeSheetHeaders) {
						const srch: string | undefined =
							ch === "vsls"
								? wb.SheetNames.find((x) => x.startsWith("VSL MASTER"))
								: excelSheetHeaders.find(
										(x) =>
											VslExcelHeaders[`${ch}`].label.toLowerCase().trim() ===
											x.toLowerCase().trim()
								  );

						if (!srch) {
							if (!ch.startsWith("VSL MASTER")) {
								if (!labelColumnError) {
									//initialize labelColumnError
									labelColumnError = [];
								}

								labelColumnError.push(
									`Sheet with label "${
										VslExcelHeaders[`${ch}`].label
									}" is missing from the upload file.`
								);
							}
						} else {
							//check the column headers inside the selected sheet if it exists
							const errorColumns = checkColumnHeaders(
								wb,
								// VslExcelHeaders[`${srch}`].label,
								srch,
								VslExcelHeaders[`${ch}`].columns
							);
							if (errorColumns) {
								//initialize labelColumnError
								if (!labelColumnError) {
									labelColumnError = [];
								}

								labelColumnError.push(
									`Sheet with label ${srch} is missing the following column headings: ${errorColumns.join(
										", "
									)}. If they are included, they must be the top row.`
								);
							}
						}
					}

					if (labelColumnError) {
						setIsValidData(false);
						setLabelColumnErrors(labelColumnError);
						setLoading(false);
						return;
					}
					const mapped = getESVDJson(wb, esvdSheetName.trim());

					//get stats on data
					const items: any = {};
					const duplicateStudyIds: any = {};
					// const invalidCodedDate: any = {};
					// const invalidReviewedDate: any = {};
					// const noStudyId: any = {};
					const duplicateBiomesCodes: any = {};
					const duplicateBiomesV2Codes: any = {};
					const duplicateEcosystemsCodes: any = {};
					const duplicateEcosystemsV2Codes: any = {};
					const duplicateEcozonesCodes: any = {};
					const duplicateTEEBsCodes: any = {};
					let rowIndex = 2;
					mapped.forEach((x: any, i: number) => {
						//excel row index
						rowIndex = i + 1 + 1; //first 1 caters for header and second 1 caters forzero-based index
						x.rowindex = rowIndex;
						//check for unique studyids
						if (x.studyid) {
							if (items[`${x.studyid}`]) {
								items[`${x.studyid}`].push(rowIndex);
								//mark value as a duplicate
								duplicateStudyIds[`${x.studyid}`] =
									`Studyid: ${x.studyid}, Rows:` + items[`${x.studyid}`];
							} else {
								items[`${x.studyid}`] = [rowIndex];
							}
						}

						//check for duplicate biome codes in one cell
						if (x.biomecode) {
							const biomes: string[] = [];
							const codeSplit = x.biomecode.toString().split(";");
							codeSplit.forEach((arr: string) => {
								if (biomes.includes(arr.trim())) {
									//means it's duplicate
									if (!duplicateBiomesCodes[`${rowIndex}`]) {
										// 	duplicateBiomesCodes[`${x.valueid}`].push(arr.trim());
										// } else {
										duplicateBiomesCodes[`${rowIndex}`] = [arr.trim()];
									}
								} else {
									biomes.push(arr.trim());
								}
							});
							//join after removing duplicates if any
							x.biomecode = biomes.join("; ");
						}

						//check for duplicate Ecosystem codes in one cell
						if (x.ecosystemcode) {
							const codes: string[] = [];
							const codeSplit = x.ecosystemcode.toString().split(";");
							codeSplit.forEach((arr: string) => {
								if (codes.includes(arr.trim())) {
									if (!duplicateEcosystemsCodes[`${rowIndex}`]) {
										duplicateEcosystemsCodes[`${rowIndex}`] = [arr.trim()];
									}
								} else {
									codes.push(arr.trim());
								}
							});
							//join after removing duplicates if any
							x.ecosystemcode = codes.join("; ");
						}

						//check for duplicate biomeV2 codes in one cell
						if (x.biomeV2code) {
							const biomesV2: string[] = [];
							const codeSplit = x.biomeV2code.toString().split(";");
							codeSplit.forEach((arr: string) => {
								if (biomesV2.includes(arr.trim())) {
									//means it's duplicate
									if (!duplicateBiomesV2Codes[`${rowIndex}`]) {
										duplicateBiomesV2Codes[`${rowIndex}`] = [arr.trim()];
									}
								} else {
									biomesV2.push(arr.trim());
								}
							});
							//join after removing duplicates if any
							x.biomeV2code = biomesV2.join("; ");
						}

						//check for duplicate Ecozone codes in one cell
						if (x.ecozonecode) {
							const codes: string[] = [];
							const codeSplit = x.ecozonecode.toString().split(";");
							codeSplit.forEach((arr: string) => {
								if (codes.includes(arr.trim())) {
									if (!duplicateEcozonesCodes[`${rowIndex}`]) {
										// 	duplicateEcozonesCodes[`${x.valueid}`].push(arr.trim());
										// } else {
										duplicateEcozonesCodes[`${rowIndex}`] = [arr.trim()];
									}
								} else {
									codes.push(arr.trim());
								}
							});
							//join after removing duplicates if any
							x.ecozonecode = codes.join("; ");
						}

						//check for duplicate Ecosystem codes in one cell
						if (x.ecosystemV2code) {
							const codes: string[] = [];
							const codeSplit = x.ecosystemV2code.toString().split(";");
							codeSplit.forEach((arr: string) => {
								if (codes.includes(arr.trim())) {
									if (!duplicateEcosystemsV2Codes[`${rowIndex}`]) {
										// 	duplicateEcosystemsCodes[`${x.valueid}`].push(arr.trim());
										// } else {
										duplicateEcosystemsV2Codes[`${rowIndex}`] = [arr.trim()];
									}
								} else {
									codes.push(arr.trim());
								}
							});
							//join after removing duplicates if any
							x.ecosystemV2code = codes.join("; ");
						}

						//check for duplicate teebs codes in one cell
						checkDuplicateCodes(
							x,
							"EcosystemServiceCode".toLocaleLowerCase(),
							duplicateTEEBsCodes
						);
					});

					if (Object.keys(duplicateStudyIds).length > 0) {
						setDuplicateStudyIds(duplicateStudyIds);
						setIsValidData(false);
					} else {
						setDuplicateStudyIds(null);
					}

					// if (Object.keys(noStudyId).length > 0) {
					// 	setNoStudyIdEntries(noStudyId);
					// 	setIsValidData(false);
					// } else {
					// 	setNoStudyIdEntries(null);
					// }

					if (Object.keys(duplicateBiomesCodes).length > 0) {
						setDupBiomesCodes(duplicateBiomesCodes);
					} else {
						setDupBiomesCodes(null);
					}

					if (Object.keys(duplicateEcosystemsCodes).length > 0) {
						setDupEcosystemsCodes(duplicateEcosystemsCodes);
					} else {
						setDupEcosystemsCodes(null);
					}

					if (Object.keys(duplicateBiomesV2Codes).length > 0) {
						setDupBiomesV2Codes(duplicateBiomesV2Codes);
					} else {
						setDupBiomesV2Codes(null);
					}

					if (Object.keys(duplicateEcozonesCodes).length > 0) {
						setDupEcozonesCodes(duplicateEcozonesCodes);
					} else {
						setDupEcozonesCodes(null);
					}

					if (Object.keys(duplicateEcosystemsV2Codes).length > 0) {
						setDupEcosystemsV2Codes(duplicateEcosystemsV2Codes);
					} else {
						setDupEcosystemsV2Codes(null);
					}

					if (Object.keys(duplicateTEEBsCodes).length > 0) {
						setDupTEEBsCodes(duplicateTEEBsCodes);
					} else {
						setDupTEEBsCodes(null);
					}

					const jsonDataset: any = {};
					jsonDataset.vsls = mapped;

					jsonDataset.biomesecosystems = getExcelTabJson(
						wb,
						VslExcelHeaders.biomes.label
					);

					jsonDataset.biomesecozones = getExcelTabJson(
						wb,
						VslExcelHeaders.biomesv2.label
					);

					jsonDataset.teebs = getExcelTabJson(wb, VslExcelHeaders.teebs.label);
					jsonDataset.protectionstatus = getExcelTabJson(
						wb,
						VslExcelHeaders.protectionstatus.label
					);
					jsonDataset.countries = getExcelTabJson(
						wb,
						VslExcelHeaders.countries.label
					);

					// ScaleOfStudySites; from Scale_Text column in ESVD

					/* Update state */
					setData({
						uploaddata: jsonDataset,
						esvdCount: mapped.length,
						filename: file.name,
					});
				} catch (err: any) {
					// setLoading(false);
				}
				setLoading(false);
			};
			// const file = this.state.file;
			if (rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);
		} catch (err: any) {
			setLoading(false);
		}
	};

	function getESVDJson(wb: XLSX.WorkBook, sheetName: string) {
		const ws = wb.Sheets[sheetName];
		/* Convert array of arrays */
		//https://docs.sheetjs.com/docs/api/utilities/
		const dat = XLSX.utils.sheet_to_json(ws, {
			// header: 1,
			blankrows: false,
			raw: true,
			defval: "",
			dateNF: 'd"/"m"/"yyyy',
			// range: 1000,
		});

		// // remove rows without valueIds
		// const filtered = dat.filter((x: any) => x.StudyID);

		const mapped = dat.map((row: any, i) => {
			const item: any = {};
			Object.entries(VslColumnMapping).map(([key, value]) => {
				item[`${value.toLocaleLowerCase()}`] = row[key];
			});
			return item;
		});
		return mapped;
	}

	function getExcelTabJson(wb: XLSX.WorkBook, sheetName: string) {
		const ws = wb.Sheets[sheetName];
		/* Convert array of arrays */
		//https://docs.sheetjs.com/docs/api/utilities/
		const dat = XLSX.utils.sheet_to_json(ws, {
			// header: 1,
			blankrows: false,
			raw: true,
			defval: "",
			dateNF: 'd"/"m"/"yyyy',
			// range: 1000,
		});

		const datt: any = dat.map((d: any, i) => {
			const newObj: any = {};
			for (const key in d) {
				if (key.startsWith("__EMPTY")) {
					delete d[key];
					continue;
				}
				newObj[`${key.toLocaleLowerCase()}`] = d[key];
			}
			return newObj;
		});
		return datt;
	}

	function checkColumnHeaders(
		wb: XLSX.WorkBook,
		sheetName: string,
		expectedHeaders: string[]
	): string[] | null {
		const ws = wb.Sheets[sheetName];
		/* Convert array of arrays */
		//https://docs.sheetjs.com/docs/api/utilities/
		//This takes each used column in the sheet and uses the first cell value as object keys
		//So eg if the sheet has Code and Name as the values in the top rowa, they are used as keys for all the remaining rows and
		//all rows apart from the top row are converted to object array ie [{code:"1...", Name:"1..."}, {code:"2...", Name:"2..."}...]
		const dat: any[] = XLSX.utils.sheet_to_json(ws, {
			// header: 1,
			blankrows: false,
			raw: true,
			defval: "",
			dateNF: 'd"/"m"/"yyyy',
		});

		const errorColumns: string[] = [];
		//Due the above explanation, since we want to ascertain if the sheet contains the expected headers we have passed in the first row
		//we pick on the first element of the object array (the first element will represent the first row in the sheet)
		//and check if any keys in it matches our expected headers. If so, then it is valid and we return true. Else it's invalid and we return false

		const firstObjectKeys: string[] = Object.keys(dat[0]);
		for (const txt of expectedHeaders) {
			if (
				!firstObjectKeys.find(
					(x) => x.toLowerCase().trim() === txt.toLowerCase().trim()
				)
			) {
				errorColumns.push(txt);
			}
		}

		return errorColumns.length === 0 ? null : errorColumns;
	}

	const checkDuplicateCodes = (
		rowItem: any,
		keyName: string,
		dupCodesHolder: any
	) => {
		if (rowItem[keyName]) {
			const codes: string[] = [];
			const codeSplit = rowItem[keyName].toString().split(";");
			codeSplit.forEach((arr: string) => {
				if (codes.includes(arr.trim())) {
					if (!dupCodesHolder[`Row ${rowItem.rowIndex}`]) {
						// 	dupCodesHolder[`${rowItem.valueid}`].push(arr.trim());
						// } else {
						dupCodesHolder[`Row ${rowItem.rowindex}`] = [`code: ${arr.trim()}`];
					}
				} else {
					codes.push(arr.trim());
				}
			});
			//join after removing duplicates if any
			rowItem[keyName] = codes.join("; ");
		}
	};

	const resetDups = () => {
		setDupEcosystemsCodes(null);
		setDupEcozonesCodes(null);
		setDuplicateStudyIds(null);
		setDupBiomesCodes(null);
		setDupBiomesV2Codes(null);
		setDupTEEBsCodes(null);
		// setNoStudyIdEntries(null);
		setData({});
		setNoEsvdMasterSheet(false);
		setIsValidData(true);
		setLabelColumnErrors(null);
	};

	const renderDataReport = () => {
		const { uploaddata } = data;
		const { vsls } = uploaddata || {};
		return (
			<div style={{ height: "50vh", marginTop: "20px" }}>
				<Card.Content style={{ marginBottom: "0", paddingBottom: "0.5em" }}>
					{((!loading && file) || !uploading) && (
						<Header
							as="h3"
							textAlign="center"
							style={{ display: "inline-block" }}>
							File details{"    "}
							{!isValidData && (
								<span style={{ color: "red" }}>
									*Please fix the red higlights below before you can proceed.
								</span>
							)}
						</Header>
					)}

					{!uploading && (
						<Header as="h5">
							{(vsls && `Uploaded file name: ${filename}`) ||
								(filename &&
									vsls === undefined &&
									!loading &&
									"Invalid data format. Please verify.")}
						</Header>
					)}
					{vsls && (
						<Card.Meta>
							<Statistic>
								<Statistic.Value>{vsls.length}</Statistic.Value> valuations
							</Statistic>
						</Card.Meta>
					)}
				</Card.Content>
				{noEsvdMasterSheet && (
					<h4>
						<label style={{ color: "red" }}>
							No sheet name starting with "VSL MASTER"
						</label>
					</h4>
				)}
				{labelColumnErrors && (
					<>
						<h4 style={{ color: "red" }}>
							{labelColumnErrors &&
								"Incorrect sheet names and/or column labels."}
						</h4>
						{labelColumnErrors && (
							<ul style={{ color: "red" }}>
								{labelColumnErrors.map((key: any, i: number) => (
									<li key={i} style={{ color: "red" }}>
										<label style={{ color: "red !important" }}>{key}</label>
									</li>
								))}
							</ul>
						)}
					</>
				)}
				{duplicateStudyIds && (
					<>
						<h4>
							{duplicateStudyIds &&
								`${
									Object.keys(duplicateStudyIds).length
								} duplicate valuation studies found.`}
						</h4>
						{duplicateStudyIds && (
							<InvalidItems
								invalidEntries={duplicateStudyIds}
								isFatal={true}
								withJoin={true}
								delimiter=", "
							/>
						)}
					</>
				)}
				{/* {invalidCodedDateEntries && (
					<>
						<h4>
							{invalidCodedDateEntries &&
								`${
									Object.keys(invalidCodedDateEntries).length
								} entries with invalid
						coded date found.`}
						</h4>
						{invalidCodedDateEntries && (
							<InvalidItems
								invalidEntries={invalidCodedDateEntries}
								isFatal={true}
							/>
						)}
					</>
				)}
				{invalidReviewdDateEntries && (
					<>
						<h4>
							{invalidReviewdDateEntries &&
								`${Object.keys(invalidReviewdDateEntries).length} entries with
						invalid reviewed date found.`}
						</h4>
						{invalidReviewdDateEntries && (
							<InvalidItems
								invalidEntries={invalidReviewdDateEntries}
								isFatal={true}
							/>
						)}
					</>
				)} */}
				{/* {noStudyIdEntries && (
					<>
						<h4>
							{noStudyIdEntries &&
								`${Object.keys(noStudyIdEntries).length} entries with
						no study Ids.`}
						</h4>
						{noStudyIdEntries && (
							<InvalidItems invalidEntries={noStudyIdEntries} isFatal={true} />
						)}
					</>
				)} */}

				{dupBiomesCodes && (
					<>
						<h4>
							{dupBiomesCodes &&
								`${Object.keys(dupBiomesCodes).length} entries with
						duplicate biome codes.`}
						</h4>
						{dupBiomesCodes && (
							<InvalidItems
								invalidEntries={dupBiomesCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupEcosystemsCodes && (
					<>
						<h4>
							{dupEcosystemsCodes &&
								`${Object.keys(dupEcosystemsCodes).length} entries with
						duplicate Ecosystems codes.`}
						</h4>
						{dupEcosystemsCodes && (
							<InvalidItems
								invalidEntries={dupEcosystemsCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupBiomesV2Codes && (
					<>
						<h4>
							{dupBiomesV2Codes &&
								`${Object.keys(dupBiomesV2Codes).length} entries with
						duplicate biomeV2 codes.`}
						</h4>
						{dupBiomesV2Codes && (
							<InvalidItems
								invalidEntries={dupBiomesV2Codes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupEcozonesCodes && (
					<>
						<h4>
							{dupEcozonesCodes &&
								`${Object.keys(dupEcozonesCodes).length} entries with
						duplicate Ecozones codes.`}
						</h4>
						{dupEcozonesCodes && (
							<InvalidItems
								invalidEntries={dupEcozonesCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupEcosystemsV2Codes && (
					<>
						<h4>
							{dupEcosystemsV2Codes &&
								`${Object.keys(dupEcosystemsV2Codes).length} entries with
						duplicate EcosystemsV2 codes.`}
						</h4>
						{dupEcosystemsV2Codes && (
							<InvalidItems
								invalidEntries={dupEcosystemsV2Codes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}

				{dupTEEBsCodes && (
					<>
						<h4>
							{dupTEEBsCodes &&
								`${Object.keys(dupTEEBsCodes).length} entries with
						duplicate TEEB codes.`}
						</h4>
						{dupTEEBsCodes && (
							<InvalidItems
								invalidEntries={dupTEEBsCodes}
								withJoin={true}
								delimiter="; "
							/>
						)}
					</>
				)}
			</div>
		);
	};
	return (
		<>
			{/* {renderFormik()} */}
			{renderDataReport()}
		</>
	);
};

export default ValidateDataset;
