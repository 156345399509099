import agent from "../../api/agent";
export const STUDYLOCATION_CREATE = "STUDYLOCATION_CREATE";
export const STUDYLOCATION_EDIT = "STUDYLOCATION_EDIT";
export const STUDYLOCATION_DELETE = "STUDYLOCATION_DELETE";
export const STUDYLOCATION_FETCH = "STUDYLOCATION_FETCH";
export const STUDYLOCATIONS_FETCH = "STUDYLOCATIONS_FETCH";

export const fetchStudyLocations = () => async (dispatch: any) => {
  const response = await agent.StudyLocations.list();

  dispatch({
    type: STUDYLOCATIONS_FETCH,
    payload: response,
  });
};
