// import _ from "lodash";
import { BIOME_ECOSYSTEMS_FETCH } from "../actions/biomeEcosystemAction";

import { TreeType } from "../models/ecosystemService";

const biomeEcosystemsReducer = (state: TreeType[] = [], action: any) => {
  switch (action.type) {
    case BIOME_ECOSYSTEMS_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default biomeEcosystemsReducer;
