import { AccessControl } from "role-acl";
import { resources, userRole, actions } from "../shared/Literals";

//https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/#:~:text=To%20start%20using%20Role%2DBased,a%20static%20and%20a%20dynamic.

const ac = new AccessControl();

ac.grant(userRole.basic)
  .execute(actions.read)
  .on(resources.home.name)
  .execute(actions.read)
  .on(resources.esvd.name)
  .on(resources.userProfile.name)
  .execute(actions.read)
  
  .grant(userRole.user)
  .extend(userRole.basic)
  .execute(actions.read)
  .on(resources.suggestStudy.name)
  
ac.grant(userRole.editorInChief)
  .extend(userRole.basic)
  .execute(actions.read)
  .on(resources.studyEditor.name);

ac.grant(userRole.dataAnalyst)
  .extend(userRole.basic)
  .execute(actions.read)
  .on(resources.study.name);

ac.grant(userRole.admin).extend([
  userRole.user,
  userRole.dataAnalyst,
  userRole.editorInChief,
]).execute(actions.read)
  .on(resources.admin.name);;

ac.grant(userRole.admin)
  // .extend(userRole.admin)
  .execute(actions.read)
  .on(resources.downloads.name);

ac.grant(userRole.admin)
  // .extend(userRole.admin)
  .execute(actions.read)
  .on(resources.settings.name);

ac.grant(userRole.admin)
  // .extend(userRole.admin)
  .execute(actions.read)
  .on(resources.vtt.name);

ac.grant(userRole.admin)
  // .extend(userRole.admin)
  .execute(actions.read)
  .on(resources.vttCalculations.name);

ac.grant(userRole.admin)
  // .extend(userRole.admin)
  .execute(actions.read)
  .on(resources.uploads.name);

// ac.grant(userRole.cocapacity)
//   .extend(userRole.admin)
//   .execute(actions.read)
//   .on(resources.admin.name);

ac.grant(userRole.cocapacity)
  .extend(userRole.admin)
  .execute(actions.read)
  .on(resources.vsluploads.name);

export default ac;
