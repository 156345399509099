import React from "react";

interface IProps {
	titleText: string;
	logoText?: string | null;
	accentText?: string | null;
	subheadText?: string | null;
	showButton?: any;
}
const WelcomeHeader = (props: IProps) => {
	const { titleText, logoText, accentText, subheadText, showButton } = props;
	return (
		<div className="account">
			<div className="account__head">
				<h3 className="account__title">
					{titleText}{" "}
					<span className="account__logo">
						{logoText}
						<span className="account__logo-accent">{accentText}</span>
					</span>
				</h3>
				<h4 className="account__subhead subhead">{subheadText}</h4>
				{showButton && showButton}
			</div>
		</div>
	);
};

export default WelcomeHeader;
