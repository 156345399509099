// import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import {
	Button,
	Card,
	Checkbox,
	Header,
	Icon,
	List,
	Table,
} from "semantic-ui-react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
// import { useVttBiome } from "../../../providers/VttBiomeProvider";
import { LIMIT, resources } from "../../../shared/Literals";
import {
	VttBiome,
	VttVariableDetailMap,
} from "../../../models/vtt-models/VttBiome";
import MyModal from "../../../layouts/Modals";
import { useVttVariableDetailMap } from "../../../providers/VttVariableDetailMapProvider";
import { ViewHandles, useVtt } from "../../../providers/VttProvider";
import { useVttBiome } from "../../../providers/VttBiomeProvider";
// import VttVariableDetailEntry from "./VttVariableDetailEntry";

const VttBiomeDetails = () => {
	const { currentView, setCurrentView } = useVtt();
	const { getVttBiome, toggleShowForUserCalculations } = useVttBiome();
	const { getVttVariableDetailMaps, deleteVttVariableDetailMap } =
		useVttVariableDetailMap();
	const [isLoading, setIsLoading] = React.useState(false);
	const [vttBiome, setVttBiome] = useState<VttBiome>();
	const [vttVariableDetailMaps, setVttVariableDetailMaps] =
		useState<VttVariableDetailMap[]>();
	const [opened, setOpened] = useState<boolean>(false);
	const [delItem, setDelItem] = useState<number | null>(null);
	const [isModalConfirmed, setIsModalConfirmed] = useState<boolean>(false);
	const [checked, setChecked] = useState<boolean | null>(false);

	// const location = useLocation<any>();

	// const history = useHistory();
	// const navigateDetails = useCallback(
	// 	() => history.push(resources.vtt.path),
	// 	[history]
	// );

	useEffect(() => {
		const { biomeid } = currentView;
		setIsLoading(true);
		(async () => {
			if (biomeid) {
				var biome = await getVttBiome(biomeid);
				setVttBiome(biome);
				biome && setChecked(biome.showForUserCalculations);
				var vtt = await getVttVariableDetailMaps(biomeid);
				setVttVariableDetailMaps(vtt);
			}
			setIsLoading(false);
		})();

		return () => {};
	}, []);

	useEffect(() => {
		if (isModalConfirmed) {
			setIsModalConfirmed(false);
			(async () => {
				if (delItem) {
					const { biomeid } = currentView;
					await deleteVttVariableDetailMap(delItem);
					var vtt = await getVttVariableDetailMaps(biomeid);
					setVttVariableDetailMaps(vtt);
					setIsLoading(false);
					setDelItem(null);
				}
			})();
		}
		return () => {};
	}, [isModalConfirmed]);

	const deleting = (id: number) => {
		setOpened(true);
		setDelItem(id);
	};

	const renderContent = () => {
		return (
			<>
				<Table celled selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Variables</Table.HeaderCell>
							<Table.HeaderCell>Sub biomes</Table.HeaderCell>
							<Table.HeaderCell>coefficient</Table.HeaderCell>
							<Table.HeaderCell>sampleMean</Table.HeaderCell>
							<Table.HeaderCell>min</Table.HeaderCell>
							<Table.HeaderCell>max</Table.HeaderCell>
							<Table.HeaderCell>is Transformed</Table.HeaderCell>
							<Table.HeaderCell>remarks</Table.HeaderCell>
							<Table.HeaderCell></Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{vttVariableDetailMaps &&
							vttVariableDetailMaps.map((vttdetail, i) => {
								return (
									<Table.Row key={i}>
										<Table.Cell>
											<Link
												to={{
													pathname: resources.vttCalculations.path,
													state: {
														id: vttdetail.id,
														variableid: vttdetail.vttVariableId,
													},
												}}>
												{vttdetail.vttVariable}
											</Link>
										</Table.Cell>
										<Table.Cell>{vttdetail.vttSubBiome}</Table.Cell>
										<Table.Cell>{vttdetail.coefficient}</Table.Cell>
										<Table.Cell>{vttdetail.sampleMean}</Table.Cell>
										<Table.Cell>{vttdetail.min}</Table.Cell>
										<Table.Cell>{vttdetail.max}</Table.Cell>
										<Table.Cell>
											{vttdetail.isTransformation ? "yes" : ""}
										</Table.Cell>
										<Table.Cell>{vttdetail.remarksText}</Table.Cell>
										<Table.Cell textAlign="right">
											<Button.Group>
												<button
													className="btn btn-success btn-sm"
													style={{ minWidth: "4em" }}
													onClick={() => {
														const { biomeid } = currentView;
														setCurrentView({
															view: ViewHandles.manageDetail,
															biomeid: biomeid,
															detailid: vttdetail.id,
														});
													}}>
													<Icon name="edit" />
												</button>
												<button
													className="btn btn-warning btn-sm"
													style={{ minWidth: "4em" }}
													onClick={() =>
														vttdetail.id && deleting(vttdetail.id)
													}>
													<Icon name="trash" />
												</button>
											</Button.Group>
										</Table.Cell>
									</Table.Row>
								);
							})}
					</Table.Body>
				</Table>
			</>
		);
	};

	const toggleShow = async (biomeid: number, checkstate: boolean) => {
		await toggleShowForUserCalculations(biomeid, checkstate);
		setChecked(checkstate);
	};

	return (
		<>
			<Card.Content>
				<h4>{vttBiome && vttBiome.name}</h4>
				<Card.Description>
					{`Constant Value:${vttBiome && vttBiome.constantValue}`}
				</Card.Description>
			</Card.Content>
			<Card.Content>
				<Checkbox
					name="showForUserCalculations"
					label="Show this Biome in the VTT to visitors"
					onChange={(e, data: any) =>
						vttBiome && vttBiome.id && toggleShow(vttBiome.id, data.checked)
					}
					checked={checked || false}
				/>
			</Card.Content>
			<Card.Content>
				<button
					className="ui button btn btn-primary "
					style={{
						backgroundColor: "#70bbfd",
						height: "2rem",
					}}
					onClick={() => {
						setCurrentView({
							view: ViewHandles.biome,
						});
					}}>
					Back
				</button>
				<button
					className="ui button btn btn-primary right floated"
					style={{
						backgroundColor: "#70bbfd",
						height: "2rem",
					}}
					onClick={() => {
						const { biomeid } = currentView;
						setCurrentView({
							view: ViewHandles.manageDetail,
							biomeid: biomeid,
						});
					}}>
					Add Variable details
				</button>
			</Card.Content>
			<Card.Content style={{ overflowY: "auto", height: "80vh" }}>
				{renderContent()}
			</Card.Content>

			<MyModal
				title="Delete"
				body="Are you sure you want to delete?"
				opened={opened}
				setOpened={setOpened}
				setConfirmation={setIsModalConfirmed}
			/>
		</>
	);
};

// const views = { list: 1, entry: 2 };

// const VttBiomeDetailWrapper = (props: any) => {
// 	const [view, setView] = useState(views.list);
// 	const [item, setItem] = useState<number | null>(null);

// 	useEffect(() => {
// 		setItem(null);
// 	}, [view]);

// 	const toggleView = (option: number) => {
// 		setView(option);
// 	};

// 	const selectedItem = (detailid: number | null) => {
// 		setItem(detailid);
// 		toggleView(views.entry);
// 	};

// 	const renderView = (option: number) => {
// 		switch (option) {
// 			case views.entry:
// 				return (
// 					<VttVariableDetailEntry toggleView={toggleView} detailid={item} />
// 				);
// 			default:
// 				return (
// 					<VttBiomeDetails
// 						toggleView={toggleView}
// 						selectedItem={selectedItem}
// 					/>
// 				);
// 		}
// 	};

// 	return <>{renderView(view)}</>;
// };

export default VttBiomeDetails;
