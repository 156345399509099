// import _ from "lodash";
import { RESET_ESVDS } from "../../actions/esvdActions";
import {
  ESVDS_FETCH_COUNT,
  ESVDS_FETCH_FULL_COUNT,
  RESET_FILTERED_ESVDS,
  ESVDS_FETCH_FILTERED_COUNT,
} from "../../actions/esvdStatsActions";

export const esvdCountReducer = (state: number = 0, action: any) => {
  switch (action.type) {
    case ESVDS_FETCH_COUNT:
      return action.payload;
    case RESET_ESVDS:
      return 0;
    default:
      return state;
  }
};

export const esvdFilteredCountReducer = (state: number = 0, action: any) => {
  switch (action.type) {
    case ESVDS_FETCH_FILTERED_COUNT:
      return action.payload;
    case RESET_FILTERED_ESVDS:
      return 0;
    default:
      return state;
  }
};

export const esvdFullCountReducer = (state: number = 0, action: any) => {
  switch (action.type) {
    case ESVDS_FETCH_FULL_COUNT:
      return action.payload;
    case RESET_ESVDS:
      return 0;
    default:
      return state;
  }
};
