import React from "react";

export function MultiSelectRenderer(props: any) {
	const { value, cellProperties, data } = props;
	const txt: any[] = [];

	const formatValue = () => {
		if (value) {
			//build data
			const sp = value.split(";");
			if (Array.isArray(sp) && data && Array.isArray(data)) {
				// const idd = parseInt(sp[0]);
				// const idd = sp[0];
				// if (!idd || typeof idd !== "string") {
				// 	return value;
				// }
				cellProperties.data = JSON.stringify(
					sp.map((label) => {
						const it = data.find((x) => x.label === label.trim());
						if (!it) return value;
						txt.push(it.label);
						return { value: it.id, label, code: it.code };
					})
				);
			}
			return txt.length > 0 ? txt.join("; ") : value;
		} else {
			return value;
		}
	};
	// The avaiable renderer-related props are:
	// - row (row index)
	// - col (column index)
	// - prop (column property name)
	// - TD (the HTML cell element)
	// - cellProperties (the cellProperties object for the edited cell)
	// console.log(txt);

	return formatValue();
	// <>
	// 	<i style={{ color: "#a9a9a9" }}>
	// 		Row: {props.row}, column: {props.col},
	// 	</i>{" "}
	// 	value: {props.value}
	// </>
}
