import React, { useContext } from "react";
// import _ from "lodash";
import agent from "../api/agent";
import { PagingParams } from "../models/PaginationParams";
import { VttBiome } from "../models/vtt-models/VttBiome";

interface IValue {
	getVttBiomes: Function;
	getVttBiomeCount: Function;
	createVttBiome: Function;
	getVttBiome: Function;
	updateVttBiome: Function;
	deleteVttBiome: Function;
	toggleShowForUserCalculations: Function;
}

const VttBiomeContext = React.createContext<IValue | null>(null);

// The FilterQueryProvider is responsible for FilterQuery management
const VttBiomeProvider = ({ children }: { children: any }) => {
	const getVttBiomeCount = async () => {
		try {
			return await agent.VttBiomes.count();
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getVttBiomes = async (
		pageParams: PagingParams,
		excludeFalseShowForUserCalculations: boolean = false
	) => {
		try {
			const vtts = excludeFalseShowForUserCalculations
				? await agent.VttBiomes.listExclude()
				: await agent.VttBiomes.list(pageParams);
			if (!vtts) return [];
			return vtts;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const getVttBiome = async (id: number) => {
		try {
			return await agent.VttBiomes.details(id);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const toggleShowForUserCalculations = async (
		id: number,
		showForUserCalculations: boolean
	) => {
		try {
			return await agent.VttBiomes.toggleShowForUserCalculations(
				id,
				showForUserCalculations
			);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const createVttBiome = async (vttBiome: VttBiome) => {
		try {
			await agent.VttBiomes.create(vttBiome);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const updateVttBiome = async (vttBiome: VttBiome) => {
		try {
			await agent.VttBiomes.update(vttBiome);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const deleteVttBiome = async (id: number) => {
		try {
			await agent.VttBiomes.delete(id);
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<VttBiomeContext.Provider
			value={{
				getVttBiomes,
				getVttBiomeCount,
				createVttBiome,
				getVttBiome,
				updateVttBiome,
				deleteVttBiome,
				toggleShowForUserCalculations,
			}}>
			{children}
		</VttBiomeContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useVttBiome = () => {
	const filterQuery = useContext(VttBiomeContext);
	if (filterQuery == null) {
		throw new Error("useVttBiome() called outside of its hook.");
	}
	return filterQuery;
};

export { VttBiomeProvider, useVttBiome };
