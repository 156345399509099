import React from "react";
import { Form, Grid } from "semantic-ui-react";

const VTTCalculationFourColumnHeader = (props: any) => {
	const { col1, col2, col3, col4 } = props;
	return (
		<Form.Field>
			<Grid>
				<Grid.Row>
					<Grid.Column width={4}>
						<h5>
							<strong>{col1}</strong>
						</h5>
					</Grid.Column>
					<Grid.Column width={4}>
						<h5>
							<strong>{col2}</strong>
						</h5>
					</Grid.Column>
					<Grid.Column width={6}>
						<h5>
							<strong>{col3}</strong>
						</h5>
					</Grid.Column>
					<Grid.Column width={2}>
						<h5>
							<strong>{col4}</strong>
						</h5>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form.Field>
	);
};

export default VTTCalculationFourColumnHeader;
