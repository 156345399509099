import React from "react";
import { Table, TableBody, TableCell, TableRow } from "semantic-ui-react";
import { Statistics } from "../../models/esvd";

const StatsPopup =(props: Statistics)=> {
  // render() {
    const  { mean, stdDev, count, max, min, median }
     = props;
    return (
      <Table basic="very" compact>
        <TableBody>
          <TableRow>
            <TableCell>Number of Valuations:</TableCell>
            <TableCell textAlign="right">{count}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mean:</TableCell>
            <TableCell textAlign="right">{mean}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Median:</TableCell>
            <TableCell textAlign="right">{median}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Standard Deviation:</TableCell>
            <TableCell textAlign="right">{stdDev}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Minimum Value:</TableCell>
            <TableCell textAlign="right">{min}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Maximum Value:</TableCell>
            <TableCell textAlign="right">{max}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  // }
}

export default StatsPopup;
