import React from "react";
import { CSVLink } from "react-csv";
import { Button, Card, Progress } from "semantic-ui-react";
import moment from "moment";
// import { useFilterQuery } from "../../providers/FilterQueryProvider";
import { useReferenceInfo } from "../../providers/ReferenceInfoProvider";
import { useAuth } from "../../providers/AuthProvider";
import { User } from "../../models/user";

interface IProp {
	sortBy: any;
}

const UserProfilesButton = (props: IProp) => {
	const { currentInfo } = useReferenceInfo();
	const { getUsers } = useAuth();
	const csvLinkRef = React.useRef<any>();
	const [csvData, setCsvData] = React.useState<any[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [filename, setFilename] = React.useState<string>("");

	const downloadUserProfiles = async () => {
		const { sortBy } = props;
		setFilename(
			`Esvd_user_profiles_${moment().format("Do-MMM-yyyy_HH-mm-ss")}_${
				currentInfo?.version
			}.csv`
		);

		setIsLoading(true);
		const data = await getUsers({
			params: {
				sortColumn: sortBy.key,
				isDescend: sortBy.order === "asc" ? false : true,
			},
		});
		const dat = data.map((user: User) => {
			const us: any = {};
			us["Display name"] = user.displayName;
			us["Username"] = user.userName;
			us["Email"] = user.email;
			us["Registration Date"] =
				moment(user.registrationDate) > moment("01-Jan-0001")
					? moment(user.registrationDate).format("DD-MM-YYYY HH:mm:ss")
					: "";
			us["Number Of SignIn"] = user.numberOfSignIn;
			us["Last SignIn Date"] =
				moment(user.lastSignInDate) > moment("01-Jan-0001")
					? moment(user.lastSignInDate).format("DD-MM-YYYY HH:mm:ss")
					: "";
			if (user.sector && user.sector.toLowerCase() === "other") {
				user.sector = `${user.sectorOther}`;
			}
			us["sector"] = user.sector;
			us["Organization"] = user.organization;
			if (
				user.purposeOfAccess &&
				user.purposeOfAccess.toLowerCase() === "other"
			) {
				user.purposeOfAccess = `${user.purposeOfAccessOther}`;
			}
			us["Purpose Of Access"] = user.purposeOfAccess;
			return us;
		});
		setCsvData(dat);
		setIsLoading(false);
		if (csvLinkRef?.current) {
			csvLinkRef.current.link.click();
		}
		// });
	};

	// const progress = () => {
	// 	if (count === 0) return 0;
	// 	let pro = 0;
	// 	let nextOffset = 0;
	// 	while (nextOffset < count) {
	// 		pro = Math.ceil(((nextOffset + 1) / count) * 100);
	// 		nextOffset++;
	// 	}
	// 	return pro;
	// };

	return (
		<>
			<Button
				positive
				loading={isLoading}
				content={"Download User Profiles"}
				onClick={() => downloadUserProfiles()}
				disabled={isLoading}
				className={`${isLoading ? "invisible" : ""}`}
			/>
			<Progress
				// percent={progress}
				// progress
				className={`${isLoading ? "" : "invisible"}`}
			/>
			<CSVLink
				ref={csvLinkRef}
				data={csvData}
				filename={filename}
				target="_blank"
				className={`${"invisible"}`}
			/>
		</>
	);
};

export default UserProfilesButton;
