import { STUDYLOCATIONS_FETCH } from "../../actions/others/studyLocationActions";

const studyLocationReducer = (state: any[] = [], action: any) => {
  switch (action.type) {
    case STUDYLOCATIONS_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default studyLocationReducer;
