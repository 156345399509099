import { Component } from "react";
import { Card } from "semantic-ui-react";
import flowerbee from "../../images/flowerbee.jpg";

class EcosystemImage extends Component {
	render() {
		return (
			<Card fluid>
				<img
					src={flowerbee}
					alt="flowerbee"
					style={{
						height: "100%",
						backgroundSize: "cover",
					}}
				/>
			</Card>
		);
	}
}

export default EcosystemImage;
