import agent from "../../api/agent";
export const VALUEDCHANGE_CREATE = "VALUEDCHANGE_CREATE";
export const VALUEDCHANGE_EDIT = "VALUEDCHANGE_EDIT";
export const VALUEDCHANGE_DELETE = "VALUEDCHANGE_DELETE";
export const VALUEDCHANGE_FETCH = "VALUEDCHANGE_FETCH";
export const VALUEDCHANGES_FETCH = "VALUEDCHANGES_FETCH";

export const fetchValuedChanges = () => async (dispatch: any) => {
  const response = await agent.ValuedChanges.list();

  dispatch({
    type: VALUEDCHANGES_FETCH,
    payload: response,
  });
};
