import { ErrorMessage, Field, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { Form, Label, Card, Grid } from "semantic-ui-react";
// import * as Yup from "yup";
import agent from "../../api/agent";
import { ErrorHandler } from "../../shared/ExceptionHandling";
import EsvdInputInline from "../../shared/InputInline";
import MyModal from "../../layouts/Modals";
// import { initReferenceInfo } from "../../shared/Literals";
// import { ReferenceInfo } from "../../models/ReferenceInfo";

interface IValuationid {
	valuationid?: string | number;
	error?: string;
}

const initValuation: IValuationid = { valuationid: "" };

const DeleteValuation = () => {
	const formiRef = React.useRef<any>();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [valuationToDelete] = React.useState<IValuationid>(initValuation);
	const [opened, setOpened] = useState<boolean>(false);
	const [isModalConfirmed, setIsModalConfirmed] = useState<boolean>(false);
	const [delItem, setDelItem] = useState<IValuationid>(initValuation);
	const [isActionNeeded, setisActionNeeded] = useState<boolean>(true);
	const [modalMessage, setModalMessage] = useState<string | null>(null);

	const clearForm = () => {
		formiRef.current?.resetForm();
		setDelItem(initValuation);
	};

	useEffect(() => {
		if (isModalConfirmed) {
			setIsModalConfirmed(false);
			(async () => {
				if (delItem) {
					setOpened(false);
					await deleteValuation(delItem);
				}
			})()
				.then((res: any) => {
					setOpened(true);
					setModalMessage("Valuation deleted successfully.");

					setisActionNeeded(false);
					clearForm();

					setIsLoading(false);
				})
				.catch((err) => {
					setOpened(true);
					setisActionNeeded(false);
					setModalMessage(err || null);
				});
		}
		return () => {};
	}, [isModalConfirmed, delItem]);

	const renderButtons = (values: any) => {
		return (
			<Fragment>
				<Grid>
					<Grid.Column width={4}>
						<button
							className="ui button btn btn-primary"
							type="reset"
							style={{
								marginTop: "1rem",
								backgroundColor: "#70bbfd",
								height: "2rem",
								width: "4rem",
							}}
							onClick={() => clearForm()}>
							Clear
						</button>
					</Grid.Column>
					<Grid.Column width={12}>
						<div className="ui right floated">
							<button
								className="ui button btn btn-danger"
								type="submit"
								style={{
									marginTop: "1rem",
									// backgroundColor: "#70bbfd",
									display: "block !important",
									height: "2rem",
									width: "4rem",
									marginRight: "2rem",
								}}>
								<span className={`${isLoading ? "hide" : ""}`}>Delete</span>
								<span
									className={`box loader-01 ${
										isLoading ? "" : "invisible"
									}`}></span>
							</button>
						</div>
					</Grid.Column>
				</Grid>
			</Fragment>
		);
	};
	const renderContent = () => {
		return (
			<>
				<br />
				<Formik
					innerRef={formiRef}
					enableReinitialize={true}
					// validationSchema={validationSchema}
					initialValues={valuationToDelete}
					onSubmit={(values, { setErrors, resetForm }) => {
						handleFormSubmit(values, setErrors, resetForm);
					}}>
					{({ values, handleSubmit, isValid, isSubmitting, dirty, errors }) => (
						<Form
							className="custom-ui-element"
							onSubmit={handleSubmit}
							autoComplete={"off"}>
							<EsvdInputInline
								name="valuationid"
								label="Valuation Id"
								fluid
								placeholder="Valuation Id to delete"
							/>
							{errors.error && (
								<Label
									style={{ marginBottom: 5, borderColor: "none" }}
									basic
									color="red"
									size="tiny"
									// content={"Please enter Valuation Id to delete."}
									content={errors.error}
								/>
							)}

							{renderButtons(values)}
						</Form>
					)}
				</Formik>
			</>
		);
	};

	const deleting = (valuation: IValuationid) => {
		setOpened(true);
		setisActionNeeded(true);
		setModalMessage("Are you sure you want to delete?");
		setDelItem(valuation);
	};

	const deleteValuation = async (valuation: IValuationid) => {
		try {
			const response = await agent.Esvds.delete(
				Number(valuation.valuationid ?? 0)
			);

			return response;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	const handleFormSubmit: Function = (
		values: IValuationid,
		setErrors: Function,
		resetForm: Function
	) => {
		if (!values.valuationid) {
			setErrors({
				error: ErrorHandler("Please enter Valuation Id to delete."),
			});
			return;
		}
		deleting(values);
	};

	return (
		<>
			<Card fluid>
				<Card.Content>
					<h4>{"Delete Valuation"}</h4>
				</Card.Content>

				<Card.Content>{renderContent()}</Card.Content>
			</Card>
			<MyModal
				title="Delete"
				body={modalMessage || ""} //"Are you sure you want to delete this Study Record ?"
				opened={opened}
				setOpened={setOpened}
				setConfirmation={setIsModalConfirmed}
				needsAction={isActionNeeded}
			/>
		</>
	);
};

export default DeleteValuation;
