import React, { useContext, useState } from "react";
// import _ from "lodash";
// import agent from "../api/agent";
// import { PagingParams } from "../models/PaginationParams";
// import { VttBiome } from "../models/vtt-models/VttBiome";

interface IValue {
	setCurrentView: Function;
	currentView: any;
}

export const ViewHandles = {
	manage: "manage",
	manageDetail: "manageDetail",
	biome: "biome",
	biomeEntry: "biomeEntry",
	subbiome: "subbiome",
	subbiomeEntry: "subbiomeEntry",
	variable: "variable",
	variableEntry: "variableEntry",
};

const VttContext = React.createContext<IValue | null>(null);

// The FilterQueryProvider is responsible for FilterQuery management
const VttProvider = ({ children }: { children: any }) => {
	const [currentView, setCurrentView] = useState<any>({
		view: ViewHandles.biome,
	});

	return (
		<VttContext.Provider
			value={{
				setCurrentView,
				currentView,
			}}>
			{children}
		</VttContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useVtt = () => {
	const vtt = useContext(VttContext);
	if (vtt == null) {
		throw new Error("useVtt() called outside of its hook.");
	}
	return vtt;
};

export { VttProvider, useVtt };
