import agent from "../api/agent";
export const ECOSYSTEM_CONDITION_CREATE = "ECOSYSTEM_CONDITION_CREATE";
export const ECOSYSTEM_CONDITION_EDIT = "ECOSYSTEM_CONDITION_EDIT";
export const ECOSYSTEM_CONDITION_DELETE = "ECOSYSTEM_CONDITION_DELETE";
export const ECOSYSTEM_CONDITION_FETCH = "ECOSYSTEM_CONDITION_FETCH";
export const ECOSYSTEM_CONDITIONS_FETCH = "ECOSYSTEM_CONDITIONS_FETCH";

export const fetchEcosystemConditions = () => async (dispatch: any) => {
  const response = await agent.EcosystemConditions.list();

  dispatch({
    type: ECOSYSTEM_CONDITIONS_FETCH,
    payload: response,
  });
};
