//column implementation

import { ItemLabels } from "../shared/Literals";
import {
  ColumnStructure,
  DisplayColumnStructure,
  SummaryStatsColumnStructure,
} from "./columnStructure";

/**
 * isFlat: values are concatenated into one column instead of having separate column for each value.
 * separator: the separator to use when concatenating the values.
 * isMulti: the the property may produce multiple columns dynamically.
 */
export const ColumnTemplate: ColumnStructure = {
  valueId: "ValueId",
  studyCode: {
    isSimple: true,
    spec: {
      caption: "StudyId",
      dbField: "studyCode",
    },
  },
  // esvdFlatBiomes: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Biomes",
  //     dbField: "esvdFlatBiomes",
  //   },
  // },
  // esvdFlatBiomeCodes: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Biome_Codes",
  //     dbField: "esvdFlatBiomeCodes",
  //   },
  // },
  esvdFlatBiomeV2s: {
    isSimple: true,
    spec: {
      caption: "ESVD2.0_Biome",
      dbField: "esvdFlatBiomeV2s",
    },
  },
  esvdFlatBiomeV2Codes: {
    isSimple: true,
    spec: {
      caption: "ESVD2.0_Biome_Codes",
      dbField: "esvdFlatBiomeV2Codes",
    },
  },
  biomes: {
    maxLen: 0,
    isMulti: true,
    specs: {
      caption: "ESVD2.0_Biome_",
      dbField: "name",
      separator: ";",
      flatten: false,
      baseColumn: "esvdFlatBiomeV2s",
    },
  },

  esvdFlatEcozones: {
    isSimple: true,
    spec: {
      caption: "ESVD2.0_Ecozones",
      dbField: "esvdFlatEcozones",
    },
  },
  esvdFlatEcozoneCodes: {
    isSimple: true,
    spec: {
      caption: "ESVD2.0_Ecozone_Codes",
      dbField: "esvdFlatEcozoneCodes",
    },
  },
    ecozones: {
    maxLen: 0,
    isMulti: true,
    specs: {
      caption: "ESVD2.0_Ecozone_",
      dbField: "name",
      separator: ";",
      flatten: false,
      baseColumn: "esvdFlatEcozones",
    },
  },
  esvdFlatEcosystemV2s: {
    isSimple: true,
    spec: {
      caption: "ESVD2.0_Ecosystems",
      dbField: "esvdFlatEcosystemV2s",
    },
  },
  esvdFlatEcosystemV2Codes: {
    isSimple: true,
    spec: {
      caption: "ESVD2.0_Ecosystem_Codes",
      dbField: "esvdFlatEcosystemV2Codes",
    },
  },
  ecosystems: {
    maxLen: 0,
    isMulti: true,
    specs: {
      caption: "ESVD2.0_Ecosystem_",
      dbField: "name",
      separator: ";",
      flatten: false,
      baseColumn: "esvdFlatEcosystemV2s",
    },
  },
  //for multi column entries use array to indicate that it's multi column
  ecosystemDescription: {
    isSimple: true,
    spec: {
      caption: "Ecosystem Text",
      dbField: "ecosystemDescription",
    },
  },
 
  // ecosystemServiceDescription: "ES Text",
  /**
   * The structure of ecosystem services demands treat.
   * Since the columns have those for subservices embeded,
   * we also process the subservices column while processing the services.
   * Unless there's a need for change
   */
  ecosystemServiceDescription: {
    isSimple: true,
    // isHide: true,
    spec: {
      caption: "ES_Text",
      dbField: "ecosystemServiceDescription",
    },
  },
  esvdFlatEcosystemServiceCodes: {
    isSimple: true,
    spec: {
      caption: "TEEB_ES",
      dbField: "esvdFlatEcosystemServiceCodes",
    },
  },
  esvdFlatEcosystemSubServiceCodes: {
    isSimple: true,
    spec: {
      caption: "TEEB_SubES",
      dbField: "esvdFlatEcosystemSubServiceCodes",
    },
  },
  ecosystemServices: {
    maxLen: 0,
    isMulti: true,
    specs: {
      caption: "ES_",
      dbField: "name",
      flatten: false,
      baseColumn: "esvdFlatEcosystemServices",      
    },
  },
  esvdFlatCICESCodes: {
    isSimple: true,
    spec: {
      caption: "CICES",
      dbField: "esvdFlatCICESCodes",
    },
  },
  esvdFlatSEEAMain: {
    isSimple: true,
    spec: {
      caption: "SEEA",
      dbField: "esvdFlatSEEAMain",
    },
  },
  esvdFlatCountries: {
    isSimple: true,
    spec: {
      caption: "Countries",
      dbField: "esvdFlatCountries",
    },
  },
  esvdFlatCountryCodes: {
    isSimple: true,
    spec: {
      caption: "Country_Codes",
      dbField: "esvdFlatCountryCodes",
    },
  },
  countryCodes: {
    maxLen: 0,
    isMulti: true,
    specs: {
      caption: "Country_Code_",
      dbField: "isoCode",
      flatten: false,
      separator: ";",
      baseColumn: "esvdFlatCountryCodes",
    },
  },
  countries: {
    maxLen: 0,
    isMulti: true,
    specs: {
      caption: "Country_",
      dbField: "name",
      flatten: false,
      baseColumn: "esvdFlatCountries",
    },
  },
  continent: "Continent",
  scaleOfStudySite: "Scale Of Site",
  studyLocation: "Study Location",
  protectionStatus: "Protection Status",
  siteArea: "Site Area",
  siteAreaUnit: "Site Area Unit",
  siteAreaInHectares: "Site Area In Hectares",
  siteLength: "Site Length",
  siteLengthUnit: "Site Length Unit",
  siteLengthKm: "Site Length Km",
  siteCondition: "Site Condition",
  ecosystemCondition: "Ecosystem Condition",
  latitude: "Latitude",
  longitude: "Longitude",
  esvdFlatValuationMethodCodes: {
    isSimple: true,
    spec: {
      caption: "Valuation Methods",
      dbField: "esvdFlatValuationMethodCodes",
    },
  },
  valuationMethods: {
    maxLen: 0,
    isMulti: true,
    specs: {
      caption: "Valuation_Method_",
      dbField: "acronym",
      flatten: false,
      baseColumn: " esvdFlatValuationMethodCodes",
    },
  },
  valuedChange: "Valued Change",
  originalValue: "Original Value",
  currency: "Currency",
  valueYear: "Value Year",
  spatialUnit: "Spatial Unit",
  temporalUnit: "Temporal Unit",
  presentValueYear: "Present Value Year",
  presentValueDiscountRate: "Present Value Discount Rate",
  beneficiaryUnit: "Beneficiary Unit",
  numberOfBeneficiaries: "Number Of Beneficiaries",
  typeOfBeneficiary: "Type Of Beneficiary",
  intPerHectarePerYear: "Int$ Per Hectare Per Year",
  authors: "Authors",
  title: "Title",
  reference: "Reference",
  yearOfPublication: "Year_Pub",
  notes: "Notes",
  secondaryDataSources: "Secondary Data Sources",
  // esvdFlatCoders: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Coded_By",
  //     dbField: "esvdFlatCoders",
  //   },
  // },
  // codedDate: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Coded Date",
  //     dbField: "codedDate",
  //     isDate: { format: "DD-MMM-YYYY" },
  //   },
  // },
  // esvdFlatReviewers: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Reviewer",
  //     dbField: "esvdFlatReviewers",
  //   },
  // },
  // reviewedDate: "ReviewedDate",
  reviewed: "Reviewed",
  // inclExcl: "inclExcl",
  inclExclText: {
    isSimple: true,
    spec: {
      caption: "inclExcl",
      dbField: "inclExclText",
    },
  },
  // inclExclComments: "inclExclComments",
};

export const SummaryStatsColumnTemplate: SummaryStatsColumnStructure = {
  valueId: "ValueId",

  biomeV2EcosystemV2Ecozones: {
    maxLen: 0,
    isMulti: true,
    specs: [
      {
        caption: ItemLabels.biomeCode,
        dbField: "code",
        separator: ";",
        flatten: true,
      },
      {
        caption: ItemLabels.biome,
        dbField: "name",
        separator: ";",
        flatten: true,
      },
    ],
  },
  ecosystemServices: {
    maxLen: 0,
    isMulti: true,
    specs: [
      {
        caption: ItemLabels.teebCode,
        dbField: "code",
        flatten: true,
      },
      {
        caption: ItemLabels.teeb,
        dbField: "name",
        flatten: true,
      },
    ],
  },

  intPerHectarePerYear: ItemLabels.Int_Per_Hectare_Per_Year,
  inclExcl:ItemLabels.InclExcl
};

export const DisplayColumnTemplate: DisplayColumnStructure = {
  // valueId: {
  //   isSimple: true,
  //   spec: {
  //     caption: "ValueID",
  //     dbField: "valueId",
  //     width: 100,
  //     // sortable: true,
  //   },
  // },
  // studyCode: {
  //   isSimple: true,
  //   spec: {
  //     caption: "StudyID",
  //     dbField: "studyId",
  //     width: 80,
  //     // sortable: true,
  //   },
  // },
  // esvdFlatBiomes: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Biomes",
  //     dbField: "esvdFlatBiomes",
  //     width: 200,
  //   },
  // },
  // esvdFlatEcosystems: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Ecosystems",
  //     dbField: "esvdFlatEcosystems",
  //     width: 200,
  //   },
  // },
  esvdFlatBiomeV2s: {
    isSimple: true,
    spec: {
      caption: "Biomes",
      dbField: "esvdFlatBiomeV2s",
      width: 200,
    },
  },
  esvdFlatEcozones: {
    isSimple: true,
    spec: {
      caption: "Ecozones",
      dbField: "esvdFlatEcozones",
      width: 200,
    },
  },
  esvdFlatEcosystemV2s: {
    isSimple: true,
    spec: {
      caption: "Ecosystems",
      dbField: "esvdFlatEcosystemV2s",
      width: 200,
    },
  },
  esvdFlatEcosystemServices: {
    isSimple: true,
    spec: {
      caption: "TEEB services",
      dbField: "esvdFlatEcosystemServices",
      width: 200,
    },
  },

  esvdFlatCICESs: {
    isSimple: true,
    spec: {
      caption: "CICES services",
      dbField: "esvdFlatCICESs",
      width: 200,
    },
  },

  esvdFlatSEEAMain: {
    isSimple: true,
    spec: {
      caption: "SEEA services",
      dbField: "esvdFlatSEEAMain",
      width: 200,
    },
  },
  // originalValue: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Original Value",
  //     dbField: "originalValue",
  //     width: 200,
  //   },
  // },
  intPerHectarePerYear: {
    isSimple: true,
    spec: {
      caption: "Value 2020 $/ha/yr",
      dbField: "intPerHectarePerYear",
      width: 150,
      align:"right",
      // sortable: true,
    },
  },

  esvdFlatValuationMethods: {
    isSimple: true,
    spec: {
      caption: "Valuation Method",
      dbField: "esvdFlatValuationMethods",
      width: 200,
    },
  },
  esvdFlatCountries: {
    isSimple: true,
    spec: {
      caption: "Countries",
      dbField: "esvdFlatCountries",
      width: 200,
    },
  },
  studyLocation: {
    isSimple: true,
    spec: {
      caption: "Location Name",
      dbField: "studyLocation",
      width: 150,
    },
  },
  scaleOfStudySite: {
    isSimple: true,
    spec: {
      caption: "Study/Site Scale",
      dbField: "scaleOfStudySite",
      width: 150,
    },
  },

  siteAreaInHectares: {
    isSimple: true,
    spec: {
      caption: "Site Area (ha)",
      dbField: "siteAreaInHectares",
      width: 150,
      align:"right",
    },
  },
  ecosystemCondition: {
    isSimple: true,
    spec: {
      caption: "Ecosystem Condition",
      dbField: "ecosystemCondition",
      width: 150,
    },
  },
  protectionStatus: {
    isSimple: true,
    spec: {
      caption: "Protection Status",
      dbField: "protectionStatus",
      width: 150,
    },
  },
  typeOfBeneficiary: {
    isSimple: true,
    spec: {
      caption: "Beneficiaries",
      dbField: "typeOfBeneficiary",
      width: 150,
    },
  },
  reference: {
    isSimple: true,
    spec: {
      caption: "Bibliographic Reference",
      dbField: "reference",
      width: 200,
    },
  },
  // reviewedDate: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Reviewed Date",
  //     dbField: "reviewedDate",
  //     width: 150,
  //     isDate: { format: "DD-MM-YYYY" },
  //   },
  // },
  reviewed: {
    isSimple: true,
    spec: {
      caption: "Reviewed",
      dbField: "reviewed",
      width: 150,
    },
  },
  // siteLengthKm: {
  //   isSimple: true,
  //   spec: {
  //     caption: "Site Length (km)",
  //     dbField: "siteLengthKm",
  //     width: 150,
  //   },
  // },

};
