import agent from "../api/agent";
import { FilterQueryParams } from "../models/EsvdQueryFilters";
import { EsvdFilters } from "../models/FilterQuery";

export const FILTER_QUERY_CREATE = "FILTER_QUERY_CREATE";
export const FILTER_QUERY_EDIT = "FILTER_QUERY_EDIT";
export const FILTER_QUERY_DELETE = "FILTER_QUERY_DELETE";
export const FILTER_QUERY_FETCH = "FILTER_QUERY_FETCH";
export const FILTER_QUERIES_FETCH = "FILTER_QUERIES_FETCH";

export const fetchFilterQueries =
  (filters: FilterQueryParams) => async (dispatch: any) => {
    try {
      // dispatch({
      //   type: IS_LOADING_FILTER_QUERIES,
      //   payload: true,
      // });
      const response = await agent.FilterQueries.list(filters);

      dispatch({
        type: FILTER_QUERIES_FETCH,
        payload: response,
      });
      // dispatch({
      //   type: IS_LOADING_FILTER_QUERIES,
      //   payload: false,
      // });
    } catch (error: any) {
      throw error.response ? error.response.statusText : error.message;
    }
  };

export const resetFilterQueries = () => async (dispatch: any) => {
  dispatch({
    type: FILTER_QUERIES_FETCH,
    payload: [],
  });
};

export const saveFilterQuery =
  (filter: EsvdFilters) => async (dispatch: any) => {
    try {
      let params = {};
      //remove empty or null properties
      for (const [key, value] of Object.entries(filter)) {
        if (
          (Array.isArray(value) && value.length > 0) ||
          (typeof value === "string" && value.trim() !== "" && value !== null)||
          (typeof value === "number" && value !== null)
        ) {
          params = { ...params, [key]: value };
        }
      }

      //do not save empty filters
      if (Object.keys(params).length === 0) {
        return;
      }
      const response = await agent.FilterQueries.create({
        filterString: JSON.stringify(params),
      });

      dispatch({
        type: FILTER_QUERY_CREATE,
        payload: response,
      });
      return response;
    } catch (error: any) {
      //throw error.response ? error.response.statusText : error.message;
    }
  };

export const deleteFilterQuery = (id: number) => async (dispatch: any) => {
  try {
    await agent.FilterQueries.delete(id);
    dispatch({
      type: FILTER_QUERY_DELETE,
      payload: id,
    });
  } catch (error: any) {
    throw error.response ? error.response.statusText : error.message;
  }
};
