import React, { useContext, useEffect, useState } from "react";
import agent from "../api/agent";
import { getMonthNameShort } from "../EsvdFunctions";
import { CurrentReferenceInfo, ReferenceInfo } from "../models/ReferenceInfo";

interface IValue {
	referenceInfos: ReferenceInfo[];
	saveReferenceInfo: Function;
	// getCurrentReferenceInfo: Function;
	getCurrentVersionInfo: Function;
	currentInfo: CurrentReferenceInfo | null;
}

const ReferenceInfoContext = React.createContext<IValue | null>(null);

// The ReferenceInfoProvider is responsible for ReferenceInfo management
const ReferenceInfoProvider = ({ children }: { children: any }) => {
	const [referenceInfos] = useState<ReferenceInfo[]>([]);

	const [currentInfo, setCurrentInfo] =
		React.useState<CurrentReferenceInfo | null>(null);

	const getCurrentVersionInfo = async () => {
		try {
			const response = await agent.ReferenceInfos.details();
			const { month, year, version, subversion } = response;
			if (month === 0) {
				setCurrentInfo({ version: "", referenceText: "" });
			} else {
				const ver: CurrentReferenceInfo = {
					version:
						month !== null && month !== undefined
							? `${getMonthNameShort(
									month
							  ).toUpperCase()}${year}V${version}.${subversion}`
							: "",
					referenceText: response.referenceText,
				};
				setCurrentInfo(ver);
			}
		} catch (error: any) {
			throw error; //.response ? error.response.statusText : error.message;
		}
	};

	useEffect(() => {
		getCurrentVersionInfo().catch((err) => null);
		return () => {};
	}, []);

	const saveReferenceInfo = async (referenceInfo: ReferenceInfo) => {
		try {
			if (referenceInfo.id === null) {
				referenceInfo.id = 0;
			}
			const response = await agent.ReferenceInfos.create(referenceInfo);

			return response;
		} catch (error: any) {
			throw error.response ? error.response.statusText : error.message;
		}
	};

	return (
		<ReferenceInfoContext.Provider
			value={{
				referenceInfos,
				saveReferenceInfo,
				getCurrentVersionInfo,
				currentInfo,
			}}>
			{children}
		</ReferenceInfoContext.Provider>
	);
};

// The useStackData hook can be used by components under an StackDataProvider to
// access the auth context value.
const useReferenceInfo = () => {
	const referenceInfo = useContext(ReferenceInfoContext);
	if (referenceInfo == null) {
		throw new Error(
			"useReferenceInfo() called outside of a referenceInfo hook?"
		);
	}
	return referenceInfo;
};

export { ReferenceInfoProvider, useReferenceInfo };
