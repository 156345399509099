// import _ from "lodash";
import { CICESES_FETCH } from "../actions/cicesActions";

import { TreeType } from "../models/ecosystemService";

const cicesReducer = (state: TreeType[] = [], action: any) => {
  switch (action.type) {
    case CICESES_FETCH:
      return action.payload;
    default:
      return state;
  }
};

export default cicesReducer;
