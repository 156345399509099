import React, { useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";

export function myModalReducer(state: any, action: any) {
	switch (action.type) {
		case "close":
			return { open: false };
		case "open":
			return { open: true, size: action.size };
		default:
			throw new Error("Unsupported action...");
	}
}

const MyModal = ({
	title,
	body,
	opened,
	setOpened,
	setConfirmation,
	needsAction = true,
}: {
	title: string;
	body: string;
	opened: boolean;
	setOpened: Function;
	setConfirmation: Function;
	needsAction?: boolean;
}) => {
	const [state, dispatch] = React.useReducer(myModalReducer, {
		open: false,
		size: "small",
	});

	const { open, size } = state;

	useEffect(() => {
		return () => {
			if (setOpened) {
				setOpened(false);
			}
		};
	});

	useEffect(() => {
		if (opened) dispatch({ type: "open", size: "small" });
	}, [opened]);

	return (
		<>
			<Modal
				centered={false}
				size={size}
				open={open}
				onClose={() => dispatch({ type: "close" })}>
				<Modal.Header>{title}</Modal.Header>
				<Modal.Content>
					<p>{body}</p>
				</Modal.Content>
				{needsAction ? (
					<Modal.Actions>
						<Button
							negative
							onClick={() => {
								dispatch({ type: "close" });
								setOpened && setOpened(false);
								setConfirmation && setConfirmation(false);
							}}>
							No
						</Button>
						<Button
							positive
							onClick={() => {
								dispatch({ type: "close" });
								setOpened && setOpened(false);
								setConfirmation && setConfirmation(true);
							}}>
							Yes
						</Button>
					</Modal.Actions>
				) : null}
			</Modal>
		</>
	);
};

export default MyModal;
